import { Component, Input } from '@angular/core';
import { ProformsAdminService } from '../../../services/proforms/proforms-admin.service';
import { CreateProFormItemPayload } from '../../../types/payloads/proforms.payloads';

@Component({
  selector: 'app-proforms-add-form-item',
  standalone: false,

  templateUrl: './proforms-add-form-item.component.html',
  styleUrl: './proforms-add-form-item.component.scss',
})
export class ProformsAddFormItemComponent {
  @Input() id: number;

  @Input() sectionId: number;

  @Input() static = false;

  constructor(private proformsAdminService: ProformsAdminService) {}

  createQuestion() {
    const payload: CreateProFormItemPayload = {
      field_type: 'radio',
      question: 'Question',
      after_item_id: this.id,
    };
    return this.proformsAdminService
      .createQuestion(this.sectionId, payload)
      .subscribe();
  }
}
