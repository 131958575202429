import { Injectable } from '@angular/core';
import { debounceTime, map, Observable, of, Subject, switchMap } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { UserDtoApiResponse } from 'src/app/common/types/responses/responses';

@Injectable({
  providedIn: 'root',
})
export class UserSearchService {
  items: Observable<UserLiteDTO[]>;

  private term: Subject<string> = new Subject<string>();

  per_page = 20;

  page = 1;

  district_id = 0;

  sort_by = '';

  sort_by_desc = false;

  district_ids: number[] = [];

  user_cohort_tag = '';

  includeAllB2bDistricts = false;

  includeE2l = false;

  includeDistrictAdmins = false;

  constructor(private apiService: APICoreService) {
    this.items = this.term.pipe(
      debounceTime(250),

      switchMap((term: string) => {
        if (term) {
          return this.findUsers(
            term,
            this.includeAllB2bDistricts,
            this.district_ids
          );
        }
        return of([]);
      })
    );
  }

  search(
    term: string,
    district_ids: number[] = [],
    includeAllB2bDistricts = false,
    includeE2l = false,
    includeDistrictAdmins = false
  ) {
    this.district_ids = district_ids;
    this.includeAllB2bDistricts = includeAllB2bDistricts;
    this.includeE2l = includeE2l;
    this.includeDistrictAdmins = includeDistrictAdmins;

    /**
     * Set the value which the search stream will work.
     */
    return this.term.next(term);
  }

  public findUsers(
    search = '',
    include_all_b2b_districts = this.includeAllB2bDistricts,
    district_ids: number[] = [],
    user_cohort_tag = '',
    per_page = 20,
    page = 1,
    include_e2l = this.includeE2l,
    include_district_admins = this.includeDistrictAdmins
  ): Observable<UserLiteDTO[]> {
    const params = {
      search,
      district_ids,
      include_all_b2b_districts,
      user_cohort_tag,
      page,
      per_page,
      include_e2l,
      include_district_admins,
    };

    return this.apiService
      .getRequest('users/search', params)
      .pipe(map((response) => response.items));
  }

  public getUsersFull(
    search = '',
    district_ids: number[] = [],
    user_cohort_tag = '',
    per_page = 20,
    page = 1,
    sort_by = '',
    sort_by_desc = false
  ): Observable<UserDtoApiResponse> {
    const params = {
      search,
      page,
      per_page,
      sort_by,
      sort_by_desc,
      district_ids,
      user_cohort_tag,
    };

    return this.apiService.getRequest('users', params);
  }

  clear() {
    this.term.next('');
  }

  getUser(userId: number) {
    return this.apiService
      .getRequest(`users/${userId}`)
      .pipe(map((res) => res.item));
  }
}
