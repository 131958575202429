import { Component, Input } from '@angular/core';
import { CompetencyDTO } from '../../../dtos/attendee-rubric.dto';
import { SessionPermissions } from '../../../helpers/coachee-log.utilities';

@Component({
  selector: 'app-leveled-competency',
  templateUrl: './leveled-competency.component.html',
  styleUrls: ['./leveled-competency.component.scss'],
  standalone: false,
})
export class LeveledCompetencyComponent {
  @Input() competency: CompetencyDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() showOptions = true;

  @Input() canEdit = SessionPermissions.EDIT;

  @Input() viewingPrevSession = false;
}
