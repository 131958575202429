import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  EvidenceDTO,
  LevelDTO,
  OptionDTO,
  StrandDTO,
  WillDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import { SessionPermissions } from 'src/app/private/shared/helpers/coachee-log.utilities';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { SmartSessionService } from 'src/app/private/shared/services/smart-coach/smart-session.service';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { CreateOptionPayload } from 'src/app/private/shared/types/payloads/coaching-log.payloads';

@Component({
  selector: 'app-leveled-competency-strand-level',
  templateUrl: './leveled-competency-strand-level.component.html',
  styleUrls: ['./leveled-competency-strand-level.component.scss'],
  standalone: false,
})
export class LeveledCompetencyStrandLevelComponent implements OnInit {
  @Input() competencyId: number;

  @Input() competencyTitle: string;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() level: number;

  @Input() levelName: string;

  @Input() strand: StrandDTO;

  @Input() video: VideoDTO | null | undefined;

  @Input() showOptions = true;

  @Input() canEdit = SessionPermissions.EDIT;

  @Input() viewingPrevSession = false;

  strandData$ = select(CoachingLogState.getStrand);

  optionData$ = select(CoachingLogState.getOptions);

  evidenceData$ = select(CoachingLogState.getEvidence);

  will$ = select(CoachingLogState.getWillsByCoachlog);

  levelData: LevelDTO;

  optionsForChildComponents: OptionDTO[] = [];

  options: OptionDTO[] = [];

  optionsHaveWills = false;

  evidence: EvidenceDTO[] = [];

  wills: WillDTO[] = [];

  hasOptionContent = false;

  hasEvidenceContent = false;

  isDrawerCollapsed = true;

  isOptionCollapsed = true;

  isSmart = false;

  user: User;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  Permissions = SessionPermissions;

  giDisabled = false;

  videoPlaying = false;

  constructor(
    private store: Store,
    private coachingLogService: CoachingLogService,
    public smartlogService: SmartSessionService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.user = store.selectSnapshot(UserState.getUser) as User;
  }

  addOption() {
    if (this.sessionData && this.userId) {
      this.smartlogService.startAddOption(
        this.competencyId,
        this.strand.id,
        this.level,
        this.sessionData?.id,
        this.userId
      );
    }
  }

  createOption(): void {
    const options: CreateOptionPayload = {
      groweCoachlogId: this.logId,
      groweCompetencyId: this.competencyId,
      groweRubricId: this.rubricId,
      groweStrandId: this.strand.id,
      content: '',
      level: this.level,
    };
    this.coachingLogService.createOption(this.logId, options);
    // delay to prevent old last option drawer opening before new one
    setTimeout(() => {
      this.isOptionCollapsed = false;
    }, 500);
  }

  updateOptions() {
    this.optionsHaveWills = this.options.some(
      (option) =>
        this.wills.filter((will) => will.optionId === option.id).length > 0
    );
    if (this.options.length > 0) {
      this.hasOptionContent = true;
    } else {
      this.isOptionCollapsed = true;
      this.hasOptionContent = false;
    }
  }

  ngOnInit(): void {
    this.sessionData$
      .pipe(
        map((filterFn) =>
          (filterFn as (id: number) => CoachingSessionDTO)(this.logId)
        )
      )
      .pipe(
        map((sessionData) => {
          if (sessionData) {
            this.sessionData = sessionData;

            this.isSmart = this.sessionData?.type.isSmart;
          }
        })
      )
      .subscribe();

    this.optionData$
      .pipe(
        map((filterFn) =>
          (
            filterFn as (
              strandId: number,
              level: number,
              logId: number
            ) => OptionDTO[]
          )(this.strand.id, this.level, this.logId)
        )
      )
      .subscribe((newData) => {
        // Deletions
        const newIds = newData.map((item) => item.id);
        const optionsToDelete: number[] = [];

        this.optionsForChildComponents.forEach((item) => {
          if (!newIds.includes(item.id)) {
            optionsToDelete.push(item.id);
          }
        });
        optionsToDelete.forEach((optionId) => {
          const optionIndex = this.optionsForChildComponents.findIndex(
            (option) => option.id === optionId
          );
          this.optionsForChildComponents.splice(optionIndex, 1);
        });

        // Additions
        newData.forEach((newOption) => {
          if (
            !this.optionsForChildComponents.find(
              (option) => option.id === newOption.id
            )
          ) {
            this.optionsForChildComponents.push(newOption);
          }
        });
        this.options = newData;
        this.updateOptions();
      });
    this.strandData$
      .pipe(
        map((filterFn) =>
          (filterFn as (userId: number, strandId: number) => StrandDTO)(
            this.userId,
            this.strand.id
          )
        )
      )
      .subscribe((data) => {
        if (data)
          switch (this.level) {
            case 1:
              this.levelData = data.level1;
              break;
            case 2:
              this.levelData = data.level2;
              break;
            case 3:
              this.levelData = data.level3;
              break;
            default:
              this.levelData = data.level4;
              break;
          }
        this.disableGI();
        this.hasEvidenceContent = this.evidence.length > 0;
      });
    this.will$
      .pipe(
        map((filterFn) =>
          (filterFn as (userId: number, logId: number) => WillDTO[])(
            this.userId,
            this.logId
          )
        )
      )
      .subscribe((wills) => {
        this.wills = wills;
        this.updateOptions();
      });
    this.evidenceData$
      .pipe(
        map((filterFn) =>
          (
            filterFn as (
              userId: number,
              strandId: number,
              level: number,
              logId: number
            ) => EvidenceDTO[]
          )(this.userId, this.strand.id, this.level, this.logId)
        )
      )
      .subscribe((data) => {
        this.hasEvidenceContent = data.length > 0;
      });
  }

  startVideo() {
    this.videoPlaying = true;
    this.changeDetector.detectChanges();
  }

  stopVideo() {
    this.videoPlaying = false;
    this.changeDetector.detectChanges();
  }

  disableGI() {
    if (this.strand && this.levelData) {
      if (
        (this.levelData.levelCompleted &&
          this.strand.completedLevel > this.level) ||
        (!this.levelData.levelCompleted &&
          this.strand.completedLevel < this.level - 1)
      ) {
        this.giDisabled = true;
      } else {
        this.giDisabled = false;
      }
    } else {
      this.giDisabled = false;
    }
  }
}
