import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  checkIfDistrictApproved,
  checkIfE2lRecommended,
} from '../../services/resources/resource.helpers';
import { PusherResourceAPIResponseItem } from '../../types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-log-resource-list-item',
  templateUrl: './coaching-log-resource-list-item.component.html',
  styleUrl: './coaching-log-resource-list-item.component.scss',
  standalone: false,
})
export class CoachingLogResourceListItemComponent implements OnInit {
  @Input() resourceListItem: PusherResourceAPIResponseItem;

  @Input() userDistrictId: number | null;

  @Input() isLoading = false;

  @Output() readonly removeResourceEvent = new EventEmitter<number>();

  path: string;

  ngOnInit(): void {
    if (this.resourceListItem.slug) {
      this.path = this.resourceListItem.slug;
    } else if (this.resourceListItem.url) {
      const splitUrl = this.resourceListItem.url.split('/');
      this.path = splitUrl[splitUrl.length - 1];
    } else {
      this.path = '';
    }

    if (this.resourceListItem.endorsements && this.userDistrictId) {
      this.resourceListItem.e2lRecommended = checkIfE2lRecommended(
        this.resourceListItem.endorsements
      );
      this.resourceListItem.districtApproved = checkIfDistrictApproved(
        this.resourceListItem.endorsements,
        this.userDistrictId
      );
    }
  }

  removeResource(resourceId: number) {
    this.removeResourceEvent.emit(resourceId);
  }
}
