import { Pipe, PipeTransform } from '@angular/core';
import { SelectableCoach } from './selectable.coach';

@Pipe({
  name: 'filterTable',
  standalone: false,
})
export class FilterCoachTableSearch implements PipeTransform {
  // eslint-disable-next-line
  transform(tableValues: SelectableCoach[], searchTerm: string) {
    return tableValues.filter((coach: SelectableCoach) => {
      const term = searchTerm.toLowerCase();
      const fullName = `${coach.profile.first_name.toLowerCase()} ${coach.profile.last_name.toLowerCase()}`;

      return fullName.includes(term);
    });
  }
}
