import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { PdSettings } from '../../shared/dtos/pd.dto';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { PdService } from '../../shared/services/pd/pd.service';

@Component({
  selector: 'app-pd-admin-page',
  templateUrl: './pd-admin-page.component.html',
  styleUrl: './pd-admin-page.component.scss',
  standalone: false,
})
export class PdAdminPageComponent implements OnInit {
  @ViewChild('districtSelect') districtSelect: NgSelectComponent;

  districts: DistrictDTO[];

  selectedDistrict: DistrictDTO | undefined;

  districtScopeId: number;

  user: User;

  districtCode: string;

  settings: PdSettings;

  isE2L = false;

  addCategoryValue: string;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private store: Store,
    private pdService: PdService,
    private districtListService: DistrictListService
  ) {}

  ngOnInit(): void {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.districtListService
      .fetchDistrictsSimple({ per_page: 10000 })
      .subscribe((districts) => {
        if (districts) {
          this.districts = districts as DistrictDTO[];
          this.selectedDistrict = this.districts.find(
            (district) => district.districtCode === this.districtCode
          );
          if (this.selectedDistrict) {
            if (this.isE2L) {
              this.districtScopeId = 0;
            } else {
              this.districtScopeId = this.selectedDistrict.id;
            }
            this.getSettings();
          }
        }
      });
  }

  getSettings() {
    this.pdService.getAdminSettings(this.districtScopeId).subscribe((res) => {
      this.settings = res.item;
    });
  }

  addCategory() {
    if (this.addCategoryValue) {
      this.pdService
        .addCategory({
          district_id: this.districtScopeId,
          title: this.addCategoryValue,
        })
        .subscribe((res) => {
          if (res) {
            this.addCategoryValue = '';
            this.getSettings();
          }
        });
    }
  }
}
