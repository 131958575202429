<div class="container">
  <app-general-header title="mySmartCoach Sessions" />
  <div class="card border-0 p-3 min-vh-100">
    <ng-container *ngIf="coachLogStatus?.loaded; else loadingTemplate">
      <ng-container *ngIf="sessionData; else sessionErrorMessage">
        <h2 class="text-center mt-3 fw-800">{{ sessionData.title }}</h2>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <div class="col text-end">
            <button
              (click)="navigateNextScreen()"
              class="btn btn-primary btn-next">
              Let's Begin <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="container">
          <form>
            <div class="row">
              <div class="col p-3">
                <h3>Session SmartCoach</h3>
                <div class="d-flex flex-nowrap align-items-center my-3">
                  <app-avatar
                    [profile]="sessionData.user.profile"
                    [avatarSize]="'medium'">
                  </app-avatar>
                  <div class="username ms-2">
                    {{ sessionData.user.profile.first_name }}
                    {{ sessionData.user.profile.last_name }}
                  </div>
                </div>
                <h3 class="mt-4">Coachee</h3>
                <div class="d-flex flex-nowrap align-items-center my-3">
                  <app-avatar
                    [profile]="coachee.profile"
                    [avatarSize]="'medium'">
                  </app-avatar>
                  <div class="username ms-2">
                    {{ coachee.profile.first_name }}
                    {{ coachee.profile.last_name }}
                  </div>
                </div>
              </div>
              <div class="col p-3">
                <h3>Session Info</h3>
                <ng-container
                  *ngIf="this.user.id === this.coachee.id; else readOnly">
                  <label class="w-100 form-label" for="title">Title</label>
                  <input
                    class="form-control"
                    type="text"
                    id="title"
                    aria-label="Title"
                    appAutosave
                    [(ngModel)]="sessionData.title"
                    [ngModelOptions]="{ standalone: true }"
                    (autosave)="updateTitle()"
                    class="form-control" />
                </ng-container>
                <ng-template #readOnly>
                  <label class="form-label">Title</label>
                  <div class="mb-3">{{ sessionData.title }}</div>
                </ng-template>
                <label class="form-label">Type</label>
                <div class="mb-3">{{ sessionData.type.title }}</div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    (change)="onCheckboxChange($event)"
                    [(ngModel)]="sessionData.isPrivate"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    id="is-private" />
                  <label class="form-check-label" for="is-private">
                    Private Session
                    <div class="form-text">
                      Private sessions can only be viewed by coachee
                    </div></label
                  >
                </div>

                <label class="w-100 form-label">Date & Time</label>
                <app-datepicker
                  *ngIf="logPermissions === Permissions.EDIT; else dateViewer"
                  #datePicker
                  (dateSelectedUnixTimestamp)="updateDate($event)"
                  [initialDateUnixTimestamp]="
                    sessionData.startDatetime
                  "></app-datepicker>
                <ng-template #dateViewer>
                  <div class="mb-3">
                    {{ sessionData.startDatetime * 1000 | date : "medium" }}
                  </div>
                </ng-template>
              </div>
              <div class="col p-3">
                <ng-container *ngIf="messageFromYourCoachVideo">
                  <h3>Message from Your Coach</h3>
                  <app-video-player
                    [video]="messageFromYourCoachVideo"></app-video-player>
                </ng-container>
                <div
                  *ngIf="this.user.id === this.coachee.id"
                  class="d-flex justify-content-center justify-content-md-end">
                  <button
                    (click)="deleteSessionModal.open()"
                    aria-label="delete session button"
                    class="btn btn-secondary fs-14 fw-800 mt-5 mb-2"
                    type="button">
                    Delete Session <i class="bi bi-trash"></i>
                  </button>
                  <app-modal
                    #deleteSessionModal
                    [modalConfig]="{
                        titleText: 'Are you sure?',
                        headerClass: ['modal-header', 'bg-primary', 'text-white'],
                        customFooter: true,
                      }">
                    <button
                      class="btn btn-closemodal"
                      title="Close"
                      aria-label="close"
                      headerContent
                      (click)="deleteSessionModal.close()">
                      <i class="bi bi-x-lg text-white"></i>
                    </button>
                    <div class="container">
                      <div class="row">
                        <div
                          class="col-12 d-flex justify-content-center align-items-center">
                          <div class="my-3 fs-14">
                            <div class="fs-18 mb-2">
                              Do you want to permanently delete this session?
                            </div>
                            By confirming this action, any growth indicators and
                            earned badges from this session will be removed.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-100 d-flex justify-content-between"
                      footerContent>
                      <button
                        (click)="deleteSessionModal.close()"
                        class="btn btn-reset">
                        Cancel
                      </button>
                      <button
                        (click)="deleteSession(); deleteSessionModal.close()"
                        class="btn btn-primary">
                        Yes, delete session
                      </button>
                    </div>
                  </app-modal>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row d-flex flex-column align-items-center mt-3">
          <ng-container *ngIf="sessionData.attendees.length">
            <div class="row text-center header-row w-50">
              <h3 class="header-row-header">Last Session</h3>
            </div>
            <ng-container *ngIf="!competenciesLoading; else lastSessionLoader">
              <ng-container
                *ngIf="
                  lastSessionCompetencies[0]?.competencies &&
                    lastSessionCompetencies[0].competencies.length > 0;
                  else noCompetenciesFound
                ">
                <div class="row row-cols-lg-3 d-flex align-items-stretch">
                  <ng-container
                    *ngFor="let userCompetencyList of lastSessionCompetencies">
                    <div
                      *ngFor="
                        let competency of userCompetencyList.competencies
                      ">
                      <div class="col-lg mt-3">
                        <div
                          class="card cursor-pointer"
                          (click)="
                            toggleCardModal(
                              userCompetencyList.userId,
                              competency.id
                            )
                          ">
                          <app-competencies-summary
                            [isSelectable]="false"
                            [userId]="userCompetencyList.userId"
                            [competencyId]="
                              competency.id
                            "></app-competencies-summary>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #noCompetenciesFound>
                <div class="row my-5 d-flex">
                  <p
                    class="text-center fw-400 fs-4"
                    *ngIf="
                      this.lastSessionCompetencies[0]?.competencies &&
                        this.lastSessionCompetencies[0].competencies.length ===
                          0;
                      else noPreviousSession
                    ">
                    No Competencies from Previous Session
                  </p>
                  <ng-template #noPreviousSession>
                    <p class="text-center fw-400 fs-4">
                      No Previous Session Found
                    </p>
                  </ng-template>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #lastSessionLoader>
              <div class="last-session-loader-container">
                <app-loader />
              </div>
            </ng-template>
          </ng-container>
          <app-leveled-competency-modal
            [showOptions]="false"
            [viewingPrevSession]="true"
            [currentSessionId]="sessionId"
            #competencyModal />
        </div>

        <app-smart-coach-dialog
          #smartCoachDialog
          [coachProfile]="sessionData.user.profile"></app-smart-coach-dialog>
      </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
      <app-loader />
    </ng-template>
    <ng-template #sessionErrorMessage>
      <ng-container *ngIf="coachLogStatus && coachLogStatus.errors">
        <app-error-message [message]="coachLogStatus.errors[0]" />
      </ng-container>
    </ng-template>
  </div>
</div>
