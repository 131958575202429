import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  ReportApiResponse,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';

import { CURRENT_SCHOOL_YEAR } from '../../../services/school-year/school-year.utilities';
import { checkDefaultChartFilters } from '../report-controller/chart-options-helper';

@Component({
  selector: 'app-kpi-controller',
  styleUrls: ['./kpi-controller.component.scss'],
  templateUrl: './kpi-controller.component.html',
  standalone: false,
})
export class KpiControllerComponent implements OnChanges {
  @ViewChild('kpiValueElement') kpiValueElement: ElementRef<HTMLDivElement>;

  @Input() kpiTitle: string;

  @Input() textColor: string;

  @Input() districtId: number;

  @Input() schoolYearId: number;

  @Input() primaryRequestParams: ReportRequestParams;

  @Input() secondaryRequestParams: ReportRequestParams;

  @Output() readonly outputValue = new EventEmitter<string>();

  numerator?: number;

  denominator?: number;

  user: User | null = null;

  kpiValue: string;

  constructor(private apiService: APICoreService, private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnChanges() {
    delete this.numerator;
    delete this.denominator;
    if (this.kpiValueElement) {
      this.kpiValueElement.nativeElement.classList.add('fadeOut');
    }
    this.getData(this.primaryRequestParams).subscribe(
      (value: ReportApiResponse) => {
        this.numerator = (value.datasets[0].data as number[])[0] || 0;
        this.calculateFinalValue();
      }
    );
    this.getData(this.secondaryRequestParams).subscribe(
      (value: ReportApiResponse) => {
        this.denominator = (value.datasets[0].data as number[])[0] || 0;
        this.calculateFinalValue();
      }
    );
  }

  calculateFinalValue() {
    if (
      (this.denominator === 0 || this.denominator) &&
      (this.numerator === 0 || this.numerator)
    ) {
      if (this.denominator === 0) {
        this.kpiValue = '-';
      } else {
        this.kpiValue = (
          Math.round((this.numerator / this.denominator) * 100) / 100
        ).toFixed(1);
      }
      this.outputValue.next(this.kpiValue);
      this.kpiValueElement.nativeElement.classList.remove('fadeOut');
    }
  }

  getData(paramEntered: ReportRequestParams): Observable<ReportApiResponse> {
    if (this.user?.district) {
      if (!this.districtId) {
        this.districtId = this.user.district.id;
      }
    }
    if (!this.schoolYearId) {
      /* skipcq: JS-0317 */
      this.schoolYearId = parseInt(CURRENT_SCHOOL_YEAR.toString(), 10);
    }

    paramEntered = checkDefaultChartFilters(paramEntered, this.user as User);

    return this.apiService.getRequest('reporting/custom', paramEntered);
  }
}
