import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import { Field } from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-view-media',
  templateUrl: './view-media.component.html',
  standalone: false,
})
export class FormViewMediaComponent implements OnInit {
  id = uuidv4();

  @Output() readonly value = new EventEmitter<FileDTO[]>();

  @Input() fieldDefinition: Field;

  @Input() initialValue: FileDTO[] | undefined;

  @Input() inputDisabled = false;

  @Output() readonly fileUploads = new EventEmitter<FileDTO>();

  constructor(private formService: FormsService) {}

  handleFileUpload(file: FileDTO) {
    this.fileUploads.emit(file);
    this.updateFileList();
  }

  filesArray: FileDTO[] = [];

  ngOnInit(): void {
    if (this.initialValue) {
      this.filesArray = this.initialValue;
    }
  }

  handleDelete(file: FileDTO) {
    this.filesArray = this.filesArray.filter((f) => f.id !== file.id);
    this.updateFileList();
  }

  updateFileList() {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.formService.scrollPosition = (window as any).scrollY;
    this.value.emit(this.filesArray);
    this.formService.promptSave.next(true);
  }
}
