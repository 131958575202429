import { Component, Input, ViewChild } from '@angular/core';

import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { VideoModalComponent } from '../modals/video-modal/video-modal.component';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
  standalone: false,
})
export class VideoCardComponent {
  @ViewChild('videoModal')
  videoModal: VideoModalComponent;

  @Input() video: VideoDTO;

  openVideoModal() {
    this.videoModal.open();
  }
}
