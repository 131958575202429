import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  SmartRubricAPIResponse,
  SmartStandardGroupAPIResponse,
} from 'src/app/common/types/responses/responses';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';
import { StandardAPIResponse } from '../../types/responses/coaching-log.responses';

@Component({
  selector: 'app-intro-competencies',
  templateUrl: './intro-competencies.component.html',
  styleUrls: ['./intro-competencies.component.scss'],
  standalone: false,
})
export class IntroCompetenciesComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  user$: Observable<User | null> = select(UserState.getUser);

  user: User | null = null;

  rubrics: SmartRubricAPIResponse[];

  competenciesList: StandardAPIResponse[] = [];

  initialStrongest: string;

  selectedStrongestId: number;

  initialWeakest: string;

  selectedWeakestId: number;

  bpComplete = false;

  limitCompetencies = true;

  limit = 4;

  selectedSmartVideo: VideoDTO | null;

  constructor(
    public introSessionService: IntroSessionService,
    private userService: UserService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  setUserValues() {
    if (this.user?.profile.strongest_egrowe_standard_why) {
      this.initialStrongest = this.user?.profile.strongest_egrowe_standard_why;
    } else {
      this.initialStrongest = '';
    }
    if (this.user?.profile.strongest_egrowe_standard_id) {
      this.selectedStrongestId =
        this.user?.profile.strongest_egrowe_standard_id;
    }
    if (this.user?.profile.weakest_egrowe_standard_why) {
      this.initialWeakest = this.user?.profile.weakest_egrowe_standard_why;
    } else {
      this.initialWeakest = '';
    }

    if (this.user?.profile.weakest_egrowe_standard_id) {
      this.selectedWeakestId = this.user?.profile.weakest_egrowe_standard_id;
    }
    this.checkComplete();
  }

  ngOnInit() {
    this.setUserValues();

    this.introSessionService.getSmartRubrics().subscribe((res) => {
      if (res.items) {
        this.rubrics = res.items;
        if (this.rubrics) {
          this.getCompetenciesList();
        }
      }
    });

    this.subs.push(
      this.introSessionService.bpComplete.subscribe((bool) => {
        this.bpComplete = bool;
      })
    );
  }

  getCompetenciesList() {
    this.rubrics.forEach((rubric) => {
      rubric.standardSet.standardGroups.forEach(
        (standardGroup: SmartStandardGroupAPIResponse) => {
          standardGroup.standards.forEach((standard: StandardAPIResponse) => {
            this.competenciesList.push(standard);
          });
        }
      );
    });
  }

  completeCompetencies() {
    this.introSessionService.setBpComplete(true, true);
  }

  updateWeakestId() {
    if (this.user) {
      const payload = {
        profile: {
          weakest_egrowe_standard_id: this.selectedWeakestId,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        if (this.user) {
          this.user.profile.weakest_egrowe_standard_id = this.selectedWeakestId;
          this.checkComplete();
        }
      });
    }
  }

  updateStrongestId() {
    if (this.user) {
      const payload = {
        profile: {
          strongest_egrowe_standard_id: this.selectedStrongestId,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        if (this.user) {
          this.user.profile.strongest_egrowe_standard_id =
            this.selectedStrongestId;
          this.checkComplete();
        }
      });
    }
  }

  saveUserProfileStrongestWhy() {
    if (this.initialStrongest == null || this.initialStrongest.length < 1) {
      return;
    }
    if (this.user) {
      this.user.profile.strongest_egrowe_standard_why = this.initialStrongest;
      const payload = {
        profile: {
          strongest_egrowe_standard_why:
            this.user.profile.strongest_egrowe_standard_why,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        this.checkComplete();
      });
    }
  }

  saveUserProfileWeakestWhy() {
    if (this.initialWeakest == null || this.initialWeakest.length < 1) {
      return;
    }
    if (this.user) {
      this.user.profile.weakest_egrowe_standard_why = this.initialWeakest;
      const payload = {
        profile: {
          weakest_egrowe_standard_why:
            this.user.profile.weakest_egrowe_standard_why,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        this.checkComplete();
      });
    }
  }

  checkComplete() {
    if (!this.bpComplete) {
      if (
        this.user?.profile.strongest_egrowe_standard_why &&
        this.user?.profile.strongest_egrowe_standard_id &&
        this.user?.profile.weakest_egrowe_standard_why &&
        this.user?.profile.weakest_egrowe_standard_id
      ) {
        this.completeCompetencies();
      }
    }
  }

  toggleLimit() {
    if (this.limitCompetencies) {
      this.limit = 100;
    } else {
      this.limit = 4;
    }
    this.limitCompetencies = !this.limitCompetencies;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
}
