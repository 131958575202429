import { Component, Input, OnInit } from '@angular/core';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';

import { abbreviatedMonthNames } from '../../../dtos/reports.dto';
import { ChartType } from '../../../enums/chart-type.enum';
import { ChartGrid } from '../../reports/report-controller/chart-options-helper';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss'],
  standalone: false,
})
export class CardReportComponent implements OnInit {
  @Input() cardTitle = 'At a Glance Report';

  @Input() cardHeight: string;

  @Input() reportTitle = '';

  @Input() drillDownTitle = '';

  @Input() chartType: ChartType = ChartType.Bar;

  @Input() yAxisLabel = '';

  @Input() xAxisLabel = '';

  @Input() requestParams: ReportRequestParams;

  @Input() percentage: boolean;

  @Input() xNameGap = 30;

  @Input() yNameGap = 25;

  @Input() gridDimensions: ChartGrid = {
    top: '10px',
    left: '23px',
    right: '15px',
    bottom: '25px',
  };

  @Input() chartColor: string;

  drillDownParams: ReportRequestParams;

  isCollapsed: boolean;

  currentError = '';

  constructor(private cardStorageService: CardStorageService) {}

  ngOnInit() {
    this.isCollapsed = this.cardStorageService.getCollapsedState(
      this.cardTitle.replace(/\s/g, '').toLowerCase()
    );
  }

  resetError() {
    this.currentError = '';
  }

  handleError(errorMessage: string) {
    this.currentError = errorMessage;
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.cardTitle.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }

  // Each controller needs to handle an event emitter to use drill-downs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickEmit(testEvent: any) {
    // Drill-down data is linked through groupIds
    const foundMonth = abbreviatedMonthNames.find(
      (monthObject) => monthObject.monthString === testEvent.data.groupId
    );

    if (foundMonth) {
      // drill-down will add filter from original request params but save the original for later
      this.drillDownParams = JSON.parse(JSON.stringify(this.requestParams));

      const queryIndex = this.drillDownParams.filters.findIndex(
        (object) => object.codename === 'month_of_school_year'
      );

      if (queryIndex !== -1) {
        this.drillDownParams.filters[queryIndex].value = [
          foundMonth.monthNumber.toString(),
        ];
      } else {
        this.drillDownParams.filters.push({
          codename: 'month_of_school_year',
          value: [foundMonth.monthNumber.toString()],
        });
      }

      // Dimmension is augmented to give new scale
      this.drillDownParams.dimension = 'session_date';

      this.drillDownTitle = foundMonth.monthString.toString();
    }
  }
}
