import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PdCreditOption } from '../../../dtos/pd.dto';
import { PdService } from '../../../services/pd/pd.service';

@Component({
  selector: 'app-pd-settings-credit',
  templateUrl: './pd-settings-credit.component.html',
  styleUrl: './pd-settings-credit.component.scss',
  standalone: false,
})
export class PdSettingsCreditComponent {
  @Input() credits: PdCreditOption[];

  @Input() districtId: number;

  @Output() readonly creditUpdated = new EventEmitter();

  @ViewChild('addCreditInput') addCreditInput: ElementRef;

  addCreditValue: string;

  addCreditMode = false;

  constructor(private pdService: PdService) {}

  addCredit() {
    this.pdService
      .addCredit({ district_id: this.districtId, title: this.addCreditValue })
      .subscribe((res) => {
        if (res) {
          this.creditUpdated.emit();
          this.addCreditMode = false;
          this.addCreditValue = '';
        }
      });
  }

  deleteCredit(creditId: number) {
    this.pdService
      .deleteCredit({ district_id: this.districtId, creditId })
      .subscribe((res) => {
        if (res) this.creditUpdated.emit();
      });
  }

  turnOnAddOptionMode() {
    this.addCreditMode = true;
    setTimeout(() => {
      this.addCreditInput.nativeElement.focus();
    });
  }
}
