import { Component, Input, OnInit } from '@angular/core';

import { AbstractViewFieldComponent } from '../base/abstract-view-field';

@Component({
  selector: 'app-form-view-text',
  templateUrl: './view-text.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
  standalone: false,
})
export class FormViewTextComponent
  extends AbstractViewFieldComponent
  implements OnInit
{
  userInput: string;

  @Input() initialValue: string | undefined;

  ngOnInit(): void {
    if (this.initialValue) {
      this.userInput = this.initialValue;
    }
  }
}
