/* eslint-disable class-methods-use-this */
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { DomElement } from 'src/app/common/utilities/dom-element';
import { FileDTO } from '../../../dtos/file.dto';
import { FileIcon } from '../../../enums/file-icons.enum';
import { UploadType } from '../../../enums/upload-type.enum';
import { FileServiceFactory } from '../../../services/file/file-service.factory';
import { FileService } from '../../../services/file/file.service';
import { VideoFileService } from '../../../services/file/video.file.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  standalone: false,
})
export class FileListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() uploadType: UploadType = UploadType.USER_UPLOAD;

  @Input() fileList: FileDTO[];

  @Input() uploadList: Observable<FileDTO>;

  @Input() logId: number;

  @Output() readonly fileCount = new BehaviorSubject<number>(0);

  @Input() canDelete = true;

  @Input() skipApiCallOnDelete = false;

  @Output() readonly deletedFile = new Subject<FileDTO>();

  downloadUrl: string;

  fileService: FileService;

  public internalList: FileDTO[] = [];

  sub: Subscription;

  documentIcons = [FileIcon.doc, FileIcon.ppt, FileIcon.sheet];

  constructor(public injector: Injector) {}

  viewFile(file: FileDTO) {
    window.open(`${EnvironmentService.apiUrl()}${file.location}`);
  }

  downloadFile(file: FileDTO) {
    this.fileService.downloadFile(file).subscribe((blob) => {
      if (!this.downloadUrl) {
        this.downloadUrl = window.URL.createObjectURL(blob);
      }
      const link = DomElement.create(
        `a[href="${this.downloadUrl}"][download="${file.name}"]`
      );
      link.click();
      link.remove();
    });
  }

  ngOnInit() {
    this.fileService = this.injector.get(FileServiceFactory[this.uploadType]);
    if (this.fileList) {
      this.internalList = this.fileList;
      this.fileCount.next(this.internalList.length + 1);
    }
    if (this.uploadList) {
      this.sub = this.uploadList.subscribe((file) => {
        this.internalList.push(file);
        this.fileCount.next(this.internalList.length + 1);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileList']) {
      if (
        changes['fileList'].currentValue !== changes['fileList'].previousValue
      ) {
        this.internalList = this.fileList;
        this.fileCount.next(this.internalList.length + 1);
      }
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  fileListUpdate() {
    this.internalList = this.fileList;
    this.fileCount.next(this.internalList.length + 1);
    if (this.uploadList) {
      this.sub = this.uploadList.subscribe((file) => {
        this.internalList.push(file);
        this.fileCount.next(this.internalList.length + 1);
      });
    }
  }

  deleteFile(file: FileDTO) {
    if (this.skipApiCallOnDelete) {
      this.internalList = this.internalList.filter((f) => f.id !== file.id);
      this.fileCount.next(this.internalList.length + 1);
      this.deletedFile.next(file);
      return;
    }

    if (this.uploadType === UploadType.VIDEO) {
      const sub = (this.fileService as VideoFileService)
        .unattachVideo(file.id)
        .subscribe(() => {
          this.internalList = this.internalList.filter((f) => f.id !== file.id);
          this.fileCount.next(this.internalList.length + 1);
          this.deletedFile.next(file);
          sub.unsubscribe();
        });
    } else {
      const sub = this.fileService.deleteFile(file).subscribe(() => {
        this.internalList = this.internalList.filter((f) => f.id !== file.id);
        this.fileCount.next(this.internalList.length + 1);
        this.deletedFile.next(file);
        sub.unsubscribe();
      });
    }
  }
}
