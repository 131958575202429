import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  ErrorHandler,
  Input,
  ViewChild,
} from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { catchError, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { RosterUploadService } from '../../services/rosters/roster-upload.service';

interface UploadingItemDTO {
  id: string;
  name: string;
  progress: number;
}

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss'],
  standalone: false,
})
export class CsvUploadComponent {
  @Input() districtId: number;

  @Input() type: string;

  isUploading = false;

  dropzoneActive = false;

  uploadingItems: { [id: string]: UploadingItemDTO } = {};

  errorMsg = '';

  successMsg = '';

  @ViewChild('fileUpload') public fileUpload: ElementRef<HTMLInputElement>;

  @ViewChild('successAlert', { static: false }) successAlert: NgbAlert;

  constructor(
    private rosterUploadService: RosterUploadService,
    private errorHandlerService: ErrorHandler
  ) {}

  toggleDropZone(status: boolean) {
    this.dropzoneActive = status;
  }

  onUploadButtonClick() {
    this.closeErrorMsg();
    this.closeSuccessMsg();
    this.fileUpload.nativeElement.click();
  }

  handleDrop(event: DragEvent) {
    this.closeErrorMsg();
    this.toggleDropZone(false);
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.uploadFile(event.dataTransfer.files[0]);
    }
  }

  closeSuccessMsg() {
    this.successMsg = '';
  }

  closeErrorMsg() {
    this.errorMsg = '';
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      Array.from(input.files).forEach((file: File) => {
        this.uploadFile(file);
      });
      this.fileUpload.nativeElement.value = '';
    }
  }

  setUploadingStatus(status: boolean) {
    this.isUploading = status;
    if (status) {
      this.fileUpload.nativeElement.setAttribute('disabled', 'disabled');
    } else {
      this.fileUpload.nativeElement.removeAttribute('disabled');
    }
  }

  uploadFile(file: File) {
    this.setUploadingStatus(true);

    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);

    if (ext === 'csv') {
      const id = uuid();
      const uploadingItem: UploadingItemDTO = {
        id,
        name: file.name,
        progress: 0,
      };
      this.uploadingItems[id] = uploadingItem;
      const sub = this.rosterUploadService
        .uploadCsvFile(file, this.districtId, this.type)
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          catchError((err: any) => {
            sub.unsubscribe();
            this.errorMsg =
              'There was an issue uploading your file. Make sure you are uploading a .CSV file type.';
            this.errorHandlerService.handleError(this.errorMsg);
            this.setUploadingStatus(false);
            sub.unsubscribe();
            delete this.uploadingItems[id];
            return of({ type: 'error', message: err });
          })
        )
        .subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            uploadingItem.progress = Math.round(
              100 * (event.loaded / event.total)
            );
          }
          if (event.type === HttpEventType.Response) {
            this.successMsg = 'File uploaded';
            setTimeout(() => {
              this.closeSuccessMsg();
            }, 5000);
            delete this.uploadingItems[id];
            if (Object.keys(this.uploadingItems).length === 0) {
              this.setUploadingStatus(false);
            }
            this.setUploadingStatus(false);
            sub.unsubscribe();
          }
        });
    } else {
      setTimeout(() => {
        this.errorMsg =
          'There was an issue uploading your file. Make sure you are uploading a .CSV file type.';
        this.errorHandlerService.handleError(this.errorMsg);
        this.setUploadingStatus(false);
      }, 1000);
    }
  }
}
