import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { TopicBuilderService } from 'src/app/private/shared/services/topic-builder/topic-builder.service';
import { CompetencyBuilderIndicatorGroupDTO } from 'src/app/private/shared/types/responses/competency-builder.responses';

@Component({
  selector: 'app-builder-leveled-competency-strand',
  templateUrl: './builder-leveled-competency-strand.component.html',
  styleUrls: ['./builder-leveled-competency-strand.component.scss'],
  standalone: false,
})
export class BuilderLeveledCompetencyStrandComponent
  implements OnInit, OnDestroy
{
  @Input() strand: CompetencyBuilderIndicatorGroupDTO;

  @Input() level1name: string;

  @Input() level2name: string;

  @Input() level3name: string;

  @Input() level4name: string;

  @Input() apiLoading: boolean;

  @Output() readonly deleteStrandEvent = new EventEmitter<number>();

  @Output() readonly badgeGraphicUploadedEvent = new EventEmitter();

  @Output() readonly updatingEvent = new EventEmitter<string>();

  subs: Subscription[] = [];

  public isCollapsed = false;

  deleteStrandLoading = false;

  currentStrand = false;

  private titleInputSubject = new Subject<string>();

  constructor(private topicBuilderService: TopicBuilderService) {}

  ngOnInit(): void {
    if (this.strand) {
      this.strand.strandBadges.sort((a, b) => a.level - b.level);
    }

    this.subs.push(
      this.topicBuilderService.deleteStrandCompleted.subscribe(() => {
        this.deleteStrandLoading = false;
      })
    );

    this.subs.push(
      this.titleInputSubject.pipe(debounceTime(350)).subscribe(() => {
        this.updateTitle();
      })
    );
  }

  updateTitle() {
    if (this.strand) {
      this.currentStrand = true;
      this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
      this.topicBuilderService
        .updateIndicatorGroup(this.strand.id, { title: this.strand.title })
        .subscribe((res) => {
          if (res) {
            this.currentStrand = false;
            this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
          }
        });
    }
  }

  onTitleInput() {
    this.titleInputSubject.next(this.strand.title);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
