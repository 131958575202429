import { Component, Input } from '@angular/core';
import { PhasesProgressAPIResponse } from '../../types/responses/plan.responses';

@Component({
  selector: 'app-plan-progress-bar',
  templateUrl: './plan-progress-bar.component.html',
  styleUrl: './plan-progress-bar.component.scss',
  standalone: false,
})
export class PlanProgressBarComponent {
  @Input() progress: PhasesProgressAPIResponse;
}
