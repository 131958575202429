import { Component, OnInit } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { CompetencyListItemDTO } from '../../../dtos/competencies.dto';
import { CompetenciesSummaryService } from '../../../services/competencies/competencies-summary.service';

@Component({
  selector: 'app-card-current-competencies',
  templateUrl: './card-current-competencies.component.html',
  styleUrls: ['./card-current-competencies.component.scss'],
  standalone: false,
})
export class CardCurrentCompetenciesComponent implements OnInit {
  isCollapsed = this.cardStorageService.getCollapsedState(
    'currentcompetencies'
  );

  isLoaded: boolean;

  noData: boolean;

  competenciesList: CompetencyListItemDTO[];

  constructor(
    private competenciesSummaryService: CompetenciesSummaryService,
    private cardStorageService: CardStorageService
  ) {}

  ngOnInit(): void {
    this.getCompetencies();
  }

  getCompetencies() {
    this.isLoaded = false;
    this.competenciesSummaryService
      .getCurrentCompetencies()
      .subscribe((response) => {
        this.competenciesList = response.items.competencies;
        if (this.competenciesList && this.competenciesList.length === 0) {
          this.noData = true;
        }
        this.isLoaded = true;
      });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'currentcompetencies',
      this.isCollapsed
    );
  }
}
