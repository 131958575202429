import { Component, Input, OnInit } from '@angular/core';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { PlanYearlyProgressDTO } from '../../../dtos/plans.dto';
import { getSchoolYearListWithFutures } from '../../../services/school-year/school-year.utilities';
import { PhaseAPIResponse } from '../../../types/responses/plan.responses';

@Component({
  selector: 'app-plans-yearly-progress',
  templateUrl: './plans-yearly-progress.component.html',
  styleUrl: './plans-yearly-progress.component.scss',
  standalone: false,
})
export class PlansYearlyProgressComponent implements OnInit {
  @Input() phase: PhaseAPIResponse;

  yearlyProgress: PlanYearlyProgressDTO = {
    schoolYears: [],
    totalDeliverables: 0,
  };

  markers: { position: number; year: string }[] = [];

  schoolYearList: SchoolYearDTO[];

  ngOnInit(): void {
    // pass in however many future years you need
    this.schoolYearList = getSchoolYearListWithFutures(5);
    this.calculateMarkers();
    this.setMarkers();
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatProgress(progress: number) {
    return Math.round(progress * 100000) / 1000;
  }

  calculateMarkers() {
    if (this.phase) {
      this.phase.deliverables.forEach((deliverable) => {
        this.yearlyProgress.totalDeliverables += 1;
        const deliverableDueDate = new Date(deliverable.due_date).getTime();
        this.schoolYearList.forEach((schoolYear) => {
          const startDate = new Date(schoolYear.start_date).getTime();
          const endDate = new Date(schoolYear.end_date).getTime();
          if (
            deliverableDueDate >= startDate &&
            deliverableDueDate <= endDate
          ) {
            const currentIndex = this.yearlyProgress.schoolYears.findIndex(
              (year) => year.schoolYear === schoolYear.name
            );
            if (currentIndex > -1) {
              this.yearlyProgress.schoolYears[
                currentIndex
              ].deliverableCount += 1;
            } else {
              this.yearlyProgress.schoolYears.push({
                schoolYear: schoolYear.name,
                deliverableCount: 1,
              });
            }
          }
        });
      });
    }
  }

  setMarkers() {
    if (this.yearlyProgress && this.yearlyProgress.schoolYears.length > 1) {
      this.yearlyProgress.schoolYears.sort(
        (a, b) =>
          parseInt(a.schoolYear.slice(-2)) - parseInt(b.schoolYear.slice(-2))
      );
      this.yearlyProgress.schoolYears.forEach((year) => {
        this.markers.push({
          position:
            Math.floor(
              (year.deliverableCount / this.yearlyProgress.totalDeliverables) *
                10000
            ) / 100,
          year: `${year.schoolYear.substring(2, 4)}-${year.schoolYear.substring(
            7,
            9
          )}`,
        });
      });
    }
  }
}
