import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { SmartSuggestionAPIResponse } from '../../../dtos/smart-suggestions.dto';
import { SmartSuggestionService } from '../../../services/smart-suggestions/smart-suggestions.service';

@Component({
  selector: 'app-card-smart-suggestions',
  templateUrl: './card-smart-suggestions.component.html',
  styleUrls: ['./card-smart-suggestions.component.scss'],
  standalone: false,
  animations: [
    trigger('fadeOut', [
      transition(':leave', [animate('250ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CardSmartSuggestionsComponent implements OnInit {
  @Output() readonly customActionClicked = new EventEmitter<string>();

  isCollapsed = this.cardStorageService.getCollapsedState('smartsuggestions');

  isLoading = true;

  smartSuggestions: SmartSuggestionAPIResponse[];

  constructor(
    private cardStorageService: CardStorageService,
    private smartSuggestionService: SmartSuggestionService
  ) {}

  ngOnInit(): void {
    this.getSmartSuggestions();
  }

  // eslint-disable-next-line class-methods-use-this
  startsWithHash(str: string): boolean {
    return str.startsWith('#');
  }

  outputCustomAction(action: string) {
    this.customActionClicked.emit(action);
  }

  getSmartSuggestions() {
    this.smartSuggestionService.getSmartSuggestions().subscribe((res) => {
      if (res) {
        this.smartSuggestions = res.items;
      }
      this.isLoading = false;
    });
  }

  dismissSuggestion(suggestionId: number) {
    this.smartSuggestionService
      .dismissSuggestion(suggestionId)
      .subscribe(() => {
        this.smartSuggestions = this.smartSuggestions.filter(
          (suggestion) => suggestion.id !== suggestionId
        );
      });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'smartsuggestions',
      this.isCollapsed
    );
  }
}
