import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { EvidenceService } from '../../services/evidence/evidence.service';
import { CommentModelDTO } from './evidence-comments.dto';

@Component({
  selector: 'app-evidence-comments',
  templateUrl: './evidence-comments.component.html',
  styleUrls: ['./evidence-comments.component.scss'],
  standalone: false,
})
export class EvidenceCommentsComponent {
  refTable = 'egrowe_evidence';

  newComment: CommentModelDTO = new CommentModelDTO();

  currentUser: User;

  @Input() refTableId: number;

  @Input() commentList: CommentModelDTO[] = [];

  constructor(private evidenceService: EvidenceService, private store: Store) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
  }

  saveComment() {
    this.newComment.ref_table = this.refTable;
    this.newComment.ref_table_id = this.refTableId;
    this.evidenceService.saveComment(this.newComment).subscribe((result) => {
      if (result.item) {
        this.commentList.push(result.item);
        this.newComment.comment = '';
      }
    });
  }

  deleteComment(comment: CommentModelDTO) {
    this.evidenceService.deleteComment(comment).subscribe(() => {
      this.commentList = this.commentList.filter(
        (item) => item.id !== comment.id
      );
    });
  }
}
