import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  Form,
  FormSubmissionStatus,
  ObservationFormSubmission,
} from 'src/app/private/shared/dtos/forms.dto';
import { FormModalDisplay } from 'src/app/private/shared/enums/forms.enum';

import { FormsService } from '../../../../services/forms/forms.service';
import { ModalComponent } from '../../../modals/modal/modal.component';

@Component({
  selector: 'app-form-submission-modal',
  templateUrl: './form-submission-modal.component.html',
  standalone: false,
})
export class FormSubmissionModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal: ModalComponent;

  @Input() districtId: number;

  @Input() formId: number;

  formData: Form;

  display: FormModalDisplay = FormModalDisplay.SUBMISSION;

  submissionStatuses = FormSubmissionStatus;

  submissionData: ObservationFormSubmission[] = [];

  submissionLibrary: [
    { items: ObservationFormSubmission[]; pagination: PaginationDTO }
  ];

  visibleSubmissionIdx = 0;

  visibleSubmission: ObservationFormSubmission | undefined;

  modalDisplays = FormModalDisplay;

  isLoading: boolean;

  totalPages = 0;

  currentPage = 1;

  totalSubmissions = 0;

  loaded = 0;

  constructor(private formsService: FormsService, private router: Router) {}

  ngOnInit() {
    this.loaded = 0;
    if (this.formId) {
      this.isLoading = true;
      this.formsService.getForm(this.formId).subscribe((form) => {
        if (form) {
          this.formData = form;
        }
        this.checkIfLoaded();
      });
      this.loadSubmissions(0);
    }
  }

  loadSubmissions(showSubmission?: number) {
    this.formsService
      .getFormSubmissionsByDistrict(
        this.formId,
        this.districtId,
        this.currentPage
      )
      .subscribe(({ items, pagination }) => {
        if (pagination.totalCount !== this.totalSubmissions) {
          this.submissionLibrary = [{ items, pagination }];
          this.totalSubmissions = pagination.totalCount;
          this.totalPages = pagination.pageCount;
        } else {
          this.submissionLibrary.push({ items, pagination });
        }
        this.submissionData = items;
        this.checkIfLoaded();
        if (showSubmission !== undefined) {
          this.showSubmission(this.submissionData[showSubmission]);
        }
      });
  }

  pageChange(newPage: number) {
    this.isLoading = true;
    this.loaded = 1;
    this.currentPage = newPage;
    this.loadSubmissions();
  }

  goToSubmission(direction: 'next' | 'previous') {
    this.isLoading = true;
    if (direction === 'next') {
      this.visibleSubmissionIdx += 1;
      if (this.visibleSubmissionIdx % 10 === 0) {
        this.currentPage += 1;
      }
    } else {
      this.visibleSubmissionIdx -= 1;
      if (this.visibleSubmissionIdx % 10 === 9) {
        this.currentPage -= 1;
      }
    }
    const submissionToDisplay = this.visibleSubmissionIdx % 10;
    const pageData = this.submissionLibrary.find(
      (page) => page.pagination.currentPage === this.currentPage
    );
    if (pageData) {
      this.submissionData = pageData.items;
      setTimeout(() => {
        this.isLoading = false;
        this.showSubmission(this.submissionData[submissionToDisplay]);
      }, 0);
    } else {
      this.loaded = 1;
      this.loadSubmissions(submissionToDisplay);
    }
  }

  showSubmission(submission: ObservationFormSubmission) {
    this.display = FormModalDisplay.SUBMISSION;
    this.visibleSubmission = submission;
    this.visibleSubmissionIdx =
      (this.currentPage - 1) * 10 + this.submissionData.indexOf(submission);
  }

  showCoachingLog(submission?: ObservationFormSubmission) {
    /* eslint-disable-next-line no-nested-ternary */
    const logId: number | null = submission
      ? submission.coachingLogId
      : this.visibleSubmission
      ? this.visibleSubmission.coachingLogId
      : null;
    if (logId) {
      window.open(`/observation/log/${logId}`, '_blank');
    }
  }

  checkIfLoaded() {
    if (this.loaded === 1) {
      this.isLoading = false;
      this.loaded = 0;
    } else {
      this.loaded = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.modal) {
      this.modal.close();
    }
  }
}
