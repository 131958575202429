import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { getAbbrCurrentTimezoneString } from 'src/app/common/utilities/time-helpers';
import { PdSession } from '../../../dtos/pd.dto';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-pd-view-details-modal',
  templateUrl: './pd-view-details-modal.component.html',
  styleUrls: ['./pd-view-details-modal.component.scss'],
  standalone: false,
})
export class PDViewDetailsModalComponent implements OnChanges {
  @Input() session: PdSession;

  @ViewChild('modal') modal: ModalComponent;

  abbrTimezone: string;

  ngOnChanges() {
    this.abbrTimezone = getAbbrCurrentTimezoneString();
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }

  // eslint-disable-next-line class-methods-use-this
  getSessionLink(session: PdSession) {
    return {
      path: `/training/session/${session.id}`,
    };
  }
}
