import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { CelebrationDTO } from '../../dtos/celebrations.dto';

@Component({
  selector: 'app-celebration-item',
  templateUrl: './celebration-item.component.html',
  styleUrls: ['./celebration-item.component.scss'],
  standalone: false,
})
export class CelebrationItemComponent implements OnInit {
  @Input() celebration: CelebrationDTO;

  @Input() user: User | null;

  @Output() readonly deleteCelebrationEvent = new EventEmitter<number>();

  @Output() readonly editCelebrationEvent = new EventEmitter<CelebrationDTO>();

  public API_URL = EnvironmentService.apiUrl();

  images: SafeUrl[] = [];

  canEdit = false;

  canDelete = false;

  canShare = false;

  schools: number[] = [];

  districts: number[] = [];

  carouselInterval = 0;

  carouselAnimation = false;

  showNavigationIndicators = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.celebration) {
      if (this.celebration.files.length > 0) {
        this.celebration.files.forEach((image) => {
          this.images.push(
            this.sanitizer.bypassSecurityTrustStyle(
              `url(${this.API_URL}${image.file_path})`
            )
          );
        });
      }
    }
    if (this.user) {
      if (this.user.id === this.celebration.endorser.id) {
        this.canEdit = true;
        this.canDelete = true;
        this.canShare = true;
      }
      if (this.user.roles.includes(UserRole.ESUITE_ADMIN)) {
        this.canDelete = true;
      }
      this.celebration.users.forEach((user) => {
        if (user.id === this.user?.id) {
          this.canShare = true;
        }
        if (user.district_id && !this.districts.includes(user.district_id)) {
          this.districts.push(user.district_id);
        }
        if (user.school_id && !this.schools.includes(user.school_id)) {
          this.schools.push(user.school_id);
        }
      });
    }
  }

  handleDeleteCelebrationEvent() {
    this.deleteCelebrationEvent.emit(this.celebration.id);
  }

  handleEditCelebrationEvent() {
    this.editCelebrationEvent.emit(this.celebration);
  }

  shareCelebration() {
    let text = '';
    this.celebration.users.forEach((user, index) => {
      text += `${user.profile.first_name} `;
      text += user.profile.last_name;
      if (index !== this.celebration.users.length - 1) {
        text += ', ';
      }
    });
    text += ` was just celebrated by ${this.celebration.endorser.profile.first_name} ${this.celebration.endorser.profile.last_name} on the competency `;
    if (this.celebration.competencies.length > 0) {
      this.celebration.competencies.forEach((competency) => {
        // only one competency right now
        text += competency.title;
      });
    }
    text += '. Way to grow! #engage2learn';
    const encodedText = encodeURIComponent(text);
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}`;
    window.open(twitterUrl, '_blank');
  }

  /* eslint-disable class-methods-use-this */
  viewFile(path: string) {
    window.open(`${EnvironmentService.apiUrl()}${path}`);
  }
}
