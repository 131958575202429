import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  DistrictDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { currentDistrictOrE2lEmployee } from 'src/app/common/utilities/check-route';
import { FormSubmissionModalComponent } from 'src/app/private/shared/components/forms/reports/form-submission-modal/form-submission-modal.component';
import { FormModalComponent } from 'src/app/private/shared/components/forms/view/form-modal/form-modal.component';
import { Form, FormStatus } from 'src/app/private/shared/dtos/forms.dto';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';

import { FilterFormSearch } from './forms-search-filter.pipe';

@Component({
  selector: 'app-forms-list-page',
  templateUrl: './forms-list-page.component.html',
  styleUrls: ['./forms-list-page.component.scss'],
  providers: [FilterFormSearch],
  standalone: false,
})
export class FormsListPageComponent {
  @ViewChild('previewModal') previewModal: FormModalComponent;

  @ViewChild('submissionModal') submissionModal: FormSubmissionModalComponent;

  tableData: Form[] = [];

  districtList: DistrictSimpleDto[] = [];

  district: DistrictDTO;

  districtCode: string;

  sortField: 'submissionCount' | 'title' = 'title';

  sortDirection: 'up' | 'down' = 'up';

  forms: Form[] = [];

  user: User | null;

  isE2lEmployee = false;

  currentMode = true;

  pagination: PaginationDTO;

  currentPage = 1;

  showModal = false;

  sort: [string, string] = ['title', 'asc'];

  modalFormId: number;

  toggleFormView() {
    this.currentMode = !this.currentMode;
    this.currentPage = 1;
    this.loadForms();
  }

  deleteForm(formId: number) {
    this.formsService.deleteForm(formId).subscribe(() => {
      this.loadForms();
    });
  }

  changeSort(id: 'submissionCount' | 'title') {
    if (this.sortField === id) {
      this.sortDirection = this.sortDirection === 'down' ? 'up' : 'down';
    } else {
      this.sortDirection = 'down';
      this.sortField = id;
    }
    this.sort = [this.sortField, this.sortDirection === 'up' ? 'asc' : 'desc'];
    this.loadForms();
  }

  hideForm(formId: number) {
    this.formsService.hideForm(formId, this.district.id).subscribe(() => {
      this.loadForms();
    });
  }

  openPreview(formId: number) {
    this.previewModal.formId = formId;
    this.previewModal.ngOnInit();
    this.previewModal.modal.open();
  }

  duplicateForm(formId: number) {
    const form = this.forms.find((f) => f.id === formId);
    this.formsService
      .duplicateForm(form as Form, this.district.id)
      .subscribe(() => {
        this.loadForms();
      });
  }

  searchForms(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;

    this.forms = new FilterFormSearch().transform(
      this.forms,
      searchTerm,
      this.currentMode
    );
  }

  showSubmissionModal(formId: number) {
    this.submissionModal.districtId = this.district.id;
    this.submissionModal.formId = formId;
    this.submissionModal.ngOnInit();
    this.submissionModal.modal.open();
  }

  userCanEdit(form: Form): boolean {
    return currentDistrictOrE2lEmployee(this.user, { id: form.districtId });
  }

  getDistrictTitle(districtId: number): string {
    return (
      this.districtList.find((d) => d.id === districtId) as DistrictSimpleDto
    ).title;
  }

  constructor(
    public formsService: FormsService,
    private route: ActivatedRoute,
    public router: Router,
    private districtListService: DistrictListService,
    private store: Store
  ) {
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.user = this.store.selectSnapshot(UserState.getUser);
    this.districtListService
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          this.districtList = districts;
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
          }
          this.loadForms();
        }
      });
  }

  loadForms(): void {
    const type = this.currentMode ? '' : FormStatus.ARCHIVED;
    this.formsService
      .loadForms(this.district.id, 10, this.currentPage, this.sort, type)
      .subscribe(([forms, pagination]) => {
        this.forms = forms;
        this.pagination = pagination;
      });
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadForms();
  }
}
