import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';

@Component({
  selector: 'app-uncheck-confirm-modal',
  templateUrl: './uncheck-confirm-modal.component.html',
  styleUrls: ['./uncheck-confirm-modal.component.scss'],
  standalone: false,
})
export class UncheckConfirmModalComponent {
  @ViewChild('internalModal')
  internalModal: ModalComponent;

  @Output() readonly confirmRemove = new EventEmitter<boolean>();

  modalConfig: ModalConfig = {
    titleText: 'Are you sure?',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
  };

  openModal() {
    this.internalModal.open();
  }

  closeModal() {
    this.internalModal.close();
  }

  continue() {
    this.internalModal.close();
    this.confirmRemove.emit(true);
  }
}
