import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  CalibrationAveragesItem,
  CalibrationAveragesResponse,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Component({
  selector: 'app-calibration-table',
  templateUrl: './calibration-table.component.html',
  styleUrls: ['./calibration-table.component.scss'],
  standalone: false,
})
export class CalibrationTableComponent implements OnInit, OnChanges {
  @Input() requestParams: ReportRequestParams;

  isLoading = true;

  loadingError = false;

  params: ReportRequestParams[] = [];

  currentPage = 1;

  totalResponses = 0;

  standards: string[];

  responses: CalibrationAveragesItem[];

  constructor(private apiService: APICoreService) {}

  ngOnInit(): void {
    this.getAverages(this.requestParams);
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change['requestParams'] &&
      change['requestParams'].firstChange === false
    ) {
      this.getAverages(this.requestParams);
    }
  }

  paginationChange() {
    const params = {
      ...this.requestParams,
      page: this.currentPage,
    };
    this.getAverages(params);
  }

  getAverages(params: ReportRequestParams) {
    this.isLoading = true;
    this.loadingError = false;

    const responseParams = {
      report: 'observationFormCalibration',
      ...params,
    };

    this.apiService
      .getRequest('reporting/load-report', responseParams)
      .subscribe({
        next: (response: CalibrationAveragesResponse) => {
          this.totalResponses = response._meta.totalCount;
          this.standards = response.standards;
          this.responses = response.items;
          this.isLoading = false;
        },
        error: () => {
          this.totalResponses = 0;
          this.standards = [];
          this.responses = [];
          this.loadingError = true;
          this.isLoading = false;
        },
        complete: () => {},
      });
  }
}
