import { Component } from '@angular/core';
import { FormFieldAbstractComponent } from '../base/edit-field-abstract';

@Component({
  selector: 'app-form-edit-freetext',
  templateUrl: './edit-freetext.component.html',
  styleUrls: ['../edit-field/form-edit-field.component.scss'],
  standalone: false,
})
export class FormEditFreetextComponent extends FormFieldAbstractComponent {}
