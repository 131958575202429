import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription, map } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { CoachingLogPusherService } from '../../services/coaching-log/coaching-log-pusher.service';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { SessionPercentageAPIResponse } from '../../types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-log-completion',
  templateUrl: './coaching-log-completion.component.html',
  styleUrls: ['./coaching-log-completion.component.scss'],
  standalone: false,
})
export class CoachingLogCompletionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() completionActive: boolean;

  @Input() sessionId: number;

  @Output() readonly hideCompletionEvent = new EventEmitter<boolean>();

  @Output() readonly percentageCalculationEvent = new EventEmitter<number>();

  subs: Subscription[] = [];

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  sessionPercentageData: SessionPercentageAPIResponse;

  loadingData = true;

  constructor(
    public coachingService: CoachingLogService,
    public logPusherService: CoachingLogPusherService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
          )
        )
        .subscribe((sessionData) => {
          this.sessionData = sessionData;
          // recalc if attendees are updated
          this.calculateCompletion();
        })
    );

    this.subs.push(
      this.logPusherService.completionRecalcEventStream.subscribe((source) => {
        if (source) {
          this.calculateCompletion();
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'competencySelected':
          this.calculateCompletion();
          break;
        default:
      }
    });
  }

  toggleCompletion(): void {
    this.hideCompletionEvent.emit(true);
  }

  calculateCompletion() {
    this.loadingData = true;

    this.coachingService
      .getSessionPercentage(this.sessionId)
      .subscribe((response: SessionPercentageAPIResponse) => {
        this.sessionPercentageData = response;

        this.percentageCalculationEvent.emit(
          this.sessionPercentageData.percentCompleted
        );

        this.loadingData = false;
      });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
