import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoachingSessionShadowerDTO } from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';

@Component({
  selector: 'app-coaching-shadower',
  templateUrl: './coaching-shadower.component.html',
  styleUrls: ['./coaching-shadower.component.scss'],
  standalone: false,
})
export class CoachingShadowerComponent {
  @Input() shadower: CoachingSessionShadowerDTO;

  @Input() logId: number;

  @Input() isCoach = false;

  @Input() calendarDetails = false;

  @Input() avatarSize:
    | 'small'
    | 'medium'
    | 'medium-large'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large'
    | 'avatar-side-nav';

  @Output() readonly removedShadower: EventEmitter<CoachingSessionShadowerDTO> =
    new EventEmitter();

  constructor(public coachingService: CoachingLogService) {}

  deleteShadower() {
    if (this.shadower.id) {
      this.coachingService.deleteShadower(this.shadower).subscribe();
    }
    this.removedShadower.emit(this.shadower);
  }

  saveShadowerPresence() {
    const intValue = this.shadower.present ? 1 : 0;
    const payload = { present: intValue };
    this.coachingService
      .updateShadower(this.shadower.userId, this.logId, payload)
      .subscribe();
  }
}
