import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/common/state/user/user.service';
import { SelectableCoach } from 'src/app/private/pages/coach-list-page/selectable.coach';

@Component({
  selector: 'app-coach-notes',
  templateUrl: './coach-notes.component.html',
  styleUrl: './coach-notes.component.scss',
  standalone: false,
})
export class CoachNotesComponent implements OnInit {
  @Input() coach: SelectableCoach;

  hasNotes = false;

  editMode = false;

  loading = false;

  initialValue: null | string;

  noteForm: FormGroup;

  @ViewChild('notes') notewrapper: ElementRef;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.noteForm = new FormGroup({
      note: new FormControl(''),
    });

    if (this.coach.personalNotes) {
      this.hasNotes = true;
      this.initialValue = this.coach.personalNotes;
      this.noteForm.controls['note'].setValue(this.coach.personalNotes);
    } else {
      this.hasNotes = false;
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      setTimeout(() => {
        if (
          this.notewrapper.nativeElement.children.length > 0 &&
          this.notewrapper.nativeElement.children[0].length > 0
        ) {
          this.notewrapper.nativeElement.children[0].children[0].focus();
        }
      }, 100);
      this.notewrapper.nativeElement.focus();
    }
  }

  updateNote() {
    if (this.initialValue !== this.noteForm.get('note')?.value) {
      this.loading = true;
      const newNote: object = {
        notes: this.noteForm.get('note')?.value,
      };

      this.userService
        .updateUserNote(this.coach.id, newNote)
        .subscribe((res) => {
          if (res) {
            if (!this.hasNotes) {
              this.coach.personalNotes = this.noteForm.get('note')?.value;
              this.hasNotes = true;
              this.editMode = false;
            }
            if (this.hasNotes && this.noteForm.get('note')?.value === '') {
              this.noteForm?.get('note')?.setValue('');
              this.hasNotes = false;
              this.editMode = false;
            } else {
              this.coach.personalNotes = this.noteForm.get('note')?.value;
              this.editMode = false;
            }
            this.loading = false;
          }
        });
    } else {
      this.toggleEditMode();
    }
  }
}
