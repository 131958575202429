import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { GradeDTO } from 'src/app/common/dtos/grade.dto';
import {
  ContentAreaDTO,
  SchoolDTO,
  UserSchoolDTO,
} from 'src/app/common/dtos/school.dto';
import {
  EditUserPayload,
  UserDTO,
  UserLiteDTO,
} from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import { userDTOLiteFromUserDTO } from '../../../helpers/translators/user.translators';
import { AlertService } from '../../../services/alert/alert.service';
import { SchoolSearchService } from '../../../services/school-search/school-search.service';
import { ModalComponent } from '../../modals/modal/modal.component';
import { UserSelectComponent } from '../../user-select/user-select.component';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrl: './edit-user-modal.component.scss',
  standalone: false,
})
export class EditUserModalComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() showModal = false;

  @Input() districtId = 0;

  @Input() user: UserDTO;

  @Output() readonly showModalChange = new EventEmitter();

  @ViewChild('editUserModal') editUserModal: ModalComponent;

  @ViewChild('coachSelect') coachSelect: UserSelectComponent;

  modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  serverError = '';

  isOpen = false;

  isLoading = false;

  form: FormGroup;

  formSubmitted = false;

  defaultSchool = 0;

  schoolListOptions: SchoolDTO[];

  gradeOptions: GradeDTO[];

  contentAreaOptions: ContentAreaDTO[];

  editUserPayload: EditUserPayload = {};

  contentAreasPayload: ContentAreaDTO[] = [];

  gradesPayload: GradeDTO[] = [];

  additionalSchoolsPayload: SchoolDTO[] = [];

  userCoach: UserLiteDTO | null;

  initialStatus: boolean | undefined;

  constructor(
    private schoolService: SchoolSearchService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showModal']?.currentValue) {
      this.modalShowing.next(true);
    }
    if (changes['user']?.currentValue) {
      this.loadUserData();
    }
  }

  ngOnInit(): void {
    // load the school options list
    this.schoolService
      .getDistrictSchools(this.districtId)
      .subscribe((results) => {
        this.schoolListOptions = results;
        if (
          this.schoolListOptions.length === 1 &&
          this.schoolListOptions[0].is_default === 1
        ) {
          this.defaultSchool = this.schoolListOptions[0].id;
        }
      });
    // load the grade options list
    this.userService.getGrades().subscribe((res) => {
      this.gradeOptions = res.items;
    });
    // load the content areas options list
    this.userService.getContentAreas().subscribe((res) => {
      this.contentAreaOptions = res.items;
    });
  }

  ngAfterViewInit(): void {
    this.editUserModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.editUserModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.editUserModal.close();
        this.isOpen = false;
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  loadUserData() {
    if (this.user) {
      if (this.user.coach) {
        this.userCoach = userDTOLiteFromUserDTO(this.user.coach);
      }
      this.initialStatus = this.assignStatus(this.user.status);

      this.form = this.formBuilder.group({
        first_name: [this.user.profile.first_name, Validators.required],
        last_name: [this.user.profile.last_name, Validators.required],
        email: [this.user.email, [Validators.required, Validators.email]],
        title: [this.user.profile.title, Validators.required],
        coach: [this.userCoach],
        school: [this.user.school_id, Validators.required],
        additional_schools: [' '],
        district_id: this.districtId,
        coachee_type: [this.user.coacheeType?.tag],
        grade_levels_served: [''],
        content_areas: [''],
        exclude_from_reports: [this.user.exclude_from_reports],
        status: [this.initialStatus, Validators.required],
      });

      if (this.user.grades && this.user.grades.length > 0) {
        this.gradesPayload = this.user.grades;
        this.f['grade_levels_served'].setValue(' ');
        this.f['grade_levels_served'].setValidators([Validators.required]);
      }

      if (this.user.content_areas && this.user.content_areas.length > 0) {
        this.contentAreasPayload = this.user.content_areas;
        this.f['content_areas'].setValue(' ');
        this.f['content_areas'].setValidators([Validators.required]);
      }

      if (this.user.userSchools && this.user.userSchools.length > 0) {
        this.additionalSchoolsPayload = [];
        this.user.userSchools.forEach((userSchool) => {
          const schoolInfo = this.schoolListOptions.find(
            (option) => option.id === userSchool.school_id
          );
          if (schoolInfo) {
            this.additionalSchoolsPayload?.push(schoolInfo);
          }
        });
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  assignStatus(status: number) {
    return Boolean(status && status === 10);
  }

  clearForm() {
    this.form.reset({
      first_name: '',
      last_name: '',
      email: '',
      title: '',
      coach: '',
      school: '',
      additional_schools: '',
      district_id: '',
      coachee_type: '',
      grade_levels_served: '',
      content_areas: '',
      exclude_from_reports: '',
      status: '',
    });
    this.gradesPayload = [];
    this.additionalSchoolsPayload = [];
    this.contentAreasPayload = [];
    if (this.coachSelect) this.coachSelect.reset();
    this.serverError = '';
  }

  closeModal() {
    this.showModal = false;
    this.formSubmitted = false;
    this.clearForm();
    this.showModalChange.emit(this.showModal);
    this.modalShowing.next(false);
    this.isLoading = false;
    if (this.editUserModal) this.editUserModal.close();
  }

  updateAssignedCoach(value: UserLiteDTO | null) {
    this.userCoach = value;
  }

  setSubmitStatus() {
    if (this.f['status'].value) {
      return 10;
    }
    return 3;
  }

  submitEditUserData() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return false;
    }
    this.isLoading = true;
    const servicesLoading: boolean[] = [];
    this.setSubmitStatus();
    this.editUserPayload = {
      profile: {
        first_name: this.f['first_name'].value,
        last_name: this.f['last_name'].value,
        title: this.f['title'].value,
      },
      email: this.f['email'].value,
      school: {
        id: this.f['school'].value,
      },
      exclude_from_reports: this.f['exclude_from_reports'].value,
      status: this.setSubmitStatus(),
    };

    this.userService
      .updateUserCoacheeType(this.user.id, this.f['coachee_type'].value)
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: () => {
          servicesLoading.push(true);
          if (servicesLoading.length === 6) {
            this.isLoading = false;
            this.closeModal();
            this.alertService.showAlert('User Settings Saved');
          }
        },
      });

    this.userService.updateUser(this.user.id, this.editUserPayload).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.serverError = error.error.message;
        setTimeout(() => {
          this.serverError = '';
        }, 3000);
      },
      next: () => {
        servicesLoading.push(true);
        if (servicesLoading.length === 6) {
          this.isLoading = false;
          this.closeModal();
          this.alertService.showAlert('User Settings Saved');
        }
      },
    });

    this.userService
      .updateAdditionalSchools(this.user.id, this.additionalSchoolsPayload)
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: () => {
          servicesLoading.push(true);
          if (servicesLoading.length === 6) {
            this.isLoading = false;
            this.closeModal();
            this.alertService.showAlert('User Settings Saved');
          }
        },
      });

    if (this.gradesPayload.length > 0) {
      this.userService
        .updateGrades(this.user.id, this.gradesPayload)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 6) {
              this.isLoading = false;
              this.closeModal();
              this.alertService.showAlert('User Settings Saved');
            }
          },
        });
    } else {
      servicesLoading.push(true);
    }

    if (this.contentAreasPayload.length > 0) {
      this.userService
        .updateContentAreas(this.user.id, this.contentAreasPayload)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 6) {
              this.isLoading = false;
              this.closeModal();
              this.alertService.showAlert('User Settings Saved');
            }
          },
        });
    } else {
      servicesLoading.push(true);
    }

    this.userService
      .updateUserCoach(this.user.id, this.userCoach ? this.userCoach.id : 0)
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: () => {
          servicesLoading.push(true);
          if (servicesLoading.length === 6) {
            this.isLoading = false;
            this.closeModal();
            this.alertService.showAlert('User Settings Saved');
          }
        },
      });

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMultiSelectValues(event: any, type: string) {
    const value = parseInt(event);
    switch (type) {
      case 'grade_levels_served':
        if (!this.gradesPayload.find((grade) => grade.id === value)) {
          const gradeInfo = this.gradeOptions.find(
            (option) => option.id === value
          );
          if (gradeInfo) {
            this.gradesPayload.push(gradeInfo);
          }
        }
        this.f['grade_levels_served'].setValue(' ');
        break;
      case 'content_areas':
        if (!this.contentAreasPayload.find((area) => area.id === value)) {
          const contentArea = this.contentAreaOptions.find(
            (option) => option.id === value
          );
          if (contentArea) {
            this.contentAreasPayload.push(contentArea);
          }
        }
        this.f['content_areas'].setValue(' ');
        break;
      case 'additional_schools':
        if (
          !this.additionalSchoolsPayload.find(
            (school) => school.id === value
          ) &&
          this.user.school_id !== value
        ) {
          const schoolInfo = this.schoolListOptions.find(
            (option) => option.id === value
          );
          if (schoolInfo) {
            this.additionalSchoolsPayload.push(schoolInfo);
          }
        }
        this.f['additional_schools'].setValue(' ');
        break;
      default:
        break;
    }
  }

  removeMultiSelectValue(
    type: string,
    value: UserSchoolDTO | ContentAreaDTO | GradeDTO
  ) {
    switch (type) {
      case 'school':
        this.additionalSchoolsPayload = this.additionalSchoolsPayload?.filter(
          (school) => school.id !== value.id
        );
        break;
      case 'contentArea':
        this.contentAreasPayload = this.contentAreasPayload?.filter(
          (area) => area.id !== value.id
        );
        if (this.contentAreasPayload.length === 0) {
          this.f['content_areas'].setValue('');
        }
        break;
      case 'grade':
        this.gradesPayload = this.gradesPayload?.filter(
          (grade) => grade.id !== value.id
        );
        if (this.gradesPayload.length === 0) {
          this.f['grade_levels_served'].setValue('');
        }
        break;
      default:
        break;
    }
  }

  deleteUser() {
    this.userService.deleteUser(this.user.id).subscribe((res) => {
      if (res) {
        this.alertService.showAlert('User Deleted');
        this.closeModal();
      }
    });
  }
}
