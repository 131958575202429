import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import {
  ResourceFiltersDTO,
  ResourceListItemDTO,
} from '../../shared/dtos/resources.dto';
import { defaultResourceListFilters } from '../../shared/services/resources/resource.helpers';
import { ResourcesSearchService } from '../../shared/services/resources/resources-search.service';

@Component({
  selector: 'app-resources-search',
  templateUrl: './resources-search.component.html',
  styleUrls: ['./resources-search.component.scss'],
  standalone: false,
})
export class ResourcesSearchComponent implements OnInit, OnDestroy {
  resourceList: ResourceListItemDTO[];

  currentSearchTerm = '';

  inputValue: string;

  isLoading = true;

  searchMetaData: PaginationDTO | null;

  currentFilters: ResourceFiltersDTO;

  canCreate = false;

  user: User;

  totalResources: number;

  subs: Subscription[] = [];

  defaultFilters = defaultResourceListFilters;

  constructor(
    private resourceService: ResourcesSearchService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    if (compareRoles(this.user.roles, [UserRole.ELEARN_RESOURCE_CREATOR])) {
      this.canCreate = true;
    }
  }

  ngOnInit(): void {
    this.subs.push(
      this.resourceService.searchResultsMetaObs.subscribe((metaData) => {
        this.searchMetaData = metaData;
      })
    );

    this.subs.push(
      this.resourceService.searchFilters.subscribe((searchFilters) => {
        this.currentFilters = searchFilters as ResourceFiltersDTO;
      })
    );

    this.subs.push(
      this.resourceService.searchTrigger.subscribe(() => {
        this.getResources();
      })
    );

    this.getResources();

    this.getTotalResources();
  }

  getResources() {
    this.currentFilters.search = this.inputValue;
    this.isLoading = true;
    const searchObservable = this.resourceService.performSearch();
    searchObservable
      .forEach((item) => {
        this.resourceList = item;
      })
      .then(() => {
        this.isLoading = false;
      });
  }

  onSearchFieldKeydown(event: { key: string }, searchTerm: string) {
    if (event.key === 'Enter') {
      this.resourceService.setFilters({
        search: searchTerm,
      });
    }
  }

  pageChanged() {
    if (this.searchMetaData) {
      this.resourceService.setFilters({
        page: this.searchMetaData.currentPage,
        search: this.currentSearchTerm,
      });
    }
  }

  getTotalResources() {
    this.resourceService.getTotalResources().subscribe((resp) => {
      this.totalResources = resp.total_resources;
    });
  }

  noFiltersApplied() {
    return (
      JSON.stringify(this.currentFilters) ===
      JSON.stringify(this.defaultFilters)
    );
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
