import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-proform-preview-modal',
  standalone: false,
  templateUrl: './proform-preview-modal.component.html',
  styleUrl: './proform-preview-modal.component.scss',
})
export class ProformPreviewModalComponent {
  @Input() formId: number;

  @ViewChild('modal') modal: ModalComponent;

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }
}
