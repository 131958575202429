import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { v4 as uuidv4 } from 'uuid';

import { Store } from '@ngxs/store';
import { take } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { CohortService } from '../../services/cohort/cohort.service';
import { DWCohort } from '../../types/responses/cohort.responses';

@Component({
  selector: 'app-cohort-select',
  templateUrl: './cohort-select.component.html',
  styleUrls: ['./cohort-select.component.scss'],
  standalone: false,
})
export class CohortSelectComponent implements OnInit, OnChanges {
  @ViewChild('select') select: NgSelectComponent;

  cohortList: DWCohort[];

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedCohortList: DWCohort[] = [];

  internalSelectedCohort: DWCohort | null;

  internalDistrictIds: number[];

  loadingDistricts: number[] = [];

  user: User;

  @Input() districtIds: number | number[] | DistrictDTO[] | null;

  @Input() simpleSelect = false;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a cohort';

  @Input() placeholder = 'Search for a cohort...';

  @Input() isMulti = false;

  @Input() defaultCohorts: number[] | DWCohort[] | null;

  @Output() readonly selectedCohortList: EventEmitter<DWCohort[]> =
    new EventEmitter();

  @Output() readonly selectedCohort: EventEmitter<DWCohort | null> =
    new EventEmitter();

  constructor(private cohortService: CohortService, private store: Store) {}

  ngOnInit(): void {
    this.user = this.store.selectSnapshot((state) => state.user.impersonated);
    this.setInternalDistrictIds();
    this.loadCohorts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'districtIds':
          if (!changes[propName].isFirstChange()) {
            this.reset();
            this.setInternalDistrictIds();
            this.loadCohorts(true);
          }
          break;
        default:
      }
    });
  }

  setInternalDistrictIds() {
    /* eslint-disable-next-line no-nested-ternary */
    this.internalDistrictIds = Array.isArray(this.districtIds)
      ? getIdsFromDefaultItemsForSelect(this.districtIds)
      : this.districtIds
      ? [this.districtIds as number]
      : [this.user.district?.id];
  }

  loadCohorts(outputAfter = false) {
    if (this.internalDistrictIds !== this.loadingDistricts) {
      this.loadingDistricts = this.internalDistrictIds;
      this.cohortService
        .fetchDwCohortsFromDistricts(this.internalDistrictIds)
        .subscribe((cohorts) => {
          take(1);
          this.cohortList = [...cohorts];
          this.loadDefaultCohorts(outputAfter);
        });
    } else {
      this.loadDefaultCohorts(outputAfter);
    }
  }

  loadDefaultCohorts(outputAfter = false) {
    this.loadingDistricts = [];
    if (this.defaultCohorts) {
      const cohortIds = getIdsFromDefaultItemsForSelect(this.defaultCohorts);
      cohortIds.forEach((cohortId) => {
        const cohort = this.cohortList.find((item) => item.id === cohortId);
        if (cohort) {
          if (this.isMulti) {
            this.internalSelectedCohortList.push(cohort);
          } else {
            this.internalSelectedCohort = cohort;
          }
        }
      });
    }
    if (outputAfter) {
      this.outputCohorts();
    }
  }

  cohortSelected(cohort: DWCohort) {
    if (this.isMulti && cohort) {
      if (!this.internalSelectedCohortList.includes(cohort)) {
        this.internalSelectedCohortList.push(cohort);
      }
      this.cohortList = this.cohortList.filter((item) => item.id !== cohort.id);
      this.select.handleClearClick();
    } else {
      this.internalSelectedCohort = cohort;
    }
    this.outputCohorts();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputCohorts() {
    if (this.isMulti) {
      this.selectedCohortList.emit(this.internalSelectedCohortList);
    } else {
      this.selectedCohort.emit(this.internalSelectedCohort);
    }
  }

  removeCohortFromList(cohort: DWCohort) {
    this.internalSelectedCohortList = this.internalSelectedCohortList.filter(
      (item) => item.id !== cohort.id
    );
    this.outputCohorts();
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedCohortList = [];
      this.select.selectedItems.forEach((item) => {
        this.select.unselect(item);
      });
    } else {
      this.internalSelectedCohort = null;
    }
  }
}
