import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { DashboardHeaderComponent } from 'src/app/private/shared/components/dashboard-header/dashboard-header.component';
import { SmartSuggestionCustomAction } from 'src/app/private/shared/enums/smart-suggestions.enum';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';

@Component({
  selector: 'app-learner-dashboard-page',
  templateUrl: './learner-dashboard.component.html',
  styleUrls: ['./learner-dashboard.component.scss'],
  standalone: false,
})
export class LearnerDashboardComponent {
  @ViewChild('dashboardHeader') dashboardHeader: DashboardHeaderComponent;

  user: User | null = null;

  mscEnabled = false;

  smartSuggestionsEnabled = false;

  constructor(
    private store: Store,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
    this.smartSuggestionsEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.smartSuggestions
    );
  }

  customSuggestionAction($event: string) {
    switch ($event) {
      case SmartSuggestionCustomAction.QUICK_WALK_MODAL:
        this.dashboardHeader.openQuickWalkModal();
        break;
      default:
        break;
    }
  }
}
