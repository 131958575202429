import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  LibraryShelfItemModelDTO,
  ResourceFolderModelDTO,
  ResourceOnlineCourseDTO,
} from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-folder-bookmark',
  templateUrl: './folder-bookmark.component.html',
  styleUrls: ['./folder-bookmark.component.scss'],
  standalone: false,
})
export class FolderBookmarkComponent implements OnInit {
  @Input() itemId: number;

  @Input() itemType: string;

  @Input() itemInAFolder = 0;

  currentUser: User;

  myShelves: ResourceFolderModelDTO[] = [];

  emptyShelf = false;

  newShelf = {} as ResourceFolderModelDTO;

  newShelfItem = {} as LibraryShelfItemModelDTO;

  childShelf: ResourceFolderModelDTO | null;

  childLoading = false;

  nameShelf = false;

  savingNewShelf = false;

  constructor(
    private router: Router,
    private resourcesBookmarkService: ResourcesBookmarkService,
    private store: Store
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.newShelf.title = '';
    this.newShelfItem.ref_table = this.itemType;
    this.newShelfItem.ref_table_id = this.itemId;
  }

  getMyShelfs() {
    this.resourcesBookmarkService
      .getMyBookmarkedFolders(this.itemType, this.itemId)
      .subscribe((response) => {
        if (response.items) {
          this.myShelves = response.items;
          if (this.myShelves.length === 0) {
            this.emptyShelf = true;
          } else {
            this.emptyShelf = false;
          }
        } else {
          this.myShelves = [];
        }
      });
  }

  updateStatus() {
    if (this.itemType === 'elearn_resource') {
      this.updateResourceStatus();
    } else if (this.itemType === 'elearn_vpl_course') {
      this.updateCourseStatus();
    } else {
      // TODO: Is this really the only error we want to throw? Maybe throwing something for Sentry so we're aware of it?
    }
  }

  updateResourceStatus() {
    this.resourcesBookmarkService
      .updateResourceStatus(this.itemId)
      .subscribe((response) => {
        if (response.item) {
          this.itemInAFolder = response.item.isOnUsersShelf;
        }
      });
  }

  updateCourseStatus() {
    this.resourcesBookmarkService.getOnlineCourses().subscribe((result) => {
      if (result.items) {
        const selectedCourse = result.items.find(
          (item: ResourceOnlineCourseDTO) => item.id === this.itemId
        );
        this.itemInAFolder = selectedCourse.isOnUsersShelf;
      }
    });
  }

  getChildShelf(folderId: number) {
    this.childLoading = true;
    this.resourcesBookmarkService
      .getChildFolder(this.itemType, this.itemId, folderId)
      .subscribe((response) => {
        if (response.item) {
          this.childShelf = response.item;
          this.newShelf.parent_shelf_id = this.childShelf?.id;
          this.childLoading = false;
        } else {
          this.childShelf = null;
          this.childLoading = false;
        }
      });
  }

  removeChildShelf() {
    this.childShelf = null;
    this.newShelf.title = '';
    this.nameShelf = false;
    delete this.newShelf.parent_shelf_id;
  }

  createShelf() {
    this.nameShelf = false;
    this.savingNewShelf = true;
    if (this.newShelf.title === '') {
      this.newShelf.title = 'New Shelf';
    }
    this.resourcesBookmarkService
      .createFolder(this.newShelf)
      .subscribe((response) => {
        if (response.item) {
          this.savingNewShelf = false;
          if (this.childShelf) {
            this.childShelf.childShelves?.push(response.item);
          } else {
            this.myShelves.push(response.item);
          }
          this.toggleFavorite(response.item);
        }
        this.newShelf.title = '';
      });
  }

  saveTitle(shelf: ResourceFolderModelDTO) {
    this.resourcesBookmarkService
      .updateFolderTitle(shelf.id, shelf.title)
      .subscribe();
  }

  toggleFavorite(shelf: ResourceFolderModelDTO) {
    if (!shelf.has_current_item) {
      this.newShelfItem.elearn_favorite_shelf_id = shelf.id;
      this.resourcesBookmarkService
        .addFavoriteFolder(shelf.id, this.newShelfItem)
        .subscribe((result) => {
          if (result) {
            shelf.has_current_item = true;
            shelf.is_active = true;
            this.updateStatus();
          }
        });
    } else {
      this.resourcesBookmarkService
        .removeFavoriteFolder(shelf.id, this.itemType, this.itemId)
        .subscribe((result) => {
          if (result) {
            shelf.has_current_item = false;
            shelf.is_active = false;
            this.updateStatus();
          }
        });
    }
  }

  gotoMyLibrary() {
    this.router.navigate(['/resources/my']);
  }

  gotoShelf(shelf: ResourceFolderModelDTO) {
    this.router.navigate([`/resources/my/folder/${shelf.id}`]);
  }
}
