<app-modal
  #modal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="session">
    <label class="label-text fw-bold mt-0">Title</label>
    <p>{{ session.title }}</p>
    <label class="label-text fw-bold mt-0">Description</label>
    <p>{{ session.description }}</p>
    <label class="label-text fw-bold mt-0">Date</label>
    <p>
      {{ session.start_datetime | formatDateOrdinal }},
      <span style="text-transform: lowercase">
        {{ session.start_datetime * 1000 | date : "hh:mma" }}
        <span *ngIf="session.end_datetime">
          - {{ session.end_datetime * 1000 | date : "hh:mma" }}
        </span></span
      >
      {{ abbrTimezone }}
    </p>
    <ng-container *ngIf="session.facilitators">
      <label class="label-text fw-bold mt-0"
        >Facilitator<span *ngIf="session.facilitators.length > 1"
          >s</span
        ></label
      >
      <p>
        <ng-container
          *ngIf="session.facilitators && session.facilitators.length > 0">
          <ng-container
            *ngFor="
              let facilitator of session.facilitators;
              let last = last;
              let first = first
            ">
            <app-users-name
              [userId]="facilitator.facilitator_user_id"></app-users-name
            ><span *ngIf="!last">, </span>
          </ng-container>
        </ng-container>
      </p>
    </ng-container>
    <ng-container
      *ngIf="
        (!session.is_virtual && session.location) ||
        (session.is_virtual && session.virtual_link)
      ">
      <label class="label-text fw-bold mt-0">Location</label>
      <p *ngIf="!session.is_virtual">{{ session.location }}</p>
      <p *ngIf="session.is_virtual">{{ session.virtual_link }}</p>
    </ng-container>
    <div footerContent [style.text-align]="'right'">
      <a
        *ngIf="getSessionLink(session) as link"
        [routerLink]="link.path"
        type="button"
        class="btn btn-md btn-primary"
        href="javascript:void(0);"
        target="_blank">
        Go to Session
      </a>
    </div>
  </ng-container>
</app-modal>
