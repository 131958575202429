import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';

@Component({
  selector: 'app-reporting-subnav',
  templateUrl: './reporting-subnav.component.html',
  standalone: false,
})
export class ReportingSubNavComponent implements OnInit {
  user: User;

  evidenceReportEnabled: boolean;

  @Input() districtCode: string;

  Roles = UserRole;

  constructor(
    private store: Store,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.evidenceReportEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.evidenceReporting
    );
  }
}
