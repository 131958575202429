import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription, take } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { LeveledCompetencyModalComponent } from 'src/app/private/shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { DatepickerOutput } from 'src/app/private/shared/components/datepicker/datepicker.component';
import { CompetencyDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import {
  checkSessionDataType,
  checkSmartCoachAuthorization,
  SessionPermissions,
} from 'src/app/private/shared/helpers/coachee-log.utilities';
import { videoDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/video.translators';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { CoachDialogService } from 'src/app/private/shared/services/smart-coach/coach-dialog/coach-dialog.service';
import { SmartCoachSessionService } from 'src/app/private/shared/services/smart-coach/smart-coach-session';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import {
  FetchSessionData,
  MarkSmartLogIsPresent,
} from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import {
  SmartCoachPreviousSessionAPIResponse,
  SmartCoachPreviousSessionOptionAPIResponse,
} from 'src/app/private/shared/types/responses/smartcoach.responses';

@Component({
  selector: 'app-smart-coach-info-page',
  templateUrl: './smart-coach-info-page.component.html',
  styleUrls: ['./smart-coach-info-page.component.scss'],
  standalone: false,
})
export class SmartCoachInfoPageComponent implements OnInit, OnDestroy {
  @ViewChild('competencyModal')
  competencyModal: LeveledCompetencyModalComponent;

  messageFromYourCoachVideo: VideoDTO;

  sessionNumber: string;

  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  coachLogStatus$: Observable<APILoadingStatus> = select(
    CoachingLogState.getCoachLogStatus
  );

  coachLogStatus: APILoadingStatus;

  evidenceData$ = select(CoachingLogState.getEvidence);

  competenciesLoading = true;

  logPermissions: SessionPermissions;

  Permissions = SessionPermissions;

  user: User;

  coachee: UserDTO;

  allCompetencies: CompetencyDTO[] = [];

  competencyData$ = select(CoachingLogState.getCompetenciesByLog);

  competencyData: CompetencyDTO[] = [];

  lastSessionCompetencies: {
    userId: number;
    competencies: CompetencyDTO[];
  }[] = [];

  sessionData: CoachingSessionDTO | null;

  subs: Subscription[] = [];

  selectedLogId: number | null;

  attendeeSelectApiCalls: Subscription | null;

  selectedTypeId: number;

  logStart: number;

  logEnd: number;

  logDuration = 1800;

  timezone: TimezoneLinkBack;

  plannedEvidenceItems: { plannedEvidence: string; option: string }[] = [];

  plannedEvidenceMessageContent = '';

  previousSession: SmartCoachPreviousSessionAPIResponse;

  coacheePresent = false;

  constructor(
    private route: ActivatedRoute,
    private featureFlagService: UnleashService,
    public coachingService: CoachingLogService,
    private router: Router,
    private store: Store,
    private smartCoachSessionService: SmartCoachSessionService,
    private smartCoachDialogService: CoachDialogService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.subs.push(
      this.coachLogStatus$.subscribe((status) => {
        this.coachLogStatus = status;
      })
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.route.params.subscribe((url) => {
        this.sessionId = parseInt(url['logId']);
        this.store.dispatch(new FetchSessionData(this.sessionId));
      })
    );
    if (!this.featureFlagService.isEnabled(FEATURE_FLAGS.mySmartCoach)) {
      this.router.navigate(['/dashboard']);
    }
    this.getSessionData();
  }

  getSessionData() {
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
          )
        )
        .subscribe((sessionData) => {
          if (sessionData) {
            if (sessionData.attendees[0].user.id === this.user.id) {
              if (!this.coacheePresent) {
                this.markCoacheePresent();
              }
            } else {
              this.getPreviousSession(sessionData.id);
            }
            this.sessionData = sessionData;
            this.coachee = sessionData.attendees[0].user;
            this.getSessionNumber();
            this.logPermissions = checkSmartCoachAuthorization(
              sessionData,
              this.user
            );

            checkSessionDataType(sessionData, 'coaching', this.router);

            if (this.sessionData.egroweCoachlogTypeId) {
              this.selectedTypeId = this.sessionData.egroweCoachlogTypeId;
            }

            this.logStart = this.sessionData.startDatetime;
            this.logEnd = this.sessionData.endDatetime;
            const seconds = this.logEnd - this.logStart;
            const roundedSeconds = Math.round(seconds / 900) * 900;
            /* eslint-disable no-nested-ternary */
            this.logDuration =
              roundedSeconds !== 0
                ? roundedSeconds > 3600
                  ? 3600
                  : roundedSeconds
                : 900;
            this.timezone = this.sessionData.timezone as TimezoneLinkBack;
          }
        })
    );
  }

  getSessionNumber() {
    if (this.coachee) {
      this.coachingService.getSmartLogs(this.coachee.id).subscribe((res) => {
        if (res) {
          const sessions = res.items;
          for (let i = 0; i < sessions.length; i += 1) {
            if (this.sessionId === sessions[i].id) {
              this.sessionNumber = (i + 1).toString();
              this.getMessageFromYourCoachVideo();
            }
          }
        }
      });
    }
  }

  getMessageFromYourCoachVideo() {
    if (this.sessionData && this.sessionNumber) {
      this.subs.push(
        this.smartCoachSessionService
          .getMessageFromYourCoachVideo(
            this.sessionData.user.id,
            this.sessionNumber
          )
          .subscribe((response) => {
            if (response.item) {
              this.messageFromYourCoachVideo = videoDTOFromAPIResponse(
                response.item
              );
            }
          })
      );
    }
  }

  getPreviousSession(logId: number) {
    this.subs.push(
      this.smartCoachSessionService
        .getPreviousSession(logId)
        .subscribe((res) => {
          take(1);
          if (res) {
            this.previousSession = res;
            if (this.sessionData) {
              this.getCompetencyList(
                this.previousSession.id,
                this.sessionData.attendees[0].user.id
              );
            }
          } else {
            this.competenciesLoading = false;
          }
          if (this.logPermissions === SessionPermissions.EDIT) {
            this.triggerDialog();
          }
        })
    );
  }

  triggerDialog() {
    if (this.sessionNumber === '1') {
      this.smartCoachDialogService.getDialogMessage(
        'e1-start-session1',
        'msc-info'
      );
    } else {
      this.smartCoachDialogService.setQueue(4);
      this.smartCoachDialogService.getDialogMessage(
        'e1-start-session2Plus',
        'msc-info',
        1
      );
      this.getPlannedEvidenceMessage();
      this.smartCoachDialogService.getDialogMessage(
        'e1-giEarned-allEvidenceReviewed',
        'msc-info',
        3
      );
      this.smartCoachDialogService.getDialogMessage(
        'e1-giEarned-moreEvidenceToReview',
        'msc-info',
        4
      );
    }
  }

  getPlannedEvidenceMessage() {
    if (this.previousSession) {
      this.previousSession.options.forEach(
        (option: SmartCoachPreviousSessionOptionAPIResponse) => {
          if (option.wills.length) {
            const plannedEvidenceItem = {
              plannedEvidence: option.wills[0].content,
              option: option.content,
            };
            this.plannedEvidenceItems.push(plannedEvidenceItem);
          }
        }
      );
      this.plannedEvidenceItems.forEach((item) => {
        this.plannedEvidenceMessageContent += `<li>"${item.plannedEvidence}" for option: "${item.option}"</li>`;
      });
      this.smartCoachDialogService.queueMessage(
        {
          message: `The items to bring from your previous session were:
        <ul style="margin-top: 1rem; padding-left: 4rem;">
          ${this.plannedEvidenceMessageContent}
        </ul>`,
          group: 'msc-info',
        },
        2
      );
    }
  }

  getCompetencyList(logId: number, userId: number) {
    this.competenciesLoading = true;
    this.subs.push(
      (this.attendeeSelectApiCalls = this.coachingService
        .getAttendeeRubrics(logId)
        .subscribe((res) => {
          if (res) {
            const newCompetencies = res?.decomposedByUser[userId]?.competencies;
            this.lastSessionCompetencies = [
              {
                competencies: newCompetencies,
                userId,
              },
            ];
          }
          this.competenciesLoading = false;
        }))
    );
  }

  selectLog(logId: number) {
    this.store.dispatch(new FetchSessionData(logId));
    this.selectedLogId = logId;
  }

  markCoacheePresent() {
    this.smartCoachSessionService
      .markCoacheePresent(this.sessionId)
      .subscribe(() => {
        take(1);
        if (this.sessionData) {
          this.getPreviousSession(this.sessionData.id);
          this.coacheePresent = true;
          this.store.dispatch(new MarkSmartLogIsPresent(this.sessionData.id));
        }
      });
  }

  updateTitle() {
    if (this.sessionData) {
      this.coachingService.updateSmartLogTitle(
        this.sessionData?.id,
        this.sessionData?.title
      );
    }
  }

  updateDate(dateUpdate: DatepickerOutput[]) {
    if (dateUpdate.length > 0) {
      this.coachingService.updateSmartLogDate(
        this.sessionData?.id as number,
        dateUpdate[0].time / 1000
      );
    }
  }

  navigateNextScreen() {
    this.router.navigate([
      `/smart-coach/log/${this.sessionData?.id}/competencies`,
    ]);
  }

  toggleCardModal(userId: number, competencyId: number) {
    this.competencyModal.coachlogId = this.previousSession.id;
    this.competencyModal.competencyId = competencyId;
    this.competencyModal.userId = userId;
    this.competencyModal.ngOnInit();
    this.competencyModal.modalShowing.next(
      !this.competencyModal.modalShowing.value
    );
  }

  deleteSession() {
    if (this.sessionData) {
      this.coachingService
        .deleteCurrentMSCSession(this.sessionData)
        .subscribe(() => {
          this.router.navigate(['/coaching']);
        });
    }
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    const coachlogUpdates = {
      is_private: false,
    };

    if (target?.checked) {
      coachlogUpdates.is_private = true;
    }

    if (this.sessionData) {
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
