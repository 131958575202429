import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { TodoType } from '../../../enums/todo-type.enum';
import { ToDoDTO } from '../../todos/card-todos/todo.dto';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-todo-view-details-modal',
  templateUrl: './todo-view-details-modal.component.html',
  styleUrls: ['./todo-view-details-modal.component.scss'],
  standalone: false,
})
export class TodoViewDetailsModalComponent implements OnChanges {
  @Input() todo: ToDoDTO;

  @ViewChild('modal') modal: ModalComponent;

  isPlanTodo = false;

  timezone = TimezoneLinkBack.GMT;

  ngOnChanges() {
    if (this.todo) {
      this.isPlanTodo =
        this.todo.type === TodoType.PLAN_DELIVERABLE ||
        this.todo.type === TodoType.PLAN_ACTION_ITEM;
    }
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }

  // eslint-disable-next-line class-methods-use-this
  getPlanLink(todo: ToDoDTO) {
    if (todo.planTodoMeta?.deliverableDisplayName) {
      return {
        path: `/plans/implementation-plan/${todo.planTodoMeta.planId}/details`,
        queryParams: { deliverable_id: todo.planTodoMeta.deliverableId },
      };
    }
    if (todo.planTodoMeta?.actionItemDisplayName) {
      return {
        path: `/plans/implementation-plan/${todo.planTodoMeta.planId}/details`,
        queryParams: { action_item_id: todo.planTodoMeta.actionItemId },
      };
    }
    return undefined;
  }
}
