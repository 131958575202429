import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileLiteDTO } from 'src/app/common/dtos/profile.dto';
import { getTextColor } from 'src/app/common/utilities/contrast-checker';
import { AvatarDTO } from './avatar.dto';
import { avatarDTOFromProfile } from './avatar.translators';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: false,
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() profile: ProfileLiteDTO | null;

  @Input() avatarData: AvatarDTO;

  @Input() hideTooltip = false;

  @Input() userId: number | string;

  @ViewChild('element', { static: false }) element: ElementRef;

  @Input() avatarSize:
    | 'small'
    | 'medium'
    | 'medium-large'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large'
    | 'avatar-side-nav';

  @Input() grayScale: boolean;

  @Input() profileLinkActive = true;

  initials: string;

  initialsColor: string;

  photoUrl: string;

  constructor(private router: Router) {}

  /**
   * Gets the photoUrl from avatar data.
   * If data is not present, it will set the avatar to
   * the users initials.
   *
   * This function sets the variables locally and does not have
   * a return.
   */
  getAvatarData(): void {
    if (this.avatarData.photoUrl) {
      this.photoUrl = this.avatarData.photoUrl;
    } else this.getInitials();
  }

  /**
   * Gets initials from the avatar data.
   *
   * The avatar will then be set to the users initials inside
   * a color circle; dictated by the avatarData color attribute
   *
   * This function sets the variables locally and does not have
   * a return.
   */
  getInitials(): void {
    let initials = '';
    if (this.avatarData.firstName) {
      initials += this.avatarData.firstName[0];
    }
    if (this.avatarData.lastName) {
      initials += this.avatarData.lastName[0];
    }
    this.initials = initials;
    this.initialsColor = getTextColor(this.avatarData.color);
  }

  /**
   * Sets up avatarData from the current profile and updates if profileLink is active.
   *
   * Updated data includes first name, last name, color, and profile photo.
   */
  setupUserData() {
    if (this.profile) {
      this.avatarData = avatarDTOFromProfile(this.profile);
      if (this.profile.title === 'Smart Coach') {
        this.profileLinkActive = false;
      }
    }
    if (this.avatarData) {
      this.getAvatarData();
      if (this.avatarData.lastName.includes('SmartCoach')) {
        this.profileLinkActive = false;
      }
    }
  }

  /**
   * Reroutes the user's portfolio page
   */
  navigateToPortfolio() {
    if (this.profile?.user_id && this.profileLinkActive) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/portfolio/${this.profile.user_id}`])
      );
      window.open(url, '_blank');
    } else if (this.userId) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/portfolio/${this.userId}`])
      );
      window.open(url, '_blank');
    }
  }

  ngOnInit(): void {
    this.setupUserData();
  }

  /**
   *
   * @param changes
   *
   * Sees if the avatarData has changed and does not equal it's current profile
   * or value
   *
   * setupUserData() is subsquentially ran if new changes are detected
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['avatarData'] &&
        changes['avatarData'].previousValue !==
          changes['avatarData'].currentValue) ||
      (changes['profile'] &&
        changes['profile'].previousValue !== changes['profile'].currentValue)
    ) {
      this.setupUserData();
    }
  }
}
