import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { ReportFileService } from '../../shared/services/general/report-file.service';

@Component({
  selector: 'app-reporting-download',
  templateUrl: './reporting-download.component.html',
  styleUrls: ['./reporting-download.component.scss'],
  standalone: false,
})
export class ReportingDownloadComponent implements OnInit {
  subs: Subscription[] = [];

  jobId: string;

  fileName: string;

  fileUrl: string;

  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private store: Store,
    private reportFileService: ReportFileService
  ) {
    this.jobId = this.route.snapshot.paramMap.get('jobId') as string;
  }

  ngOnInit() {
    this.reportFileService.getReportFile(this.jobId).subscribe((item) => {
      if (item) {
        this.fileName = item.file_name;
        this.fileUrl = `${EnvironmentService.apiUrl()}${item.file_path}`;
      }
    });
  }
}
