import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { schoolDTOFromAPIResponse } from '../../helpers/translators/districts.translators';
import { AlertService } from '../../services/alert/alert.service';
import { SchoolService } from '../../services/school.service';
import { CreateSchoolPayload } from '../../types/payloads/rosters.payloads';
import { SchoolAPIResponse } from '../../types/responses/district-list.responses';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-roster-school-create-modal',
  templateUrl: './roster-school-create-modal.component.html',
  styleUrl: './roster-school-create-modal.component.scss',
  standalone: false,
})
export class RosterSchoolCreateModalComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() showModal = false;

  @Input() districtId: number;

  @Output() readonly showModalChange = new EventEmitter();

  @Output() readonly schoolCreated = new EventEmitter();

  @ViewChild('rostersSchoolModal')
  rostersSchoolModal: ModalComponent;

  isOpen = false;

  schoolForm: FormGroup;

  formSubmitted = false;

  isSubmitting = false;

  serverError = '';

  modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private formBuilder: FormBuilder,
    private schoolService: SchoolService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.schoolForm = this.formBuilder.group({
      title: ['', Validators.required],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zip: [''],
      phone: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showModal'].currentValue) {
      this.modalShowing.next(true);
    }
  }

  ngAfterViewInit() {
    this.rostersSchoolModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.rostersSchoolModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.rostersSchoolModal.close();
        this.isOpen = false;
      }
    });
  }

  get f() {
    return this.schoolForm.controls;
  }

  closeModal() {
    this.schoolForm.reset();
    this.formSubmitted = false;
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalShowing.next(false);
  }

  // eslint-disable-next-line consistent-return
  createSchool() {
    this.formSubmitted = true;

    if (this.schoolForm.invalid) {
      return false;
    }

    this.isSubmitting = true;
    const payload: CreateSchoolPayload = {
      title: this.f['title'].value,
      district_id: this.districtId,
      address: this.f['address'].value,
      address2: this.f['address2'].value,
      city: this.f['city'].value,
      state: this.f['state'].value,
      zip: this.f['zip'].value,
      phone: this.f['phone'].value,
    };
    this.schoolService.createSchool(payload).subscribe({
      error: (error) => {
        this.isSubmitting = false;
        this.serverError = error.error.message;
      },
      next: (response) => {
        if (response.item) {
          const newSchool: SchoolAPIResponse = response.item;
          const newSchoolDTO = schoolDTOFromAPIResponse(newSchool);
          this.schoolCreated.next(newSchoolDTO);
          this.closeModal();
          this.isSubmitting = false;
          this.schoolForm.reset();
          setTimeout(() => {
            this.alertService.showAlert(
              ` ${newSchoolDTO.title} has been created`
            );
          }, 500);
        }
      },
    });
  }
}
