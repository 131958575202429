import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import {
  Calendar,
  CalendarOptions,
  EventApi,
  EventChangeArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Subscription, take } from 'rxjs';
import { CalendarEventDTO } from 'src/app/common/dtos/calendar.dto';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { guessTimezone } from 'src/app/common/utilities/time-helpers';

import { EventImpl } from '@fullcalendar/core/internal';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { PdService } from 'src/app/private/shared/services/pd/pd.service';
import { CreateSessionModalComponent } from '../../shared/components/create-session-modal/create-session-modal.component';
import { LogViewDetailsModalComponent } from '../../shared/components/log-view-details-modal/log-view-details-modal.component';
import { DuplicateSessionModalComponent } from '../../shared/components/modals/duplicate-session-modal/duplicate-session-modal.component';
import { PDViewDetailsModalComponent } from '../../shared/components/modals/pd-view-details-modal/pd-view-details-modal.component';
import { TodoViewDetailsModalComponent } from '../../shared/components/modals/todo-view-details-modal/todo-view-details-modal.component';
import { ToDoDTO } from '../../shared/components/todos/card-todos/todo.dto';
import { CoachingSessionDTO } from '../../shared/dtos/coaching-session.dto';
import { PdListFilterDTO, PdSession } from '../../shared/dtos/pd.dto';
import { TodoType } from '../../shared/enums/todo-type.enum';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { TodoService } from '../../shared/services/todo/todo.service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { UpdateLogPayload } from '../../shared/types/payloads/coaching-log.payloads';

@Component({
  selector: 'app-calendar-page',
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
  standalone: false,
})
export class CalendarPageComponent implements OnInit, OnDestroy, AfterViewInit {
  pageTitle = 'Calendar';

  subs: Subscription[] = [];

  @ViewChild('eventCalendar') calendarComponent: FullCalendarComponent;

  @ViewChild('logViewDetailsModal') logModal: LogViewDetailsModalComponent;

  @ViewChild('todoViewDetailsModal')
  todoViewDetailsModal: TodoViewDetailsModalComponent;

  @ViewChild('createSessionModal')
  createSessionModal: CreateSessionModalComponent;

  @ViewChild('duplicateSessionModal')
  duplicateSessionModal: DuplicateSessionModalComponent;

  @ViewChild('pdSessionDetailsModal')
  pdSessionDetailsModal: PDViewDetailsModalComponent;

  calendarApi: Calendar;

  coachlogId = 0;

  loading = false;

  calendarEvents: CalendarEventDTO[] = [];

  businessHours = {
    startTime: '5:00',
    endTime: '19:00',
  };

  calendarView: string;

  calendarOptions: CalendarOptions = {
    plugins: [
      dayGridPlugin,
      bootstrap5Plugin,
      listPlugin,
      interactionPlugin,
      timeGridPlugin,
      interactionPlugin,
    ],
    initialView: 'timeGridWeek',
    themeSystem: 'bootstrap5',
    headerToolbar: {
      start: 'today prev next',
      center: 'title',
      end: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek',
    },
    eventTextColor: 'black',
    height: 600,
    editable: true,
    eventResizableFromStart: true,
    selectable: true,
    businessHours: this.businessHours,
    selectConstraint: this.businessHours,
    events: this.calendarEvents,
    dateClick: this.handleDayTimeEvent.bind(this),
    datesSet: this.handleEvents.bind(this),
    eventClick: this.dateEvent.bind(this),
    eventDrop: this.handleCalendarEventChange.bind(this),
    eventResize: this.handleCalendarEventChange.bind(this),
    eventAllow: this.handleEventAllow.bind(this),
  };

  coachlogSearchOptions: CoachlogSearchPayloadItemsDTO = {
    keywords: '',
    from_date: 0,
    to_date: 0,
    competency_items: [],
    school_items: [],
    coach_items: [],
    coachee_items: [],
    per_page: 1000,
    own_only: 1,
    sort_order: 'DESC',
  };

  pdSearchOptions: PdListFilterDTO = {
    from_date: null,
    to_date: null,
    keyword: '',
    facilitators: [],
    types: [],
    virtual: BooleanNumber.FALSE,
    onsite: BooleanNumber.FALSE,
    categories: [],
    per_page: 1000,
    page: 1,
    include_completed: BooleanNumber.FALSE,
    competencyList: [],
    user_id: 0,
  };

  timeZone: string | null = null;

  sessions: CoachingSessionDTO[];

  sessionsEmpty = false;

  user: User | null = null;

  isCoachUser = false;

  canViewUserSelect = false;

  mscEnabled = false;

  selectedStartDate: number | null;

  selectedStartTime: string | null;

  selectedEndTime: string | null;

  isViewingOtherUserCalendar = false;

  key = 'calendarView';

  duplicateSession: CoachingSessionDTO;

  todo: ToDoDTO;

  todos: ToDoDTO[];

  TodoType = TodoType;

  pdSessions: PdSession[];

  pdSession: PdSession;

  constructor(
    private coachingLogService: CoachingLogService,
    private todoService: TodoService,
    private cdRef: ChangeDetectorRef,
    private featureFlagService: UnleashService,
    private store: Store,
    private pdService: PdService
  ) {
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      if (this.user.roles.includes(UserRole.COACH)) {
        this.isCoachUser = true;
      }

      if (
        this.user.roles.includes(UserRole.SCHOOL_ADMIN) ||
        this.user.roles.includes(UserRole.DISTRICT_ADMIN) ||
        this.user.roles.includes(UserRole.E2L_EMPLOYEE)
      ) {
        this.canViewUserSelect = true;
      }
    }
  }

  ngOnInit(): void {
    const view = window.localStorage.getItem(this.key);
    if (view) {
      this.calendarView = JSON.parse(view);
      this.calendarOptions = {
        ...this.calendarOptions,
        initialView: this.calendarView,
      };
    }

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getSessions();
      })
    );

    this.subs.push(
      this.coachingLogService.coachlogDeleted.subscribe(() => {
        this.getSessions();
      })
    );
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();

    const transferDate = new Date(this.calendarApi.view.activeEnd).getTime();
    const startDate = new Date(this.calendarApi.view.activeStart).getTime();

    this.coachlogSearchOptions.to_date = transferDate / 1000;
    this.coachlogSearchOptions.from_date = startDate / 1000;

    this.cdRef.detectChanges();
  }

  getTimeZone(calendarTimeZone: string): void {
    if (calendarTimeZone === 'local') {
      this.timeZone = guessTimezone(new Date());
    } else {
      this.timeZone = calendarTimeZone;
    }
  }

  getSessions(): void {
    this.loading = true;

    const includePrivateLogs = 1;

    this.coachingLogService.setFilters(this.coachlogSearchOptions);

    this.coachingLogService.getSessions(includePrivateLogs).subscribe((res) => {
      take(1);
      if (res) {
        this.sessions = res[0];
        if (this.sessions.length === 0) {
          this.sessionsEmpty = true;
        }
      }

      this.calendarEvents = this.sessions.map((session) => {
        const startDate = new Date(session.startDatetime * 1000);
        const endDate = new Date(session.endDatetime * 1000);
        let eventColor;
        const isSmart = session.type.isSmart;
        let durationEditable = false;
        let editable = false;
        if (isSmart && this.user?.id === session.attendees[0].userId) {
          editable = true;
        } else if (
          this.isCoachUser &&
          this.user?.id === session.user.id &&
          !isSmart
        ) {
          editable = true;
          durationEditable = true;
        }
        switch (session.type.view) {
          case 'universal-log':
            eventColor = '#DFD0F3';
            break;
          case 'egrowe-coachlog-v2':
            if (isSmart) {
              eventColor = '#F1C1E8';
            } else {
              eventColor = '#DFD0F3';
            }
            break;
          case 'observation':
            eventColor = '#C6DEEC';
            break;
          default:
            eventColor = '#FFBCB4';
        }
        return {
          id: session.id,
          title: session.title,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          classNames: [session.type.view, `msc-${isSmart}`],
          backgroundColor: eventColor,
          borderColor: eventColor,
          constraint: isSmart ? null : 'businessHours',
          editable,
          durationEditable,
          allDay: isSmart,
        };
      });

      // todos only for your own calendar
      if (!this.isViewingOtherUserCalendar) {
        this.getTodos();
      } else {
        this.loading = false;
      }

      // get Training Sessions
      this.getPD();

      // Accessibility
      this.calendarApi.el
        .querySelector('.fc-scroller-liquid-absolute')
        ?.setAttribute('tabindex', '0');

      this.calendarApi.el
        .querySelector('table.fc-scrollgrid.fc-scrollgrid-liquid')
        ?.setAttribute('role', '');

      this.calendarApi.el
        .querySelector('tbody[role="rowgroup"]')
        ?.setAttribute('role', '');
    });
  }

  getTodos() {
    this.subs.push(
      this.todoService.getTodos().subscribe((res) => {
        if (res) {
          this.calendarEvents = this.calendarEvents.concat(
            this.mapTodosToEvents(res)
          );
          this.todos = res;
          this.getPlanTodos();
        }
      })
    );
  }

  getPlanTodos() {
    if (this.user) {
      this.subs.push(
        this.todoService
          .getImplementationPlanTodos(this.user.id)
          .subscribe((res) => {
            if (res) {
              this.calendarEvents = this.calendarEvents.concat(
                this.mapTodosToEvents(res)
              );
              this.todos = this.todos.concat(res);
              this.loading = false;
            }
          })
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mapTodosToEvents(todos: ToDoDTO[]) {
    const eventColor = '#A3D9A5';
    return todos.map((todo) => {
      // Convert the Unix timestamp to a Date object in UTC
      const utcStartDate = new Date(todo.dueDate * 1000);

      // Adjust for the local time zone offset to make it start in local time
      const localStartDate = new Date(
        utcStartDate.getTime() + utcStartDate.getTimezoneOffset() * 60 * 1000
      );
      const localEndDate = new Date(localStartDate);
      localEndDate.setDate(localStartDate.getDate() + 1);

      return {
        id: todo.id,
        title: todo.content,
        start: localStartDate.toISOString(),
        end: localEndDate.toISOString(),
        backgroundColor: eventColor,
        borderColor: eventColor,
        constraint: 'businessHours',
        editable: false,
        durationEditable: false,
        allDay: true,
        classNames: [todo.type],
      };
    });
  }

  getPD() {
    this.loading = true;

    // copy the filters
    const startDaterange = new Date(this.calendarApi.view.activeStart);
    const endDaterange = new Date(this.calendarApi.view.activeEnd);

    this.pdSearchOptions.from_date = new NgbDate(
      startDaterange.getFullYear(),
      startDaterange.getMonth() + 1,
      startDaterange.getDate()
    );
    this.pdSearchOptions.to_date = new NgbDate(
      endDaterange.getFullYear(),
      endDaterange.getMonth() + 1,
      endDaterange.getDate()
    );

    if (this.user?.id) {
      this.pdSearchOptions.user_id = this.user.id;
    }
    if (this.coachlogSearchOptions.own_only_user_id) {
      this.pdSearchOptions.user_id =
        this.coachlogSearchOptions.own_only_user_id;
    }
    this.pdSearchOptions.include_completed = 1;

    this.subs.push(
      this.pdService.getSessions(this.pdSearchOptions).subscribe((resp) => {
        if (resp.items) {
          this.pdSessions = resp.items;
          this.calendarEvents = this.calendarEvents.concat(
            this.mapPDToEvents(resp.items)
          );
        }
        this.loading = false;
      })
    );
    /* this.todoService.getTodos().subscribe((res) => {
        if (res) {
          this.calendarEvents = this.calendarEvents.concat(
            this.mapTodosToEvents(res)
          );
          this.todos = res;
          this.getPlanTodos();
        }
      }) */
  }

  // eslint-disable-next-line class-methods-use-this
  mapPDToEvents(events: PdSession[]) {
    const eventColor = '#fcd690';
    return events.map((event) => {
      // Convert the Unix timestamp to a Date object in UTC
      const startDate = new Date(event.start_datetime * 1000);
      const endDate = new Date(event.end_datetime * 1000);

      return {
        id: `pd:${event.id}`,
        title: event.title,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        backgroundColor: eventColor,
        borderColor: eventColor,
        constraint: 'businessHours',
        editable: false,
        durationEditable: false,
        allDay: false,
        classNames: ['pd'],
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEvents(event: EventApi | any): void {
    window.localStorage.setItem(this.key, JSON.stringify(event.view.type));
    const endDate = new Date(event.view.activeEnd).getTime();
    const startDate = new Date(event.view.activeStart).getTime();

    this.coachlogSearchOptions.to_date = endDate / 1000;
    this.coachlogSearchOptions.from_date = startDate / 1000;

    this.getTimeZone(event.view.dateEnv.timeZone);
    this.getSessions();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateEvent(event: EventApi | any) {
    const { classNames, id, title } = event.event;

    if (
      [
        'implementation_deliverable',
        'implementation_action_item',
        'growelab',
        'egrowe',
      ].includes(classNames[0])
    ) {
      const matchedTodo = this.todos.find((todo) => todo.content === title);
      if (matchedTodo) {
        this.todo = matchedTodo;
        if (
          this.todo.type === TodoType.PLAN_DELIVERABLE ||
          this.todo.type === TodoType.PLAN_ACTION_ITEM
        ) {
          this.todoViewDetailsModal.modal.config.titleText =
            'Implementation Plan To-Do';
        } else {
          this.todoViewDetailsModal.modal.config.titleText = 'To-Do';
        }
        this.todoViewDetailsModal.openModal();
      }
    } else if (['pd'].includes(classNames[0])) {
      const matchedSession = this.pdSessions.find(
        (pdSession) => `pd:${pdSession.id}` === id
      );
      if (matchedSession) {
        this.pdSession = matchedSession;
      }
      this.pdSessionDetailsModal.modal.config.titleText = 'PD Session';
      this.pdSessionDetailsModal.openModal();
    } else {
      this.coachlogId = id;
      this.logModal.openModal();
    }
  }

  showSelectedUserCalendar(user: UserLiteDTO | null) {
    if (user) {
      this.pageTitle = `${user.profile.first_name} ${user.profile.last_name}'s Calendar`;
      this.coachlogSearchOptions.own_only_user_id = user.id;
      this.calendarOptions.dateClick = undefined;
      this.getSessions();
      this.isViewingOtherUserCalendar = true;
    }
  }

  clearSelectedUser() {
    this.pageTitle = 'Calendar';
    if (this.user) {
      this.coachlogSearchOptions.own_only_user_id = this.user.id;
    }
    this.calendarOptions.dateClick = this.handleDayTimeEvent.bind(this);
    this.getSessions();
    this.isViewingOtherUserCalendar = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDayTimeEvent(event: EventApi | any) {
    const selectedDate = new Date(event.date);
    const businessHours =
      event.jsEvent.target.classList.value !== 'fc-non-business';
    this.selectedStartDate = selectedDate.getTime() / 1000;
    if (businessHours) {
      if (!event.allDay) {
        this.selectedStartTime = `${selectedDate.getHours()}:${
          selectedDate.getMinutes() ? selectedDate.getMinutes() : '00'
        }`;
        this.selectedEndTime = `${selectedDate.getHours() + 1}:${
          selectedDate.getMinutes() ? selectedDate.getMinutes() : '00'
        }`;
      } else {
        this.selectedStartTime = '5:00';
        this.selectedEndTime = '19:00';
      }
      this.createSessionModal.openModal();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCalendarEventChange(changeEvent: EventChangeArg | any) {
    const isSmart = changeEvent.event.classNames.includes('msc-true');
    const newStartDate = new Date(changeEvent.event.start);
    const newEndDate = new Date(changeEvent.event.end);
    const coachLogId = changeEvent.event._def.publicId;
    const payload = {} as UpdateLogPayload;
    payload.start_datetime = newStartDate.getTime() / 1000;
    if (isSmart && payload.start_datetime) {
      this.coachingLogService.updateSmartLogDate(
        coachLogId,
        payload.start_datetime + 60 * 60 * 10 // change default midnight start time to 10AM local
      );
    } else {
      payload.end_datetime = newEndDate.getTime() / 1000;
      this.coachingLogService.updateCoachingLog(coachLogId, payload);
    }
  }

  // eslint-disable-next-line
  handleEventAllow(dropInfo: EventApi | any, draggedEvent: EventImpl | any) {
    return (
      (draggedEvent.classNames.includes('msc-true') && dropInfo.allDay) ||
      (!draggedEvent.classNames.includes('msc-true') && !dropInfo.allDay)
    );
  }

  updateDuplicateSessionDate(session: CoachingSessionDTO) {
    this.duplicateSession = session;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
    if (this.createSessionModal) this.createSessionModal.closeModal();
  }
}
