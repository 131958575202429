import { Component, Input, OnInit } from '@angular/core';

import { ProfileLiteDTO } from '../../../../common/dtos/profile.dto';
import { CachingService } from '../../services/storage/caching.service';
import { UserSearchService } from '../../services/user-search/user-search.service';

@Component({
  selector: 'app-users-name',
  templateUrl: './users-name.component.html',
  styleUrls: ['./users-name.component.scss'],
  providers: [UserSearchService],
  standalone: false,
})
export class UsersNameComponent implements OnInit {
  @Input() userId: number | undefined;

  @Input() userProfile: ProfileLiteDTO;

  @Input() firstNameOnly = false;

  profile: ProfileLiteDTO;

  constructor(
    private userSearch: UserSearchService,
    private cachingService: CachingService
  ) {}

  ngOnInit() {
    if (this.userProfile) {
      this.profile = this.userProfile;
    } else if (this.userId) {
      const cachedProfile = this.cachingService.getUserIdProfile(this.userId);
      if (cachedProfile) {
        this.profile = cachedProfile;
      } else {
        this.userSearch.getUser(this.userId).subscribe((response) => {
          if (response) {
            if (this.userId) {
              this.cachingService.setUserIdProfile(
                this.userId,
                response.profile
              );
            }
            this.profile = response.profile;
          }
        });
      }
    }
  }
}
