import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { AlertService } from '../../shared/services/alert/alert.service';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { ReportService } from '../../shared/services/report/report.service';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { ReportingState } from '../../shared/state/reporting/reporting.state';
import { ReportFiltersDTO } from '../reporting-page/filters/report-filters.dto';

@Component({
  selector: 'app-reporting-badge-progress',
  templateUrl: './reporting-badge-progress.component.html',
  styleUrls: ['./reporting-badge-progress.component.scss'],
  standalone: false,
})
export class ReportingBadgeProgressComponent implements OnInit, OnDestroy {
  chartTypes = ChartType;

  selectedSchool: SchoolDTO;

  selectedDistricts: DistrictSimpleDto[];

  canFilterByDistrict = false;

  badgeProgressParams: ReportRequestParams;

  disableGIGrowthDownload = false;

  disableBadgesEarnedDownload = false;

  disableMicrocredentialProgressDownload = false;

  giGrowthReportEnabled = false;

  defaultFilters: KpiReportFilter[];

  subs: Subscription[] = [];

  microcredentialDrilldownType = 'competency';

  selectedSchoolId = 0;

  lastUpdatedDate: string;

  constructor(
    private reportPageFiltersService: ReportPageFiltersService,
    private reportService: ReportService,
    private alertService: AlertService,
    private featureFlagService: UnleashService,
    private store: Store
  ) {
    const lastUpdatedDate$ = this.store.select(ReportingState.getUpdatedDate);
    lastUpdatedDate$.subscribe((date) => {
      this.lastUpdatedDate = date;
    });
    const user = this.store.selectSnapshot(UserState.getUser) as User;
    this.selectedDistricts = [user.district as DistrictSimpleDto];
    this.canFilterByDistrict = checkIfE2L(user) || checkIfB2b(user);
    this.giGrowthReportEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.giGrowthReport
    );
  }

  downloadCSV(report: string): void {
    if (report === 'gi_growth') {
      this.disableGIGrowthDownload = true;
    } else {
      this.disableBadgesEarnedDownload = true;
    }
    this.reportPageFiltersService.queueReportDownload(report);
  }

  downloadBadgeProgressCSV() {
    this.disableMicrocredentialProgressDownload = true;

    let reportType = 'microCredentialProgressDownloadBySchools';
    if (this.microcredentialDrilldownType === 'learner') {
      reportType = 'microCredentialProgressDownloadByLearners';
    }

    let downloadFilters = {} as ReportFiltersDTO;
    downloadFilters =
      this.reportPageFiltersService.currentFiltersDictionary['badge_progress'];

    let filters = translateKpiReportFilterFromFilterDTO(downloadFilters);

    if (this.selectedSchoolId) {
      filters = [
        ...filters,
        {
          codename: 'school',
          title: 'School',
          value: [this.selectedSchoolId.toString()],
          displayName: 'School',
        },
      ];
    }

    this.subs.push(
      this.reportService.generateReportCSV(reportType, filters).subscribe({
        error: (res) => {
          if (res.status === 404) {
            this.alertService.showAlert('Search did not return any results.');
          }
        },
        next: (res) => {
          if (res.has_results) {
            // res.file_name should always start with a slash, but need to be sure for the link to work properly
            if (!res.file_name.startsWith('/')) {
              res.file_name = `/${res.file_name}`;
            }
            window.open(
              `${EnvironmentService.apiUrl()}${res.file_name}`,
              '_blank'
            );
            this.disableMicrocredentialProgressDownload = false;
          } else {
            this.alertService.showAlert('Search did not return any results.');
            this.disableMicrocredentialProgressDownload = false;
          }
        },
      })
    );
  }

  ngOnInit(): void {
    this.subs.push(
      this.reportPageFiltersService.filters.subscribe((filters) => {
        if (
          filters.schoolYearsFilter &&
          filters.schoolYearsFilter.length > 0 &&
          filters.reportName === 'badges_earned_growth'
        ) {
          this.defaultFilters = translateKpiReportFilterFromFilterDTO(filters);
        }
      })
    );
  }

  updateMicrocredentialDrilldownType(drilldownType: string): void {
    this.microcredentialDrilldownType = drilldownType;
  }

  changeSchool(schoolId: number) {
    this.selectedSchoolId = schoolId;
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
