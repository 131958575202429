import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import { EvidenceDTO } from '../../dtos/attendee-rubric.dto';

@Component({
  selector: 'app-card-evidence-for-review-item',
  templateUrl: './card-evidence-for-review-item.component.html',
  styleUrls: ['./card-evidence-for-review-item.component.scss'],
  standalone: false,
})
export class CardEvidenceForReviewItemComponent implements OnInit {
  @Input() profile: ProfileDTO;

  @Input() evidence: EvidenceDTO;

  @Input() dateAdded: number;

  @Output() readonly evidenceAssesed: EventEmitter<EvidenceAssessedDTO> =
    new EventEmitter();

  dateString: string;

  ngOnInit(): void {
    this.dateString = new Date(this.dateAdded * 1000).toDateString();
  }

  handleAssessment(response: EvidenceAssessedDTO) {
    this.evidenceAssesed.next(response);
  }
}
