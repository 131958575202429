import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { Animations } from 'src/app/common/utilities/animations';
import { confirmPasswordValidator } from 'src/app/private/shared/components/new-password/password-validators/password-validators.directive';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [Animations.fadeInOut, Animations.delayfadeInOut],
  standalone: false,
})
export class ChangePasswordComponent implements OnInit {
  newPasswordForm: FormGroup;

  resetToken: string;

  submitted = false;

  showPassword = false;

  successful = false;

  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.route.queryParams.subscribe((params) => {
      this.resetToken = params['token'];
    });
  }

  ngOnInit() {
    this.newPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validators: confirmPasswordValidator() }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newPasswordForm.controls;
  }

  changePassword() {
    this.submitted = true;

    if (this.newPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService
      .resetPassword(this.resetToken, this.f['password'].value)
      .subscribe({
        error: (error) => {
          const errorMessage = error.error.message.toLowerCase();
          if (
            errorMessage === 'wrong password reset token.' ||
            errorMessage === 'no password token'
          ) {
            this.resetToken = '';
          }
          this.loading = false;
        },
        next: () => {
          this.loading = false;
          this.router.navigate(['/login']);
        },
      });
  }
}
