<div *ngIf="session && !session.is_completed" class="img-bg">
  <img src="/assets/bg-session-pre.jpg" />
</div>
<div class="container">
  <div
    class="d-flex justify-content-between align-items-center mb-4"
    style="max-width: 1215px">
    <div class="col-9">
      <div class="fs-17 fw-800">
        <a href="/training/catalog" style="color: #000"
          >< Back to Learning &amp; Events</a
        >
      </div>
    </div>
    <div class="col-3 col-md-auto">
      <app-action-buttons />
    </div>
  </div>
  <div class="card session-details-card border-0">
    <ng-container *ngIf="session; else loadingTemplate">
      <div [ngClass]="isFacilitator ? 'extra-padding' : ''">
        <div class="d-flex justify-content-between">
          <div class="event fs-14 fw-600 pb-3">
            <span class="fw-800">Event</span>: {{ session.event?.title }}
          </div>
          <div *ngIf="(isFacilitator || isCreator) && !session.is_completed">
            <button
              class="btn-reset fw-600 fs-16 ms-4"
              style="white-space: nowrap"
              (click)="editSessionModal.modal.open()">
              <i class="bi bi-pencil color-tertiary fs-12 me-1"></i> Edit
              Session Details
            </button>
            <app-edit-pd-session-modal
              [session]="session"
              (sessionUpdated)="updateSession($event)"
              (sessionDeleted)="navigateToCatalog()"
              #editSessionModal></app-edit-pd-session-modal>
          </div>
        </div>
        <div class="date-tab">
          <div class="date-box justify-content-center align-items-center">
            <div class="month fw-800">
              {{ session.start_datetime * 1000 | date : "MMM" | uppercase }}
            </div>
            <div class="day fw-800">
              {{ session.start_datetime * 1000 | date : "dd" }}
            </div>
          </div>
          <div class="vert-div"></div>
        </div>

        <div class="header-content">
          <div class="title fs-26 fw-800 mb-3">
            {{ session.title }}
          </div>
          <div class="fs-23">
            {{ session.start_datetime | formatDateOrdinal }},
            <span style="text-transform: lowercase">
              {{ session.start_datetime * 1000 | date : "hh:mma" }}
              <span *ngIf="session.end_datetime">
                - {{ session.end_datetime * 1000 | date : "hh:mma" }}
              </span></span
            >
            {{ abbrTimezone }}
          </div>
        </div>

        <div class="session-data">
          <!--///////////////////////////////////////////// -->
          <!-- CONTENT  /////////////////////////////////// -->
          <!--///////////////////////////////////////////// -->
          <div class="content">
            <div class="d-flex justify-content-start mb-5">
              <div
                class="session-image me-4"
                [ngStyle]="{
                  'background-image': backgroundImage
                }"></div>
              <div class="session-desc">
                {{ session.description }}
              </div>
            </div>

            <div class="item">
              <div class="label">
                Facilitator<span
                  *ngIf="
                    session.facilitators && session.facilitators.length > 1
                  "
                  >s</span
                >:
              </div>
              <div class="d-flex flex-wrap w-100">
                <ng-container
                  *ngIf="
                    session.facilitators && session.facilitators.length > 0
                  ">
                  <ng-container
                    *ngFor="let facilitator of session.facilitators">
                    <div
                      class="d-flex justify-content-start align-items-center fs-14 mb-3 mx-2"
                      style="min-width: 30%">
                      <app-avatar
                        [hideTooltip]="true"
                        [profile]="facilitator.user.profile"
                        [avatarSize]="'small'"
                        [profileLinkActive]="true" />
                      <span class="ms-2">
                        {{ facilitator.user.profile.first_name }}
                        {{ facilitator.user.profile.last_name }}</span
                      >
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div *ngIf="isFacilitator" class="item">
              <div class="label">Attendance Code:</div>
              <div>
                <button
                  class="btn-reset p-0"
                  (click)="copyCode(session.attendance_code)">
                  {{ session.attendance_code }} <i class="bi bi-copy ms-1"></i>
                </button>
              </div>
            </div>
            <div class="item-columns">
              <div class="item">
                <div class="label">Type:</div>
                {{ session.type }}
              </div>
              <div class="item">
                <div class="label">Time:</div>
                {{ duration }}
              </div>
              <ng-container *ngIf="!session.is_virtual">
                <div class="item">
                  <div class="label">Location:</div>
                  {{ session.location }}
                </div>
              </ng-container>
              <div class="item d-flex">
                <div class="label">Credits:</div>
                <div class="credits-wrapper">
                  <ng-container
                    *ngIf="session.credits && session.credits.length > 0">
                    <ng-container *ngFor="let credit of session.credits">
                      <div class="credit-item">
                        <div class="amt">{{ credit.credit_amount }}</div>
                        <div class="type">{{ credit.credit_type }}</div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <ng-container *ngIf="session.is_virtual">
              <div class="item">
                <div class="label">Virtual Session:</div>
                {{ session.virtual_link }}
              </div>
            </ng-container>

            <!--///////////////////////////////////////////// -->
            <!--NOTES ///////////////////////////////////// -->
            <!--///////////////////////////////////////////// -->
            <ng-container *ngIf="isRegistered || isFacilitator">
              <div class="item d-flex m4-5">
                <div class="label fs-18">
                  Notes:
                  <i
                    *ngIf="isFacilitator"
                    class="bi bi-info-circle-fill mx-1 fs-6"
                    ngbTooltip="Notes are only visible to registered attendees"></i>
                </div>

                <ng-container *ngIf="!isFacilitator; else editNotes">
                  <div>
                    <app-safehtml html="{{ session.notes }}"></app-safehtml>
                  </div>
                </ng-container>
                <ng-template #editNotes>
                  <div class="w-100">
                    <app-wysiwyg-editor
                      #notesEditor
                      [editorContent]="initialNotesContent"
                      (editorContentEvent)="updateNotes($event)"
                      [version]="'mini'"
                      [isSaving]="isSavingNotes"></app-wysiwyg-editor>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <!--///////////////////////////////////////////// -->
            <!--ATTENDEE MGMT //////////////////////////////// -->
            <!--///////////////////////////////////////////// -->
            <ng-container *ngIf="isFacilitator">
              <div class="attmgmt-header fs-26 fw-600">
                Attendee Management:
              </div>
              <!-- USER CREDIT FORM -->
              <form [formGroup]="usersCreditForm">
                <table class="table attendee-table">
                  <thead>
                    <tr>
                      <th>ATTENDEE</th>
                      <th
                        *ngIf="!earnedCredits"
                        class="text-center"
                        style="width: 100px">
                        PRESENT
                      </th>
                      <th>
                        <div class="text-start">
                          <div>CREDITS</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="!session.is_completed">
                      <ng-container
                        *ngIf="
                          usersCreditForm.value.users &&
                            usersCreditForm.value.users.length > 0;
                          else attmessage
                        ">
                        <ng-container formArrayName="users">
                          <ng-container
                            *ngFor="let user of users.controls; let i = index">
                            <tr [formGroupName]="i" class="mb-4XXX">
                              <td>
                                <div
                                  [ngClass]="{
                                    'not-present': !user.value.is_present
                                  }"
                                  class="d-flex align-items-center fs-16 mb-3">
                                  <app-avatar
                                    [hideTooltip]="true"
                                    [profile]="user.value.user_profile"
                                    [avatarSize]="'medium'"
                                    [profileLinkActive]="true" />
                                  <span class="ms-2 fw-800">
                                    {{ user.value.user_profile.first_name }}
                                    {{
                                      user.value.user_profile.last_name
                                    }}</span
                                  >
                                  <button
                                    aria-label="remove attendee button"
                                    class="btn btn-delete btn-sm"
                                    type="button"
                                    ngbTooltip="Remove Attendee"
                                    [autoClose]="'outside'"
                                    [ngbPopover]="popoverContent"
                                    [popoverTitle]="'Remove Attendee'"
                                    #p="ngbPopover"
                                    #t="ngbTooltip"
                                    [container]="'body'"
                                    (click)="t.close()">
                                    <i class="bi bi-x-circle-fill"></i>
                                  </button>
                                  <ng-template #popoverContent>
                                    <div>
                                      Are you sure you wish to remove this
                                      attendee?
                                    </div>
                                    <div class="app-hr-thin"></div>
                                    <div class="text-end mt-2">
                                      <button
                                        type="button"
                                        class="btn btn-secondary btn-sm px-3 m-1"
                                        (click)="p.close()">
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-primary btn-sm px-3 m-1"
                                        (click)="
                                          unregisterUser(
                                            sessionId,
                                            user.value.user_id
                                          );
                                          p.close()
                                        ">
                                        Yes
                                      </button>
                                    </div>
                                  </ng-template>
                                </div>
                              </td>
                              <td class="text-center">
                                <input
                                  class="form-check-input mt-2"
                                  (change)="
                                    onPresentCheckboxChange(
                                      $event,
                                      user.value.user_profile.user_id
                                    )
                                  "
                                  formControlName="is_present"
                                  type="checkbox"
                                  id="is-present" />
                              </td>
                              <td style="width: 40%">
                                <ng-container *ngIf="user.value.is_present"
                                  ><ng-container
                                    formArrayName="credits"
                                    *ngFor="
                                      let credit of getCreditsFor(i);
                                      let creditIndex = index
                                    ">
                                    <div
                                      [formGroupName]="creditIndex"
                                      class="mb-2">
                                      <div
                                        class="d-flex justify-content-between align-items-center mb-2">
                                        <div
                                          class="fw-800"
                                          style="flex-grow: 1">
                                          {{ credit.value.credit_type }}
                                        </div>
                                        <div class="text-danger fs-13 mx-3">
                                          <div
                                            *ngIf="credit.errors?.['required']">
                                            Required
                                          </div>
                                          <div
                                            *ngIf="credit.errors?.['maxValueExceeded']">
                                            Exceeds max limit
                                          </div>
                                        </div>
                                        <input
                                          type="number"
                                          min="0"
                                          [max]="credit.value.credit_max_value"
                                          class="form-control credit-input"
                                          formControlName="credit_value"
                                          [ngClass]="{
                                            'is-invalid': credit.invalid
                                          }" />
                                      </div>
                                    </div> </ng-container
                                ></ng-container>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-template #attmessage>
                        <tr>
                          <td colspan="3">
                            <div class="no-content-message">
                              No attendees for this event
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </ng-container>
                    <!--///////////////////////////////////////////// -->
                    <!-- SHOW READONLY DATA IF SESSION IS COMPLETED /-->
                    <!--///////////////////////////////////////////// -->
                    <ng-container *ngIf="session.is_completed && earnedCredits">
                      <ng-container *ngFor="let earnedCredit of earnedCredits">
                        <tr>
                          <td>
                            <div class="d-flex align-items-center fs-16 mb-3">
                              <app-avatar
                                [hideTooltip]="true"
                                [profile]="earnedCredit.user.profile"
                                [avatarSize]="'medium'"
                                [profileLinkActive]="true" />
                              <span class="ms-2 fw-800">
                                {{ earnedCredit.user.profile.first_name }}
                                {{ earnedCredit.user.profile.last_name }}</span
                              >
                            </div>
                          </td>

                          <td style="width: 40%">
                            <ng-container
                              *ngFor="let credit of earnedCredit.credits">
                              <div
                                class="d-flex justify-content-between align-items-center mb-2">
                                <div class="fw-800" style="flex-grow: 1">
                                  {{ credit.credit_type }}
                                </div>
                                <div>
                                  {{ credit.credit_value }}
                                </div>
                              </div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </tbody>
                </table>
                <ng-container *ngIf="!session.is_completed">
                  <div class="add-att">
                    <div class="label me-3 fw-600">+ Add Attendee:</div>
                    <div class="att-select">
                      <app-user-select
                        #attendeeSelect
                        [clearAfterSelection]="true"
                        [isMulti]="false"
                        [includeAllB2bDistricts]="true"
                        (selectedUser)="registerUser($event)" />
                    </div>
                  </div>
                  <div
                    *ngIf="
                      usersCreditForm.value.users &&
                      usersCreditForm.value.users.length > 0
                    "
                    class="complete text-center">
                    <button
                      class="btn btn-primary btn-largexx btn-complete fw-800"
                      [ngbPopover]="sessionCompletePopover"
                      #downloadCSVPopover="ngbPopover"
                      [disabled]="usersCreditForm.invalid || formSubmitting">
                      Complete Session and Award Credits
                    </button>
                    <ng-template #sessionCompletePopover>
                      Completing a session awards credits, certificates, and
                      ends the session. Attendees and credits cannot be modified
                      once a session is completed.
                      <p>Do you want to complete the session now?</p>
                      <div class="text-end mt-2">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm px-3 m-1"
                          (click)="downloadCSVPopover.close()">
                          No
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm px-3 m-1"
                          (click)="
                            completeSession(); downloadCSVPopover.close()
                          ">
                          Yes
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </ng-container>
              </form>
            </ng-container>
          </div>
          <div *ngIf="!isFacilitator" class="divider"></div>
          <!--///////////////////////////////////////////// -->
          <!-- SIDEBAR  /////////////////////////////////// -->
          <!--///////////////////////////////////////////// -->
          <div *ngIf="!isFacilitator" class="sidebar">
            <div class="sub-header"><span>REGISTRATION</span></div>
            <div class="item d-flex justify-content-between">
              <div class="label">Registration Window:</div>
              <div>
                {{
                  session.registration_start_datetime
                    | formatDateOrdinal : "MMM d"
                }}
                <span
                  *ngIf="
                    session.registration_end_datetime &&
                    session.registration_end_datetime !== null
                  ">
                  -
                  {{
                    session.registration_end_datetime
                      | formatDateOrdinal : "MMM d"
                  }}</span
                >
              </div>
            </div>
            <div class="item d-flex justify-content-between">
              <div class="label">Attendance Cap:</div>
              <div>{{ session.max_registrations }}</div>
            </div>
            <div class="item d-flex justify-content-between">
              <div class="label">Registered Users:</div>
              <div>{{ session.attendees.length }}</div>
            </div>
            <div class="mt-4 mb-5 text-center">
              <button
                *ngIf="
                  !isRegistered &&
                  !session.is_completed &&
                  !sessionOverdue &&
                  isInRegistrationWindow &&
                  !hasReachedMaxRegistrations
                "
                type="button"
                class="btn btn-primary btn-register fw-800"
                style="font-size: 18px"
                (click)="selfRegister(session.id)">
                Register
              </button>
              <button
                *ngIf="isRegistered && !session.is_completed && !sessionOverdue"
                type="button"
                class="btn btn-secondary btn-register fw-800"
                style="font-size: 18px; color: #fff"
                (click)="unregisterUser(session.id, user.id)">
                Cancel Registration
              </button>
            </div>

            <ng-container *ngIf="isRegistered && !session.is_completed">
              <div class="sub-header"><span>ATTENDANCE</span></div>
              <div class="item">
                <ng-container *ngIf="!isPresent; else present">
                  <div class="fw-800 fs-12 mb-2">ENTER ATTENDANCE CODE</div>
                  <form #codeForm="ngForm" (ngSubmit)="attCodeSubmit()">
                    <div class="input-group">
                      <input
                        type="text"
                        id="attcode"
                        #attcode="ngModel"
                        name="attcode"
                        class="form-control"
                        [(ngModel)]="attendanceCode"
                        maxlength="4"
                        required
                        [ngClass]="{
                          'is-invalid': attcode.invalid && attcode.touched
                        }" />
                      <button
                        arai-label="Apply Attendance code"
                        type="submit"
                        class="btn btn-primary fs-12 fw-800">
                        <span
                          *ngIf="attendanceLoading"
                          class="spinner-border spinner-border-sm me-1"></span
                        >Apply
                      </button>
                    </div>
                    <ng-container *ngIf="errorMessage">
                      <div class="alert alert-danger text-center mt-2 mb-3 p-2">
                        {{ errorMessage }}
                      </div>
                    </ng-container>
                  </form>
                </ng-container>
                <ng-template #present>
                  <div class="fs-16 pb-3">
                    You have been marked present for this session.
                  </div>
                </ng-template>
              </div>
            </ng-container>

            <!-- ONLY IF COMPLETED  -->
            <ng-container *ngIf="session.is_completed">
              <ng-container *ngIf="viewingUser && (isPresent || selAttendeeId)">
                <div class="sub-header"><span>ATTENDANCE</span></div>
                <div class="item">
                  <div class="fs-16 pb-3">
                    {{ viewingUser.profile.first_name }} attended and completed
                    this session on
                    {{ session.start_datetime | formatDateOrdinal : "MMM d" }},
                    {{ session.start_datetime * 1000 | date : "yyy" }}
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="creditsReceived && (isPresent || selAttendeeId)">
                <div class="sub-header"><span>CREDITS AWARDED</span></div>
                <div class="item">
                  <ng-container
                    *ngIf="
                      creditsReceived.credits &&
                      creditsReceived.credits.length > 0
                    ">
                    <ng-container *ngFor="let credit of creditsReceived.credits"
                      ><div
                        *ngIf="credit.credit_type"
                        class="d-flex justify-content-between align-items-center mb-3">
                        <div class="fs-13 fw-800">
                          {{ credit.credit_type }}:
                        </div>
                        <div class="fs-15 ms-2">{{ credit.credit_value }}</div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #nocredits>
                    No credits are available
                  </ng-template>
                </div>
                <div class="item text-center mb-5">
                  <a
                    href="{{ creditsReceived.certificate }}"
                    target="_blank"
                    class="fs-16">
                    <i class="bi-file-pdf"></i> Certificate of Completion
                  </a>
                </div>
              </ng-container>
              <ng-container
                *ngIf="isPresent && session && session.survey_proform_form_id">
                <div class="sub-header"><span>SURVEY</span></div>
                <div class="item">
                  <div class="fs-16 pb-3 text-center">
                    <button
                      type="button"
                      class="btn btn-primary btn-register fw-800"
                      style="font-size: 18px"
                      (click)="takeSurvey()">
                      TAKE SURVEY
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="w-100" style="height: 400px">
        <app-loader />
      </div>
    </ng-template>
  </div>
</div>
<app-proform-survey-modal
  *ngIf="session"
  [formId]="session.survey_proform_form_id"
  #surveyModal />
