import { Component, Input, OnInit } from '@angular/core';

import { FroalaOptions } from '../../../wysiwyg-editor/wysiwyg-editor.component';
import { AbstractViewFieldComponent } from '../base/abstract-view-field';

@Component({
  selector: 'app-form-view-wysiwyg',
  templateUrl: './view-wysiwyg.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
  standalone: false,
})
export class FormViewWysiwygComponent
  extends AbstractViewFieldComponent
  implements OnInit
{
  userInput: string;

  @Input() initialValue: string | undefined;

  editorConfig: FroalaOptions;

  ngOnInit(): void {
    this.editorConfig = {
      editorClass: 'form full',
      toolbarBottom: true,
    };
    if (this.initialValue) {
      this.editorConfig.initialContent = this.initialValue;
    }
  }
}
