import { Component, Input } from '@angular/core';
import { UserDTO } from 'src/app/common/state/user/user.dto';

@Component({
  selector: 'app-user-select-label',
  templateUrl: './user-select-label.component.html',
  styleUrls: ['./user-select-label.component.scss'],
  standalone: false,
})
export class UserSelectLabelComponent {
  @Input() user: UserDTO;
}
