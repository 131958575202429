import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription, map } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { Animations } from 'src/app/common/utilities/animations';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkPresentAttendee } from 'src/app/common/utilities/session-helpers';
import {
  AttendeeRubricDTO,
  CompetencyListItemDTO,
} from '../../shared/dtos/attendee-rubric.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from '../../shared/dtos/coaching-session.dto';
import { PusherAttendeeDTO } from '../../shared/dtos/pusher-user.dto';
import { checkSessionDataType } from '../../shared/helpers/coachee-log.utilities';
import { CoachingLogEvidenceService } from '../../shared/services/coaching-log/coaching-log-evidence.service';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import {
  FetchAttendeeRubrics,
  FetchSessionData,
} from '../../shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from '../../shared/state/coaching-log/coaching-log.state';
import { CompetenciesAPIResponse } from '../../shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-coaching-log-page',
  templateUrl: './coaching-log-page.component.html',
  styleUrls: ['./coaching-log-page.component.scss'],
  animations: [Animations.delayfadeInOut, Animations.fadeInOut],
  standalone: false,
})
export class CoachingLogPageComponent implements OnInit, OnDestroy {
  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  rubricData: AttendeeRubricDTO[] = [];

  user: User;

  loggedInAsAttendee = false;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  coachLogStatus$: Observable<APILoadingStatus> = select(
    CoachingLogState.getCoachLogStatus
  );

  coachLogStatus: APILoadingStatus;

  rubricIsLoading$: Observable<boolean> = select(
    CoachingLogState.getRubricLoadingStatus
  );

  rubricIsLoading = false;

  competenciesByLog$ = select(CoachingLogState.getCompetenciesByLog);

  sessionLoaded = false;

  sessionId: number;

  notesActive = false;

  notesTabActive = true;

  resourcesActive = false;

  resourcesTabActive = true;

  completionActive = false;

  completionTabActive = true;

  subs: Subscription[] = [];

  currentScreen = 'selectCompetencies';

  competencySelectionLoading = false;

  competencySelections: CompetencyListItemDTO[] = [];

  attendeeIds: number[] = [];

  userSelectAttendees: UserDTO[] | null = [];

  selectedAttendee: UserDTO;

  assignedRubrics: CompetenciesAPIResponse[] = [];

  competencySelected: boolean;

  attendeeUserDTOs: UserDTO[] = [];

  hasPresentAttendee = false;

  evidenceIdParam: number;

  pusherAttendees: PusherAttendeeDTO[];

  percentComplete: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public coachingService: CoachingLogService,
    public coachingLogEvidenceService: CoachingLogEvidenceService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    this.route.queryParams.subscribe((params) => {
      this.evidenceIdParam = parseInt(params['evidence_id']);
      this.coachingLogEvidenceService.setEvidenceId(this.evidenceIdParam);
    });

    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO | null)(
              this.sessionId
            )
          )
        )
        .subscribe((data) => {
          if (data) {
            this.sessionData = data;
            checkSessionDataType(data, 'coaching', this.router);
            this.attendeeUserDTOs = this.sessionData.attendees
              .filter((attendee) => attendee.present)
              .map((attendee) => attendee.user);
            if (!this.selectedAttendee) {
              if (this.attendeeUserDTOs && this.attendeeUserDTOs.length > 0) {
                // eslint-disable-next-line prefer-destructuring
                this.selectedAttendee = this.attendeeUserDTOs[0];
              }
            }
            if (this.sessionData) {
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
            } else {
              this.hasPresentAttendee = false;
            }
            if (this.sessionData.currentUsersRole === 'coachee') {
              this.loggedInAsAttendee = true;
            }

            this.updatePercentComplete(this.sessionData.percentComplete);
          }
        })
    );

    this.subs.push(
      this.rubricIsLoading$.subscribe((status) => {
        this.rubricIsLoading = status;
      })
    );

    this.subs.push(
      this.coachLogStatus$.subscribe((status) => {
        if (status.loaded) {
          this.coachLogStatus = status;
          this.sessionLoaded = true;
        }
      })
    );

    this.subs.push(
      this.coachingService.attendancePusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );

    this.subs.push(
      this.coachingService.logPusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );
  }

  getAttendees() {
    const attendeesOnPage =
      this.coachingService.attendancePusherService.attendeeRosters[
        `${this.sessionId}-${this.currentScreen}`
      ];

    if (attendeesOnPage && attendeesOnPage.length > 0) {
      this.pusherAttendees =
        this.coachingService.logPusherService.attendeeRosters[
          this.sessionId
        ].map((sessionAttendee) => {
          const samePage = attendeesOnPage.some(
            (pageAttendee) => pageAttendee.id === sessionAttendee.id
          );
          return {
            avatar: sessionAttendee.avatar,
            samePage,
            id: sessionAttendee.id,
          };
        });
    }
  }

  /* eslint-disable class-methods-use-this */
  attendeeCompareFn(
    attendee1: CoachingSessionAttendeeDTO,
    attendee2: CoachingSessionAttendeeDTO
  ) {
    return attendee1.id === attendee2.id;
  }

  getCurrentCompetencyData() {
    let competenciesSetForAllUsers = false;
    this.attendeeIds = [];
    const attendeeCompetencyDictionary: {
      [key: number]: number[];
    } = {};
    if (this.sessionData) {
      this.attendeeUserDTOs.forEach((attendee) => {
        attendeeCompetencyDictionary[attendee.id] = [];
      });
      this.rubricData.forEach((attendee) => {
        this.attendeeIds.push(attendee.userId);
        attendee.rubrics.forEach((rubric) => {
          rubric.competencyGroups.forEach((group) => {
            group.competencies.forEach((competency) => {
              this.competencySelections.forEach((selection) => {
                if (selection.id === competency.id) {
                  attendeeCompetencyDictionary[attendee.userId].push(
                    competency.id
                  );
                  selection.selected = true;
                }
              });
            });
          });
        });
      });
    }

    const selectedCompetencies = this.competencySelections.filter(
      (competency) => competency.selected
    );

    // Add competencies for people added to the log after competencies were selected
    if (
      !competenciesSetForAllUsers &&
      this.currentScreen === 'selectCompetencies'
    ) {
      Object.keys(attendeeCompetencyDictionary).forEach((attendeeId) => {
        let missingCompetencies: CompetencyListItemDTO[] = [];
        if (attendeeCompetencyDictionary[parseInt(attendeeId)].length > 0) {
          const attendeeCompetencyIds =
            attendeeCompetencyDictionary[parseInt(attendeeId)];
          missingCompetencies = selectedCompetencies.filter(
            (competencyId) => !attendeeCompetencyIds.includes(competencyId.id)
          );
        } else {
          missingCompetencies = selectedCompetencies;
        }

        if (missingCompetencies.length > 0) {
          missingCompetencies.forEach((competency) => {
            this.coachingService
              .addCompetency(
                {
                  egrowe_rubric_id: competency.rubricId as number,
                  egrowe_standard_id: competency.id as number,
                  user_id: parseInt(attendeeId),
                },
                this.sessionId
              )
              .subscribe();
          });
        }
      });
      competenciesSetForAllUsers = true;
    }
  }

  handleCompetencySelectOutput(hasSelection: boolean) {
    this.competencySelected = hasSelection;
  }

  handleCompetencySelectLoading(loading: boolean) {
    this.competencySelectionLoading = loading;
  }

  toggleNotes(): void {
    this.notesActive = !this.notesActive;
    this.notesTabActive = !this.notesTabActive;
  }

  toggleResources(): void {
    this.resourcesActive = !this.resourcesActive;
    this.resourcesTabActive = !this.resourcesTabActive;
  }

  toggleCompletion(): void {
    this.completionActive = !this.completionActive;
    this.completionTabActive = !this.completionTabActive;
  }

  ngOnInit(): void {
    if (this.router.url.includes('growth')) {
      this.currentScreen = 'groweCoaching';
    }

    if (this.router.url.includes('summary')) {
      this.currentScreen = 'sessionSummary';
    }

    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.coachingService.currentPage = this.currentScreen;
      this.store.dispatch(new FetchSessionData(this.sessionId));
      this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
      if (this.currentScreen === 'groweCoaching') {
        this.listenForCompetencyChanges();
      }
    });

    this.subs.push(
      this.attendeeRubrics$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => AttendeeRubricDTO[])(this.sessionId)
          )
        )
        .subscribe((data) => {
          // This causes an infinite loop if you don't check if the data has changed
          if (data && data !== this.rubricData) {
            this.rubricData = data;
            this.getCurrentCompetencyData();
          }
        })
    );
  }

  listenForCompetencyChanges() {
    const channel = this.coachingService.logPusherService.getChannel(
      `coachlog-v2-${this.sessionId}`
    );
    if (channel) {
      channel.bind('coachlog:standard_deleted', () => {
        this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
      });
      channel.bind('coachlog:standards', () => {
        this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];

    this.coachingService.disconnectFromPusherChannel(this.sessionId);
  }

  updateAttendeeRubrics() {
    this.rubricData = [];
    this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
  }

  navigateNextScreen() {
    if (this.competencySelected) {
      this.router.navigate([`/coaching/log/${this.sessionId}/growth`]);
    }
  }

  updatePercentComplete(e: number) {
    this.percentComplete = e;
  }
}
