import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom, map, Subscription, take } from 'rxjs';
import {
  CoachingSessionFilterDTO,
  coachingSessionFilterDTOToCoachlogSearchPayloadItemsDTO,
  emptyCoachingSessionFilters,
  setDefaultCoachingSessionFilterDateRange,
} from 'src/app/common/dtos/coaching-session-filter.dto';
import { CoachlogSearchPayloadItemsDTO } from 'src/app/common/dtos/coachlog-search-payload.dto';
import { MeModeStorageService } from 'src/app/common/services/storage/me-mode-storage';
import {
  compareRoles,
  UserRole,
} from 'src/app/common/state/user/role/user-role';
import { UserType } from 'src/app/common/state/user/type/user-type';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { ngbDateFromISOString } from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { FilterList } from '../../shared/components/applied-filters/applied-filters.dto';
import { CoachingSessionFilterModalComponent } from '../../shared/components/modals/coaching-session-filter-modal/coaching-session-filter-modal.component';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import { CoachingSessionDTO } from '../../shared/dtos/coaching-session.dto';
import { userDTOLiteFromUserDTO } from '../../shared/helpers/translators/user.translators';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { DistrictSearchService } from '../../shared/services/district-search/district-search.service';
import { schoolYearList } from '../../shared/services/school-year/school-year.utilities';
import { FEATURE_FLAGS } from '../../shared/services/unleash/unleash.helpers';
import { UnleashService } from '../../shared/services/unleash/unleash.service';
import { ResetLogDefaults } from '../../shared/state/coaching-log/coaching-log.actions';

@Component({
  selector: 'app-coaching-sessions-page',
  templateUrl: './coaching-sessions-page.component.html',
  styleUrls: ['./coaching-sessions-page.component.scss'],
  standalone: false,
})
export class CoachingSessionsPageComponent implements OnInit, OnDestroy {
  displayFilterModal = false;

  coachingSessionFilters: CoachingSessionFilterDTO =
    emptyCoachingSessionFilters();

  subs: Subscription[] = [];

  loading = true;

  sessionsEmpty = false;

  sessions: CoachingSessionDTO[];

  sessionsMetaData: PaginationDTO;

  selectedLogId: number;

  coachlogSearchOptions: CoachlogSearchPayloadItemsDTO = {
    keywords: '',
    from_date: 0,
    to_date: 0,
    competency_items: [],
    school_items: [],
    coach_items: [],
    coachee_items: [],
    per_page: 50,
    own_only: 0,
    sort_order: 'DESC',
    page: 1,
  };

  @ViewChild('filterModal') filterModal: CoachingSessionFilterModalComponent;

  @ViewChild('createSessionModal') videoModal: ModalComponent;

  dateOpen = false;

  userRoles = UserRole;

  canSeeMeMode = false;

  canCreateLog = false;

  canSeeAllSchools = false;

  keywords = '';

  user: User;

  showAppliedFilters = true;

  mscEnabled = false;

  ownOnly = false;

  hasUrlQueryParams = false;

  constructor(
    private coachingLogService: CoachingLogService,
    private store: Store,
    private featureFlagService: UnleashService,
    private meModeStorageService: MeModeStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private districtSearchService: DistrictSearchService
  ) {
    this.mscEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.mySmartCoach
    );
  }

  ngOnInit() {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canSeeMeMode =
      this.user.type !== UserType.LEARNER ||
      this.user.isCoach ||
      this.user.isLeader ||
      compareRoles([UserRole.E2L_EMPLOYEE], this.user.roles);
    this.canSeeAllSchools = compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
      this.user.roles
    );
    if (this.mscEnabled) {
      this.canCreateLog = true;
    } else {
      this.canCreateLog = compareRoles([UserRole.COACH], this.user.roles);
    }
    this.coachingSessionFilters = emptyCoachingSessionFilters();

    if (this.user.district && !checkIfE2L(this.user)) {
      this.coachingSessionFilters.district = this.user.district;
    }

    this.ownOnly =
      this.meModeStorageService.getEnabledState('coachingsessions');

    this.handleQueryParams();

    this.subs.push(
      this.coachingLogService.searchFiltersItemsObs.subscribe((res) => {
        if (res) {
          this.coachlogSearchOptions = res;
        }
      })
    );

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getSessions();
      })
    );
  }

  handleQueryParams() {
    // Check for filter query params in URL
    this.route.queryParams.subscribe((params) => {
      const filterKeys = Object.keys(params).filter((key) =>
        key.startsWith('filters[')
      );
      // If there are filter query params in URL
      if (filterKeys.length > 0) {
        // Set flag to true
        this.hasUrlQueryParams = true;
        // Create an array of promises as we populate this.coachingSessionFilters with data from the URL
        const promises = filterKeys.map((key) => {
          let schoolYear;
          // Extract the filter name and value from the URL
          const filterName = key.replace(/^filters\[(.*)\]$/, '$1'); // ex: 'filters[coachee]' -> 'coachee'
          const filterValue = params[key]; // ex: 1

          if (filterValue !== undefined) {
            switch (filterName) {
              case 'coachee':
                // lastValueFrom is used to convert an observable to a promise
                return lastValueFrom(
                  this.userService.fetchUserById(filterValue).pipe(
                    map((res) => {
                      this.coachingSessionFilters.coacheeList = [
                        userDTOLiteFromUserDTO(res),
                      ];
                    })
                  )
                );
              case 'district_id':
                return lastValueFrom(
                  this.districtSearchService.getDistrict(filterValue).pipe(
                    map((res) => {
                      if (res) {
                        this.coachingSessionFilters.district = res;
                      }
                    })
                  )
                );

              case 'school_year':
                schoolYear = schoolYearList.find(
                  (sy) => sy.id === parseInt(filterValue)
                );
                if (schoolYear) {
                  const startDate = new Date(schoolYear.start_date);
                  const endDate = new Date(schoolYear.end_date);
                  this.coachingSessionFilters.fromDate = ngbDateFromISOString(
                    startDate.toISOString()
                  );
                  this.coachingSessionFilters.toDate = ngbDateFromISOString(
                    endDate.toISOString()
                  );
                }
                return Promise.resolve();
              default:
                return Promise.resolve(); // Resolve immediately for unknown filters
            }
          } else {
            return Promise.resolve(); // Resolve immediately if filter value is undefined
          }
        });

        // Wait until all promises are resolved before setting filters
        // This ensures the API call is made only once, and is made after populating all filters from URL
        Promise.all(promises).then(() => {
          this.setFilters();
          this.hasUrlQueryParams = false;
        });
      } else {
        this.setFilters();
      }

      if (params['me_mode'] !== undefined) {
        this.ownOnly = params['me_mode'] === '1';
      }
    });
  }

  showFilterModal() {
    this.displayFilterModal = true;
  }

  setFilters(filters?: CoachingSessionFilterDTO) {
    // This timeout and this.showAppliedFilters exist to refresh the applied filters component (b/c sometimes it doesn't recognize changes)
    if (filters) {
      this.coachingSessionFilters = filters;
    }
    this.coachlogSearchOptions =
      coachingSessionFilterDTOToCoachlogSearchPayloadItemsDTO(
        this.coachingSessionFilters
      );
    this.showAppliedFilters = false;
    setTimeout(() => {
      this.showAppliedFilters = true;
    }, 0);
    this.getSessions();
  }

  getSessions(): void {
    if (!this.hasUrlQueryParams) {
      this.router.navigate(['/coaching']);
    }
    this.loading = true;
    this.coachlogSearchOptions.own_only = this.ownOnly ? 1 : 0;
    this.meModeStorageService.storeEnabledState(
      'coachingsessions',
      this.ownOnly
    );
    this.coachingLogService.setFilters(this.coachlogSearchOptions);
    this.coachingLogService.getSessions().subscribe((res) => {
      take(1);
      if (res) {
        this.sessions = res[0];
        this.sessionsMetaData = res[1];
        if (this.sessions.length === 0) {
          this.sessionsEmpty = true;
        }
      }
      this.loading = false;
    });
  }

  setDefaultDateDateRange() {
    const defaultDates = setDefaultCoachingSessionFilterDateRange();
    this.coachingSessionFilters.fromDate = defaultDates.fromDate;
    this.coachingSessionFilters.toDate = defaultDates.toDate;
  }

  removeFilter(keyName: string) {
    /* eslint-disable-next-line default-case */
    switch (keyName) {
      case 'date':
        this.setDefaultDateDateRange();
        break;
      case 'logtype':
        this.coachingSessionFilters.logType = null;
        break;
    }
    this.setFilters();
  }

  removeFilterFromList(filterList: FilterList) {
    /* eslint-disable-next-line default-case */
    switch (filterList.keyName) {
      case 'schools':
        if (this.coachingSessionFilters.schoolList) {
          this.coachingSessionFilters.schoolList =
            this.coachingSessionFilters.schoolList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'competency_items':
        if (this.coachingSessionFilters.competencyList) {
          this.coachingSessionFilters.competencyList =
            this.coachingSessionFilters.competencyList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'coach_items':
        if (this.coachingSessionFilters.coachList) {
          this.coachingSessionFilters.coachList =
            this.coachingSessionFilters.coachList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'coachee_items':
        if (this.coachingSessionFilters.coacheeList) {
          this.coachingSessionFilters.coacheeList =
            this.coachingSessionFilters.coacheeList.filter(
              (item) => item !== filterList.value
            );
        }
        break;
    }
    this.setFilters();
  }

  closeFilterModal() {
    this.displayFilterModal = false;
  }

  pageChanged(pageNumb: number) {
    this.coachlogSearchOptions.page = pageNumb;
    this.getSessions();
  }

  handleMeMode() {
    if (this.ownOnly && checkIfE2L(this.user)) {
      this.coachingSessionFilters.district = null;
      this.setFilters();
    }
    this.getSessions();
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetLogDefaults());

    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
