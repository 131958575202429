import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { NewProFormPayload } from '../../types/payloads/proforms.payloads';

@Injectable({
  providedIn: 'root',
})
export class ProformsFormListService {
  constructor(private apiService: APICoreService) {}

  getForms(districtId?: number, pageSize = 10, page?: number, type?: string) {
    return this.apiService.getRequest(
      `growelab/pro-forms/forms?expand=submissionCount,district&district_id=${districtId}&per_page=${pageSize}&page=${page}&type=${type}`
    );
  }

  createForm(payload: NewProFormPayload) {
    return this.apiService.postRequest('growelab/pro-forms/forms', payload);
  }
}
