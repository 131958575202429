<div class="container">
  <app-general-header
    [title]="'Manage ' + ((district && district.title) || 'District')" />
  <div class="card border-0 p-3">
    <app-settings-nav [districtCode]="districtCode" />
    <div class="text-end mt-3 px-xl-5">
      <button
        (click)="createForm()"
        [disabled]="isCreating"
        class="btn btn-primary px-4">
        <ng-container *ngIf="isCreating">
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-container>
        <i class="bi bi-plus"></i> Create Form
      </button>
    </div>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <div class="table-wrapper w-100 mt-3 px-xl-5">
        <table
          *ngIf="forms && forms.length > 0; else noResults"
          class="table gl-striped-table my-4">
          <!--TABLE HEADER-->
          <thead>
            <tr class="align-middle">
              <th scope="col">Title</th>
              <th class="no-wrap" scope="col">Owner</th>
              <th class="no-wrap" scope="col">Status</th>
              <th class="no-wrap" scope="col">Activity</th>
              <th class="np-wrap" scope="col"></th>
            </tr>
          </thead>
          <!--TABLE BODY-->
          <tbody>
            <tr *ngFor="let form of forms" class="align-middle">
              <td>
                <span class="fw-600 color-primary">{{ form.title }}</span>
              </td>
              <td>
                {{ form.district?.title }}
              </td>
              <td>
                {{ form.status.toLowerCase() }}
              </td>
              <td class="submission-col">
                <ng-container
                  *ngIf="
                    form.submissionCount && form.submissionCount > 0;
                    else noSubmissionBlock
                  ">
                  <button
                    ngbTooltip="View submissions"
                    class="btn btn-link color-tertiary p-0">
                    {{ form.submissionCount }} submissions
                  </button>
                </ng-container>
                <ng-template #noSubmissionBlock
                  ><div class="no-wrap">0 submissions</div></ng-template
                >
              </td>
              <td class="edit-col">
                <button
                  ngbTooltip="Edit"
                  (click)="
                    this.router.navigate(['proforms', 'form-builder', form.id])
                  "
                  class="btn btn-link color-tertiary p-1">
                  <i id="edit-{{ form.title }}" class="bi bi-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <section>
        <div class="d-flex justify-content-center">
          <ngb-pagination
            *ngIf="pagination && pagination.totalCount > 10"
            [(page)]="pagination.currentPage"
            [pageSize]="pagination.perPage"
            [collectionSize]="pagination.totalCount"
            [maxSize]="5"
            (pageChange)="pageChange($event)"></ngb-pagination>
        </div>
      </section>
      <ng-template #noResults>
        <div
          class="w-100 p-5 text-center d-flex justify-content-center align-items-center"
          style="min-height: 300px">
          <div class="xtext-center xtext-body-secondary">
            <p *ngIf="district && district.id === 2">
              No templates created yet
            </p>
            <p *ngIf="!district || district.id != 2">
              There are no matching forms associated with your district.
            </p>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loadingTemplate>
      <div style="clear: both; height: 300px"></div>
      <app-loader [top]="200" />
    </ng-template>
  </div>
</div>
