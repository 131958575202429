import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import {
  KpiReportFilter,
  ReportRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { ReportControllerComponent } from '../../shared/components/reports/report-controller/report-controller.component';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { ReportService } from '../../shared/services/report/report.service';
import { ReportingState } from '../../shared/state/reporting/reporting.state';
import { ReportFiltersDTO } from '../reporting-page/filters/report-filters.dto';

@Component({
  selector: 'app-reporting-evidence',
  templateUrl: './reporting-evidence.component.html',
  styleUrls: ['./reporting-evidence.component.scss'],
  standalone: false,
})
export class ReportingEvidenceComponent implements OnInit, OnDestroy {
  @ViewChild('p') csvDownloadingPopover: NgbPopover;

  @ViewChild('evidenceArtifactsReport')
  evidenceArtifactsReport: ReportControllerComponent;

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  chartTypes = ChartType;

  filters: KpiReportFilter[];

  evidenceParams: ReportRequestParams;

  canFilterByDistrict = false;

  subs: Subscription[] = [];

  name = 'null';

  disableDownload = false;

  noResults = false;

  lastUpdatedDate: string;

  constructor(
    private store: Store,
    private reportService: ReportService,
    private reportFiltersService: ReportPageFiltersService
  ) {
    const lastUpdatedDate$ = this.store.select(ReportingState.getUpdatedDate);
    lastUpdatedDate$.subscribe((date) => {
      this.lastUpdatedDate = date;
    });
    const user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canFilterByDistrict = checkIfE2L(user) || checkIfB2b(user);
  }

  ngOnInit(): void {
    this.subs.push(
      this.reportFiltersService.filters.subscribe(
        (filters: ReportFiltersDTO) => {
          if (filters.schoolYearFilter) {
            this.filters = translateKpiReportFilterFromFilterDTO(filters);

            this.evidenceParams = {
              dataset: 'fct_evidence_submission',
              dimension: 'school_name',
              group: 'level',
              measure: {
                type: 'count',
                field: '*',
              },
              filters: [...this.filters],
            };
          }
        }
      )
    );
  }

  downloadCSV(): void {
    this.disableDownload = true;

    let reportType = '';

    switch (this.evidenceArtifactsReport.drilldownType.length) {
      case 2:
        reportType = 'evidenceCountUsersDownload';
        this.filters = this.evidenceArtifactsReport.params.filter(
          (param) => param.dimension === 'user_name'
        )[0].filters;
        break;
      case 3:
        reportType = 'evidenceCountCompetenciesDownload';
        this.filters = this.evidenceArtifactsReport.params.filter(
          (param) => param.dimension === 'standard_name'
        )[0].filters;
        break;
      case 1:
      default:
        reportType = 'evidenceCountSchoolsDownload';
    }

    this.subs.push(
      this.reportService.generateReportCSV(reportType, this.filters).subscribe({
        error: () => {
          this.csvDownloadingPopover.close();
          this.noResults = true;
          this.disableDownload = false;
        },
        next: (res) => {
          if (res.has_results) {
            // res.file_name should always start with a slash, but need to be sure for the link to work properly
            if (!res.file_name.startsWith('/')) {
              res.file_name = `/${res.file_name}`;
            }
            window.open(
              `${EnvironmentService.apiUrl()}${res.file_name}`,
              '_blank'
            );
          } else {
            this.noResults = true;
          }
          this.disableDownload = false;
        },
      })
    );
  }

  closeErrorMsg() {
    this.noResults = false;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  toolTipFormatter(params: any): string {
    return `
    <strong>
      Level ${params.seriesName}
    </strong>
    <br/>
     ${params.value} evidence artifacts
    `;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
