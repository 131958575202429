import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserService } from 'src/app/common/state/user/user.service';
import { AvatarComponent } from '../../avatar/avatar.component';
import { AvatarDTO } from '../../avatar/avatar.dto';
import { avatarDTOFromProfile } from '../../avatar/avatar.translators';

@Component({
  selector: 'app-todo-assignee',
  templateUrl: './todo-assignee.component.html',
  styleUrls: ['./todo-assignee.component.scss'],
  standalone: false,
})
export class TodoAssigneeComponent implements OnInit, OnChanges {
  @Input() userId: number;

  @ViewChild('avatar') avatar: AvatarComponent;

  avatarData: AvatarDTO;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.userService.fetchUserById(this.userId).subscribe((userDTO) => {
      if (userDTO) {
        this.userService.addUserToCache(userDTO);
        this.avatarData = avatarDTOFromProfile(userDTO.profile);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'].currentValue) {
      if (changes['userId'].currentValue !== changes['userId'].previousValue) {
        this.userId = changes['userId'].currentValue;
        this.getUser();
      }
    }
  }
}
