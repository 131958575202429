import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { KpiReportFilter } from 'src/app/common/dtos/reports.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { ReportControllerComponent } from '../../../shared/components/reports/report-controller/report-controller.component';
import {
  ChartColorsAll,
  DetailedPieData,
} from '../../../shared/components/reports/report-controller/series-helper';
import { ChartType } from '../../../shared/enums/chart-type.enum';

@Component({
  selector: 'app-card-report-time-frequency',
  templateUrl: './card-report-time-frequency.component.html',
  standalone: false,
})
export class CardReportTimeFrequencyComponent implements OnInit {
  @Input() defaultFilters: KpiReportFilter[];

  @ViewChild('pieChart', { static: true }) pieChart: ReportControllerComponent;

  timeFrequencyFilters: KpiReportFilter[];

  cardTitle = 'Time & Frequency';

  isCollapsed: boolean;

  donutChartType = ChartType.Donut;

  chartColors = ChartColorsAll;

  sessionCounts: DetailedPieData[];

  currentError = '';

  constructor(private cardStorageService: CardStorageService) {}

  ngOnInit() {
    this.isCollapsed = this.cardStorageService.getCollapsedState(
      this.cardTitle.replace(/\s/g, '').toLowerCase()
    );
    if (this.defaultFilters) {
      this.timeFrequencyFilters = deepCopy(this.defaultFilters);
      this.timeFrequencyFilters.push({
        codename: 'is_present',
        value: ['1'],
      });
    }
  }

  resetError() {
    this.currentError = '';
  }

  handleError(errorMessage: string) {
    this.currentError = errorMessage;
  }

  setSessionCounts(data: EChartsOption) {
    if (data?.series && Array.isArray(data.series) && data.series.length) {
      this.sessionCounts = (data.series[0] as PieSeriesOption)
        .data as DetailedPieData[];
    }
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.cardTitle.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }
}
