import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { userFromDTO } from 'src/app/common/state/user/user.utilities';
import { DomElement } from 'src/app/common/utilities/dom-element';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { UploadStyles } from '../../shared/components/file-management/file-upload/file-upload.component';
import { YearSelectComponent } from '../../shared/components/year-select/year-select.component';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';
import { FileDTO } from '../../shared/dtos/file.dto';
import {
  AddPdTranscriptEntryPayload,
  GetPdTranscriptsPayload,
  PdCredit,
  PdSettings,
  PdTranscriptAPIResponse,
  PdTranscriptDTO,
} from '../../shared/dtos/pd.dto';
import { UploadType } from '../../shared/enums/upload-type.enum';
import { PdEventEvidenceFileService } from '../../shared/services/file/pd-event-evidence.file.service';
import { PdService } from '../../shared/services/pd/pd.service';
import { CURRENT_SCHOOL_YEAR } from '../../shared/services/school-year/school-year.utilities';

@Component({
  selector: 'app-transcript-page',
  templateUrl: './transcript-page.component.html',
  styleUrl: './transcript-page.component.scss',
  standalone: false,
})
export class TranscriptPageComponent implements OnInit, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChild('yearSelect') yearSelect: YearSelectComponent;

  currentUser: User;

  isAdmin = false;

  isE2L = false;

  transcriptUser: User;

  currentFilters: GetPdTranscriptsPayload = {
    sort_by: '',
    sort_by_desc: 0,
    school_year: CURRENT_SCHOOL_YEAR.name,
    credit_type: '',
    source: '',
  };

  transcriptData: PdTranscriptDTO[];

  addEntryMode = false;

  addEntryPayload = {} as AddPdTranscriptEntryPayload;

  formSubmitted = false;

  uploadStyle = UploadStyles.EVIDENCE;

  uploadType = UploadType.PD_EVENT_EVIDENCE;

  attachment: FileDTO | null;

  settings: PdSettings;

  yearlyTotals: PdCredit[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    date: 'gray',
    title: 'gray',
    source: 'gray',
    credit_type: 'gray',
  };

  sortInfo = {
    column: '',
    direction: '',
  };

  loadingIcons = true;

  manualEntryError = '';

  isLoading = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private pdService: PdService,
    private pdEventEvidenceService: PdEventEvidenceFileService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.currentUser) {
      this.isE2L = checkIfE2L(this.currentUser);
      this.isAdmin =
        this.currentUser.roles.includes(UserRole.DISTRICT_ADMIN) ||
        this.currentUser.roles.includes(UserRole.OPERATOR) ||
        this.currentUser.roles.includes(UserRole.E2L_EMPLOYEE);
    }
    this.getProfileData();
    document.body.classList.add('session_post');
  }

  getProfileData() {
    this.route.params.subscribe((url) => {
      if (url['userId']) {
        this.userService.fetchUserById(url['userId']).subscribe(
          (res) => {
            if (res) {
              if (this.isAdmin) {
                this.transcriptUser = userFromDTO(res);
              } else {
                this.transcriptUser = this.currentUser;
              }
              this.getTranscriptData();
            }
          },
          (error) => {
            if (error.status === 404) {
              this.router.navigate(['/training/transcript']);
            }
          }
        );
      } else {
        this.transcriptUser = this.currentUser;
        this.getTranscriptData();
      }
    });
  }

  getTranscriptData() {
    this.pdService
      .getTranscript(
        this.isAdmin ? this.transcriptUser.id : this.currentUser.id,
        this.currentFilters
      )
      .subscribe((res) => {
        if (res.items) {
          this.createTranscriptListObject(res.items);
          this.pdEventEvidenceService.setUserId(this.transcriptUser.id);
          this.getYearlyTotals();
          this.updateColumns();
          this.parseEvidenceLinks();
          this.getSettings();
        }
      });
  }

  /*
   * This takes the API response, and groups credits together for the same session
   * accounts for pd_session_id being empty (manual entries)
   */
  createTranscriptListObject(apiresponseItems: PdTranscriptAPIResponse[]) {
    const transcriptListTemp: PdTranscriptDTO[] = []; // temp, will have indexes based on groupings
    const transcriptList: PdTranscriptDTO[] = [];
    let existingIndex = -1;
    apiresponseItems.forEach((responseItem, index) => {
      // is it from a pd_session and not first loop
      if (responseItem.pd_session_id > 0 && transcriptListTemp.length > 0) {
        existingIndex = -1;
        // find the existing item based on pd_session_id
        transcriptListTemp.forEach((item, index2) => {
          if (item.pd_session_id === responseItem.pd_session_id) {
            existingIndex = index2;
          }
        });
        // if found existing, just add the credits
        if (existingIndex >= 0) {
          transcriptListTemp[existingIndex].credits.push({
            credit_type: responseItem.credit_type,
            credit_value: responseItem.credit_value,
          });
        } else {
          // not found, so create it
          transcriptListTemp[index] = {
            id: responseItem.id,
            pd_event_id: responseItem.pd_event_id,
            pd_session_id: responseItem.pd_session_id,
            session: responseItem.session,
            source: responseItem.source,
            title: responseItem.title,
            user: responseItem.user,
            user_id: responseItem.user_id,
            credits: [
              {
                credit_type: responseItem.credit_type,
                credit_value: responseItem.credit_value,
              },
            ],
            event: responseItem.event,
            date: responseItem.date,
            evidence_link: responseItem.evidence_link,
            sort_order: index,
          };
        }
      } else {
        // a manual entry, or the first entry - no grouping, always add it
        transcriptListTemp[index] = {
          id: responseItem.id,
          pd_event_id: responseItem.pd_event_id,
          pd_session_id: responseItem.pd_session_id,
          session: responseItem.session,
          source: responseItem.source,
          title: responseItem.title,
          user: responseItem.user,
          user_id: responseItem.user_id,
          credits: [
            {
              credit_type: responseItem.credit_type,
              credit_value: responseItem.credit_value,
            },
          ],
          event: responseItem.event,
          date: responseItem.date,
          evidence_link: responseItem.evidence_link,
          sort_order: index,
        };
      }
    });
    // transcriptListTemp indexes are not incremental, which messes up the ngFor
    // so push them onto a new array so indexes are incremental
    transcriptListTemp.forEach((item) => {
      transcriptList.push(item);
    });
    // sort them in the same order the API returned them
    transcriptList.sort((a, b) => a.sort_order - b.sort_order);
    // set the var
    this.transcriptData = transcriptList;
  }

  handleUserSearchOutput(user: UserLiteDTO | null) {
    if (user) {
      this.router.navigate([`/training/transcript/${user.id}`]);
    } else {
      this.router.navigate(['/training/transcript']);
    }
  }

  handleFileOutput(file: FileDTO) {
    this.attachment = file;
    this.addEntryPayload.attachment = file.location;
  }

  getSettings() {
    this.pdService
      .getAdminSettings(this.transcriptUser.district?.id)
      .subscribe((res) => {
        if (res) {
          this.settings = res.item;
        }
      });
  }

  onCreditValueChange(value: number) {
    if (value === null) {
      this.manualEntryError = '';
    } else if (value >= 0 && value !== null) {
      this.manualEntryError = '';
      // Ensure that only a maximum of 99 is allowed
      if (value > 99) {
        this.manualEntryError = 'Credit amount has a limit of 99.';
      } else {
        this.manualEntryError = '';
      }
      // Check if number does not exceed 1 decimal point
      if (!/^-?\d+(\.\d{1})?$/.test(value.toString())) {
        this.manualEntryError =
          'Credit amount cannot have more than one decimal place.';
      }
    } else {
      this.manualEntryError = 'Credit amount cannot be negative.';
    }
  }

  addManualEntry() {
    this.formSubmitted = true;
    this.addEntryPayload.source = 'Manual Entry';
    if (
      this.addEntryPayload.date &&
      this.addEntryPayload.title &&
      !this.manualEntryError
    ) {
      if (
        this.addEntryPayload.credit_value === undefined ||
        this.addEntryPayload.credit_value === null ||
        !this.addEntryPayload.credit_type
      ) {
        delete this.addEntryPayload.credit_type;
        delete this.addEntryPayload.credit_value;
      }

      this.pdService
        .addTranscriptEntry(this.transcriptUser.id, this.addEntryPayload)
        .subscribe((res) => {
          if (res) {
            this.getTranscriptData();
            this.addEntryPayload = {} as AddPdTranscriptEntryPayload;
            this.formSubmitted = false;
            this.addEntryMode = false;
            this.attachment = null;
            this.manualEntryError = '';
          }
        });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  viewFile(location: string) {
    window.open(`${EnvironmentService.apiUrl()}${location}`);
  }

  handleSelectedYear(schoolYear: SchoolYearDTO | null) {
    if (schoolYear) {
      this.currentFilters.school_year = schoolYear.name;
      this.getTranscriptData();
    }
  }

  clearManualEntry() {
    this.addEntryMode = !this.addEntryMode;
    this.addEntryPayload = {} as AddPdTranscriptEntryPayload;
    this.formSubmitted = false;
    this.attachment = null;
    this.manualEntryError = '';
  }

  getYearlyTotals() {
    this.pdService
      .getTranscriptYearlyTotals(
        this.transcriptUser.id,
        this.currentFilters.school_year
      )
      .subscribe((res) => {
        if (res.items) {
          this.yearlyTotals = res.items;
        }
      });
  }

  updateColumns() {
    this.headers.forEach((header) => {
      if (header.sortable !== this.sortInfo.column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });
    if (this.sortInfo.direction === '' || this.sortInfo.column === '') {
      this.sortIconTemp[this.sortInfo.column] = 'gray';
    }
    this.loadingIcons = false;
  }

  onSort({ column, direction }: SortEvent) {
    if (direction === '') {
      this.currentFilters.sort_by = '';
      this.currentFilters.sort_by_desc = 0;
    } else {
      this.currentFilters.sort_by = column;
      if (direction === 'asc') {
        this.currentFilters.sort_by_desc = 0;
      } else if (direction === 'desc') {
        this.currentFilters.sort_by_desc = 1;
      }
    }
    this.sortInfo.column = column;
    this.sortInfo.direction = direction;
    this.loadingIcons = true;
    this.getTranscriptData();
  }

  parseEvidenceLinks() {
    if (this.transcriptData) {
      this.transcriptData.forEach((item, index) => {
        if (item.evidence_link) {
          this.transcriptData[index].evidence_link = `/uploads${
            item.evidence_link.split('uploads')[1]
          }`;
          this.transcriptData[index].evidence_title = this.transcriptData[
            index
          ].evidence_link
            .split('/')
            .at(-1)
            ?.split('.')[0];
        }
      });
    }
  }

  downloadTranscript() {
    this.isLoading = true;
    this.pdService
      .downloadTranscript(
        this.transcriptUser.id,
        this.currentFilters.school_year
      )
      .subscribe((link) => {
        if (link) {
          const downloadLink = DomElement.create(
            `a[href="${link}"][download="${this.transcriptUser.id}-Transcript"][target="_blank"]`
          );
          downloadLink.click();
          downloadLink.remove();
          this.isLoading = false;
        }
      });
  }

  deleteManualEntry(entryId: number) {
    this.pdService.deleteTranscriptEntry(entryId).subscribe((res) => {
      if (res) {
        this.getTranscriptData();
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ngOnDestroy() {
    document.body.classList.remove('session_post');
  }
}
