import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { BadgeEarnedDTO } from '../../dtos/badge.dto';
import { CoachingSessionUserType } from '../../enums/coaching-session-user-type.enum';
import { UserPusherService } from '../../services/pusher/user.pusher.service';
import { ModalComponent, ModalConfig } from '../modals/modal/modal.component';

@Component({
  selector: 'app-badge-earned-modal',
  templateUrl: './badge-earned-modal.component.html',
  styleUrls: ['./badge-earned-modal.component.scss'],
  standalone: false,
})
export class BadgeEarnedModalComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  badgeData: BadgeEarnedDTO;

  @ViewChild('internalModal') internalModal: ModalComponent;

  @ViewChild('inner') inner: ElementRef<HTMLDivElement>;

  subs: Subscription[] = [];

  badgeEarnedNotifierSub: Subscription;

  private isOpen = false;

  public modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  user: User;

  modalConfig: ModalConfig = {
    headerClass: ['visually-hidden'],
    hideCloseButton: true,
    options: {
      size: 'lg',
    },
  };

  @Input() currentUsersRole: CoachingSessionUserType | null;

  constructor(
    private userPusherService: UserPusherService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    if (this.badgeEarnedNotifierSub) this.badgeEarnedNotifierSub.unsubscribe();
    if (this.userPusherService.badgeEarnedNotifier) {
      this.badgeEarnedNotifierSub =
        this.userPusherService.badgeEarnedNotifier.subscribe((messageData) => {
          if (messageData) {
            this.badgeData = messageData;
            if (
              parseInt(this.badgeData.userId) === this.user.id ||
              this.currentUsersRole === CoachingSessionUserType.COACH
            ) {
              this.modalShowing.next(true);
            }
          }
        });
    }

    this.subs.push(this.badgeEarnedNotifierSub);
  }

  ngAfterViewInit() {
    this.internalModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.internalModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.internalModal.close();
        this.isOpen = false;
      }
    });
  }

  shareOnTwitter(badgeUser: string, badgeTitle: string) {
    if (this.user) {
      let shareText = '';
      if (this.user.id === parseInt(this.badgeData.userId)) {
        shareText = `I just earned the ${badgeTitle} badge”, ask me how!`;
      } else {
        shareText = `${badgeUser} just earned the ${badgeTitle} badge, way to grow!`;
      }
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`
      );
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
