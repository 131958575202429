import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { dateTimeFromNgbDate } from 'src/app/common/utilities/date-helpers';
import {
  AddPdTranscriptEntryPayload,
  CreatePdSessionPayload,
  CreateUpdateSessionCategoryPayload,
  CreateUpdateSessionCreditPayload,
  GetPdTranscriptsPayload,
  PdListFilterDTO,
  PdListFilterPayload,
  PdSessionFinishedPayload,
  UpdatePdSessionPayload,
  UpdateSessionCompetencyPayload,
} from '../../dtos/pd.dto';
import { PdPusherService } from './pd-pusher.service';

@Injectable({
  providedIn: 'root',
})
export class PdService {
  constructor(
    private apiService: APICoreService,
    private pdPusherService: PdPusherService
  ) {}

  connectToPusherChannel(sessionId: number) {
    this.pdPusherService.addChannel(`pd-${sessionId}`);
  }

  registerSession(sessionId: number) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/register`
    );
  }

  unregisterSession(sessionId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/${sessionId}/attendee/${userId}`
    );
  }

  registerSessionForAnotherUser(sessionId: number, userId: number) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/register`,
      { user_id: userId }
    );
  }

  getSession(sessionId: number) {
    this.connectToPusherChannel(sessionId);
    return this.apiService.getRequest(`growelab/pd/session/${sessionId}`);
  }

  deleteSession(sessionId: number) {
    return this.apiService.deleteRequest(`growelab/pd/session/${sessionId}`);
  }

  getCompletedUserSession(sessionId: number, attendeeId: number) {
    return this.apiService.getRequest(
      `growelab/pd/session/${sessionId}?user_id=${attendeeId}`
    );
  }

  updateSession(sessionId: number, payload: UpdatePdSessionPayload) {
    return this.apiService.putRequest(
      `growelab/pd/session/${sessionId}`,
      payload
    );
  }

  attendSession(sessionId: number, sessionCode: string) {
    return this.apiService.putRequest(
      `growelab/pd/session/${sessionId}/attend`,
      {
        code: sessionCode,
      }
    );
  }

  setAttendeePresent(sessionId: number, attendeeId: number, present: number) {
    return this.apiService.putRequest(
      `growelab/pd/session/${sessionId}/attendee/${attendeeId}`,
      {
        is_present: present,
      }
    );
  }

  getSessions(filters?: PdListFilterDTO) {
    if (filters) {
      const payload: PdListFilterPayload = {
        from_date: filters.from_date
          ? dateTimeFromNgbDate(filters.from_date).toSeconds()
          : null,
        to_date: filters.to_date
          ? dateTimeFromNgbDate(filters.to_date)
              .set({ hour: 23, minute: 59, second: 59 })
              .toSeconds()
          : null,
        search: filters.keyword,
        facilitators: filters.facilitators
          ? filters.facilitators?.map((facilitator) => facilitator.id)
          : [],
        types: filters.types,
        is_virtual: filters.virtual ? 1 : 0,
        onsite: filters.onsite ? 1 : 0,
        categories: filters.categories,
        category_options: filters.category_options,
        per_page: filters.per_page,
        page: filters.page,
        include_completed: filters.include_completed ? 1 : 0,
        competencies: filters.competencyList.map((competency) => competency.id),
        user_id: filters.user_id ? filters.user_id : 0,
      };
      return this.apiService.getRequest('growelab/pd/sessions', payload);
    }
    return this.apiService.getRequest('growelab/pd/sessions');
  }

  getTranscript(userId: number, payload: GetPdTranscriptsPayload) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript`,
      payload
    );
  }

  getTranscriptYearlyTotals(userId: number, schoolYear: string) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript-year-totals?school_year=${schoolYear}`
    );
  }

  addTranscriptEntry(userId: number, payload: AddPdTranscriptEntryPayload) {
    return this.apiService.postRequest(
      `growelab/pd/user/${userId}/transcript`,
      payload
    );
  }

  getAdminSettings(district_id = 0) {
    return this.apiService.getRequest(
      `growelab/pd/admin-settings?district_id=${district_id}`
    );
  }

  completeSession(sessionId: number, payload: PdSessionFinishedPayload) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/finished`,
      payload
    );
  }

  addCategory(payload: { district_id: number; title: string }) {
    return this.apiService.postRequest('growelab/pd/admin/category', payload);
  }

  addCategoryOption(
    categoryId: number,
    payload: { district_id: number; title: string }
  ) {
    return this.apiService.postRequest(
      `growelab/pd/admin/category/${categoryId}/option`,
      payload
    );
  }

  deleteCategory(payload: { district_id: number; categoryId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/category/${payload.categoryId}?district_id=${payload.district_id}`
    );
  }

  deleteCategoryOption(payload: { district_id: number; optionId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/category/option/${payload.optionId}?district_id=${payload.district_id}`
    );
  }

  addCredit(payload: { district_id: number; title: string }) {
    return this.apiService.postRequest('growelab/pd/admin/credit', payload);
  }

  deleteCredit(payload: { district_id: number; creditId: number }) {
    return this.apiService.deleteRequest(
      `growelab/pd/admin/credit/${payload.creditId}?district_id=${payload.district_id}`
    );
  }

  sessionEarnedCredits(sessionId: number) {
    return this.apiService.getRequest(
      `growelab/pd/session/${sessionId}/earned-credit`
    );
  }

  downloadTranscript(userId: number, schoolYear: string) {
    return this.apiService.getRequest(
      `growelab/pd/user/${userId}/transcript/download?school_year=${schoolYear}`
    );
  }

  deleteTranscriptEntry(entryId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/transcript/item/${entryId}`
    );
  }

  createSession(payload: CreatePdSessionPayload) {
    return this.apiService.postJSONRequest('growelab/pd/session', payload).pipe(
      catchError((err) => {
        throw err;
      })
    );
  }

  // Credits
  createCredit(sessionId: number, payload: CreateUpdateSessionCreditPayload) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/credit`,
      payload
    );
  }

  updateCredit(creditId: number, payload: CreateUpdateSessionCreditPayload) {
    return this.apiService.putRequest(
      `growelab/pd/session/credit/${creditId}`,
      payload
    );
  }

  deleteSessionCredit(creditId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/credit/${creditId}`
    );
  }

  // Competencies
  updateCompetency(sessionId: number, payload: UpdateSessionCompetencyPayload) {
    return this.apiService.putRequest(
      `growelab/pd/session/${sessionId}/competency`,
      payload
    );
  }

  deleteCompetency(sessionId: number, standardId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/${sessionId}/competency/${standardId}`
    );
  }

  // Categories
  createCategory(
    sessionId: number,
    payload: CreateUpdateSessionCategoryPayload
  ) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/category`,
      payload
    );
  }

  updateCategory(
    categoryId: number,
    payload: CreateUpdateSessionCategoryPayload
  ) {
    return this.apiService.putRequest(
      `growelab/pd/session/category/${categoryId}`,
      payload
    );
  }

  deleteSessionCategory(categoryId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/category/${categoryId}`
    );
  }

  // Sharing
  shareWithDistrict(sessionId: number, districtId: number) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/share/${districtId}`
    );
  }

  excludeFromDistrict(sessionId: number, districtId: number) {
    return this.apiService.postRequest(
      `growelab/pd/session/${sessionId}/unshare/${districtId}`
    );
  }

  deleteShare(sessionId: number, shareId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/${sessionId}/share/${shareId}`
    );
  }

  // Facilitators
  addFacilitator(sessionId: number, payload: { user_id: number }) {
    return this.apiService.putRequest(
      `growelab/pd/session/${sessionId}/facilitator`,
      payload
    );
  }

  deleteFacilitator(sessionId: number, userId: number) {
    return this.apiService.deleteRequest(
      `growelab/pd/session/${sessionId}/facilitator/${userId}`
    );
  }
}
