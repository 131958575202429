/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, DurationUnit } from 'luxon';
import {
  formatDurationAsString,
  fullDurationUnits,
} from '../utilities/date-helpers';

@Pipe({
  name: 'formatActivityFeedDateTime',
  standalone: false,
})
export class FormatActivityFeedDateTime implements PipeTransform {
  transform(value: number): string {
    const currentTime = DateTime.now();
    const activityTime = DateTime.fromSeconds(value);
    const diffInHours = currentTime.diff(activityTime, 'hours').hours;
    if (diffInHours >= 24) {
      return activityTime.toFormat('LLL d');
    }
    const duration = DateTime.fromSeconds(value).diffNow(
      fullDurationUnits as DurationUnit[]
    );
    return formatDurationAsString(duration, true);
  }
}
