import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Channel } from 'pusher-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import {
  CompetencyDTO,
  EvidenceAssessmentDTO,
  EvidenceDTO,
  EvidenceType,
  LevelDTO,
  OptionDTO,
  StrandDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { VideoReferenceObjectType } from 'src/app/private/shared/enums/video-reference-object-type.enum';
import { fileDTOFromEvidenceAttachment } from 'src/app/private/shared/helpers/translators/evidence.translators';
import { EvidenceService } from 'src/app/private/shared/services/evidence/evidence.service';
import { EvidenceFileService } from 'src/app/private/shared/services/file/evidence.file.service';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { EvidencePayload } from 'src/app/private/shared/types/payloads/evidence.payload';
import { evidenceModalTypes } from '../../../../competency/leveled-competency-strand/level-evidence/evidence-modal.helpers';
import { FileListComponent } from '../../../../file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../../../file-management/file-upload/file-upload.component';
import { CopyEvidenceModalComponent } from '../../../../modals/copy-evidence-modal/copy-evidence-modal.component';
import {
  ModalComponent,
  ModalConfig,
} from '../../../../modals/modal/modal.component';
import { WysiwygEditorComponent } from '../../../../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-portfolio-leveled-competency-strand-level-evidence',
  templateUrl:
    './portfolio-leveled-competency-strand-level-evidence.component.html',
  styleUrls: [
    '../../../../competency/leveled-competency-strand/level-evidence/leveled-competency-strand-level-evidence.component.scss',
  ],
  standalone: false,
})
export class PortfolioLeveledCompetencyStrandLevelEvidenceComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('evidenceModal') evidenceModal: ModalComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @ViewChild('optionSelect') optionSelect: NgSelectComponent;

  @ViewChild('contentEditor') contentEditor: WysiwygEditorComponent;

  @ViewChild('copyEvidenceModal') copyEvidenceModal: CopyEvidenceModalComponent;

  @Input() levelData: LevelDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level: number;

  @Input() strand: StrandDTO;

  @Input() competency: CompetencyDTO;

  @Input() competencyTitle: string;

  @Input() evidenceList: EvidenceDTO[] = [];

  @Input() viewOnly = false;

  optionList: OptionDTO[] = [];

  portfolioOnly: OptionDTO;

  selectedOptionId = 0;

  disableVideoRemoval = false;

  modalFileList: FileDTO[] = [];

  modalConfig: ModalConfig;

  uploadTypes = UploadType;

  uploadType: UploadType | null;

  uploadStyle = UploadStyles.EVIDENCE;

  uploadedFiles: FileDTO[] = [];

  numberOfFilesUploaded = 0;

  evidenceModalTypes = evidenceModalTypes;

  newEvidence = false;

  disableEvidenceSubmit = new BehaviorSubject<boolean>(false);

  currentEvidence: EvidenceDTO;

  initialNarrativeContent = '';

  subscriptions: Subscription[] = [];

  payload: EvidencePayload;

  hasNarrative = false;

  hasTitle = false;

  userChannel: Channel;

  isDrawerCollapsed = true;

  copyEvidenceEnabled = false;

  constructor(
    private evidenceService: EvidenceService,
    private changeRef: ChangeDetectorRef,
    private featureFlagService: UnleashService
  ) {}

  ngOnInit(): void {
    this.portfolioOnly = {
      id: 0,
      content: 'Just adding evidence to my portfolio',
      fromBankOption: null,
      fromCuratedOptionId: null,
      fromCuratedOption: null,
      resources: [],
      strandId: this.strand.id,
      level: this.level,
      coachlogId: 0,
      will: null,
    };

    this.copyEvidenceEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.copyEvidence
    );

    this.optionList = [this.portfolioOnly];

    this.modalConfig = {
      headerClass: [
        `bg-level-${this.level}`,
        'text-white',
        'text-center',
        'modal-header',
      ],
      titleText: '',
      options: {
        size: 'lg',
      },
      customFooter: true,
    };
    this.resetCurrentEvidence();
  }

  ngAfterViewInit(): void {
    this.evidenceModal.isClosing.subscribe(() => {
      this.currentEvidence.coachlogOptionId = this.selectedOptionId;
      if (this.newEvidence) {
        this.currentEvidence = {
          ...this.currentEvidence,
        };
        this.createEvidence();
      } else {
        this.updateEvidence();
      }
    });
  }

  toggleCollapse() {
    if (this.evidenceList.length > 0) {
      this.isDrawerCollapsed = !this.isDrawerCollapsed;
    }
  }

  resetCurrentEvidence(): void {
    this.initialNarrativeContent = '';
    // Only fires after component intialized, otherwise this isn't an issue anyway
    if (this.contentEditor) {
      this.contentEditor.resetContent();
    }
    this.currentEvidence = {
      id: 0,
      title: '',
      narrative: '',
      type: EvidenceType.NARRATIVE,
      userId: this.userId,
      rubricId: this.rubricId,
      level: this.level,
      competencyId: this.competency.id,
      coachlogId: 0,
      coachlogOptionId: 0,
      strandId: this.strand.id,
      addedFrom: 'portfolio',
      evidenceAttachments: [],
      assessments: [],
    };
    this.uploadType = null;
    this.checkEvidenceRequiredFields();
  }

  fileUploaded() {
    if (
      this.uploadType === UploadType.VIDEO &&
      this.uploadedFiles.length === 1 &&
      !this.newEvidence
    ) {
      this.evidenceService.addVideo(
        {
          objectId: this.currentEvidence.id,
          videoId: this.uploadedFiles[0].id,
          objectType: VideoReferenceObjectType.EVIDENCE,
        },
        this.currentEvidence
      );
    }
  }

  fileDeleted(file: FileDTO) {
    this.uploadedFiles.forEach((uploadedFile, index) => {
      if (uploadedFile.id === file.id) {
        this.uploadedFiles.splice(index, 1);
      }
    });
  }

  evidenceAssessed(response: EvidenceAssessedDTO) {
    const assessedEvidence = this.evidenceList.find(
      (e) => e.id === response.egrowe_evidence_id
    );
    if (assessedEvidence) {
      // Trick display into showing that evidence was assessed
      assessedEvidence.assessments.push({} as EvidenceAssessmentDTO);
    }
  }

  updatedEvidence(evidence: EvidenceDTO) {
    if (
      this.evidenceList.filter((haystack) => haystack.id === evidence.id)
        .length === 0
    ) {
      this.evidenceList.push(evidence);
    } else {
      this.evidenceList = this.evidenceList.map((haystack) => {
        if (haystack.id === evidence.id) {
          this.currentEvidence = evidence;
          return evidence;
        }
        return haystack;
      });
    }
  }

  removedEvidence(evidenceId: number) {
    this.evidenceList = this.evidenceList.filter((e) => e.id !== evidenceId);
  }

  removeVideo() {
    this.evidenceService.removeVideo(this.currentEvidence, true);
    this.disableVideoRemoval = true;
    // Manually update evidence
    this.updateEvidence();
  }

  createEvidence(): void {
    setTimeout(() => {
      this.evidenceService
        .addPortfolioEvidence(this.currentEvidence, this.uploadedFiles)
        .subscribe((newEvidence) => {
          if (newEvidence?.type === EvidenceType.VIDEO) {
            this.evidenceService
              .updateEvidence(newEvidence)
              .subscribe((res) => {
                this.evidenceList.push(res.items[0]);
                this.selectedOptionId = 0;
                this.isDrawerCollapsed = false;
              });
          } else {
            this.evidenceList.push(newEvidence);
            this.selectedOptionId = 0;
            this.isDrawerCollapsed = false;
          }
        });
    }, 500);
  }

  updateEvidence(): void {
    setTimeout(() => {
      this.evidenceService
        .updatePortfolioEvidence(this.currentEvidence)
        .subscribe((res) => {
          this.updatedEvidence(res.items[0]);
          this.selectedOptionId = 0;
        });
    }, 500);
  }

  addEvidence(): void {
    this.resetCurrentEvidence();
    this.initialNarrativeContent = '';
    this.newEvidence = true;
    this.evidenceModal.config.titleText = this.competencyTitle
      ? this.competencyTitle
      : 'Add Evidence';
    this.uploadedFiles.length = 0;
    this.evidenceModal.open();
  }

  editEvidence(id: number): void {
    this.disableVideoRemoval = false;
    this.newEvidence = false;
    this.evidenceModal.config.titleText = this.competencyTitle
      ? this.competencyTitle
      : 'Edit Evidence';
    this.currentEvidence = JSON.parse(
      JSON.stringify(this.evidenceList.find((e) => e.id === id))
    ) as EvidenceDTO;
    // Line is important for coachlog evidence
    this.initialNarrativeContent = this.currentEvidence.narrative || '';
    this.checkEvidenceRequiredFields();
    this.uploadType =
      this.currentEvidence.type === EvidenceType.VIDEO
        ? UploadType.VIDEO
        : UploadType.EVIDENCE;
    this.selectedOptionId = this.currentEvidence.coachlogOptionId;
    this.uploadedFiles.length = 0;
    if (this.uploadComponent && this.uploadType === UploadType.EVIDENCE) {
      (this.uploadComponent.fileService as EvidenceFileService).setEvidenceId(
        this.currentEvidence.id
      );
    }
    if (this.currentEvidence.evidenceAttachments.length > 0) {
      this.currentEvidence.evidenceAttachments.forEach((e) =>
        this.uploadedFiles.push(fileDTOFromEvidenceAttachment(e))
      );
      this.numberOfFilesUploaded = this.uploadedFiles.length;
    }
    if (this.currentEvidence.videos && this.currentEvidence.videos.length > 0) {
      this.numberOfFilesUploaded = this.currentEvidence.videos.length;
    }
    this.evidenceModal.open();
  }

  checkEvidenceRequiredFields() {
    const currentStatus: [boolean, boolean] = [
      this.hasNarrative,
      this.hasTitle,
    ];
    this.hasNarrative = this.currentEvidence.narrative.length > 0;
    this.hasTitle = this.currentEvidence.title.length > 0;
    const newStatus: [boolean, boolean] = [this.hasNarrative, this.hasTitle];
    if (
      currentStatus[0] !== newStatus[0] ||
      currentStatus[1] !== newStatus[1]
    ) {
      this.changeRef.detectChanges();
    }
  }

  changeEvidenceType() {
    this.currentEvidence.type =
      this.uploadType === UploadType.VIDEO
        ? EvidenceType.VIDEO
        : EvidenceType.NARRATIVE;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  closeModalOnly() {
    this.evidenceModal.dismiss();
    this.selectedOptionId = 0;
  }

  openCopyEvidenceModal(evidenceId: number) {
    this.copyEvidenceModal.evidenceId = evidenceId;
    this.copyEvidenceModal.modal.open();
  }

  updateNarrative(narrative: string) {
    this.currentEvidence.narrative = narrative;
    this.checkEvidenceRequiredFields();
  }
}
