import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export interface TopicDTO {
  title: string;
  id: number;
  is_calibration?: number;
}

@Component({
  selector: 'app-topic-select',
  templateUrl: './topic-select.component.html',
  styleUrls: ['./topic-select.component.scss'],
  standalone: false,
})
export class TopicSelectComponent implements OnInit, OnChanges {
  labelId = uuidv4();

  @Input() label = 'Search for and select a topic';

  @Input() defaultTopic: TopicDTO | null;

  @Input() topics: TopicDTO[];

  @Input() districtIds: number[];

  @Input() placeholder = 'Topic';

  @Output() readonly selectedTopic: EventEmitter<TopicDTO> = new EventEmitter();

  internalSelectedTopic: TopicDTO | null;

  searchLoading = false;

  ngOnInit(): void {
    if (this.defaultTopic) {
      this.internalSelectedTopic = this.defaultTopic;
    }
    if (this.topics && this.topics.length === 0) {
      this.searchLoading = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['districtIds'] &&
      changes['districtIds'].firstChange === false
    ) {
      if (
        changes['districtIds'].currentValue !==
          changes['districtIds'].previousValue &&
        this.internalSelectedTopic
      ) {
        this.internalSelectedTopic = null;
      }
    } else if (changes['topics']) {
      if (this.topics?.length > 0) {
        this.searchLoading = false;
      }
    }
  }

  topicSelected(topic: TopicDTO) {
    this.internalSelectedTopic = topic;
    this.selectedTopic.emit(topic);
  }

  reset() {
    this.internalSelectedTopic = null;
  }
}
