import { Pipe, PipeTransform } from '@angular/core';
import { SelectableCoachee } from './selectable.coachee';

@Pipe({
  name: 'filterTable',
  standalone: false,
})
export class FilterTableSearch implements PipeTransform {
  // eslint-disable-next-line
  transform(tableValues: SelectableCoachee[], searchTerm: string) {
    return tableValues.filter((coachee: SelectableCoachee) => {
      const term = searchTerm.toLowerCase();
      const fullName = `${coachee.profile.first_name.toLowerCase()} ${coachee.profile.last_name.toLowerCase()}`;

      return (
        fullName.includes(term) ||
        coachee.cohorts.some((cohort) =>
          cohort.tag.toLowerCase().includes(term)
        )
      );
    });
  }
}
