import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  dateFromUnixTimestamp,
  ngbDateStructFromDate,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { AlertService } from '../../../services/alert/alert.service';
import { PlansService } from '../../../services/plans/plans.service';
import { PusherAssignmentChangesDTO } from '../../../services/plans/plans.service.dto';
import {
  AddAssignment,
  CreateActionItem,
} from '../../../state/implementation-plan/implementation-plan.actions';
import { CreateActionItemPayload } from '../../../types/payloads/plan.payload';
import { DeliverableAPIResponse } from '../../../types/responses/plan.responses';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-action-item-modal',
  templateUrl: './create-action-item-modal.component.html',
  styleUrl: './create-action-item-modal.component.scss',
  standalone: false,
})
export class CreateActionItemModalComponent implements OnInit {
  @Input() deliverable: DeliverableAPIResponse;

  @Input() displayName: string;

  @Input() districtId: number;

  @ViewChild('createActionItemModal')
  createActionItemModal: ModalComponent;

  @ViewChild('actionItemDueDatePicker') dueDatePicker: DatepickerComponent;

  user: User;

  isE2L = false;

  form: FormGroup;

  formSubmitted = false;

  isLoading = false;

  serverError = '';

  dueDate = '';

  assignees: UserLiteDTO[] = [];

  datePickerMinDate: Date;

  datePickerMaxDate: Date;

  defaultDueDate: number;

  constructor(
    private formBuilder: FormBuilder,
    private plansService: PlansService,
    private alertService: AlertService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
    });
    if (this.deliverable) {
      this.datePickerMinDate = new Date(this.deliverable.start_date);
      this.datePickerMinDate.setDate(this.datePickerMinDate.getDate() + 1);
      this.datePickerMaxDate = new Date(this.deliverable.due_date);
      this.datePickerMaxDate.setDate(this.datePickerMaxDate.getDate() + 1);
      this.defaultDueDate = this.datePickerMaxDate.getTime();
    }
  }

  openModal() {
    this.createActionItemModal.open();
  }

  clearForm() {
    this.form.reset({
      title: '',
      description: '',
    });
    this.dueDate = '';
    this.dueDatePicker.selectedDate = ngbDateStructFromDate(
      new Date(this.defaultDueDate)
    );
    this.assignees = [];
    this.isLoading = false;
    this.formSubmitted = false;
  }

  createActionItem() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return false;
    }
    this.isLoading = true;

    const createActionItemPayload: CreateActionItemPayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      due_date: this.dueDate,
    };

    this.plansService
      .createActionItem(this.deliverable.id, createActionItemPayload)
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: (actionItem) => {
          if (actionItem.item) {
            this.store.dispatch(new CreateActionItem(actionItem.item));
            if (this.assignees.length) {
              let addUserCount = 0;
              this.assignees.forEach((user) => {
                this.plansService
                  .addActionItemAssignee(actionItem.item.id, user.id)
                  .subscribe((assignee) => {
                    if (assignee.item) {
                      addUserCount += 1;
                      const addAssignmentChanges: PusherAssignmentChangesDTO = {
                        for: 'implementation_action_item',
                        for_id: actionItem.item.id,
                        assignment: assignee.item,
                        implementation_phase_id: Number(
                          this.deliverable.implementation_phase_id
                        ),
                        implementation_deliverable_id: this.deliverable.id,
                      };
                      this.store.dispatch(
                        new AddAssignment(addAssignmentChanges)
                      );
                      if (addUserCount === this.assignees.length) {
                        this.createActionItemModal.close();
                        this.alertService.showAlert(
                          `${this.displayName} Created`
                        );
                      }
                    }
                  });
              });
            } else {
              this.createActionItemModal.close();
              this.alertService.showAlert(`${this.displayName} Created`);
            }
          }
        },
      });
    return true;
  }

  updateDueDate(date: DatepickerOutput[]) {
    let actionDueDate = date[0].time;
    const minStartDate = this.datePickerMinDate.getTime();
    if (actionDueDate > this.defaultDueDate) {
      actionDueDate = this.defaultDueDate;
    } else if (actionDueDate < minStartDate) {
      actionDueDate = minStartDate;
    }
    this.dueDate = dateFromUnixTimestamp(actionDueDate);
    if (this.dueDatePicker) {
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        actionDueDate / 1000
      );
    }
  }

  updateAssignees(user: UserLiteDTO | null) {
    if (
      user &&
      !this.assignees.filter((assignee) => assignee.id === user.id).length
    ) {
      this.assignees.push(user);
    }
  }

  removeAssignee(user: UserLiteDTO) {
    this.assignees = this.assignees.filter(
      (assignee) => assignee.id !== user.id
    );
  }
}
