import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import {
  ReportRequestParams,
  TextResponse,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  TimezoneLinkBack,
  getTimezoneLinkbackFromAbbreviation,
  guessTimezone,
} from 'src/app/common/utilities/time-helpers';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-table-controller',
  templateUrl: './table-controller.component.html',
  styleUrls: ['./table-controller.component.scss'],
  standalone: false,
})
export class TableControllerComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() requestParams: ReportRequestParams;

  @Input() questionTitle: string;

  @Input() fieldId: string;

  @ViewChild('responseModal') modal: ModalComponent;

  modalResponse = '';

  isLoading = true;

  params: ReportRequestParams[] = [];

  currentPage = 1;

  totalResponses = 0;

  responses: TextResponse[];

  responseTimes: number[] = [];

  timeZone: TimezoneLinkBack;

  spinnerOptions = {
    text: 'Loading...',
    textColor: '#27004b',
    fontFamily: 'greycliff-cf',
    maskColor: 'rgba(255, 255, 255, 0.8)',
    zlevel: 10,
    fontSize: 24,
    showSpinner: false,
  };

  user: User;

  constructor(
    private apiService: APICoreService,
    private store: Store,
    private cdRef: ChangeDetectorRef
  ) {
    this.timeZone = getTimezoneLinkbackFromAbbreviation(guessTimezone());
  }

  ngOnInit(): void {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    // this.isLoading = false;
    this.getResponses(this.requestParams);
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change['requestParams'] &&
      change['requestParams'].firstChange === false
    ) {
      this.getResponses(this.requestParams);
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getResponses(params: ReportRequestParams) {
    this.isLoading = true;
    const responseParams = {
      report: 'observationFormTextResponses',
      ...params,
    };
    this.apiService
      .getRequest('reporting/load-report', responseParams)
      .subscribe((response) => {
        this.totalResponses = response.totalCount;
        this.responses = response.items.sort(
          (a: TextResponse, b: TextResponse) => {
            const aTime = DateTime.fromFormat(
              a.submission_date,
              'yyyy-MM-dd HH:mm:ss'
            ).toSeconds();
            const bTime = DateTime.fromFormat(
              b.submission_date,
              'yyyy-MM-dd HH:mm:ss'
            ).toSeconds();
            if (aTime < bTime) {
              return 1;
            }
            if (bTime > aTime) {
              return -1;
            }
            return 0;
          }
        );
        this.responses.forEach((submission: TextResponse) => {
          this.responseTimes.push(
            DateTime.fromFormat(
              submission.submission_date,
              'yyyy-MM-dd HH:mm:ss',
              { zone: 'utc' }
            ).toSeconds()
          );
        });
        this.isLoading = false;
      });
  }

  paginationChange() {
    const params = {
      ...this.requestParams,
      page: this.currentPage,
    };
    this.getResponses(params);
  }

  /* eslint-disable-next-line class-methods-use-this */
  getSessionLink(coachlogID: number) {
    return `/observation/log/${coachlogID}/info`;
  }

  hasOverflow(elementIndex: number) {
    const element = document.getElementById(
      `response-${this.fieldId}-${elementIndex}`
    );

    if (element) {
      if (element.clientHeight >= 80) {
        return (
          element.clientHeight < element.scrollHeight ||
          element.clientWidth < element.scrollWidth
        );
      }
    }
    return false;
  }

  openResponseModal(responseText: string) {
    this.modal.config.titleText = this.questionTitle;
    this.modal.config.headerClass = [
      'modal-header',
      'bg-primary',
      'text-white',
    ];
    this.modal.config.showFooter = false;
    this.modal.config.options = { centered: true };
    this.modalResponse = responseText;

    this.modal.open();
  }
}
