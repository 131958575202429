import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import {
  KpiReportFilter,
  ReportingLogDetailsItemDTO,
} from '../../../common/dtos/reports.dto';
import { toHoursAndMinutes } from '../../../common/utilities/time-helpers';
import { AvatarDTO } from '../../shared/components/avatar/avatar.dto';
import {
  SortEvent,
  SortableHeader,
} from '../../shared/directives/sortable-header.directive';
import { ReportPageFiltersService } from '../../shared/services/report/report-page-filters.service';
import { ReportSessionDetailsService } from '../../shared/services/report/report-session-details.service';

interface LogDetailsSortIcon {
  title: 'gray' | 'none';
  session_type: 'gray' | 'none';
  observation_form: 'gray' | 'none';
  startdate: 'gray' | 'none';
  duration: 'gray' | 'none';
  coachee: 'gray' | 'none';
  is_present: 'gray' | 'none';
  absent_reason: 'gray' | 'none';
  coach: 'gray' | 'none';
  shadower: 'gray' | 'none';
  coaching_source: 'gray' | 'none';
  school: 'gray' | 'none';
}

@Component({
  selector: 'app-reporting-session-details',
  templateUrl: './reporting-session-details.component.html',
  styleUrls: ['./reporting-session-details.component.scss'],
  standalone: false,
})
export class ReportingSessionDetailsComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  selectedSchool: SchoolDTO | null;

  selectedDistrict: DistrictSimpleDto | null;

  canFilterByDistrict = false;

  isLoading = false;

  disableDownload = false;

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  tableData: ReportingLogDetailsItemDTO[];

  listMeta: PaginationDTO;

  filters: KpiReportFilter[];

  sortIconTemp: LogDetailsSortIcon = {
    title: 'gray',
    session_type: 'gray',
    observation_form: 'gray',
    startdate: 'gray',
    duration: 'gray',
    coachee: 'gray',
    is_present: 'gray',
    absent_reason: 'gray',
    coach: 'gray',
    shadower: 'gray',
    coaching_source: 'gray',
    school: 'gray',
  };

  sortBy = '';

  sortByDesc = 0;

  column = '';

  direction = '';

  lastUpdatedDate: string;

  constructor(
    private store: Store,
    private reportLogDetailsService: ReportSessionDetailsService,
    private reportFiltersService: ReportPageFiltersService
  ) {
    const user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canFilterByDistrict = checkIfE2L(user) || checkIfB2b(user);
  }

  ngOnInit() {
    this.subs.push(
      this.reportFiltersService.filters.pipe().subscribe((filters) => {
        if (filters.schoolYearFilter) {
          this.filters = translateKpiReportFilterFromFilterDTO(filters);
          this.getLogDetails(this.filters);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  downloadCSV(): void {
    this.disableDownload = true;
    this.reportFiltersService.queueReportDownload('session_details');
  }

  getLogDetails(filters: KpiReportFilter[], page?: number) {
    this.isLoading = true;
    this.lastUpdatedDate = DateTime.now().toFormat('MM/dd h:mm a');
    this.reportLogDetailsService
      .getSessionDetails(filters, page, this.sortBy, this.sortByDesc)
      .subscribe((res) => {
        this.tableData = res.items;
        this.listMeta = res._meta;
        this.isLoading = false;
      });
  }

  toHoursAndMinutes = toHoursAndMinutes;

  // eslint-disable-next-line class-methods-use-this
  getSessionLink(log: ReportingLogDetailsItemDTO) {
    if (log.logType.includes('mySmartCoach')) {
      return `/smart-coach/log/${log.id}/info`;
    }
    switch (log.logTypeView) {
      case 'egrowe-coachlog-v2':
        return `/coaching/log/${log.id}/info`;
      case 'universal-log':
        return `/coaching/log/${log.id}/info`;
      case 'observation':
        return `/observation/log/${log.id}/info`;
      case 'general-log':
        return `/general/log/${log.id}/info`;
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAvatarData(
    attendeeName: string,
    attendeeColor: string,
    attendeePhotoUrl: string
  ): AvatarDTO {
    const names = attendeeName.split(' ');
    return {
      firstName: names[0],
      lastName: names[1],
      color: attendeeColor,
      photoUrl: attendeePhotoUrl
        ? `${EnvironmentService.apiUrl()}${attendeePhotoUrl}`
        : undefined,
    };
  }

  paginationChange() {
    this.getLogDetails(this.filters, this.listMeta.currentPage);
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable as keyof LogDetailsSortIcon] = 'gray';
      } else if (header.direction === '') {
        this.sortIconTemp[header.sortable as keyof LogDetailsSortIcon] = 'gray';
      } else {
        this.sortIconTemp[header.sortable as keyof LogDetailsSortIcon] = 'none';
      }
    });

    this.sortBy = column;
    this.sortByDesc = 0;
    if (direction === 'asc') {
      this.sortByDesc = 1;
    }
    if (direction === '') {
      this.sortBy = '';
    }

    this.column = column;
    this.direction = direction;

    this.getLogDetails(this.filters);
  }
}
