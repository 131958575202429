<app-modal
  #modal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false,
    titleText: 'Survey'
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  &lt;app-proform [formId]={{ formId }}/&gt;
</app-modal>
