import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AlertDTO, AlertService } from '../../services/alert/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false,
})
export class AlertComponent implements OnInit, OnDestroy {
  @ViewChild('globalAlert')
  globalAlert: NgbAlert;

  subs: Subscription[] = [];

  alertSpecs: AlertDTO;

  active = false;

  constructor(private alertService: AlertService) {}

  /**
   * Subscribes to the current alertSpecs and alertSubjects.
   *
   * The alertSpecs subscription adds spec if component is not
   * active
   *
   * The alertSubject sets if the alert is active
   *
   * This function runs onInit
   */
  ngOnInit(): void {
    this.subs.push(
      this.alertService.alertSpecs.subscribe((specs) => {
        if (!this.active) {
          this.alertSpecs = specs;
        }
      })
    );

    this.subs.push(
      this.alertService.showAlertSubject.subscribe((res) => {
        if (res && !this.active) {
          this.active = res;
          setTimeout(() => this.globalAlert?.close(), 2000);
        }
      })
    );
  }

  /**
   * Unsubscribes from alertSubjet and alertSpecs onDestroy
   */
  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
