/* eslint-disable array-callback-return */
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, debounceTime, take } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { MOCK_USER_DTO } from 'src/app/common/state/user/user.mocks';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { EvidenceAssessedDTO } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { EvidenceSummaryComponent } from '../../shared/components/evidence-summary/evidence-summary.component';
import {
  SortEvent,
  SortableHeader,
} from '../../shared/directives/sortable-header.directive';
import { userDTOFromAPIResponse } from '../../shared/helpers/translators/user.translators';
import { EvidenceService } from '../../shared/services/evidence/evidence.service';

interface EvidenceRow {
  coachee: UserDTO;
  evidence: string;
  competency: string;
  level: number;
  dateUploaded: number;
  reviewed: boolean;
  id: number;
  autoOpenModal: boolean;
}

@Component({
  selector: 'app-coachee-evidence-page',
  templateUrl: './coachee-evidence-page.component.html',
  styleUrls: ['./coachee-evidence-page.component.scss'],
  standalone: false,
})
export class CoacheeEvidencePageComponent implements OnInit {
  user: User;

  dayOfWeek = new Date().toLocaleDateString('en-US', { weekday: 'long' });

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @ViewChildren(EvidenceSummaryComponent)
  evidenceSummaries: QueryList<EvidenceSummaryComponent>;

  tableData: EvidenceRow[];

  coacheeEvidence: EvidenceRow[] = [];

  isLoading = true;

  evidenceIdParam: number;

  listMeta: PaginationDTO;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    sort_user_name: 'gray',
    sort_evidence_title: 'gray',
    sort_competency_name: 'gray',
    sort_level: 'gray',
    sort_date: 'gray',
    sort_is_reviewed: 'gray',
  };

  sortBy = 'sort_date';

  sortByDesc = 1;

  searchTerm = '';

  private searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private evidenceService: EvidenceService,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.getAllCoacheeEvidenceList();
    this.searchSubject.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.getAllCoacheeEvidenceList(searchValue);
    });

    this.route.queryParams.subscribe((params) => {
      this.evidenceIdParam = parseInt(params['evidence_id']);
    });
  }

  search(event: Event) {
    this.searchTerm = (event.target as HTMLInputElement).value;
    this.searchSubject.next(this.searchTerm);
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else if (header.direction === '') {
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    this.sortBy = column;
    this.sortByDesc = 1;
    if (direction === 'desc') {
      this.sortByDesc = 0;
    }
    if (direction === '') {
      this.sortBy = 'sort_date';
    }

    this.getAllCoacheeEvidenceList(this.searchTerm);
  }

  getAllCoacheeEvidenceList(
    searchTerm?: string,
    currentPage = 1,
    sortBy = this.sortBy,
    sortByDesc = this.sortByDesc
  ): void {
    this.coacheeEvidence = [];
    this.isLoading = true;
    this.evidenceService
      .getAllCoacheeEvidenceList(currentPage, sortBy, sortByDesc, searchTerm)
      .subscribe((res) => {
        take(1);
        if (res) {
          this.listMeta = res._meta;

          res.items.forEach((item) => {
            const row: EvidenceRow = {
              coachee: MOCK_USER_DTO,
              evidence: '',
              competency: '',
              level: 0,
              dateUploaded: 0,
              reviewed: false,
              id: item.evidence.id,
              autoOpenModal: false,
            };
            row.coachee = userDTOFromAPIResponse(item.user);
            row.competency = item.evidence.standard.title;
            row.evidence = item.evidence.title;
            row.dateUploaded = item.evidence.created_at;
            row.level = item.evidence.level;
            row.reviewed = item.evidence.assessments.length > 0;
            if (this.evidenceIdParam === item.evidence.id) {
              row.autoOpenModal = true;
            }
            this.coacheeEvidence.push(row);
          });
          this.tableData = this.coacheeEvidence;
          this.isLoading = false;
        }
      });
  }

  updateReviewedStatus(response: EvidenceAssessedDTO) {
    const foundIndex = this.tableData.findIndex(
      (x) => x.id === response.egrowe_evidence_id
    );
    this.tableData[foundIndex].reviewed = true;
  }
}
