import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoachingSessionFilterDTO,
  emptyCoachingSessionFilters,
} from 'src/app/common/dtos/coaching-session-filter.dto';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import {
  dateTimeFromNgbDate,
  ngbDateFromDate,
} from 'src/app/common/utilities/date-helpers';
import { checkIfB2b, checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { CompetencySelectComponent } from '../../competency/competency-select/competency-select.component';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { LogTypeSelectComponent } from '../../log-type-select/log-type-select.component';
import { SchoolSelectComponent } from '../../school-select/school-select.component';
import { UserSelectComponent } from '../../user-select/user-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-coaching-session-filter-modal',
  templateUrl: './coaching-session-filter-modal.component.html',
  styleUrls: ['./coaching-session-filter-modal.component.scss'],
  standalone: false,
})
export class CoachingSessionFilterModalComponent
  implements AfterViewInit, OnInit
{
  @Input() initialFilters: CoachingSessionFilterDTO;

  @Output() readonly setFilters: EventEmitter<CoachingSessionFilterDTO> =
    new EventEmitter<CoachingSessionFilterDTO>();

  @Output() readonly modalClose: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('districtSelect') districtSelect: DistrictSelectComponent;

  @ViewChild('logTypeSelect') logTypeSelect: LogTypeSelectComponent;

  @ViewChild('schoolSelect') schoolSelect: SchoolSelectComponent;

  @ViewChild('competencySelect') competencySelect: CompetencySelectComponent;

  @ViewChild('coachSelect') coachSelect: UserSelectComponent;

  @ViewChild('coacheeSelect') coacheeSelect: UserSelectComponent;

  @Input() user: User | null;

  currentFilters: CoachingSessionFilterDTO;

  canSeeAllSchools = false;

  isE2L = false;

  canFilterByDistrict = false;

  ngOnInit(): void {
    if (this.user) {
      this.canSeeAllSchools = compareRoles(
        [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
        this.user.roles
      );

      this.isE2L = checkIfE2L(this.user);
      this.canFilterByDistrict = this.isE2L || checkIfB2b(this.user);
    }

    this.currentFilters =
      deepCopy(this.initialFilters) || emptyCoachingSessionFilters();
  }

  ngAfterViewInit(): void {
    this.modal.open();
    this.modal.isDismissing.subscribe(() => {
      this.modalClose.emit();
    });
  }

  closeModal(saveFilters = false): void {
    if (saveFilters) {
      if (!this.currentFilters.fromDate.year) {
        this.currentFilters.fromDate = ngbDateFromDate(
          dateTimeFromNgbDate(this.currentFilters.toDate)
            .minus({ days: 7 })
            .toJSDate()
        );
      }
      if (!this.currentFilters.toDate) {
        this.currentFilters.toDate = ngbDateFromDate(
          dateTimeFromNgbDate(this.currentFilters.fromDate)
            .plus({ days: 7 })
            .toJSDate()
        );
      }
      this.setFilters.emit(this.currentFilters);
    }
    this.modal.close();
    this.modalClose.emit();
  }

  clearFilters(district?: DistrictSimpleDto | null): void {
    this.currentFilters = emptyCoachingSessionFilters();
    if (district) {
      this.currentFilters.district = district;
    } else {
      this.districtSelect.reset();
    }
    this.logTypeSelect.reset();
    this.competencySelect.reset();
    this.schoolSelect.reset();
    this.coachSelect.reset();
    this.coacheeSelect.reset();
  }

  updateSchools(schools: SchoolDTO[] | SchoolDTO) {
    this.currentFilters.schoolList =
      typeof schools === 'object' ? (schools as SchoolDTO[]) : [schools];
  }
}
