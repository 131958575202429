import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, merge, Subject, Subscription } from 'rxjs';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { FileDTO } from '../../../dtos/file.dto';
import { UploadType } from '../../../enums/upload-type.enum';
import { getIcon } from '../../../helpers/icon.utilities';
import { AlertService } from '../../../services/alert/alert.service';
import { BadgeFileService } from '../../../services/file/badge.file.service';
import { StandardIconFileService } from '../../../services/file/standard-icon.file.service';
import { TopicBuilderService } from '../../../services/topic-builder/topic-builder.service';
import { CompetencyStandardAPIResponse } from '../../../types/responses/coaching-log.responses';
import {
  CompetencyBuilderIndicatorSetAPIResponse,
  CompetencyBuilderStandardAPIResponse,
} from '../../../types/responses/competency-builder.responses';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../file-management/file-upload/file-upload.component';
import { ModalComponent } from '../../modals/modal/modal.component';
import { FroalaOptions } from '../../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-create-edit-competency-modal',
  templateUrl: './create-edit-competency-modal.component.html',
  styleUrl: './create-edit-competency-modal.component.scss',
  standalone: false,
})
export class CreateEditCompetencyModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  modalConfig = {
    titleText: 'Competency Builder',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    options: {
      size: 'xl',
    },
  };

  customFroalaOptions: FroalaOptions = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'textColor'],
        buttonsVisible: 3,
      },
      moreRich: {
        buttons: ['insertLink'],
        buttonsVisible: 1,
      },
    },
    editorClass: 'mini',
  };

  @ViewChild('createEditCompetencyModal')
  createEditCompetencyModal: ModalComponent;

  @ViewChild('iconUploadComponent') iconUploadComponent: FileUploadComponent;

  @Input() topic: CompetencyBuilderStandardAPIResponse;

  @Output() readonly refreshCompetencies = new EventEmitter();

  subs: Subscription[] = [];

  currentCompetency: CompetencyBuilderStandardAPIResponse | null;

  currentIndicatorSet: CompetencyBuilderIndicatorSetAPIResponse;

  iconUploadStyle = UploadStyles.BADGE;

  microUploadStyle = UploadStyles.BADGE;

  iconUploadType = UploadType.STANDARD_ICON;

  microUploadType = UploadType.MICROCREDENTIAL_BADGE;

  microBadgeExists = false;

  deleteLoading = false;

  addStrandLoading = false;

  apiLoading = false;

  updatingContent = false;

  updatingTitle = false;

  private titleInputSubject = new Subject<string>();

  constructor(
    private topicBuilderService: TopicBuilderService,
    private alertService: AlertService,
    private badgeFileService: BadgeFileService,
    private standardIconFileService: StandardIconFileService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.topicBuilderService.topicBuilderAPILoading.subscribe((loading) => {
        this.apiLoading = loading;
        this.cdr.detectChanges();
      })
    );

    this.subs.push(
      this.titleInputSubject.pipe(debounceTime(350)).subscribe((title) => {
        this.updateCompetency('title', title);
      })
    );
  }

  ngAfterViewInit(): void {
    merge(
      this.createEditCompetencyModal.isClosing,
      this.createEditCompetencyModal.isDismissing
    ).subscribe(() => {
      this.currentCompetency = null;
      this.refreshCompetencies.emit();
      this.iconUploadStyle = UploadStyles.BADGE;
      this.microUploadStyle = UploadStyles.BADGE;
      this.microBadgeExists = false;
      this.apiLoading = false;
    });
  }

  openModal(competency?: CompetencyStandardAPIResponse) {
    if (competency) {
      this.currentCompetency = deepCopy(competency);
      if (this.currentCompetency?.icon) {
        this.currentCompetency.icon = getIcon(this.currentCompetency.icon);
        this.iconUploadStyle = UploadStyles.NONE;
      } else {
        this.iconUploadStyle = UploadStyles.BADGE;
      }
      this.loadIndicatorSetData();
    }
    this.createEditCompetencyModal.open();
  }

  closeModal() {
    this.createEditCompetencyModal.close();
  }

  loadIndicatorSetData() {
    if (this.currentCompetency) {
      this.topicBuilderService
        .getIndicatorSet(this.topic.id, this.currentCompetency.id)
        .subscribe((res) => {
          this.currentIndicatorSet = res.item;
          setTimeout(() => {
            if (this.currentCompetency && this.iconUploadComponent) {
              this.standardIconFileService.setStandardId(
                this.currentCompetency.id
              );
            }
          }, 500);
          const badgeData = this.currentIndicatorSet.standard.competencyBadge;
          if (badgeData) {
            this.badgeFileService.setBadgeId(badgeData.id);
            if (badgeData.image && badgeData.image !== '/img/e2L.Logo.webp') {
              badgeData.image = getIcon(badgeData.image);
              this.microUploadStyle = UploadStyles.NONE;
              this.microBadgeExists = true;
            } else {
              this.microUploadStyle = UploadStyles.BADGE;
              this.microBadgeExists = false;
            }
          }
        });
    }
  }

  updateCompetencyIcon(file: FileDTO) {
    if (this.currentCompetency && file.location) {
      this.currentCompetency.icon = getIcon(file.location);
      this.iconUploadStyle = UploadStyles.NONE;
    }
    this.refreshCompetencies.emit();
  }

  updateMicroBadge(file: FileDTO) {
    if (this.currentIndicatorSet.standard.competencyBadge && file.location) {
      this.currentIndicatorSet.standard.competencyBadge.image = getIcon(
        file.location
      );
      this.microUploadStyle = UploadStyles.NONE;
      this.microBadgeExists = true;
    }
  }

  createCompetency() {
    this.topicBuilderService
      .createCompetency(
        this.topic.id,
        this.topic.standardSet.standardGroups[0].id
      )
      .subscribe((createCompetencyResponse) => {
        if (createCompetencyResponse) {
          this.currentCompetency = createCompetencyResponse.item;
          if (this.currentCompetency) {
            this.standardIconFileService.setStandardId(
              this.currentCompetency.id
            );
          }
          this.topicBuilderService
            .getIndicatorSet(this.topic.id, createCompetencyResponse.item.id)
            .subscribe((indicatorSetResponse) => {
              if (indicatorSetResponse) {
                this.topicBuilderService
                  .createIndicatorGroup(
                    indicatorSetResponse.item.egrowe_indicator_set_id
                  )
                  .subscribe((indicatorGroupResponse) => {
                    if (indicatorGroupResponse) {
                      this.topicBuilderService
                        .getIndicatorSet(
                          this.topic.id,
                          createCompetencyResponse.item.id
                        )
                        .subscribe((updatedIndicatorSetResponse) => {
                          this.currentIndicatorSet =
                            updatedIndicatorSetResponse.item;
                          if (
                            this.currentIndicatorSet.standard.competencyBadge
                          ) {
                            this.badgeFileService.setBadgeId(
                              this.currentIndicatorSet.standard.competencyBadge
                                .id
                            );
                          }
                          this.topicBuilderService.createCompetencySource.next(
                            true
                          );
                          this.alertService.showAlert('Competency created');
                          this.openModal();
                        });
                    }
                  });
              }
            });
        }
      });
  }

  deleteCompetency() {
    if (this.currentCompetency) {
      this.deleteLoading = true;
      this.topicBuilderService
        .deleteCompetency(this.currentCompetency.id)
        .subscribe((res) => {
          if (res) {
            this.deleteLoading = false;
            this.createEditCompetencyModal.close();
            this.refreshCompetencies.emit();
            this.alertService.showAlert('Competency deleted');
          }
        });
    }
  }

  addStrand() {
    this.addStrandLoading = true;
    this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
    this.topicBuilderService
      .createIndicatorGroup(this.currentIndicatorSet.egrowe_indicator_set_id)
      .subscribe((res) => {
        if (res) {
          this.addStrandLoading = false;
          this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
          this.loadIndicatorSetData();
          this.alertService.showAlert('Strand added');
        }
      });
  }

  deleteStrand(strandId: number) {
    this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
    this.topicBuilderService.deleteIndicatorGroup(strandId).subscribe((res) => {
      if (res) {
        this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
        this.loadIndicatorSetData();
        this.alertService.showAlert('Strand deleted');
      }
    });
  }

  saveCompetency() {
    this.alertService.showAlert('Competency saved');
    this.closeModal();
  }

  updateCompetency(type: string, payload?: string) {
    if (this.currentCompetency) {
      if (type === 'title') {
        this.updatingTitle = true;
        this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
        this.topicBuilderService
          .updateCompetency(this.currentCompetency.id, {
            rubric_id: this.topic.id,
            title: this.currentCompetency.title,
          })
          .subscribe((res) => {
            if (res) {
              this.refreshCompetencies.emit();
              this.updatingTitle = false;
              this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
            }
          });
      } else if (type === 'content') {
        this.updatingContent = true;
        this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
        this.topicBuilderService
          .updateCompetency(this.currentCompetency.id, {
            rubric_id: this.topic.id,
            content: payload || ' ',
          })
          .subscribe((res) => {
            if (res) {
              this.refreshCompetencies.emit();
              this.updatingContent = false;
              this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
            }
          });
      }
    }
  }

  onTitleInput(title: string) {
    this.titleInputSubject.next(title);
  }

  onInputChangeEvent(event: string) {
    this.apiLoading = true;
    if (event === 'competencyContent') {
      this.updatingContent = true;
      this.cdr.detectChanges();
    } else if (event === 'levelContent') {
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
