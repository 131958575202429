import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CompetencyPreviewDTO,
  CompetencyRequestDTO,
} from 'src/app/private/shared/dtos/competencies.dto';
import { CompetenciesSummaryService } from '../../../services/competencies/competencies-summary.service';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-competency-preview',
  templateUrl: './preview-competency.component.html',
  styleUrl: './preview-competency.component.scss',
  standalone: false,
})
export class PreviewCompetencyComponent implements OnChanges {
  @Input() competencyData: CompetencyRequestDTO | null;

  @ViewChild('competencyPreviewModal') competencyPreviewModal: ModalComponent;

  competency: CompetencyPreviewDTO | null;

  constructor(private competenciesSummaryService: CompetenciesSummaryService) {}

  ngOnChanges(changes: SimpleChanges) {
    // Fetch competency data
    if (changes['competencyData'] && this.competencyData) {
      this.competenciesSummaryService
        .getSingleCompetency(this.competencyData)
        .subscribe({
          next: (competency) => {
            this.competency = competency.item;
          },
          error: () => {
            this.competency = null;
          },
        });
    }
  }

  openModal() {
    this.competencyPreviewModal.open();
  }

  closeModal() {
    this.competencyPreviewModal.close();
  }
}
