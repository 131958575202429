<app-modal
  #logViewDetailsModal
  (isDismissing)="closeModal()"
  [modalConfig]="{
    titleText: 'Coaching Session',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    showFooter: false
  }">
  <button
    class="btn btn-closemodal"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>
  <ng-container *ngIf="errorMessage">
    {{ errorMessage }}
  </ng-container>
  <ng-container *ngIf="coachlog">
    <ng-container *ngIf="editable; else viewOnly">
      <div class="field-holder">
        <label class="label-text mt-0" for="coachlog-type">Session Type</label>
        <div>
          {{ coachlog.type.title }}
        </div>
      </div>
      <div class="field-holder">
        <label class="label-text" for="coachlog-title">Title</label>
        <input
          type="text"
          class="form-control"
          id="coachlog-title"
          aria-label="Session Title"
          placeholder="Session Title"
          appAutosave
          [(ngModel)]="coachlog.title"
          (autosave)="updateTitle()" />
      </div>
      <div class="field-holder">
        <label class="label-text" for="session-datetime">Date & Time</label>
        <app-datepicker
          id="session-datetime"
          (validTime)="isTimeValid = $event"
          (dateSelectedUnixTimestamp)="updateDateTime($event)"
          [initialDateUnixTimestamp]="coachlog.startDatetime"
          [defaultStartTime]="logStart || '9:00'"
          [defaultEndTime]="logEnd || '9:30'"
          [timezone]="coachlog.timezone"
          [timepicker]="true" />
      </div>
      <div class="field-holder">
        <label class="label-text" for="session-schools">School</label>
        <div>
          <ng-container
            id="session-schools"
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.school">
                  <app-school-name [schoolId]="attendee.user.school.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="field-holder">
        <label class="label-text" for="session-district">District</label>
        <div>
          <ng-container
            id="session-district"
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.district">
                  <app-district-name [districtId]="attendee.user.district.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div style="clear: both; height: 8px"></div>
      <div class="title-text">Attendees</div>
      <hr class="hr-line" />
      <div class="field-holder">
        <div class="label-text mb-2">Owner:</div>
        <div class="d-flex flex-row">
          <ng-container *ngIf="coachlog.user">
            <app-avatar
              [profile]="coachlog.user.profile"
              [avatarSize]="'small'">
            </app-avatar>
            <div class="username ms-2">
              {{ coachlog.user.profile.first_name }}
              {{ coachlog.user.profile.last_name }}
            </div>
          </ng-container>
        </div>
      </div>
      <ng-container
        *ngIf="
          coachlog.attendees.length + coachlog.shadowers.length > maxCount
        ">
        <div class="fw-800 text-center mt-3 mb-2">
          Maximum number of participants reached
        </div>
      </ng-container>

      <div class="label-text">Participants:</div>
      <div class="d-flex flex-row flex-wrap">
        <div
          class="me-3"
          style="flex-basis: 45%"
          *ngFor="let attendee of coachlog.attendees">
          <app-coaching-attendee
            [attendee]="attendee"
            [isCoach]="true"
            [calendarDetails]="true"
            avatarSize="small"
            (removedAttendee)="removeCoachee(attendee)" />
        </div>
      </div>
      <ng-container *ngIf="coachlog.type.isClassroomVisit; else defaultSelect">
        <ng-container *ngIf="coachlog.attendees.length < 1">
          <label class="label-text">Add Participant</label>
          <app-user-select
            [districtId]="districtId"
            [includeAllB2bDistricts]="includeAllB2bDistricts"
            [clearAfterSelection]="true"
            (selectedUser)="addCoachee($event)" />
        </ng-container>
      </ng-container>
      <ng-template #defaultSelect>
        <ng-container
          *ngIf="
            coachlog.attendees.length + coachlog.shadowers.length <= maxCount
          ">
          <label class="label-text mt-1">Add Participant</label>
          <app-user-select
            [districtId]="districtId"
            [includeAllB2bDistricts]="includeAllB2bDistricts"
            [clearAfterSelection]="true"
            (selectedUser)="addCoachee($event)" />
        </ng-container>
      </ng-template>
      <div class="label-text mt-2">Shadowers:</div>
      <div class="d-flex flex-row flex-wrap">
        <div
          class="me-3"
          style="flex-basis: 45%"
          *ngFor="let shadower of coachlog.shadowers">
          <app-coaching-shadower
            avatarSize="small"
            [shadower]="shadower"
            [calendarDetails]="true"
            [isCoach]="true"
            (removedShadower)="removeShadower($event)" />
        </div>
      </div>
      <ng-container
        *ngIf="
          coachlog.attendees.length + coachlog.shadowers.length <= maxCount &&
          coachlog.shadowers.length < 8
        ">
        <label class="label-text mt-1">Add Shadower</label>
        <app-user-select
          [clearAfterSelection]="true"
          [includeE2l]="true"
          [includeDistrictAdmins]="true"
          (selectedUser)="addShadower($event)" />
      </ng-container>
      <div footerContent class="mt-3 d-flex justify-content-between">
        <ng-container>
          <button
            (click)="deleteSessionModal.open()"
            aria-label="delete session button"
            class="btn btn-txt-delete me-2 mb-2"
            type="button">
            Delete
          </button>
          <app-modal
            #deleteSessionModal
            [modalConfig]="{
              titleText: 'Are you sure?',
              headerClass: ['modal-header', 'bg-primary', 'text-white'],
              customFooter: true,
            }">
            <button
              class="btn btn-closemodal"
              title="Close"
              aria-label="close"
              headerContent
              (click)="deleteSessionModal.close()">
              <i class="bi bi-x-lg text-white"></i>
            </button>
            <div class="container">
              <div class="row">
                <div
                  class="col-12 d-flex justify-content-center align-items-center">
                  <div class="my-3 fs-14">
                    <div class="fs-18 mb-2">
                      Do you want to permanently delete this session?
                    </div>
                    <ng-container
                      *ngIf="!(coachlog.type.view === 'general-log')">
                      By confirming this action, any growth indicators
                      <ng-container *ngIf="!coachlog.type.isClassroomVisit">
                        and earned badges
                      </ng-container>
                      from this session will be removed.
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-between" footerContent>
              <button
                (click)="deleteSessionModal.close()"
                class="btn btn-reset">
                Cancel
              </button>
              <button
                (click)="deleteSession(); deleteSessionModal.close()"
                class="btn btn-primary">
                Yes, delete session
              </button>
            </div>
          </app-modal>
        </ng-container>
        <div class="d-flex">
          <button
            (click)="duplicateSession(coachlog)"
            class="btn btn-secondary me-2">
            Duplicate Session
          </button>
          <a
            [routerLink]="getSessionLink(coachlog)"
            type="button"
            class="btn btn-md btn-primary pt-2"
            href="javascript:void(0);"
            target="_blank">
            Open Session
          </a>
        </div>
      </div>
    </ng-container>

    <ng-template #viewOnly>
      <ng-container *ngIf="!isSmart; else smartView">
        <div class="field-holder">
          <span class="label-text">Session Type: &nbsp;</span
          >{{ coachlog.type.title }}
        </div>
        <div class="field-holder">
          <span class="label-text">Title: &nbsp;</span>{{ coachlog.title }}
        </div>
        <div class="field-holder">
          <span class="label-text">Date & Time: &nbsp;</span
          >{{
            coachlog.startDatetime
              ? (coachlog.startDatetime | showDateTimezone)
              : ""
          }}
        </div>
        <div class="field-holder">
          <span class="label-text">School: &nbsp;</span>
          <ng-container
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.school">
                  <app-school-name [schoolId]="attendee.user.school.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="field-holder">
          <span class="label-text">District: &nbsp;</span>
          <ng-container
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.district">
                  <app-district-name [districtId]="attendee.user.district.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div style="clear: both; height: 8px"></div>
        <div class="title-text">Attendees</div>
        <hr class="hr-line" />
        <div class="field-holder">
          <div class="label-text">Owner:</div>
        </div>
        <div class="d-flex flex-row mb-3">
          <ng-container *ngIf="coachlog.user">
            <app-avatar
              [profile]="coachlog.user.profile"
              [avatarSize]="'small'">
            </app-avatar>

            <div class="username ms-2">
              {{ coachlog.user.profile.first_name }}
              {{ coachlog.user.profile.last_name }}
            </div>
          </ng-container>
        </div>
        <div class="field-holder">
          <div class="label-text">Participants:</div>
        </div>

        <div class="d-flex flex-row flex-wrap">
          <div
            class="me-3"
            style="flex-basis: 45%"
            *ngFor="let attendee of coachlog.attendees">
            <div class="d-flex flex-row mb-3">
              <app-avatar
                [profile]="attendee.user.profile"
                [avatarSize]="'small'">
              </app-avatar>

              <div class="username ms-2">
                {{ attendee.user.profile.first_name }}
                {{ attendee.user.profile.last_name }}
              </div>
            </div>
          </div>
        </div>

        <div class="field-holder">
          <div class="label-text">Shadowers:</div>
        </div>

        <div class="d-flex flex-row flex-wrap">
          <div
            class="me-3"
            style="flex-basis: 45%"
            *ngFor="let shadower of coachlog.shadowers">
            <div class="d-flex flex-row mb-3">
              <app-avatar
                [profile]="shadower.user.profile"
                [avatarSize]="'small'">
              </app-avatar>
              <div class="username ms-2">
                {{ shadower.user.profile.first_name }}
                {{ shadower.user.profile.last_name }}
              </div>
            </div>
          </div>
        </div>
        <div footerContent [style.text-align]="'right'">
          <a
            [routerLink]="getSessionLink(coachlog)"
            type="button"
            class="btn btn-md btn-primary"
            href="javascript:void(0);"
            target="_blank">
            Open Session
          </a>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #smartView>
      <div class="field-holder">
        <span class="label-text">Session Type: &nbsp;</span>
        <div>
          {{ coachlog.type.title }}
        </div>
      </div>
      <div class="field-holder">
        <span class="label-text">Title: &nbsp;</span>
        <input
          type="text"
          class="form-control"
          id="coachlog-title"
          aria-label="Session Title"
          placeholder="Session Title"
          appAutosave
          [(ngModel)]="coachlog.title"
          (autosave)="updateSmartTitle()" />
      </div>
      <div class="field-holder">
        <label class="label-text" for="session-datetime">Date & Time</label>
        <app-datepicker
          id="session-datetime"
          (validTime)="isTimeValid = $event"
          (dateSelectedUnixTimestamp)="updateSmartLogDate($event)"
          [initialDateUnixTimestamp]="coachlog.startDatetime"
          [timezone]="coachlog.timezone"
          [pickTimezones]="false"
          [timepicker]="false" />
      </div>
      <div class="field-holder">
        <span class="label-text">School: &nbsp;</span>
        <div>
          <ng-container
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.school">
                  <app-school-name [schoolId]="attendee.user.school.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="field-holder">
        <span class="label-text">District: &nbsp;</span>
        <div>
          <ng-container
            *ngFor="
              let attendee of coachlog.attendees;
              let last = last;
              let first = first
            ">
            <ng-container *ngIf="first">
              <ng-container *ngIf="attendee.user">
                <ng-container *ngIf="attendee.user.district">
                  <app-district-name [districtId]="attendee.user.district.id" />
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div style="clear: both; height: 8px"></div>
      <div class="title-text">Attendees</div>
      <hr class="hr-line" />
      <div class="field-holder">
        <div class="label-text">Owner:</div>
      </div>
      <div class="d-flex flex-row mb-3">
        <ng-container *ngIf="coachlog.user">
          <app-avatar [profile]="coachlog.user.profile" [avatarSize]="'small'">
          </app-avatar>

          <div class="username ms-2">
            {{ coachlog.user.profile.first_name }}
            {{ coachlog.user.profile.last_name }}
          </div>
        </ng-container>
      </div>
      <div class="field-holder">
        <div class="label-text">Coachee:</div>
      </div>
      <div class="d-flex flex-row mb-3">
        <ng-container *ngFor="let attendee of coachlog.attendees">
          <app-avatar [profile]="attendee.user.profile" [avatarSize]="'small'">
          </app-avatar>

          <div class="username ms-2">
            {{ attendee.user.profile.first_name }}
            {{ attendee.user.profile.last_name }}
          </div>
        </ng-container>
      </div>
      <div footerContent [style.text-align]="'right'">
        <a
          [routerLink]="getSessionLink(coachlog)"
          type="button"
          class="btn btn-md btn-primary"
          href="javascript:void(0);"
          target="_blank">
          Open Session
        </a>
      </div>
    </ng-template>
  </ng-container>
</app-modal>
