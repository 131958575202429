import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import {
  dateTimeFromNgbDate,
  ngbDateStructFromDate,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  standalone: false,
})
export class DaterangePickerComponent implements OnInit, OnChanges {
  hoveredDate: NgbDate | null = null;

  @Input() label = '';

  labelId = uuidv4();

  displayDateFrom = '';

  displayDateTo = '';

  dateOpen = false;

  minStruct: NgbDateStruct;

  maxStruct: NgbDateStruct;

  @Input() disableTextInput = false;

  @Input() minDate: number | null = null;

  @Input() maxDate: number | null = null;

  @Input() fromDate: NgbDate | null = null;

  @Input() toDate: NgbDate | null = null;

  @Output() readonly selectedFromDate: EventEmitter<NgbDate | null> =
    new EventEmitter();

  @Output() readonly selectedToDate: EventEmitter<NgbDate | null> =
    new EventEmitter();

  ngOnInit() {
    this.updateMinMax();
    this.displayDates();
  }

  updateMinMax() {
    if (this.minDate) {
      this.minStruct = ngbDateStructFromUnixTimestamp(this.minDate);
    }
    if (this.maxDate) {
      this.maxStruct = ngbDateStructFromUnixTimestamp(this.maxDate);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      /* eslint-disable-next-line default-case */
      switch (propName) {
        case 'fromDate':
        case 'toDate':
          this.displayDates();
          break;
        case 'minDate':
        case 'maxDate':
          this.updateMinMax();
          break;
      }
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate) {
      if (date.after(this.fromDate)) {
        this.toDate = date;
      } else if (date <= this.fromDate) {
        this.toDate = this.fromDate;
      }
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate && this.fromDate) {
      this.dateOpen = false;
    }

    if (this.toDate) {
      this.selectedFromDate.emit(this.fromDate);
      this.selectedToDate.emit(this.toDate);

      this.displayDates();
    }
  }

  onDateFromChange(newDate: string) {
    const date = this.convertStringDate(newDate);
    if (!Number.isNaN(date.year)) {
      this.fromDate = date;
      if (this.toDate) {
        const from = dateTimeFromNgbDate(date).toSeconds();
        const to = dateTimeFromNgbDate(this.toDate).toSeconds();
        if (from > to) {
          this.toDate = this.fromDate;
        }
      }
      this.selectedToDate.emit(this.toDate);
      this.selectedFromDate.emit(this.fromDate);
    } else {
      this.fromDate = null;
    }
  }

  onDateToChange(newDate: string) {
    const date = this.convertStringDate(newDate);
    if (!Number.isNaN(date.year)) {
      this.toDate = date;
      if (this.fromDate) {
        const to = dateTimeFromNgbDate(date).toSeconds();
        const from = dateTimeFromNgbDate(this.fromDate).toSeconds();
        if (to < from) {
          this.fromDate = this.toDate;
        }
      }
      this.selectedFromDate.emit(this.fromDate);
      this.selectedToDate.emit(this.toDate);
    } else {
      this.toDate = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  convertStringDate(dateAsString: string) {
    return ngbDateStructFromDate(
      DateTime.fromFormat(dateAsString, 'M/d/yyyy').toJSDate()
    ) as NgbDate;
  }

  displayDates() {
    if (this.fromDate) {
      const date = this.showDate(this.fromDate);
      if (date !== 'Invalid DateTime') {
        this.displayDateFrom = date;
      }
    }
    if (this.toDate) {
      const date = this.showDate(this.toDate);
      if (date !== 'Invalid DateTime') {
        this.displayDateTo = date;
      }
    }
    if (!this.fromDate && !this.toDate) {
      this.clearDates();
    }
  }

  clearDates() {
    this.displayDateFrom = '';
    this.displayDateTo = '';
    this.fromDate = null;
    this.toDate = null;
    this.selectedFromDate.emit(null);
    this.selectedToDate.emit(null);
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  /* eslint-disable-next-line class-methods-use-this */
  showDate(dateVal: NgbDate) {
    if (dateVal) {
      const outputFormat = 'M/d/yyyy';
      return dateTimeFromNgbDate(dateVal).toFormat(outputFormat);
    }
    return '';
  }
}
