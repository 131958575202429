/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';

import { ProfileDTO } from '../dtos/profile.dto';

@Pipe({
  name: 'fullNameFromProfile',
  standalone: false,
})
export class FullNameProfilePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  // skipcq: JS-0105
  transform(profile?: ProfileDTO): string {
    return profile ? `${profile.first_name} ${profile.last_name}` : '';
  }
}
