import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  CreateProFormAnswerPayload,
  CreateProFormItemPayload,
  CreateSectionPayload,
  UpdateProFormAnswerPayload,
  UpdateProFormItemPayload,
  UpdateProFormPayload,
  UpdateSectionPayload,
} from '../../types/payloads/proforms.payloads';
import {
  CreateAnswerOptionAPIResponse,
  CreateUpdateSectionAPIResponse,
  ProFormsAPIResponse,
  UpdateAnswerOptionAPIResponse,
  UpdateProFormsQuestionAPIResponse,
} from '../../types/responses/proforms.responses';
import { AlertService, AlertType } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ProformsAdminService {
  private ProFormData: ProFormsAPIResponse | null = null;

  private ProFormSource = new BehaviorSubject(this.ProFormData);

  public currentProForm = this.ProFormSource.asObservable();

  private currentActiveQuestionData: number | null = null;

  private currentActiveQuestionSource = new BehaviorSubject(
    this.currentActiveQuestionData
  );

  public currentActiveQuestion =
    this.currentActiveQuestionSource.asObservable();

  constructor(
    private apiService: APICoreService,
    private alertService: AlertService
  ) {}

  // INCLUDES ALL EXTRA FIELDS
  getProForm(formId: number) {
    this.apiService
      .getRequest(
        `growelab/pro-forms/forms/${formId}?expand=district,districtsSharedWith.district,districtsExcludedFrom.district,submissions,sections.questions.answerOptions`
      )
      .subscribe({
        error: (error) => {
          this.ProFormSource.next(null);
          this.alertService.showAlert(`${error.error.message}`);
        },
        next: (response) => {
          if (response.item) {
            this.ProFormData = response.item;
            this.ProFormSource.next(this.ProFormData);
          } else {
            this.ProFormSource.next(null);
          }
        },
      });
  }

  // Replaces the current form entirely - extraFields must match getform
  updateProForm(formId: number, payload: UpdateProFormPayload) {
    this.apiService
      .putRequest(
        `growelab/pro-forms/forms/${formId}?expand=district,districtsSharedWith.district,districtsExcludedFrom.district,submissions,sections.questions.answerOptions`,
        payload
      )
      .subscribe({
        error: (error) => {
          this.alertService.showAlert(
            error.error.message,
            undefined,
            AlertType.DANGER
          );
        },
        next: (response) => {
          if (response.item) {
            this.ProFormData = response.item;
            if (this.ProFormData) {
              this.ProFormData.is_shared = Number(this.ProFormData?.is_shared);
            }
            this.ProFormSource.next(this.ProFormData);
            this.alertService.showAlert('Form Updated');
          }
        },
      });
  }

  deleteForm(formId: number): Observable<{ item: ProFormsAPIResponse }> {
    return this.apiService
      .deleteRequest(`growelab/pro-forms/forms/${formId}`)
      .pipe(
        tap({
          next: (response) => {
            if (response.item) {
              this.ProFormData = null;
              this.ProFormSource.next(this.ProFormData);
            }
          },
          error: (error) => {
            this.alertService.showAlert(
              error.error.message,
              undefined,
              AlertType.DANGER
            );
          },
        })
      );
  }

  // SHARING
  handleShareDistricts(incomingDistricts: DistrictSimpleDto[]) {
    const incomingDistrictIds = incomingDistricts.map(
      (district) => district.id
    );
    if (this.ProFormData?.districtsSharedWith) {
      // Handle districts to be deleted (those in form.districtsSharedWith but not in incomingDistricts)
      this.ProFormData.districtsSharedWith.forEach((share) => {
        if (
          this.ProFormData &&
          !incomingDistrictIds.includes(share.district_id)
        ) {
          this.deleteProFormShare(this.ProFormData.id, share.id).subscribe({
            error: (error) => {
              this.alertService.showAlert(
                error.error.message,
                undefined,
                AlertType.DANGER
              );
            },
            next: () => {
              if (this.ProFormData) {
                this.getProForm(this.ProFormData.id);
              }
            },
          });
        }
      });

      // Handle new districts to be added (those in incomingDistricts but not in session.sharing)
      incomingDistricts.forEach((district) => {
        // Check if the district is not already in this.form.districtsSharedWith
        if (
          this.ProFormData &&
          !this.ProFormData.districtsSharedWith?.some(
            (share) => share.district_id === district.id
          )
        ) {
          // If it's not found, we proceed to share it
          this.shareProForm(this.ProFormData.id, district.id).subscribe({
            error: (error) => {
              this.alertService.showAlert(
                error.error.message,
                undefined,
                AlertType.DANGER
              );
            },
            next: () => {
              if (this.ProFormData) {
                this.getProForm(this.ProFormData.id);
              }
            },
          });
        }
      });
    }
    return of(incomingDistrictIds);
  }

  handleExcludeDistricts(incomingDistricts: DistrictSimpleDto[]) {
    const incomingDistrictIds = incomingDistricts.map(
      (district) => district.id
    );
    if (this.ProFormData?.districtsExcludedFrom) {
      // Handle districts to be deleted (those in session.sharing but not in incomingDistricts)
      this.ProFormData.districtsExcludedFrom.forEach((share) => {
        if (
          !share.is_shared &&
          !incomingDistrictIds.includes(share.district_id)
        ) {
          if (this.ProFormData) {
            this.deleteProFormShare(this.ProFormData.id, share.id).subscribe({
              error: (error) => {
                this.alertService.showAlert(
                  error.error.message,
                  undefined,
                  AlertType.DANGER
                );
              },
              next: () => {
                if (this.ProFormData) {
                  this.getProForm(this.ProFormData.id);
                }
              },
            });
          }
        }
      });
      // Handle new districts to be added (those in incomingDistricts but not in session.sharing)
      incomingDistricts.forEach((district) => {
        if (
          this.ProFormData &&
          !this.ProFormData.districtsExcludedFrom?.some(
            (share) => share.district_id === district.id
          )
        ) {
          this.unshareProForm(this.ProFormData.id, district.id).subscribe({
            error: (error) => {
              this.alertService.showAlert(
                error.error.message,
                undefined,
                AlertType.DANGER
              );
            },
            next: () => {
              if (this.ProFormData) {
                this.getProForm(this.ProFormData.id);
              }
            },
          });
        }
      });
    }

    return of(incomingDistrictIds);
  }

  // REMOVE WHEN DONE

  shareProForm(formId: number, districtId: number) {
    return this.apiService
      .postRequest(`growelab/pro-forms/${formId}/share/${districtId}`)
      .pipe(catchError((err) => throwError(() => err)));
  }

  unshareProForm(formId: number, districtId: number) {
    return this.apiService
      .postRequest(`growelab/pro-forms/${formId}/unshare/${districtId}`)
      .pipe(catchError((err) => throwError(() => err)));
  }

  deleteProFormShare(formId: number, shareId: number) {
    return this.apiService
      .deleteRequest(`growelab/pro-forms/${formId}/share/${shareId}`)
      .pipe(catchError((err) => throwError(() => err)));
  }

  /// ////

  // SECTIONS

  createSection(
    formId: number,
    payload: CreateSectionPayload
  ): Observable<CreateUpdateSectionAPIResponse> {
    return this.apiService.postRequest(
      `growelab/pro-forms/form-sections/${formId}/questions`,
      payload
    );
  }

  updateSection(
    sectionId: number,
    payload: UpdateSectionPayload
  ): Observable<CreateUpdateSectionAPIResponse> {
    return this.apiService.putRequest(
      `growelab/pro-forms/form-sections/${sectionId}`,
      payload
    );
  }

  deleteSection(sectionId: number) {
    return this.apiService.deleteRequest(
      `growelab/pro-forms/form-sections/${sectionId}`
    );
  }

  // Items - currently only have Question items, more types to be added later

  createQuestion(sectionId: number, payload: CreateProFormItemPayload) {
    return this.apiService.postRequest(
      `growelab/pro-forms/section/${sectionId}/questions`,
      payload
    );
  }

  updateQuestion(
    questionId: number,
    payload: UpdateProFormItemPayload
  ): Observable<UpdateProFormsQuestionAPIResponse> {
    return this.apiService.putRequest(
      `growelab/pro-forms/form-questions/${questionId}/questions`,
      payload
    );
  }

  deleteQuestion(questionId: number) {
    return this.apiService.deleteRequest(
      `growelab/pro-forms/form-questions/${questionId}`
    );
  }

  setCurrentQuestion(questionId: number) {
    this.currentActiveQuestionSource.next(questionId);
  }

  // ANSWER OPTIONS

  createdAnswerOption(
    questionId: number,
    payload: CreateProFormAnswerPayload
  ): Observable<CreateAnswerOptionAPIResponse> {
    return this.apiService.postRequest(
      `growelab/pro-forms/question/${questionId}/answers`,
      payload
    );
  }

  updateAnswerOption(
    answerId: number,
    payload: UpdateProFormAnswerPayload
  ): Observable<UpdateAnswerOptionAPIResponse> {
    return this.apiService.putRequest(
      `growelab/pro-forms/form-question-answer-options/${answerId}`,
      payload
    );
  }

  deleteAnswerOption(answerId: number) {
    return this.apiService.deleteRequest(
      `growelab/pro-forms/form-question-answer-options/${answerId}`
    );
  }
}
