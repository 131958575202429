import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { PdListFilterDTO, PdSettings } from '../../../dtos/pd.dto';
import { CompetencySelectComponent } from '../../competency/competency-select/competency-select.component';
import { UserSelectComponent } from '../../user-select/user-select.component';
import { ModalComponent } from '../modal/modal.component';

export interface PdCategoryOptionFilter {
  category: string;
  option: string;
}

@Component({
  selector: 'app-pd-filter-modal',
  templateUrl: './pd-filter-modal.component.html',
  styleUrl: './pd-filter-modal.component.scss',
  standalone: false,
})
export class PdFilterModalComponent implements OnInit {
  @ViewChild('pdFilterModal') pdFilterModal: ModalComponent;

  @ViewChild('typeSelect') typeSelect: NgSelectComponent;

  @ViewChild('facilitatorSelect') facilitatorSelect: UserSelectComponent;

  @ViewChild('categorySelect') categorySelect: NgSelectComponent;

  @ViewChild('categoryOptionSelect') categoryOptionSelect: NgSelectComponent;

  @ViewChild('competencySelect') competencySelect: CompetencySelectComponent;

  @Input() initialFilters: PdListFilterDTO;

  @Input() user: User;

  @Input() settings: PdSettings;

  @Input() context: 'normal' | 'copy' = 'normal';

  @Input() isE2L: boolean;

  @Input() currentCategoryOptions: PdCategoryOptionFilter[] = [];

  @Output() readonly setFilters: EventEmitter<PdListFilterDTO> =
    new EventEmitter<PdListFilterDTO>();

  @Output() readonly setCategoryOptions: EventEmitter<
    PdCategoryOptionFilter[]
  > = new EventEmitter<PdCategoryOptionFilter[]>();

  currentFilters: PdListFilterDTO;

  categoryOptions: PdCategoryOptionFilter[] = [];

  ngOnInit(): void {
    if (this.initialFilters) {
      this.setInitialFilters();
    }
  }

  setInitialFilters() {
    if (this.settings && !this.categoryOptions.length) {
      this.settings.categories.forEach((cat) => {
        const options = cat.options.map((option) => ({
          category: cat.title,
          option: option.option_value,
        }));
        this.categoryOptions = this.categoryOptions.concat(options);
      });
    }
    this.currentFilters = {} as PdListFilterDTO;
    this.currentFilters = deepCopy(this.initialFilters);
    if (this.facilitatorSelect) {
      this.facilitatorSelect.internalSelectedUserList =
        this.currentFilters.facilitators || [];
    }
    if (this.competencySelect) {
      this.competencySelect.internalSelectedCompetencyList =
        this.currentFilters.competencyList;
    }
  }

  handleTypeSelect(type: string) {
    if (type && !this.currentFilters.types.includes(type)) {
      this.currentFilters.types.push(type);
    }
    this.typeSelect.handleClearClick();
  }

  handleCategorySelect(category: PdCategoryOptionFilter) {
    if (category) {
      if (!this.currentCategoryOptions.includes(category)) {
        if (!this.currentFilters.categories.includes(category.category)) {
          this.currentFilters.categories.push(category.category);
        }
        if (!this.currentFilters.category_options?.includes(category.option)) {
          this.currentFilters.category_options?.push(category.option);
        }
        this.currentCategoryOptions.push(category);
      }
      this.categorySelect.handleClearClick();
    }
  }

  removeCategory(categoryOption: PdCategoryOptionFilter) {
    this.currentCategoryOptions = this.currentCategoryOptions.filter(
      (item) => item !== categoryOption
    );
    if (this.currentFilters.categories.includes(categoryOption.category)) {
      this.currentFilters.categories = this.currentFilters.categories.filter(
        (item) => item !== categoryOption.category
      );
    }
    if (this.currentFilters.category_options?.includes(categoryOption.option)) {
      this.currentFilters.category_options =
        this.currentFilters.category_options?.filter(
          (item) => item !== categoryOption.option
        );
    }
  }

  clearFilters() {
    this.facilitatorSelect.reset();
    this.competencySelect.reset();
  }

  closeModal(saveFilters = false) {
    if (saveFilters) {
      this.setFilters.emit(this.currentFilters);
      this.setCategoryOptions.emit(this.currentCategoryOptions);
    }
    this.clearFilters();
    this.pdFilterModal.close();
  }

  removeType(type: string) {
    this.currentFilters.types = this.currentFilters.types.filter(
      (item) => item !== type
    );
  }
}
