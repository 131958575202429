import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/common/state/user/user.model';
import { CelebrationDTO } from '../../../dtos/celebrations.dto';

@Component({
  selector: 'app-card-celebrations-feed',
  templateUrl: './card-celebrations-feed.component.html',
  styleUrls: ['./card-celebrations-feed.component.scss'],
  standalone: false,
})
export class CardCelebrationsFeedComponent {
  @Input() user: User;

  @Input() celebrations: CelebrationDTO[] | null;

  @Output() readonly openCelebrationModalEvent = new EventEmitter();

  handleOpenCelebrationModal() {
    this.openCelebrationModalEvent.emit();
  }
}
