<div class="outer-wrapper">
  <div class="form-item-wrapper" [class.static]="static">
    <button class="btn-reset item item-question" (click)="createQuestion()">
      <div class="item-icon">Q</div>
      <div class="item-label">Question</div>
    </button>
    <button class="btn-reset item item-content">
      <div class="item-icon">
        <img
          class="svg-icon"
          src="/assets/proforms/form-item-content.svg"
          alt="Content Icon" />
      </div>
      <div class="item-label">Content</div>
    </button>
    <button class="btn-reset item item-image">
      <div class="item-icon"><i class="bi bi-image"></i></div>
      <div class="item-label">Image</div>
    </button>
    <button class="btn-reset item item-section">
      <div class="item-icon">
        <img
          class="svg-icon"
          src="/assets/proforms/form-item-section.svg"
          alt="Section Icon" />
      </div>
      <div class="item-label">Section Break</div>
    </button>
  </div>
</div>
