import { Directive, EventEmitter, Input, Output } from '@angular/core';

/* eslint-disable @angular-eslint/directive-class-suffix, @angular-eslint/no-host-metadata-property */

export type SortColumn = string;
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
  standalone: false,
})
export class SortableHeader {
  @Input() sortable: SortColumn = '';

  @Input() direction: SortDirection = '';

  @Output() readonly sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
