import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { ResourceFolderModelDTO } from '../../../dtos/resources.dto';
import { ResourcesBookmarkService } from '../../../services/resources/resources-bookmark.service';

@Component({
  selector: 'app-my-resources-list',
  templateUrl: './my-resources-list.component.html',
  styleUrls: ['./my-resources-list.component.scss'],
  standalone: false,
})
export class MyResourcesListComponent implements OnInit {
  myFolders: ResourceFolderModelDTO[];

  myStarredFolders: ResourceFolderModelDTO[] | null = null;

  newFolder = {} as ResourceFolderModelDTO;

  createdByYouFolder: ResourceFolderModelDTO;

  canCreate = true;

  user: User;

  copiedStatus: boolean;

  constructor(
    private resourceService: ResourcesBookmarkService,
    private clipboard: Clipboard,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.newFolder.title = '';
    this.getCreatedByYouFolder();
    this.getMyFolders();
  }

  getCreatedByYouFolder() {
    this.resourceService.getCreatedByYou().subscribe((result) => {
      if (result.item) {
        this.createdByYouFolder = result.item;
      }
    });
  }

  getMyFolders() {
    this.resourceService.getMyFolders().subscribe((response) => {
      if (response.items) {
        this.myStarredFolders = response.items.filter(
          (shelf: ResourceFolderModelDTO) => shelf.usersFavorite === 1
        );
        this.myFolders = response.items.filter(
          (shelf: ResourceFolderModelDTO) => shelf.usersFavorite === 0
        );
      } else {
        this.myFolders = [];
      }
    });
  }

  createFolder() {
    this.resourceService.createFolder(this.newFolder).subscribe((response) => {
      if (response.item) {
        this.myFolders.push(response.item);
        this.clearFields();
      }
    });
  }

  clearFields() {
    this.newFolder.title = '';
    this.newFolder.description = '';
  }

  starSelect(id: number, favorite: number, folder: ResourceFolderModelDTO[]) {
    if (favorite) {
      const starSelected = folder.find(
        (item: ResourceFolderModelDTO) => item.id === id
      );
      if (starSelected) starSelected.isLoading = true;
      this.resourceService.deleteStarFolder(id).subscribe((response) => {
        if (response) {
          this.updateLocalFolder(folder, id, 0);
        }
      });
    } else {
      const selected = folder.find(
        (item: ResourceFolderModelDTO) => item.id === id
      );
      if (selected) selected.isLoading = true;
      this.resourceService.postStarFolder(id).subscribe((response) => {
        if (response) {
          this.updateLocalFolder(folder, id, 1);
        }
      });
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  updateLocalFolder(
    folders: ResourceFolderModelDTO[],
    id: number,
    value: number
  ) {
    folders.forEach((folder) => {
      if (folder.id === id) {
        folder.usersFavorite = value;
        folder.isLoading = false;
      }
    });
  }

  copyPageToClipboard() {
    this.clipboard.copy(
      `${window.location.origin}/resources/shared/${this.user.id}`
    );

    this.copiedStatus = true;
    setTimeout(() => {
      this.copiedStatus = false;
    }, 3000);
  }

  copyToClipboard(folderId: number | null) {
    if (folderId) {
      this.clipboard.copy(
        `${window.location.origin}/resources/shared/${this.user.id}/folder/${folderId}`
      );
    } else {
      this.clipboard.copy(window.location.href);
    }
    this.copiedStatus = true;
    setTimeout(() => {
      this.copiedStatus = false;
    }, 3000);
  }
}
