import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { ImplementationViewReportModalComponent } from '../../shared/components/implementation-view-report-modal/implementation-view-report-modal.component';
import { CreateReportModalComponent } from '../../shared/components/plans/create-report-modal/create-report-modal.component';
import { AlertService } from '../../shared/services/alert/alert.service';
import { PlansService } from '../../shared/services/plans/plans.service';
import { FetchPlanDetails } from '../../shared/state/implementation-plan/implementation-plan.actions';
import {
  ImplementationPlanState,
  PlanDetailsItemStateModel,
} from '../../shared/state/implementation-plan/implementation-plan.state';
import { PlanStatusReportItemAPIResponse } from '../../shared/types/responses/plan.responses';
import { checkIfPlanEditor } from '../plans-page/plans-page-helpers';

@Component({
  selector: 'app-implementation-status-reports-page',
  templateUrl: './implementation-status-reports-page.component.html',
  styleUrl: './implementation-status-reports-page.component.scss',
  standalone: false,
})
export class ImplementationStatusReportsPageComponent
  implements OnInit, OnDestroy
{
  @ViewChild('viewReportModal')
  viewReportModal: ImplementationViewReportModalComponent;

  @ViewChild('createReportModal')
  createReportModal: CreateReportModalComponent;

  user: User;

  planId: number;

  subs: Subscription[] = [];

  planDetails$: Observable<PlanDetailsItemStateModel> = select(
    ImplementationPlanState.getPlanDetails
  );

  planDetails: PlanDetailsItemStateModel;

  isPlanEditor = false;

  tableData: PlanStatusReportItemAPIResponse[];

  reportsMeta: PaginationDTO;

  statusReportsLoading = false;

  canDelete = false;

  reportContent: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private plansService: PlansService,
    private alertService: AlertService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    if (this.user) {
      this.isPlanEditor = checkIfPlanEditor(this.user);
      this.canDelete =
        this.user.roles.includes(UserRole.OPERATOR) ||
        this.user.roles.includes(UserRole.REMOTE_OPERATOR);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['planId']) {
        this.planId = parseInt(params['planId']);
        this.store.dispatch(new FetchPlanDetails(this.planId));
        this.planDetails$.subscribe((details) => {
          if (details) {
            this.planDetails = deepCopy(details);
            this.planDetails.start_date = this.formatDate(
              this.planDetails.start_date
            );
            this.planDetails.end_date = this.formatDate(
              this.planDetails.end_date
            );
          }
        });

        this.getPlanStatusReports(this.planId);
      }
    });
  }

  getPlanStatusReports(planId: number, page?: number) {
    this.statusReportsLoading = true;
    this.subs.push(
      this.plansService
        .getPlanStatusReports(planId, page)
        .subscribe((response) => {
          this.tableData = response.items;
          this.reportsMeta = response._meta;
          this.statusReportsLoading = false;
        })
    );
  }

  deleteReport(reportId: number) {
    this.subs.push(
      this.plansService.deletePlanStatusReport(reportId).subscribe(() => {
        this.alertService.showAlert('Status Report Deleted');
        this.getPlanStatusReports(this.planId);
      })
    );
  }

  openCreateReportModal() {
    this.createReportModal.modal.open();
  }

  openViewReportModal(reportContent: string) {
    this.reportContent = reportContent;
    this.viewReportModal.modal.open();
  }

  paginationChanges(page: number) {
    this.getPlanStatusReports(this.planId, page);
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatDate(date: string) {
    // date comes in YYYY-MM-DD format
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  }

  updateReports() {
    this.getPlanStatusReports(this.planId);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
