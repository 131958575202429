import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getResourceIcon } from '../../../enums/resource-type.enum';
import {
  checkIfDistrictApproved,
  checkIfE2lRecommended,
} from '../../../services/resources/resource.helpers';
import { ResourcePreviewDTO } from './resource-preview.dto';

@Component({
  selector: 'app-resource-preview',
  templateUrl: './resource-preview.component.html',
  styleUrls: ['./resource-preview.component.scss'],
  standalone: false,
})
export class ResourcePreviewComponent implements OnInit {
  @Input() resource: ResourcePreviewDTO;

  @Input() limitDescriptionLength = true;

  @Input() showRemovalIcon = false;

  @Input() userDistrictId: number | null;

  @Output() readonly signalRemoval: EventEmitter<number> = new EventEmitter();

  description: string;

  iconString: string;

  ngOnInit() {
    this.iconString = getResourceIcon(this.resource.type);
    this.description = this.limitDescriptionLength
      ? `${this.resource.description.substring(0, 70)} ...`
      : this.resource.description;
    if (this.resource.endorsements && this.userDistrictId) {
      this.resource.e2lRecommended = checkIfE2lRecommended(
        this.resource.endorsements
      );
      this.resource.districtApproved = checkIfDistrictApproved(
        this.resource.endorsements,
        this.userDistrictId
      );
    }
  }

  removeItem() {
    this.signalRemoval.emit(this.resource.id);
  }
}
