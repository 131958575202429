import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CelebrationDTO } from 'src/app/private/shared/dtos/celebrations.dto';
import { CoachDialogService } from 'src/app/private/shared/services/smart-coach/coach-dialog/coach-dialog.service';
import { IntroSessionService } from 'src/app/private/shared/services/smart-coach/intro-session.service';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';

@Component({
  selector: 'app-smart-coach-intro-page',
  templateUrl: './smart-coach-intro-page.component.html',
  styleUrls: ['./smart-coach-intro-page.component.scss'],
  standalone: false,
})
export class SmartCoachIntroPageComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  user: User;

  activeTab: number;

  overviewComplete = false;

  whyComplete = false;

  egroweComplete = false;

  bpComplete = false;

  coachComplete = false;

  planComplete = false;

  celebrations: CelebrationDTO[] | null = null;

  @ViewChild('avatar', { static: false }) avatar: ElementRef;

  @ViewChild('track') track: ElementRef;

  constructor(
    private store: Store,
    private router: Router,
    private featureFlagService: UnleashService,
    public introSessionService: IntroSessionService,
    private smartcoachDialog: CoachDialogService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    setTimeout(() => {
      this.selectTab(0);
    }, 100);

    if (this.user) {
      if (!this.featureFlagService.isEnabled(FEATURE_FLAGS.mySmartCoach)) {
        this.router.navigate(['/dashboard']);
      }
      this.introSessionService.getIntroSessionStatus(this.user.id);
    }

    // session status
    this.subs.push(
      this.introSessionService.overviewComplete.subscribe((bool) => {
        this.overviewComplete = bool;
      })
    );
    this.subs.push(
      this.introSessionService.whyComplete.subscribe((bool) => {
        this.whyComplete = bool;
      })
    );
    this.subs.push(
      this.introSessionService.egroweComplete.subscribe((bool) => {
        this.egroweComplete = bool;
      })
    );
    this.subs.push(
      this.introSessionService.bpComplete.subscribe((bool) => {
        this.bpComplete = bool;
      })
    );
    this.subs.push(
      this.introSessionService.coachComplete.subscribe((bool) => {
        this.coachComplete = bool;
      })
    );
    this.subs.push(
      this.introSessionService.planComplete.subscribe((bool) => {
        this.planComplete = bool;
      })
    );
    this.smartcoachDialog.clearMessages();
  }

  selectTab(tabNumber: number) {
    this.activeTab = tabNumber;
    this.track.nativeElement.setAttribute('data-currentTab', this.activeTab);
  }

  finishSession() {
    this.router.navigate(['/coaching']);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
