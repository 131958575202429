import { Component, Input } from '@angular/core';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { ChildBreadcrumbModelDTO } from '../../../dtos/resources.dto';

@Component({
  selector: 'app-resources-breadcrumb',
  templateUrl: './resources-breadcrumb.component.html',
  styleUrl: './resources-breadcrumb.component.scss',
  standalone: false,
})
export class ResourcesBreadcrumbComponent {
  @Input() breadcrumbs: ChildBreadcrumbModelDTO[];

  @Input() folderOwner: UserLiteDTO;

  @Input() sharedFolder = false;
}
