import { Injectable } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';

@Injectable({
  providedIn: 'root',
})
export class SmartSuggestionService {
  constructor(private apiService: APICoreService) {}

  getSmartSuggestions() {
    return this.apiService.getRequest('users/smart-suggestions');
  }

  dismissSuggestion(suggestionId: number) {
    return this.apiService.postRequest(
      `smart-suggestion/${suggestionId}/dismiss`
    );
  }
}
