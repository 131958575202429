import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { v4 as uuidv4 } from 'uuid';

import { CoachingSessionTypeDTO } from '../../dtos/coaching-session.dto';
import { getIdsFromDefaultItemsForSelect } from '../../helpers/select.utilities';
import { LogTypeSearchService } from '../../services/log-type-search/log-type-search.service';
import { FEATURE_FLAGS } from '../../services/unleash/unleash.helpers';
import { UnleashService } from '../../services/unleash/unleash.service';

@Component({
  selector: 'app-log-type-select',
  templateUrl: './log-type-select.component.html',
  styleUrls: ['./log-type-select.component.scss'],
  standalone: false,
})
export class LogTypeSelectComponent implements OnInit, OnChanges {
  logTypeList: CoachingSessionTypeDTO[];

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedLogType: CoachingSessionTypeDTO | null = null;

  internalSelectedLogTypeList: CoachingSessionTypeDTO[] = [];

  internalDistrictIds: number[] = [];

  user: User;

  @ViewChild('select') select: NgSelectComponent;

  @Input() simpleSelect = false;

  @Input() fieldInvalid = false;

  @Input() clearAfterSelection = false;

  @Input() forLogCreation = false;

  @Input() label = 'Session Type';

  @Input() placeholder = 'Select a session type';

  @Input() isMulti = false;

  @Input() districtId: number | number[] | DistrictDTO[] | undefined;

  @Input() defaultLogTypes: number | number[] | CoachingSessionTypeDTO[] | null;

  @Input() omitMSC = false;

  @Output() readonly selectedLogTypeList: EventEmitter<
    CoachingSessionTypeDTO[]
  > = new EventEmitter();

  @Output()
  readonly selectedLogType: EventEmitter<CoachingSessionTypeDTO | null> =
    new EventEmitter();

  invalid = false;

  constructor(
    private store: Store,
    private logTypeSearch: LogTypeSearchService,
    private featureFlagService: UnleashService
  ) {}

  ngOnInit(): void {
    this.user = this.store.selectSnapshot((state) => state.user.user);
    this.setDistrictIds();
    if (this.internalDistrictIds && this.internalDistrictIds.length > 0) {
      this.loadLogTypes();
    }
  }

  setDefaultLogTypes(): void {
    this.reset();
    if (this.defaultLogTypes) {
      let logTypeIds: number[];
      if (Array.isArray(this.defaultLogTypes)) {
        logTypeIds = getIdsFromDefaultItemsForSelect(this.defaultLogTypes);
      } else {
        logTypeIds = [this.defaultLogTypes];
      }
      logTypeIds.forEach((logTypeId) => {
        const chosenType = this.logTypeList.find(
          (logType) => logType.id === logTypeId
        );
        if (chosenType) {
          if (this.isMulti) {
            this.internalSelectedLogTypeList.push(chosenType);
          } else {
            this.internalSelectedLogType = chosenType;
          }
        }
      });
    }
  }

  setDistrictIds(): void {
    if (this.districtId) {
      if (Array.isArray(this.districtId)) {
        this.internalDistrictIds = getIdsFromDefaultItemsForSelect(
          this.districtId
        );
      } else {
        this.internalDistrictIds = [this.districtId];
      }
    } else {
      this.internalDistrictIds = [this.user?.district?.id as number];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'districtId':
          this.setDistrictIds();
          this.loadLogTypes();
          break;
        case 'fieldInvalid':
          this.checkIfFieldInvalid();
          break;
        default:
          break;
      }
    });
  }

  checkIfFieldInvalid() {
    this.invalid = this.fieldInvalid;
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedLogTypeList = [];
    } else {
      this.internalSelectedLogType = null;
    }
    if (this.select) {
      this.select.selectedItems.forEach((item) => {
        this.select.unselect(item);
      });
    }
  }

  loadLogTypes() {
    this.logTypeSearch
      // This will need to be refactored at some point to allow for multiple districts
      .getLogTypes(this.internalDistrictIds[0] || 0)
      .pipe(
        map((logTypes) => {
          if (logTypes) {
            this.logTypeList = [];
            logTypes.forEach((logType) => {
              let includeLogType = false;
              if (this.forLogCreation) {
                if (this.user?.isCoach && !logType.isSmart) {
                  includeLogType = true;
                }
                if (
                  this.featureFlagService.isEnabled(
                    FEATURE_FLAGS.mySmartCoach
                  ) &&
                  logType.isSmart
                ) {
                  includeLogType = true;
                }
              } else {
                includeLogType = true;
              }
              if (includeLogType) {
                this.logTypeList.push(logType);
              }
            });
            this.setDefaultLogTypes();
          }
          if (this.omitMSC) {
            this.logTypeList = this.logTypeList.filter((item) => !item.isSmart);
          }
          this.searchLoading = false;
        })
      )
      .subscribe();
  }

  logTypeSelected(logType: CoachingSessionTypeDTO) {
    if (this.isMulti && logType) {
      if (!this.internalSelectedLogTypeList.includes(logType)) {
        this.internalSelectedLogTypeList.push(logType);
      }
      this.select.handleClearClick();
    } else {
      this.internalSelectedLogType = logType;
    }
    this.outputLogTypes();
    if (this.clearAfterSelection) {
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputLogTypes() {
    if (this.isMulti) {
      this.selectedLogTypeList.emit(this.internalSelectedLogTypeList);
    } else {
      this.selectedLogType.emit(this.internalSelectedLogType);
    }
  }

  removeLogTypeFromList(logType: CoachingSessionTypeDTO) {
    this.internalSelectedLogTypeList = this.internalSelectedLogTypeList.filter(
      (l) => l.id !== logType.id
    );
    this.logTypeList.push(logType);
    this.logTypeList.sort();
    this.outputLogTypes();
  }
}
