import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription } from 'rxjs';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  checkIfCoach,
  checkIfCreator,
  checkIfShadower,
  checkPresentAttendee,
} from 'src/app/common/utilities/session-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { DatepickerOutput } from 'src/app/private/shared/components/datepicker/datepicker.component';
import { getValidTimepickerValue } from 'src/app/private/shared/components/datepicker/datepicker.helpers';
import { SessionTypeModalComponent } from 'src/app/private/shared/components/session-type-modal/session-type-modal.component';
import { CelebrationDTO } from 'src/app/private/shared/dtos/celebrations.dto';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
} from 'src/app/private/shared/dtos/coaching-session.dto';
import { PusherAttendeeDTO } from 'src/app/private/shared/dtos/pusher-user.dto';
import { AlertService } from 'src/app/private/shared/services/alert/alert.service';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { FetchSessionData } from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import {
  AbsentReasonsAPIResponse,
  CoachlogTypesAPIResponse,
} from 'src/app/private/shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-general-log-info-page',
  templateUrl: './general-log-info-page.component.html',
  styleUrls: ['./general-log-info-page.component.scss'],
  standalone: false,
})
export class GeneralLogInfoPageComponent implements OnInit, OnDestroy {
  @ViewChild('sessionTypeModal')
  sessionTypeModal: SessionTypeModalComponent;

  subs: Subscription[] = [];

  sessionId: number;

  sessionData$ = select(CoachingLogState.getSessionData);

  coachLogStatus$: Observable<APILoadingStatus> = select(
    CoachingLogState.getCoachLogStatus
  );

  coachLogStatus: APILoadingStatus;

  sessionLoaded = false;

  user$: Observable<User | null> = select(UserState.getUser);

  user: User;

  sessionData: CoachingSessionDTO | null;

  coachlogTypesList: CoachlogTypesAPIResponse[];

  absentReasonsList: AbsentReasonsAPIResponse[];

  isCoach: boolean;

  isCreator: boolean;

  isShadower: boolean;

  logStart: string;

  logEnd: string;

  isTimeValid = true;

  timezone: TimezoneLinkBack;

  hasPresentAttendee = false;

  celebrations: CelebrationDTO[] | null = null;

  districtId: number | null = null;

  includeAllB2bDistricts = false;

  pusherAttendees: PusherAttendeeDTO[];

  sessionCreator: UserDTO | null;

  resourcesActive = false;

  resourcesTabActive = true;

  constructor(
    private route: ActivatedRoute,
    public coachingService: CoachingLogService,
    private store: Store,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.sessionId = parseInt(url['logId']);
      this.coachingService.currentPage = 'general-log-info';
      this.store.dispatch(new FetchSessionData(this.sessionId));
    });

    // if user is not an e2L employee, filter user select options to only users in their district
    if (this.user) {
      if (
        !this.user.roles.includes(UserRole.E2L_EMPLOYEE) &&
        this.user.district
      ) {
        this.districtId = this.user.district.id;
        if (this.user.district.isB2b === 1) {
          this.includeAllB2bDistricts = true;
        }
      }
    }

    this.sessionData$
      .pipe(
        map((filterFn) =>
          (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
        )
      )
      .pipe(
        map((sessionData) => {
          if (sessionData) {
            this.sessionData = sessionData;

            this.userService
              .fetchUserById(this.sessionData.creatorUserId)
              .subscribe((user) => {
                if (user) {
                  this.sessionCreator = user;
                }
              });

            this.isCoach = checkIfCoach(this.sessionData);
            this.isCreator = checkIfCreator(this.sessionData, this.user.id);
            this.isShadower = checkIfShadower(this.sessionData);
            this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
            this.logStart = getValidTimepickerValue(
              this.sessionData.startDatetime,
              this.sessionData.timezone
            );
            this.logEnd = getValidTimepickerValue(
              this.sessionData.endDatetime,
              this.sessionData.timezone
            );
            this.timezone = this.sessionData.timezone as TimezoneLinkBack;
          }
        })
      )
      .subscribe();

    this.coachLogStatus$.subscribe((status) => {
      if (status.loaded) {
        this.coachLogStatus = status;
        this.sessionLoaded = true;
      }
    });

    this.subs.push(
      this.coachingService.getAbsentReasons().subscribe((response) => {
        this.absentReasonsList = response.items;
      })
    );

    this.subs.push(
      this.coachingService.attendancePusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );

    this.subs.push(
      this.coachingService.logPusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );
  }

  getAttendees() {
    const attendeesOnPage =
      this.coachingService.attendancePusherService.attendeeRosters[
        `${this.sessionId}-general-log-info`
      ];

    if (attendeesOnPage && attendeesOnPage.length > 0) {
      this.pusherAttendees =
        this.coachingService.logPusherService.attendeeRosters[
          this.sessionId
        ].map((sessionAttendee) => {
          const samePage = attendeesOnPage.some(
            (pageAttendee) => pageAttendee.id === sessionAttendee.id
          );
          return {
            avatar: sessionAttendee.avatar,
            samePage,
            id: sessionAttendee.id,
          };
        });
    }
  }

  onCheckboxChange($event: Event): void {
    const target = $event.target as HTMLInputElement | null;
    const coachlogUpdates = {
      is_private: false,
    };

    if (target?.checked) {
      coachlogUpdates.is_private = true;
    }

    if (this.sessionData) {
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  addCoachee(newCoachee: UserLiteDTO | null) {
    if (newCoachee) {
      // check if shadower...
      const isShadower = this.sessionData?.shadowers.find(
        (item) => item.userId === newCoachee.id
      );
      const isCreator = this.sessionData?.creatorUserId === newCoachee.id;
      if (!isShadower && !isCreator) {
        this.coachingService.addAttendee(newCoachee.id, this.sessionData?.id);
      } else if (isShadower) {
        this.alertService.showAlert(
          'Selected User is already a shadower of this session'
        );
      } else if (isCreator) {
        this.alertService.showAlert(
          'The creator of the session cannot be a participant'
        );
      }
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.sessionData) {
      this.sessionData.attendees = this.sessionData.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserLiteDTO | null) {
    if (newShadower) {
      const isCoachee = this.sessionData?.attendees.find(
        (item) => item.userId === newShadower.id
      );
      const isCreator = this.sessionData?.creatorUserId === newShadower.id;
      if (!isCoachee && !isCreator) {
        this.coachingService.addShadower(newShadower.id, this.sessionData?.id);
      } else if (isCoachee) {
        this.alertService.showAlert(
          'Selected User is already a participant of this session'
        );
      } else if (isCreator) {
        this.alertService.showAlert(
          'The creator of the session cannot be a shadower'
        );
      }
    }
  }

  saveTitle() {
    if (this.sessionData) {
      const coachlogUpdates = { title: this.sessionData?.title };
      this.coachingService.updateCoachingLog(
        this.sessionData?.id,
        coachlogUpdates
      );
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput[]) {
    const startJsTimestamp = timeUpdate[0].time / 1000;
    const endJsTimestamp = timeUpdate[1].time / 1000;
    this.logStart = getValidTimepickerValue(
      startJsTimestamp,
      timeUpdate[0].timezone
    );
    this.timezone = timeUpdate[0].timezone;
    this.logEnd = getValidTimepickerValue(
      endJsTimestamp,
      timeUpdate[0].timezone
    );
    if (this.isTimeValid) {
      this.coachingService.updateCoachingLog(this.sessionData?.id as number, {
        start_datetime: startJsTimestamp,
        end_datetime: endJsTimestamp,
        timezone: this.timezone,
      });
    }
  }

  deleteSession() {
    this.coachingService.deleteCurrentSession(this.sessionId).subscribe();
  }

  openSessionTypeModal() {
    this.sessionTypeModal.openModal();
  }

  toggleResources(): void {
    this.resourcesActive = !this.resourcesActive;
    this.resourcesTabActive = !this.resourcesTabActive;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }

    this.coachingService.disconnectFromPusherChannel(this.sessionId);
  }
}
