import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PdAttendee } from '../../dtos/pd.dto';
import { PusherGuide } from '../pusher/pusher-guide.service';
import { PusherService } from '../pusher/pusher.service';

@Injectable({
  providedIn: 'root',
})
export class PdPusherService extends PusherGuide {
  public sessionUpdatedStream = new Subject<boolean>();

  public sessionUpdatedNotifier = this.sessionUpdatedStream.asObservable();

  public sessionDeletedStream = new Subject<boolean>();

  public sessionDeletedNotifier = this.sessionDeletedStream.asObservable();

  public attendeeAddedStream = new Subject<PdAttendee>();

  public attendeeAddedNotifier = this.attendeeAddedStream.asObservable();

  public attendeeMarkedPresentStream = new Subject<PdAttendee>();

  public attendeeMarkedPresentNotifier =
    this.attendeeMarkedPresentStream.asObservable();

  public attendeeDeletedStream = new Subject<PdAttendee>();

  public attendeeDeletedNotifier = this.attendeeDeletedStream.asObservable();

  public user: User | null;

  constructor(pusherService: PusherService, private store: Store) {
    super(pusherService, 'presence');
  }

  protected override addDefaultBindings(channelId: string): void {
    this.user = this.store.selectSnapshot(UserState.getUser);
    const channel = this.getChannel(channelId);
    if (channel) {
      channel.bind('session-updated', (message: { user_id: number }) => {
        if (message.user_id !== this.user?.id) {
          this.sessionUpdatedStream.next(true);
        }
      });
      channel.bind('session-deleted', (message: { user_id: number }) => {
        if (message.user_id !== this.user?.id) {
          this.sessionDeletedStream.next(true);
        }
      });
      channel.bind(
        'attendee-added',
        (message: { user_id: number; attendee: PdAttendee }) => {
          if (message.user_id !== this.user?.id) {
            this.attendeeAddedStream.next(message.attendee);
          }
        }
      );
      channel.bind(
        'attendee-marked-present',
        (message: { user_id: number; attendee: PdAttendee }) => {
          if (message.user_id !== this.user?.id) {
            this.attendeeMarkedPresentStream.next(message.attendee);
          }
        }
      );
      channel.bind(
        'attendee-deleted',
        (message: { user_id: number; attendee: PdAttendee }) => {
          if (message.user_id !== this.user?.id) {
            this.attendeeDeletedStream.next(message.attendee);
          }
        }
      );
    }
  }
}
