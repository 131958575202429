import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CoachingSessionDTO } from '../../../dtos/coaching-session.dto';
import {
  OptionChoiceDTO,
  SmartOptionsDataDTO,
} from '../../../dtos/smartcoach.dto';
import { SmartOptionsDataDTOFromSmartOptionsDataApiResponse } from '../../../helpers/translators/smart-coach.translators';
import { SmartSessionService } from '../../../services/smart-coach/smart-session.service';
import { CoachingLogState } from '../../../state/coaching-log/coaching-log.state';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-smart-option-modal',
  templateUrl: './smart-option-modal.component.html',
  styleUrls: ['./smart-option-modal.component.scss'],
  standalone: false,
})
export class SmartOptionModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showModal: boolean;

  @Input() logId: number;

  @Output() readonly showModalChange: EventEmitter<boolean> =
    new EventEmitter();

  @Output() readonly optionsAreAdded: EventEmitter<boolean> =
    new EventEmitter();

  @ViewChild('smartOptionModal') smartOptionModal: ModalComponent;

  subs: Subscription[] = [];

  dataLoading = true;

  isProcessing = false;

  addOptionsData: SmartOptionsDataDTO;

  detailedOption: OptionChoiceDTO | undefined;

  hasSelectedOptions = false;

  noCuratedOptionsMessage: string;

  isSmart = false;

  currentLevel: number;

  limitResources = true;

  limit = 3;

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  constructor(public smartCoachService: SmartSessionService) {}

  ngOnInit() {
    this.subs.push(
      this.smartCoachService.addOptionProcessing.subscribe((isProcessing) => {
        this.isProcessing = isProcessing;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.smartOptionModal) {
      if (changes['showModal'].currentValue) {
        delete this.detailedOption;
        this.subs.push(
          this.smartCoachService.getAddOptionsData().subscribe((result) => {
            if (result.error && result.error_message) {
              this.noCuratedOptionsMessage = result.error_message;
            }

            this.currentLevel = this.smartCoachService.addOptionLevel;
            this.smartOptionModal.config.headerClass = [
              'modal-header',
              'bg-primary',
              'text-white',
            ];
            this.smartOptionModal.config.headerClass.push(
              `bg-level-${this.currentLevel}`
            );
            this.addOptionsData =
              SmartOptionsDataDTOFromSmartOptionsDataApiResponse(result);

            this.smartOptionModal.config.titleText =
              this.addOptionsData.standardTitle;

            this.getDetailedOption();
            this.dataLoading = false;
          })
        );
        // Allows the content to update before the modal is opened.
        setTimeout(() => {
          this.smartOptionModal.open();
        }, 300);
      } else {
        this.currentLevel = 0;
        this.smartOptionModal.close();
      }
    }
  }

  hideModal() {
    this.showModalChange.emit(false);
  }

  getDetailedOption() {
    if (this.addOptionsData.optionChoices[0]) {
      this.showOptionDetails(this.addOptionsData.optionChoices[0].id);
    }
  }

  showOptionDetails(optionId: number) {
    this.detailedOption = this.addOptionsData.optionChoices.find(
      (option) => option.id === optionId
    );
  }

  toggleOption(eventData: number) {
    const itemToUpdate: OptionChoiceDTO | undefined =
      this.addOptionsData.optionChoices.find((item) => item.id === eventData);

    if (itemToUpdate) {
      if (itemToUpdate.isSelected) {
        itemToUpdate.isSelected = false;
      } else {
        itemToUpdate.isSelected = true;
      }
    }
    this.hasSelectedOptions = this.checkIfSelectedItems();
  }

  checkIfSelectedItems() {
    return this.addOptionsData.optionChoices.some(
      (choice) => choice.isSelected
    );
  }

  toggleLimit() {
    if (this.limitResources) {
      this.limit = 50;
    } else {
      this.limit = 3;
    }
    this.limitResources = !this.limitResources;
  }

  submitOptions() {
    this.smartCoachService.addOptionProcessingSource.next(true);
    const selectedCurationOptionIds: number[] = [];
    this.addOptionsData.optionChoices.forEach((optionChoice) => {
      if (optionChoice.isSelected) {
        selectedCurationOptionIds.push(optionChoice.id);
      }
    });

    this.smartCoachService
      .addOptions(selectedCurationOptionIds)
      .subscribe((result) => {
        if (result?.items?.length > 0) {
          setTimeout(() => {
            this.smartCoachService.addOptionProcessingSource.next(false);
            this.optionsAreAdded.emit(true);
            this.smartCoachService.hideAddOption();
          }, 500);
        }
      });
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
