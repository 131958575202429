<app-modal
  #modal
  [modalConfig]="{
    titleText: 'Edit Session Details',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    options: { size: 'xl' },
    closeOnBgClick: false,
  }">
  <button
    [disabled]="isUpdating || !form.valid"
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="closeModal()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <div class="container">
    <div *ngIf="isUpdating">
      <div class="w-100 is-updating-container">
        <app-loader [text]="'Updating'"></app-loader>
      </div>
    </div>
    <div class="card border-0">
      <div class="card-body">
        <form [formGroup]="form" class="mt-3">
          <div class="row mb-5 flex-column flex-xl-row">
            <div class="col">
              <h3 class="fs-19 mt-2 mb-0 fw-bold">Details</h3>
              <hr class="border border-tertiary border-1" />
              <label for="title" class="form-label">Title</label>
              <div class="input-group">
                <input
                  formControlName="title"
                  type="text"
                  class="form-control"
                  id="title"
                  aria-label="title"
                  [ngClass]="{
                    'is-invalid': f['title'].errors
                  }" />
              </div>
              <label for="short-description" class="form-label"
                >Short Description</label
              >
              <div class="input-group">
                <textarea
                  formControlName="shortDescription"
                  class="form-control"
                  id="short-description"
                  aria-label="Short Description"
                  [ngClass]="{
                    'is-invalid': f['shortDescription'].errors
                  }"></textarea>
              </div>
              <label class="form-label">Image</label>
              <div class="mb-3">
                <ng-container *ngIf="!imagePath; else imagePreview">
                  <app-file-upload
                    #uploadComponent
                    [uploadType]="uploadType"
                    [style]="uploadStyle"
                    [multiple]="true"
                    (outputFiles)="updateImage($event)" />
                </ng-container>
                <ng-template #imagePreview>
                  <img
                    src="{{ imagePath }}"
                    alt="Session Image"
                    class="session-image" />
                  <button
                    (click)="deleteImage()"
                    aria-label="Delete image"
                    class="btn btn-delete"
                    type="button"
                    ngbTooltip="Delete Image">
                    <i class="bi bi-trash"></i>
                  </button>
                </ng-template>
              </div>
              <div class="mb-3">
                <label for="type" class="form-label">Type</label>
                <ng-select
                  formControlName="type"
                  id="type"
                  autocomplete="off"
                  [clearable]="false"
                  [placeholder]="'Select a type'"
                  [ngClass]="{
                    'is-invalid': f['type'].errors
                  }"
                  (change)="updateType($event)">
                  <ng-container *ngFor="let type of pdSessionTypes">
                    <ng-option [value]="type.value">{{ type.label }}</ng-option>
                  </ng-container>
                </ng-select>
              </div>
              <div class="mb-3">
                <label for="virtual" class="form-label">Virtual/OnSite</label>
                <ng-select
                  formControlName="virtual"
                  id="virtual"
                  autocomplete="off"
                  [clearable]="false"
                  [placeholder]="'Select an option'"
                  [ngClass]="{
                    'is-invalid': f['virtual'].errors
                  }"
                  (change)="updateIsVirtual($event)">
                  <ng-container *ngFor="let option of virtualOptions">
                    <ng-option [value]="option.value">{{
                      option.label
                    }}</ng-option>
                  </ng-container>
                </ng-select>
              </div>
              <div class="mb-3">
                <label class="form-label">Date & Time</label>
                <app-datepicker
                  #dateAndTimePicker
                  (dateSelectedUnixTimestamp)="updateDateTime($event)"
                  [initialDateUnixTimestamp]="session.start_datetime"
                  [defaultStartTime]="startDateTime"
                  [defaultEndTime]="endDateTime"
                  [minDate]="todaysDate"
                  [timepicker]="true"
                  [timezone]="defaultTimezone"></app-datepicker>
                <ng-container *ngIf="dateAndTimeInvalid">
                  <p class="text-danger my-2">Please select a valid date.</p>
                </ng-container>
              </div>
              <label for="location" class="form-label">Location</label>
              <div
                *ngIf="f['virtual'].value === 0; else hardcodedVirtualLocation"
                class="input-group">
                <input
                  formControlName="location"
                  type="text"
                  class="form-control"
                  id="location"
                  aria-label="location" />
              </div>
              <ng-template #hardcodedVirtualLocation>
                <p>Virtual</p>
              </ng-template>
              <div *ngIf="f['virtual'].value === 1" class="mb-3">
                <label for="virtual-link" class="form-label"
                  >Virtual Session Link</label
                >
                <div class="input-group">
                  <input
                    formControlName="virtualLink"
                    type="text"
                    class="form-control"
                    id="virtual-link"
                    aria-label="Virtual Session Link" />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Registration Window</label>
                <div class="row align-items-center">
                  <div class="col">
                    <app-datepicker
                      (dateSelectedUnixTimestamp)="
                        updateRegistrationFromDate($event)
                      "
                      [initialDateUnixTimestamp]="
                        session.registration_start_datetime
                      "
                      [timezone]="defaultTimezone"></app-datepicker>
                  </div>
                  <div class="col-2">
                    <div class="text-center text-nowrap">to</div>
                  </div>
                  <div class="col">
                    <app-datepicker
                      (dateSelectedUnixTimestamp)="
                        updateRegistrationToDate($event)
                      "
                      [minDate]="registrationMinDate"
                      [initialDateUnixTimestamp]="
                        session.registration_end_datetime
                      "
                      [timezone]="defaultTimezone"></app-datepicker>
                  </div>
                </div>
              </div>
              <div>
                <label for="attendance-cap" class="form-label"
                  >Attendance Cap</label
                >
                <div class="input-group" style="max-width: 60px">
                  <input
                    formControlName="attendanceCap"
                    type="number"
                    class="form-control"
                    id="attendance-cap"
                    aria-label="Attendance Cap"
                    [ngClass]="{
                      'is-invalid': f['attendanceCap'].errors
                    }" />
                </div>
                <div *ngIf="f['attendanceCap'].hasError('nonNegativeInteger')">
                  <p class="fs-13 mb-2 text-danger">Must be a whole number.</p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mb-5">
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Credits</h3>
                <hr class="border border-tertiary border-1" />
                <ng-container *ngIf="settings">
                  <ng-container
                    *ngIf="
                      settings.credits?.length === 0;
                      else addCreditsTemplate
                    ">
                    <p>
                      No credits are available for your district. Please contact
                      your district operator.
                    </p>
                  </ng-container>
                  <ng-template #addCreditsTemplate>
                    <ng-container
                      formArrayName="credits"
                      *ngFor="let credit of credits.controls; let i = index">
                      <div class="d-flex mb-2">
                        <ng-container [formGroupName]="i">
                          <div class="col-8">
                            <ng-select
                              #creditTypeSelect
                              formControlName="creditType"
                              id="credit-type"
                              autocomplete="off"
                              [clearable]="false"
                              [placeholder]="'Select a credit type'"
                              [ngClass]="{
                                'is-invalid': credit.get('creditType')?.errors
                              }"
                              (change)="updateCreditType($event, i)">
                              <ng-container
                                *ngFor="let credit of settings.credits">
                                <ng-option
                                  [value]="credit.title"
                                  [disabled]="
                                    isCreditTypeSelected(credit.title, i)
                                  "
                                  >{{ credit.title }}</ng-option
                                >
                              </ng-container>
                            </ng-select>
                          </div>
                          <div class="col-3 ps-2">
                            <input
                              formControlName="creditAmount"
                              type="number"
                              class="form-control"
                              id="credit-amount"
                              aria-label="Credit Amount"
                              [ngClass]="{
                                'is-invalid': credit.get('creditAmount')?.errors
                              }"
                              (input)="updateCreditAmount($event, i)" />
                          </div>
                          <div class="col-1 d-flex align-items-center">
                            <button
                              (click)="deleteCredit(i)"
                              aria-label="Delete Credit button"
                              class="btn btn-delete btn-sm"
                              type="button"
                              ngbTooltip="Delete Credit">
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        *ngIf="
                          credit.get('creditAmount')?.hasError('nonNegative')
                        ">
                        <p class="fs-13 mb-2 text-danger">
                          Credit amount must be above zero.
                        </p>
                      </div>
                      <div
                        *ngIf="
                          credit.get('creditAmount')?.hasError('overLimit')
                        ">
                        <p class="fs-13 mb-2 text-danger">
                          Credit amount has a limit of 99.
                        </p>
                      </div>
                      <div
                        *ngIf="
                          credit.get('creditAmount')?.hasError('invalidDecimal')
                        ">
                        <p class="fs-13 mb-2 text-danger">
                          Credit amount cannot have more than one decimal place.
                        </p>
                      </div>
                    </ng-container>
                    <button
                      (click)="addCredit()"
                      type="button"
                      class="btn btn-link fw-bold p-0 text-decoration-none">
                      <i class="bi bi-plus"></i>Create Credit
                    </button>
                  </ng-template>
                </ng-container>
              </div>
              <div class="mb-5">
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Categories</h3>
                <hr class="border border-tertiary border-1" />
                <ng-container *ngIf="settings">
                  <ng-container
                    *ngIf="
                      settings.categories?.length === 0;
                      else addCategoriesTemplate
                    ">
                    <p>
                      No categories are available for your district. Please
                      contact your district operator.
                    </p>
                  </ng-container>
                  <ng-template #addCategoriesTemplate>
                    <ng-container
                      formArrayName="categories"
                      *ngFor="
                        let category of categories.controls;
                        let i = index
                      ">
                      <div class="d-flex mb-2">
                        <ng-container [formGroupName]="i">
                          <div class="col">
                            <ng-select
                              #categorySelect
                              formControlName="category"
                              id="category"
                              autocomplete="off"
                              [placeholder]="'Select a category'"
                              [clearable]="false"
                              [ngClass]="{
                                'is-invalid': category.get('category')?.errors
                              }"
                              (change)="updateCategory($event, i)">
                              <ng-container
                                *ngFor="let category of settings.categories">
                                <ng-option [value]="category.title">
                                  {{ category.title }}
                                </ng-option>
                              </ng-container>
                            </ng-select>
                          </div>
                          <div class="col ps-2">
                            <ng-select
                              #categoryOptionSelect
                              formControlName="categoryOptions"
                              id="category-options"
                              autocomplete="off"
                              [placeholder]="'Select an option'"
                              [clearable]="false"
                              [ngClass]="{
                                'is-invalid':
                                  category.get('categoryOptions')?.errors
                              }"
                              (change)="updateCategoryOption($event, i)">
                              <ng-container
                                *ngFor="
                                  let option of getFilteredOptions(
                                    categories.controls[i].get('category')
                                      ?.value
                                  )
                                ">
                                <ng-option [value]="option.option_value">
                                  {{ option.option_value }}
                                </ng-option>
                              </ng-container>
                            </ng-select>
                          </div>
                          <div class="col-1 d-flex align-items-center">
                            <button
                              aria-label="Delete Category button"
                              class="btn btn-delete btn-sm"
                              type="button"
                              (click)="deleteCategory(i)"
                              ngbTooltip="Delete Category">
                              <i class="bi bi-trash"></i>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <button
                      (click)="addCategory()"
                      type="button"
                      class="btn btn-link fw-bold p-0 text-decoration-none">
                      <i class="bi bi-plus"></i>Create Category
                    </button>
                  </ng-template>
                </ng-container>
              </div>
              <div class="mb-5">
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Competency Alignment</h3>
                <hr class="border border-tertiary border-1" />
                <app-competency-select
                  #selectCompetency
                  [districtIds]="districtIds"
                  [clearAfterSelection]="false"
                  [isMulti]="true"
                  [defaultCompetencies]="currentCompetencies"
                  (selectedCompetencyList)="updateCompetencies($event)"
                  [placeholder]="'Select competency'" />
              </div>
              <div class="mb-5" *ngIf="proFormsEnabled">
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Survey</h3>
                <hr class="border border-tertiary border-1" />
                <ng-select
                  #surveySelect
                  formControlName="survey"
                  id="survey"
                  autocomplete="off"
                  [placeholder]="'Select a Survey'"
                  [clearable]="true">
                  <ng-container *ngFor="let form of surveyFormList">
                    <ng-option [value]="form.id">
                      {{ form.title }}
                    </ng-option>
                  </ng-container>
                </ng-select>
              </div>
              <div *ngIf="isE2L" class="mb-5">
                <h3 class="fs-19 mt-2 mb-0 fw-bold">Sharing (e2L only)</h3>
                <hr class="border border-tertiary border-1" />
                <div class="d-flex align-items-center mb-3">
                  <label class="form-check-label" for="is-shared-switch">
                    Share session across districts?
                  </label>
                  <button
                    aria-label="Share Across Districts"
                    class="form-switch btn-reset ms-2">
                    <input
                      id="is-shared-switch"
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      formControlName="isShared" />
                  </button>
                </div>
                <ng-container *ngIf="f['isShared'].value">
                  <div class="mb-3">
                    <div class="mb-2">
                      Share with these districts (default is share across all
                      districts):
                    </div>
                    <app-district-select
                      #shareWithDistrictSelect
                      (selectedDistrictList)="handleShareDistricts($event)"
                      [isMulti]="true">
                    </app-district-select>
                  </div>
                  <div class="mb-3">
                    <div class="mb-2">Exclude from these districts:</div>
                    <app-district-select
                      #excludeFromDistrictSelect
                      (selectedDistrictList)="handleExcludeDistricts($event)"
                      [isMulti]="true">
                    </app-district-select>
                  </div>
                </ng-container>
              </div>

              <h3 class="fs-19 mt-2 mb-0 fw-bold">Facilitators</h3>
              <hr class="border border-tertiary border-1" />
              <app-user-select
                [clearAfterSelection]="true"
                (selectedUser)="addFacilitator($event)"></app-user-select>
              <div class="row mt-2">
                <ng-container *ngFor="let facilitator of facilitators">
                  <div class="d-flex align-items-center col-6 my-1">
                    <app-avatar
                      [profile]="facilitator.profile"
                      [avatarSize]="'small'"></app-avatar>
                    <span class="ms-2">
                      {{ facilitator.profile.first_name }}
                      {{ facilitator.profile.last_name }}
                    </span>
                    <button
                      (click)="removeFacilitator(facilitator)"
                      aria-label="Remove facilitator button"
                      class="btn btn-delete btn-sm"
                      type="button"
                      ngbTooltip="Remove Facilitator">
                      <i class="bi bi-x-circle-fill"></i>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="errorMessage">
            <div class="alert alert-danger text-start mt-3 mb-0" role="alert">
              {{ errorMessage }}
              <button
                type="button"
                class="btn-close"
                (click)="errorMessage = ''"
                data-bs-dismiss="alert"
                aria-label="Close"></button>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>

  <div footerContent>
    <div class="d-flex justify-content-between">
      <button
        aria-label="delete session button"
        class="btn btn-delete"
        type="button"
        [autoClose]="'outside'"
        [ngbPopover]="deletePopoverContent"
        [popoverTitle]="'Delete Session'"
        #popover="ngbPopover">
        Delete
      </button>
      <ng-template #deletePopoverContent>
        Permanently delete this session?
        <div class="text-end mt-2">
          <button
            type="button"
            class="btn btn-secondary btn-sm px-3 m-1"
            (click)="popover.close()">
            No
          </button>
          <button
            type="button"
            class="btn btn-primary btn-sm px-3 m-1"
            (click)="popover.close(); deleteSession()">
            Yes
          </button>
        </div>
      </ng-template>
      <button
        [disabled]="isUpdating || !form.valid"
        type="button"
        class="btn"
        id="footer-close-button"
        (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</app-modal>
