import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, map, take } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { CoachingSessionDTO } from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { WysiwygEditorConfig } from '../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-coaching-log-notes',
  templateUrl: './coaching-log-notes.component.html',
  styleUrls: ['./coaching-log-notes.component.scss'],
  standalone: false,
})
export class CoachingLogNotesComponent implements OnInit, OnDestroy {
  @Input() user: User | null;

  @Input() notesActive: boolean;

  @Input() sessionId: number;

  @Output() readonly hideNotesEvent = new EventEmitter<boolean>();

  initialContent: string;

  notesSaving = false;

  notes = '';

  sessionData$ = select(CoachingLogState.getSessionData);

  sessionData: CoachingSessionDTO | null;

  subs: Subscription[] = [];

  config: WysiwygEditorConfig = {
    useCodox: true,
    width: 340,
    height: 240,
  };

  constructor(public coachingService: CoachingLogService) {}

  updateNotes(notes: string) {
    this.notesSaving = true;
    this.coachingService.updateNotes(this.sessionId, notes);
  }

  toggleNotes(): void {
    this.initialContent = this.sessionData?.sessionNotes || '';
    this.hideNotesEvent.emit(true);
  }

  ngOnInit(): void {
    this.config.editId = `log${this.sessionId}notes`;
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
          )
        )
        .subscribe((sessionData) => {
          this.notesSaving = false;
          this.sessionData = sessionData;
        })
    );
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
          ),
          take(1)
        )
        .subscribe((sessionData) => {
          this.initialContent = sessionData?.sessionNotes || '';
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
