import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  SortEvent,
  SortableHeader,
} from '../../directives/sortable-header.directive';
import { SchoolSearchService } from '../../services/school-search/school-search.service';

@Component({
  selector: 'app-roster-schools',
  templateUrl: './roster-schools.component.html',
  styleUrls: ['./roster-schools.component.scss'],
  standalone: false,
})
export class RosterSchoolComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  @Input() schoolData: SchoolDTO[];

  @Output() readonly editSchoolEvent = new EventEmitter<number>();

  tableData: SchoolDTO[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortIconTemp: any = {
    title: 'gray',
    number: 'gray',
    date: 'gray',
  };

  subs: Subscription[] = [];

  // eslint-disable-next-line class-methods-use-this
  columnDataMap = (school: SchoolDTO, column: string): string | number => {
    switch (column) {
      case 'title':
        return school.title || '';
      case 'number':
        return school.counts.users || '';
      case 'date':
        return school.updated_at || '';
      default:
        return school.title || '';
    }
  };

  constructor(private schoolSearchService: SchoolSearchService) {}

  ngOnInit(): void {
    this.tableData = this.schoolData;

    this.subs.push(
      this.schoolSearchService.items.subscribe((res) => {
        this.tableData = res;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schoolData'].currentValue) {
      this.tableData = this.schoolData;
      this.subs.push(
        this.schoolSearchService.items.subscribe((res) => {
          this.tableData = res;
        })
      );
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[header.sortable] = 'gray';
      } else {
        this.sortIconTemp[header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.tableData = this.schoolData;
      this.sortIconTemp[column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = this.compare(
          this.columnDataMap(a, column as string),
          this.columnDataMap(b, column as string)
        );
        return direction === 'asc' ? -res : res;
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  compare(v1: string | number, v2: string | number) {
    if (typeof v1 === 'string' && typeof v2 === 'string') {
      v1 = v1.toUpperCase();
      v2 = v2.toUpperCase();
    }
    // eslint-disable-next-line no-nested-ternary
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
