import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Channel } from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { CompetencyListItemDTO } from '../../../dtos/attendee-rubric.dto';
import { getIcon } from '../../../helpers/icon.utilities';
import { videoDTOFromAPIResponse } from '../../../helpers/translators/video.translators';
import { CoachingLogPusherService } from '../../../services/coaching-log/coaching-log-pusher.service';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { CompetencyStatus } from './select-competency.helpers';

interface StatusUpdate {
  loading?: boolean;
  selected?: boolean;
}

@Component({
  selector: 'app-select-competency',
  templateUrl: './select-competency.component.html',
  styleUrls: ['./select-competency.component.scss'],
  standalone: false,
})
export class SelectCompetencyComponent implements OnInit {
  @Input() isSelected: boolean;

  @Input() isSelectable = false;

  @Input() logId: number;

  @Input() competency: CompetencyListItemDTO;

  @Input() topicTitle: string | undefined;

  @Output() readonly competencyEvent: BehaviorSubject<CompetencyStatus> =
    new BehaviorSubject({} as CompetencyStatus);

  currentIcon: string;

  showVideo = false;

  selected = false;

  loading = false;

  competencyVideo?: VideoDTO;

  logPusherService: Channel;

  competencyStatus: CompetencyStatus;

  constructor(
    private coachingLogService: CoachingLogService,
    private changeDetector: ChangeDetectorRef,
    private coachPusherService: CoachingLogPusherService
  ) {}

  changeStatus(status: StatusUpdate) {
    this.competencyStatus = {
      ...this.competencyStatus,
      ...status,
    };
    this.loading = this.competencyStatus.loading;
    this.competencyEvent.next(this.competencyStatus);
  }

  updateCompetency() {
    if (!this.competencyStatus.loading) {
      this.changeStatus({ loading: true });
      if (this.selected) {
        this.removeCompetency();
      } else {
        this.addCompetency();
      }
    }
  }

  addCompetency() {
    this.selected = true;
    this.coachingLogService
      .addCompetency(
        {
          egrowe_standard_id: this.competency.id,
          egrowe_rubric_id: this.competency.rubricId,
        },
        this.logId
      )
      .subscribe({
        complete: () => {
          this.changeStatus({ loading: false, selected: true });
          this.selected = true;
        },
        error: () => {
          this.changeStatus({ loading: false });
        },
      });
  }

  removeCompetency() {
    this.selected = false;
    this.coachingLogService
      .deleteCompetency(this.competency.id, this.logId)
      .subscribe({
        complete: () => {
          this.changeStatus({ loading: false, selected: false });
          this.selected = false;
        },
        error: () => {
          this.changeStatus({ loading: false });
        },
      });
  }

  handleVideoClick() {
    this.showVideo = true;
    this.changeDetector.detectChanges();
  }

  handleVideoClose() {
    this.showVideo = false;
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.competencyStatus = {
      competencyId: this.competency.id,
      loading: false,
      selected: false,
    };
    this.selected = this.isSelected;
    const icon: string = this.competency?.icon || '';
    this.currentIcon = getIcon(icon);

    this.logPusherService = this.coachPusherService.getChannel(
      `coachlog-v2-${this.logId}`
    );

    if (this.logPusherService) {
      this.logPusherService.bind(
        'coachlog:standards',
        (data: { standard_id: string; rubric_id: string }) => {
          if (this.competency.id === parseInt(data.standard_id)) {
            if (!this.selected) {
              this.selected = true;
              this.changeStatus({ selected: true });
            }
          }
        }
      );
      this.logPusherService.bind(
        'coachlog:standard_deleted',
        (data: { item: string; user_id: string }) => {
          if (this.competency.id === parseInt(data.item)) {
            if (this.selected) {
              this.selected = false;
              this.changeStatus({ selected: false });
            }
          }
        }
      );
    }
    if (this.competency.overviewVideo) {
      this.competencyVideo = videoDTOFromAPIResponse(
        this.competency.overviewVideo
      );
    }
  }
}
