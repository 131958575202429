<div class="container container-main">
  <app-dashboard-header #dashboardHeader :dashboardType="learner" />
  <div class="row mb-3">
    <div class="col-xl-8">
      <div class="row mb-3">
        <div class="col">
          <app-card-current-competencies />
        </div>
      </div>
      <div class="row gy-3">
        <div class="col-md-6">
          <app-card-todos />
        </div>
        <div class="col-md-6">
          <app-card-ideas />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-recent-celebration />
        </div>
      </div>
      <div class="row gy-3">
        <div class="col">
          <app-card-recent-badges *ngIf="user" [userId]="user.id" />
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <ng-container *ngIf="smartSuggestionsEnabled">
        <div class="col">
          <app-card-smart-suggestions
            (customActionClicked)="customSuggestionAction($event)" />
        </div>
      </ng-container>

      <div *ngIf="mscEnabled" class="row mb-3">
        <div class="col">
          <app-card-mysmartcoach [user]="user" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-growth-snapshot />
        </div>
      </div>
    </div>
  </div>
</div>
