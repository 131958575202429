import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { GradeDTO } from 'src/app/common/dtos/grade.dto';
import {
  ContentAreaDTO,
  SchoolDTO,
  UserSchoolDTO,
} from 'src/app/common/dtos/school.dto';
import {
  CreateUserPayloadDTO,
  UserDTO,
  UserLiteDTO,
} from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import { AlertService } from '../../services/alert/alert.service';
import { SchoolSearchService } from '../../services/school-search/school-search.service';
import { ModalComponent } from '../modals/modal/modal.component';
import { UserSelectComponent } from '../user-select/user-select.component';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
  standalone: false,
})
export class CreateUserModalComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() showModal = false;

  @Input() districtId = 0;

  @Output() readonly showModalChange = new EventEmitter();

  @ViewChild('coachSelect') coachSelect: UserSelectComponent;

  formSubmitted = false;

  isLoading = false;

  serverError = '';

  isOpen = false;

  modalShowing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  schoolListOptions: SchoolDTO[];

  form: FormGroup;

  gradeOptions: GradeDTO[];

  contentAreaOptions: ContentAreaDTO[];

  contentAreasPayload: ContentAreaDTO[] = [];

  gradesPayload: GradeDTO[] = [];

  additionalSchoolsPayload: SchoolDTO[] = [];

  userCoach: UserLiteDTO | null;

  @ViewChild('createUserModal') createUserModal: ModalComponent;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private schoolService: SchoolSearchService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // load the school list
    this.schoolService
      .getDistrictSchools(this.districtId)
      .subscribe((results) => {
        this.schoolListOptions = results;
        this.form = this.formBuilder.group({
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          title: ['', Validators.required],
          password: ['', Validators.required],
          school_id: ['', Validators.required],
          district_id: this.districtId,
          coachee_type: ['Teacher', Validators.required],
          grade_levels_served: [''],
          content_areas: [''],
          additional_schools: [''],
          exclude_from_reports: [''],
        });
      });
    // load the grade options list
    this.userService.getGrades().subscribe((res) => {
      this.gradeOptions = res.items;
    });
    // load the content areas options list
    this.userService.getContentAreas().subscribe((res) => {
      this.contentAreaOptions = res.items;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showModal'].currentValue) {
      this.modalShowing.next(true);
    }
    this.clearForm();
  }

  ngAfterViewInit() {
    this.createUserModal.isDismissing.subscribe(() => {
      this.modalShowing.next(false);
    });

    this.modalShowing.subscribe((shown) => {
      if (shown) {
        if (!this.isOpen) {
          this.createUserModal.open();
          this.isOpen = true;
        }
      } else if (this.isOpen) {
        this.createUserModal.close();
        this.isOpen = false;
      }
    });
  }

  clearForm() {
    if (!this.form) {
      return;
    }
    this.form.reset({
      first_name: '',
      last_name: '',
      email: '',
      title: '',
      password: '',
      school_id: '',
      district_id: this.districtId,
      coachee_type: 'Teacher',
    });
    this.additionalSchoolsPayload = [];
    this.gradesPayload = [];
    this.contentAreasPayload = [];
    if (this.coachSelect) this.coachSelect.reset();
  }

  get f() {
    return this.form.controls;
  }

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalShowing.next(false);
    this.clearForm();
    this.formSubmitted = false;
    this.isLoading = false;
    this.serverError = '';
    if (this.createUserModal) {
      this.createUserModal.close();
    }
  }

  createUser(): boolean {
    this.formSubmitted = true;

    if (this.form.invalid) {
      return false;
    }

    this.isLoading = true;

    const payload: CreateUserPayloadDTO = {
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      title: this.f['title'].value,
      email: this.f['email'].value,
      district_id: this.f['district_id'].value,
      school_id: this.f['school_id'].value,
      coachee_type: this.f['coachee_type'].value,
      password: this.f['password'].value,
    };

    this.userService.createUser(payload).subscribe({
      error: (error) => {
        this.isLoading = false;
        this.serverError = error.error.message;
        setTimeout(() => {
          this.serverError = '';
        }, 3000);
      },
      next: (newUser) => {
        const createdUser: UserDTO = newUser;
        this.isLoading = false;
        this.serverError = '';
        this.addAdditionalFieldsToCreatedUser(createdUser.id);
        setTimeout(() => {
          this.alertService.showAlert(
            `User ${createdUser.profile.first_name} ${createdUser.profile.last_name} <${createdUser.email}> has been created`
          );
        }, 500);
        this.closeModal();
      },
    });
    return true;
  }

  addAdditionalFieldsToCreatedUser(userId: number) {
    const servicesLoading: boolean[] = [];
    const excludeFromReports = this.f['exclude_from_reports'].value;
    if (this.additionalSchoolsPayload?.length > 0) {
      this.userService
        .updateAdditionalSchools(userId, this.additionalSchoolsPayload)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 5) {
              this.userCreatedHandler();
            }
          },
        });
    } else {
      servicesLoading.push(true);
      if (servicesLoading.length === 5) {
        this.userCreatedHandler();
      }
    }

    if (this.gradesPayload?.length > 0) {
      this.userService.updateGrades(userId, this.gradesPayload).subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 3000);
        },
        next: () => {
          servicesLoading.push(true);
          if (servicesLoading.length === 5) {
            this.userCreatedHandler();
          }
        },
      });
    } else {
      servicesLoading.push(true);
      if (servicesLoading.length === 5) {
        this.userCreatedHandler();
      }
    }

    if (this.contentAreasPayload?.length > 0) {
      this.userService
        .updateContentAreas(userId, this.contentAreasPayload)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 5) {
              this.userCreatedHandler();
            }
          },
        });
    } else {
      servicesLoading.push(true);
      if (servicesLoading.length === 5) {
        this.userCreatedHandler();
      }
    }

    if (this.userCoach?.id) {
      this.userService
        .updateUserCoach(userId, this.userCoach ? this.userCoach.id : 0)
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 5) {
              this.userCreatedHandler();
            }
          },
        });
    } else {
      servicesLoading.push(true);
      if (servicesLoading.length === 5) {
        this.userCreatedHandler();
      }
    }

    if (excludeFromReports) {
      this.userService
        .updateUser(userId, { exclude_from_reports: excludeFromReports })
        .subscribe({
          error: (error) => {
            this.isLoading = false;
            this.serverError = error.error.message;
            setTimeout(() => {
              this.serverError = '';
            }, 3000);
          },
          next: () => {
            servicesLoading.push(true);
            if (servicesLoading.length === 5) {
              this.userCreatedHandler();
            }
          },
        });
    } else {
      servicesLoading.push(true);
      if (servicesLoading.length === 5) {
        this.userCreatedHandler();
      }
    }
  }

  userCreatedHandler() {
    this.isLoading = false;
    this.clearForm();
    this.closeModal();
  }

  updateAssignedCoach(user: UserLiteDTO | null) {
    if (user) {
      this.userCoach = user;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMultiSelectValues(event: any, type: string) {
    const value = parseInt(event);
    switch (type) {
      case 'grade_levels_served':
        if (!this.gradesPayload.find((grade) => grade.id === value)) {
          const gradeInfo = this.gradeOptions.find(
            (option) => option.id === value
          );
          if (gradeInfo) {
            this.gradesPayload.push(gradeInfo);
          }
        }
        this.f['grade_levels_served'].setValue(' ');
        break;
      case 'content_areas':
        if (!this.contentAreasPayload.find((area) => area.id === value)) {
          const contentArea = this.contentAreaOptions.find(
            (option) => option.id === value
          );
          if (contentArea) {
            this.contentAreasPayload.push(contentArea);
          }
        }
        this.f['content_areas'].setValue(' ');

        break;
      case 'additional_schools':
        if (
          !this.additionalSchoolsPayload.find(
            (school) => school.id === value
          ) &&
          parseInt(this.f['school_id'].value) !== value
        ) {
          const schoolInfo = this.schoolListOptions.find(
            (option) => option.id === value
          );
          if (schoolInfo) {
            this.additionalSchoolsPayload.push(schoolInfo);
          }
        }
        this.f['additional_schools'].setValue(' ');

        break;
      default:
        break;
    }
  }

  removeMultiSelectValue(
    type: string,
    value: UserSchoolDTO | ContentAreaDTO | GradeDTO
  ) {
    switch (type) {
      case 'school':
        this.additionalSchoolsPayload = this.additionalSchoolsPayload?.filter(
          (school) => school.id !== value.id
        );
        break;
      case 'contentArea':
        this.contentAreasPayload = this.contentAreasPayload?.filter(
          (area) => area.id !== value.id
        );
        break;
      case 'grade':
        this.gradesPayload = this.gradesPayload?.filter(
          (grade) => grade.id !== value.id
        );
        break;
      default:
        break;
    }
  }
}
