import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngxs/store';
import { DistrictDTO } from 'src/app/common/dtos/district.dto';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { v4 as uuidv4 } from 'uuid';
import { getIdsFromDefaultItemsForSelect } from '../../../helpers/select.utilities';

@Component({
  selector: 'app-coach-select',
  templateUrl: './coach-select.component.html',
  styleUrls: ['./coach-select.component.scss'],
  standalone: false,
})
export class CoachSelectComponent implements OnChanges {
  @ViewChild('select') select: NgSelectComponent;

  coachList: UserLiteDTO[];

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedCoachList: UserLiteDTO[] = [];

  internalSelectedCoach: UserLiteDTO | null;

  internalDistrictIds: number[] = [];

  user: User;

  @Input() districtIds: number | number[] | DistrictDTO[] | null;

  @Input() simpleSelect = false;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a coach';

  @Input() placeholder = 'Search for a coach...';

  @Input() isMulti = false;

  @Input() defaultCoaches: number[] | UserLiteDTO[] | null;

  @Output() readonly selectedCoachList: EventEmitter<UserLiteDTO[]> =
    new EventEmitter();

  @Output() readonly selectedCoach: EventEmitter<UserLiteDTO | null> =
    new EventEmitter();

  constructor(private userService: UserService, private store: Store) {
    this.user = this.store.selectSnapshot((state) => state.user.impersonated);
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((propName) => {
      switch (propName) {
        case 'districtIds':
          if (!changes['districtIds'].isFirstChange()) {
            this.reset();
          }
          this.setInternalDistrictIds();
          this.loadCoaches(true);
          break;
        default:
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  searchCoaches(term: string, coach: UserLiteDTO) {
    term = term.toLocaleLowerCase();
    return (
      coach.profile.first_name.toLocaleLowerCase().includes(term) ||
      coach.profile.last_name.toLocaleLowerCase().includes(term)
    );
  }

  setInternalDistrictIds() {
    /* eslint-disable-next-line no-nested-ternary */
    this.internalDistrictIds = Array.isArray(this.districtIds)
      ? getIdsFromDefaultItemsForSelect(this.districtIds)
      : this.districtIds
      ? [this.districtIds as number]
      : [this.user.district?.id];
  }

  loadCoaches(outputAfter = false) {
    this.searchLoading = true;
    this.userService
      .fetchCoaches(this.internalDistrictIds)
      .subscribe((coaches) => {
        this.coachList = [...coaches];
        this.loadDefaultCoaches(outputAfter);
        this.searchLoading = false;
      });
  }

  loadDefaultCoaches(outputAfter = false) {
    if (this.defaultCoaches) {
      const coachIds = getIdsFromDefaultItemsForSelect(this.defaultCoaches);
      coachIds.forEach((coachId) => {
        const coachUser = this.coachList.find((coach) => coach.id === coachId);
        if (
          coachUser?.district_id &&
          this.internalDistrictIds.includes(coachUser.district_id)
        ) {
          if (this.isMulti) {
            this.internalSelectedCoachList.push(coachUser);
          } else {
            this.internalSelectedCoach = coachUser;
          }
        }
      });
    }
    if (outputAfter) {
      this.outputCoaches();
    }
  }

  coachSelected(coach: UserLiteDTO) {
    if (this.isMulti && coach) {
      if (!this.internalSelectedCoachList.includes(coach)) {
        this.internalSelectedCoachList.push(coach);
      }
      this.coachList = this.coachList.filter((item) => item.id !== coach.id);
      this.select.handleClearClick();
    } else {
      this.internalSelectedCoach = coach;
    }
    this.outputCoaches();
    if (this.clearAfterSelection) {
      // Without a timeout you get infinite recursion
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]);
      });
    }
  }

  outputCoaches() {
    if (this.isMulti) {
      this.selectedCoachList.emit(this.internalSelectedCoachList);
    } else {
      this.selectedCoach.emit(this.internalSelectedCoach);
    }
  }

  removeCoachFromList(coach: UserLiteDTO) {
    this.internalSelectedCoachList = this.internalSelectedCoachList.filter(
      (item) => item.id !== coach.id
    );
    this.outputCoaches();
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedCoachList = [];
      this.select.selectedItems.forEach((item) => {
        this.select.unselect(item);
      });
    } else {
      this.internalSelectedCoach = null;
    }
  }
}
