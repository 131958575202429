import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import {
  TopicFilters,
  TopicSearchParams,
  emptyTopicSearchParams,
} from 'src/app/private/pages/topics-page/topics-page-helpers';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-topic-page-filter-modal',
  templateUrl: './topic-page-filter-modal.component.html',
  styleUrl: './topic-page-filter-modal.component.scss',
  standalone: false,
})
export class TopicPageFilterModalComponent implements AfterViewInit, OnInit {
  @Input() initialFilters: TopicFilters;

  @ViewChild('districtSelect') districtSelect: DistrictSelectComponent;

  @ViewChild('modal') modal: ModalComponent;

  @Output() readonly modalClose: EventEmitter<void> = new EventEmitter<void>();

  @Output() readonly setFilters: EventEmitter<TopicSearchParams> =
    new EventEmitter();

  currentTopicFilters: TopicSearchParams;

  ngOnInit(): void {
    if (this.initialFilters) {
      this.currentTopicFilters =
        deepCopy(this.initialFilters) || emptyTopicSearchParams();
    }
  }

  ngAfterViewInit(): void {
    this.modal.open();
    this.modal.isDismissing.subscribe(() => {
      this.modalClose.emit();
    });
  }

  closeModal(): void {
    this.modal.close();
    this.modalClose.emit();
  }

  applyFilters() {
    this.setFilters.emit(this.currentTopicFilters);
    this.modal.close();
  }

  updateDistricts(districts: DistrictSimpleDto[] | null) {
    if (districts) {
      const districtIds: number[] = [];
      districts.forEach((d) => {
        districtIds.push(d.id);
      });
      this.currentTopicFilters.districts = districtIds;
    }
  }

  clearFilters(): void {
    this.currentTopicFilters = {
      ...this.currentTopicFilters,
      districts: [],
    };
    this.districtSelect.reset();
  }
}
