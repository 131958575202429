import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserPermission } from 'src/app/common/state/user/permission/user-permission';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import {
  oneDecimalValidator,
  pdSessionTypes,
  positiveIntegerValidator,
  positiveValidator,
  valueLimitValidator,
  virtualOptions,
} from 'src/app/common/utilities/pd-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import {
  getTimezoneLinkBackFromTimezone,
  TimezoneLinkBack,
} from 'src/app/common/utilities/time-helpers';
import { CompetencySelectComponent } from 'src/app/private/shared/components/competency/competency-select/competency-select.component';
import {
  DatepickerComponent,
  DatepickerOutput,
} from 'src/app/private/shared/components/datepicker/datepicker.component';
import { DistrictSelectComponent } from 'src/app/private/shared/components/district-select/district-select.component';
import { FileListComponent } from 'src/app/private/shared/components/file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import { WysiwygEditorComponent } from 'src/app/private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import {
  CreatePdSessionPayload,
  PdCategoryOption,
  PdSession,
  PdSettings,
} from 'src/app/private/shared/dtos/pd.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { getIcon } from 'src/app/private/shared/helpers/icon.utilities';
import { CompetenciesSelectService } from 'src/app/private/shared/services/competencies/competencies-select.service';
import { ProformsFormListService } from 'src/app/private/shared/services/proforms/proforms-formlist.service';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import {
  GetAllProFormsAPIResponse,
  ProFormsAPIResponse,
} from 'src/app/private/shared/types/responses/proforms.responses';
import { PdService } from '../../../shared/services/pd/pd.service';
import { CopyPdSessionModalComponent } from '../copy-pd-session-modal/copy-pd-session-modal.component';

const emptyPayload: CreatePdSessionPayload = {
  title: '',
  description: '',
  type: '',
  is_shared: 0,
  image: '',
  start_datetime: 0,
  end_datetime: 0,
  location: '',
  is_virtual: 0,
  virtual_link: null,
  max_registrations: 0,
  survey_proform_form_id: 0,
  notes: '',
  credits: [],
  facilitators: [],
  competencies: [],
  categories: [],
  sharing: [],
};

@Component({
  selector: 'app-create-pd-session',
  templateUrl: './create-pd-session.component.html',
  styleUrl: './create-pd-session.component.scss',
  standalone: false,
})
export class CreatePdSessionPageComponent implements OnInit {
  @ViewChild('copyPdSessionModal')
  copyPdSessionModal: CopyPdSessionModalComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @ViewChild('attendeeNotesEditor') attendeeNotesEditor: WysiwygEditorComponent;

  @ViewChild('dateAndTimePicker') dateAndTimePicker: DatepickerComponent;

  @ViewChild('selectCompetency') selectCompetency: CompetencySelectComponent;

  @ViewChild('shareWithDistrictSelect')
  shareWithDistrictSelect: DistrictSelectComponent;

  @ViewChild('excludeFromDistrictSelect')
  excludeFromDistrictSelect: DistrictSelectComponent;

  user: User;

  isE2L = false;

  canCreate = false;

  usersDistrictHasSessions = true;

  currentScreen = '';

  form: FormGroup;

  eventFormSubmitted = false;

  detailsFormSubmitted = false;

  imagePath = '';

  uploadStyle = UploadStyles.SIMPLE;

  uploadType = UploadType.USER_UPLOAD;

  pdSessionTypes = pdSessionTypes;

  virtualOptions = virtualOptions;

  creditData: { creditType: string; creditAmount: number }[] = [];

  selectedCreditTypes: string[] = [];

  categoryData: { category: string; categoryOptions: PdCategoryOption }[] = [];

  selectedOptionsByCategory: { [category: string]: string[] } = {};

  settings: PdSettings;

  districtIds: number[] = [];

  currentCompetencies: CompetencyDTO[] = [];

  surveyFormList: ProFormsAPIResponse[] = [];

  todaysDate = DateTime.now().toJSDate();

  todaysDateTime = DateTime.now().toSeconds();

  tomorrowsDate = DateTime.now().plus({ days: 1 }).toJSDate();

  tomorrowsDateTime = DateTime.now().plus({ days: 1 }).toSeconds();

  dateAndTimeInvalid = false;

  defaultTimezone: TimezoneLinkBack = TimezoneLinkBack.Central;

  facilitators: UserLiteDTO[] = [];

  payload: CreatePdSessionPayload = emptyPayload;

  errorMessage = '';

  submitLoading = false;

  eventReference = '';

  proFormsEnabled = false;

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private pdService: PdService,
    private competenciesSelectService: CompetenciesSelectService,
    private proformsFormListService: ProformsFormListService,
    private featureFlagService: UnleashService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.isE2L = checkIfE2L(this.user);
    if (this.user.district) {
      const districtTimezone = getTimezoneLinkBackFromTimezone(
        this.user.district.timezone
      );
      if (districtTimezone) {
        this.defaultTimezone = districtTimezone;
      }
      if (!this.isE2L) {
        this.districtIds = [this.user.district.id];
        this.payload.district_id = this.user.district?.id;
      }
    }
    this.proFormsEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.proForms
    );
  }

  ngOnInit() {
    this.resetPayload();
    this.getSettings();
    this.getSurveys();
    this.checkUserPermissions();
  }

  resetPayload() {
    this.payload = { ...emptyPayload, pd_event_id: undefined };
  }

  resetForm() {
    this.resetPayload();
    this.eventReference = '';
    this.imagePath = '';
    this.eventFormSubmitted = false;
    this.creditData = [];
    this.categoryData = [];
    this.currentCompetencies = [];
    this.initializeForm();
  }

  checkUserPermissions() {
    this.userService.fetchUserPermissions().subscribe((res) => {
      if (res) {
        this.canCreate = res.items.some(
          (item: { name: string }) =>
            item.name === UserPermission.CREATE_PD_SESSION
        );
        if (!this.canCreate) {
          this.router.navigate(['/permission-denied']);
        } else {
          this.initializeForm();
          this.getSessions();
          this.facilitators = [this.user];
          this.payload.facilitators = [this.user.id];
        }
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  getSessions() {
    this.pdService.getSessions().subscribe((resp) => {
      if (resp.items) {
        if (resp.items.length === 0) {
          this.usersDistrictHasSessions = false;
          this.currentScreen = 'eventDetails';
        } else {
          this.currentScreen = 'newOrExisting';
        }
      }
    });
  }

  getSettings() {
    this.pdService.getAdminSettings(this.user.district?.id).subscribe((res) => {
      if (res.item) {
        this.settings = res.item;
      }
    });
  }

  getSurveys() {
    this.proformsFormListService
      .getForms(this.user?.district?.id, 50, 1, 'survey')
      .subscribe((resp: GetAllProFormsAPIResponse) => {
        if (resp.items) {
          this.surveyFormList = resp.items;
        }
      });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      shortDescription: ['', Validators.required],
      type: [null, Validators.required],
      virtual: [null, Validators.required],
      credits: this.formBuilder.array(
        this.creditData.map((credit) => this.formBuilder.group(credit))
      ),
      categories: this.formBuilder.array(
        this.categoryData.map((category) => this.formBuilder.group(category))
      ),
      location: [''],
      virtualLink: [''],
      survey: [''],
      attendanceCap: [10, [Validators.required, positiveIntegerValidator()]],
      isShared: new FormControl(false),
    });
  }

  copySession(session: PdSession) {
    this.copyPdSessionModal.modal.close();
    this.currentScreen = 'eventDetails';
    this.eventReference = session.title;
    this.form = this.formBuilder.group({
      title: [`${session.title} (Copy)`, Validators.required],
      shortDescription: [session.description, Validators.required],
      type: [session.type, Validators.required],
      virtual: [session.is_virtual ? 1 : 0, Validators.required],
      credits: session.credits
        ? this.formBuilder.array(
            session.credits.map((credit) =>
              this.formBuilder.group({
                creditType: [credit.credit_type, Validators.required],
                creditAmount: [
                  credit.credit_amount,
                  [
                    Validators.required,
                    positiveValidator(),
                    valueLimitValidator(),
                  ],
                ],
              })
            )
          )
        : this.formBuilder.array([
            this.creditData.map((credit) => this.formBuilder.group(credit)),
          ]),
      categories: session.categories
        ? this.formBuilder.array(
            session.categories.map((category) =>
              this.formBuilder.group({
                category: [category.category, Validators.required],
                categoryOptions: [category.value, Validators.required],
              })
            )
          )
        : this.formBuilder.array(
            this.categoryData.map((category) =>
              this.formBuilder.group(category)
            )
          ),
      location: [''],
      virtualLink: [''],
      survey: [
        session.survey_proform_form_id ? session.survey_proform_form_id : null,
      ],
      attendanceCap: [10, [Validators.required, positiveIntegerValidator()]],
      isShared: new FormControl(false),
    });

    this.updateSelectedCreditTypes();
    this.updateSelectedOptions();

    if (session.image) {
      this.payload.image = session.image;
      this.imagePath = getIcon(session.image);
    }

    this.payload.notes = session.notes;
    if (session.competencies) {
      session.competencies.forEach((competency) => {
        this.competenciesSelectService
          .getCompetency(competency.egrowe_standard_id)
          .subscribe((comp) => {
            if (comp) {
              this.currentCompetencies.push(comp);
              this.selectCompetency.internalSelectedCompetencyList =
                this.currentCompetencies;
            }
          });
      });
    }

    this.payload.pd_event_id = session.pd_event_id;
  }

  // CREDITS
  get credits(): FormArray {
    return this.form.get('credits') as FormArray;
  }

  addCredit() {
    this.credits.push(
      this.formBuilder.group({
        creditType: [null, Validators.required],
        creditAmount: [
          null,
          [
            Validators.required,
            positiveValidator(),
            valueLimitValidator(),
            oneDecimalValidator(),
          ],
        ],
      })
    );
    this.watchCreditTypeChanges();
  }

  deleteCredit(index: number) {
    this.credits.removeAt(index);
    this.updateSelectedCreditTypes();
  }

  watchCreditTypeChanges() {
    this.credits.valueChanges.subscribe(() => this.updateSelectedCreditTypes());
  }

  updateSelectedCreditTypes() {
    this.selectedCreditTypes = [];
    this.credits.controls.forEach((group) => {
      const creditType = group.get('creditType')?.value;
      if (creditType && creditType !== null) {
        this.selectedCreditTypes.push(creditType);
      }
    });
  }

  isCreditTypeSelected(creditTitle: string, currentIndex: number): boolean {
    const isSelected = this.selectedCreditTypes.includes(creditTitle);
    const currentControl = this.credits.at(currentIndex).get('creditType');
    return isSelected && currentControl?.value !== creditTitle;
  }

  // CATEGORIES
  get categories(): FormArray {
    return this.form.get('categories') as FormArray;
  }

  addCategory() {
    const newCategoryGroup = this.formBuilder.group({
      category: [null, Validators.required],
      categoryOptions: [null, Validators.required],
    });

    // Watch for changes in the first dropdown (category)
    newCategoryGroup.get('category')?.valueChanges.subscribe(() => {
      newCategoryGroup.get('categoryOptions')?.reset(); // Reset the second dropdown when the category changes
      this.updateSelectedOptions(); // Update selected options globally
    });

    // Watch for changes in the second dropdown (categoryOptions)
    newCategoryGroup.get('categoryOptions')?.valueChanges.subscribe(() => {
      this.updateSelectedOptions(); // Recompute selected options for all categories
    });

    this.categories.push(newCategoryGroup);
  }

  updateSelectedOptions() {
    this.selectedOptionsByCategory = {}; // Reset the selected options tracking

    this.categories.controls.forEach((control) => {
      const category = control.get('category')?.value;
      const option = control.get('categoryOptions')?.value;

      if (category && option) {
        if (!this.selectedOptionsByCategory[category]) {
          this.selectedOptionsByCategory[category] = [];
        }

        if (!this.selectedOptionsByCategory[category].includes(option)) {
          this.selectedOptionsByCategory[category].push(option);
        }
      }
    });
  }

  getFilteredOptions(categoryTitle: string): PdCategoryOption[] {
    // Return options relevant to the selected category and not already selected for it
    const category = this.settings.categories.find(
      (cat) => cat.title === categoryTitle
    );

    if (!category) return []; // No options if the category is invalid or not found

    return category.options.filter(
      (option) =>
        !this.selectedOptionsByCategory[categoryTitle]?.includes(
          option.option_value
        ) // Exclude already-selected options
    );
  }

  deleteCategory(index: number) {
    this.categories.removeAt(index);
    this.updateSelectedOptions(); // Update selected options after deletion
  }

  addCompetencies(competencies: CompetencyDTO[] | null) {
    if (competencies) {
      if (competencies !== undefined || competencies !== null) {
        this.currentCompetencies = competencies;
      }
    } else {
      this.currentCompetencies = [];
    }
  }

  // IMAGE UPLOAD
  updateImage(file: FileDTO) {
    this.payload.image = file.location;
    this.imagePath = getIcon(file.location);
  }

  deleteImage() {
    this.payload.image = '';
    this.imagePath = '';
  }

  // NAVIGATION
  navigateToNewOrExisting() {
    this.resetForm();
    this.currentScreen = 'newOrExisting';
  }

  navigateToSessionDetails() {
    this.eventFormSubmitted = true;
    const eventDetailsFormIsValid =
      this.form.get('title')?.valid &&
      this.form.get('shortDescription')?.valid &&
      this.form.get('type')?.valid &&
      this.form.get('virtual')?.valid &&
      this.credits.controls.every(
        (group) =>
          group.get('creditType')?.valid && group.get('creditAmount')?.valid
      ) &&
      this.categories.controls.every(
        (group) =>
          group.get('category')?.valid && group.get('categoryOptions')?.valid
      );
    this.payload.notes = this.attendeeNotesEditor.editorContent;
    if (eventDetailsFormIsValid) {
      this.currentScreen = 'sessionDetails';
    }
  }

  // DATE & TIME
  updateDateTime(output: DatepickerOutput[]) {
    this.payload.start_datetime = output[0].time / 1000;
    this.payload.end_datetime = output[1].time / 1000;
    if (this.dateAndTimeInvalid) {
      this.dateAndTimeInvalid = false;
    }
  }

  updateRegistrationFromDate(output: DatepickerOutput[]) {
    this.payload.registration_start_datetime = output[0].time / 1000;
  }

  updateRegistrationToDate(output: DatepickerOutput[]) {
    this.payload.registration_end_datetime = Math.floor(
      DateTime.fromMillis(output[0].time).endOf('day').toSeconds()
    );
  }

  // FACILITATORS
  alreadyAdded(user: UserLiteDTO) {
    return this.facilitators.find((facilitator) => facilitator.id === user.id);
  }

  addFacilitator(facilitator: UserLiteDTO | null) {
    if (facilitator) {
      if (!this.alreadyAdded(facilitator)) {
        this.facilitators.push(facilitator);
        this.payload.facilitators.push(facilitator.id);
      }
    }
  }

  removeFacilitator(facilitator: UserLiteDTO) {
    this.facilitators = this.facilitators.filter(
      (fac) => fac.id !== facilitator.id
    );
    this.payload.facilitators = this.payload.facilitators.filter(
      (fac) => fac !== facilitator.id
    );
  }

  updateSharing(
    selectedDistricts: DistrictSimpleDto[],
    isShared: number
  ): void {
    // Track updated district IDs for efficient processing
    const updatedDistricts = selectedDistricts.map((district) => district.id);

    // Handle the opposite dropdown
    const oppositeDropdown =
      isShared === 1
        ? this.excludeFromDistrictSelect
        : this.shareWithDistrictSelect;

    // Update the opposite dropdown's internalSelectedDistrictList
    oppositeDropdown.internalSelectedDistrictList =
      oppositeDropdown.internalSelectedDistrictList.filter(
        (district) => !updatedDistricts.includes(district.id)
      );

    // Update the payload sharing
    this.payload.sharing = this.payload.sharing.filter((item) => {
      if (item.is_shared !== isShared) {
        // Remove districts that are newly selected in this dropdown
        const index = updatedDistricts.indexOf(item.district_id);
        if (index !== -1) {
          updatedDistricts.splice(index, 1);
          return false; // Remove conflicting entry
        }
        return true; // Retain entries with different is_shared values
      }

      // Retain selected districts
      const index = updatedDistricts.indexOf(item.district_id);
      if (index !== -1) {
        updatedDistricts.splice(index, 1); // Remove from list to add
        return true;
      }

      // Remove deselected districts
      return false;
    });

    // Add any new districts to the payload
    updatedDistricts.forEach((districtId) => {
      this.payload.sharing.push({
        district_id: districtId,
        is_shared: isShared,
      });
    });
  }

  publishSession() {
    this.detailsFormSubmitted = true;
    this.submitLoading = true;

    if (!this.dateAndTimePicker.selectedDate) {
      this.dateAndTimeInvalid = true;
      this.submitLoading = false;
      return;
    }
    if (!this.form.get('attendanceCap')?.valid) {
      this.submitLoading = false;
    } else {
      this.payload.title = this.form.get('title')?.value;
      this.payload.description = this.form.get('shortDescription')?.value;
      this.payload.type = this.form.get('type')?.value;
      this.payload.is_virtual = this.form.get('virtual')?.value;
      this.payload.credits = this.credits.controls
        .filter((group) => {
          const creditType = group.get('creditType')?.value;
          const creditAmount = group.get('creditAmount')?.value;
          return creditType && creditAmount; // Only include if both are valid
        })
        .map((group) => ({
          type: group.get('creditType')?.value,
          amount: group.get('creditAmount')?.value,
        }));

      this.payload.categories = this.categories.controls
        .filter((group) => {
          const category = group.get('category')?.value;
          const categoryOptions = group.get('categoryOptions')?.value;
          return category && categoryOptions; // Only include if both are valid
        })
        .map((group) => ({
          category: group.get('category')?.value,
          value: group.get('categoryOptions')?.value,
        }));

      this.payload.competencies = this.currentCompetencies.map(
        (comp) => comp.id
      );
      this.payload.survey_proform_form_id = this.form.get('survey')?.value;
      this.payload.location = this.form.get('location')?.value;
      this.payload.virtual_link = this.form.get('virtualLink')?.value || null;
      this.payload.max_registrations = this.form.get('attendanceCap')?.value;
      this.payload.is_shared = this.form.get('isShared')?.value ? 1 : 0;

      this.pdService.createSession(this.payload).subscribe({
        error: (err) => {
          this.submitLoading = false;
          this.errorMessage = `There was an issue with your request: ${err.error.message}`;
        },
        next: (res) => {
          this.submitLoading = false;
          this.router.navigate([`training/session/${res.item.id}`]);
        },
      });
    }
  }
}
