<div
  class="container fb-container"
  [ngClass]="[isSidebarOpen ? '' : 'minimal']">
  <div class="d-flex justify-content-start">
    <button
      (click)="backToSettings()"
      class="text-decoration-none text-body-secondary btn btn-link p-0">
      < Back to ProForms
    </button>
  </div>
  <div class="card fb-card p-0 mt-3">
    <div class="fb-card-header">
      <h1 class="fs-3 mb-0 fw-light color-secondary">ProForms</h1>
      <h2 class="fs-1 fw-600 mb-0">Form Builder</h2>
    </div>
    <div class="p-3 p-lg-5 position-relative">
      <ng-container *ngIf="form; else loadingTemplate">
        <div class="sidebar" [class.open]="isSidebarOpen">
          <div class="sidebar-inner-wrapper">
            <button class="btn btn-toggle-sidebar" (click)="toggleSidebar()">
              <i *ngIf="isSidebarOpen" class="bi bi-chevron-right"></i>
              <i *ngIf="!isSidebarOpen" class="bi bi-gear"></i>
            </button>
            <div class="sidebar-body px-5 py-3 py-lg-5">
              <h3 class="fw-bold mb-1 fs-4">Settings</h3>
              <hr class="border border-tertiary border-1 mt-0" />
              <!-- For MVP, anonymity will always be 'none' and should not be editable. Will be able to edit later -->
              <!-- <div class="d-flex align-items-center">
                Allow Anonymous Submissions?
                <button
                  aria-label="competency-visibility-button"
                  class="form-switch btn-reset">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    [checked]="form.anonymity"
                    (change)="toggleAnonymous()" />
                </button>
              </div> -->
              <div class="form-group mb-3">
                <label class="form-label fw-bold" for="status">Status</label>
                <ng-select
                  labelForId="status"
                  [items]="statuses"
                  bindLabel="value"
                  [clearable]="false"
                  [searchable]="false"
                  bindValue="key"
                  [(ngModel)]="form.status"
                  name="status"
                  placeholder="Select status"
                  (ngModelChange)="updateStatus()"></ng-select>
              </div>
              <div class="form-group mt-2">
                <label class="form-label fw-bold" for="status">Sharing</label>
                <div
                  class="d-flex align-items-center justify-content-between mb-3">
                  <span class="d-block">Share form across districts?</span>
                  <button
                    aria-label="competency-visibility-button"
                    class="form-switch btn-reset">
                    <input
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      [checked]="form.is_shared === 1"
                      (change)="toggleSharing($event)" />
                  </button>
                </div>
              </div>
              <ng-container *ngIf="form.is_shared === 1">
                <div class="mb-3">
                  <label class="mb-2">
                    Share with these districts (default is share across all your
                    districts):
                  </label>
                  <app-district-select
                    #shareWithDistrictSelect
                    (selectedDistrictList)="handleShareDistricts($event)"
                    [defaultDistricts]="districtsSharedWith"
                    [isMulti]="true"></app-district-select>
                </div>
                <div class="mb-3">
                  <label class="mb-2">Exclude these districts:</label>
                  <app-district-select
                    #excludeFromDistrictSelect
                    (selectedDistrictList)="handleExcludeDistricts($event)"
                    [defaultDistricts]="districtsExcludedFrom"
                    [isMulti]="true"></app-district-select>
                </div>
              </ng-container>
              <ng-container *ngIf="isArchived">
                <button
                  class="btn btn-delete px-0 mt-5"
                  [autoClose]="'outside'"
                  [ngbPopover]="deletePopoverContent"
                  [popoverTitle]="'Delete Form'"
                  #popover="ngbPopover">
                  Delete
                </button>
                <ng-template #deletePopoverContent>
                  Permanently delete this form?
                  <div class="text-end mt-2">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm px-3 m-1"
                      (click)="popover.close()">
                      No
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm px-3 m-1"
                      (click)="popover.close(); deleteForm()">
                      Yes
                    </button>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="content-wrapper" [class.full-width]="!isSidebarOpen">
          <div class="content">
            <div #header class="sticky-header" [class.stuck]="isStuck">
              <div [ngClass]="{ 'container px-5 pt-2 pb-1': isStuck }">
                <div class="d-flex justify-content-between align-items-center">
                  <input
                    [(ngModel)]="form.title"
                    (ngModelChange)="onTitleChange($event)"
                    placeholder="Title"
                    type="text"
                    class="form-control title-input fw-800" />

                  <button
                    type="button"
                    class="btn btn-primary fw-bold"
                    (click)="previewModal.openModal()">
                    <i class="bi bi-eye me-2"></i> Preview
                  </button>
                </div>
              </div>
            </div>

            <div class="card outro-card mt-5" [class.focused]="isEditingOutro">
              <div
                class="card-header outro-card-header fs-6 d-flex justify-content-between">
                Outro
                <i
                  class="bi bi-info-circle-fill"
                  ngbTooltip="The outro is where you can write a confirmation message or next
          steps. Respondents will see this message once they submit the
          form."
                  containerClass="outro-card-tooltip"></i>
              </div>
              <div #outroBody>
                <ng-container *ngIf="isEditingOutro; else previewOutro">
                  <div class="card-body outro-card-body">
                    <app-wysiwyg-editor
                      [version]="'custom'"
                      [customOptions]="toolbarBottomFroalaOptions"
                      [editorContent]="form.outro"
                      (editorContentEvent)="
                        updateOutro($event)
                      "></app-wysiwyg-editor>
                  </div>
                </ng-container>
                <ng-template #previewOutro>
                  <div
                    (click)="showEditOutro($event)"
                    class="card-body outro-card-body position-relative pointer p-5">
                    <i class="bi bi-pencil color-tertiary edit-icon"></i>
                    <app-safehtml [html]="form.outro"></app-safehtml>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <app-proform-preview-modal #previewModal [formId]="form.id" />
      </ng-container>
      <ng-template #loadingTemplate>
        <div
          class="w-100 position-relative"
          style="background-color: #fff; height: 500px">
          <app-loader></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
