import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { Animations } from 'src/app/common/utilities/animations';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [Animations.fadeInOut, Animations.delayfadeInOut],
  standalone: false,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  submitted = false;

  loading = false;

  errorMessage: string | null;

  forceLogout: string | null;

  googleOAuthClientId: string;

  liwcLink: string;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.googleOAuthClientId = EnvironmentService.googleOAuthClientId();
    this.liwcLink = `https://clever.com/oauth/authorize?response_type=code&client_id=${EnvironmentService.cleverClientId()}&redirect_uri=${encodeURIComponent(
      EnvironmentService.cleverRedirectURI()
    )}`;
    this.errorMessage = StorageService.getItem('loginError');
    if (this.errorMessage) {
      StorageService.removeItem('loginError');
    }
    this.forceLogout = StorageService.getItem('forceLogout', 5500);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).handleGSIResponse = this.handleGSIResponse;

    const gsiScript = document.createElement('script');
    gsiScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
    document.head.appendChild(gsiScript);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleGSIResponse = (response: any) => {
    this.loading = true;

    this.authService.googleLogin(response).subscribe({
      error: (err) => {
        this.loading = false;
        this.errorMessage = err.error.message;
        this.cdr.detectChanges();
      },
    });
  };

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService
      .login(this.f['username'].value, this.f['password'].value)
      .subscribe({
        error: (err) => {
          this.loading = false;
          this.errorMessage = err.error?.message;
        },
      });
  }
}
