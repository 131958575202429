import { Component, Input, OnInit } from '@angular/core';
import { ReportRequestParams } from 'src/app/common/dtos/reports.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { User } from 'src/app/common/state/user/user.model';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-card-leader-glance',
  templateUrl: './card-leader-glance.component.html',
  styleUrls: ['./card-leader-glance.component.scss'],
  standalone: false,
})
export class CardLeaderGlanceComponent implements OnInit {
  @Input() dashboardType = 'leader-dashboard';

  @Input() user: User | null;

  @Input() schoolYearId: number; // default to current school year if not provided

  @Input() sessionParams: ReportRequestParams; // overwrites traditional kpi logic (backwards compatible)

  @Input() obvservationParams: ReportRequestParams;

  @Input() strandBadgeParams: ReportRequestParams;

  @Input() microcredentialParams: ReportRequestParams;

  @Input() giParams: ReportRequestParams;

  @Input() celebrationParams: ReportRequestParams;

  @Input() campusParams: ReportRequestParams;

  @Input() coachedParams: ReportRequestParams;

  @Input() otherSupportSessionsParams: ReportRequestParams;

  isCollapsed: boolean;

  cardTitle = 'Year at a Glance';

  requestParams: ReportRequestParams;

  leaderGlanceDataList: GlanceData[] = [];

  categories: string[] = [
    'Coaching Conversations',
    'Observations',
    'Other Support Sessions',
    'GIs Earned',
    'Strand Badges Earned',
    'Microcredentials Earned',
    'Schools Coached',
    'Staff Coached',
    'Celebrations',
  ];

  constructor(
    private cardStorageService: CardStorageService,
    private reportService: ReportService
  ) {}

  ngOnInit(): void {
    if (this.dashboardType === 'reporting') {
      this.cardTitle = 'At a Glance';
      this.isCollapsed = false;
    } else {
      this.isCollapsed = this.cardStorageService.getCollapsedState(
        this.cardTitle.replace(/\s/g, '').toLowerCase()
      );
    }

    this.categories.forEach((category) => {
      this.getLeaderGlanceData(category);
    });
  }

  getLeaderGlanceData(request: string) {
    if (this.user?.district) {
      let categoryOrder = 0;
      if (request === 'Coaching Conversations') {
        this.requestParams = this.sessionParams;
      } else if (request === 'Observations') {
        this.requestParams = this.obvservationParams;
        categoryOrder = 1;
      } else if (request === 'Other Support Sessions') {
        this.requestParams = this.otherSupportSessionsParams;
        categoryOrder = 2;
      } else if (request === 'GIs Earned') {
        this.requestParams = this.giParams;
        categoryOrder = 3;
      } else if (request === 'Strand Badges Earned') {
        this.requestParams = this.strandBadgeParams;
        categoryOrder = 4;
      } else if (request === 'Microcredentials Earned') {
        this.requestParams = this.microcredentialParams;
        categoryOrder = 5;
      } else if (request === 'Schools Coached') {
        this.requestParams = this.campusParams;
        categoryOrder = 6;
      } else if (request === 'Staff Coached') {
        this.requestParams = this.coachedParams;
        categoryOrder = 7;
      } else if (request === 'Celebrations') {
        this.requestParams = this.celebrationParams;
        categoryOrder = 8;
      }

      this.reportService
        .getLeaderGlanceData(
          this.requestParams,
          this.user.district.id,
          this.schoolYearId
        )
        .subscribe((res) => {
          const glanceData = {} as GlanceData;
          glanceData.title = request;
          glanceData.count = res.datasets[0].data[0];
          glanceData.order = categoryOrder;
          this.leaderGlanceDataList.push(glanceData);
        });
    }
  }

  getCardListInOrder() {
    return this.leaderGlanceDataList.sort(
      (first, second) => first.order - second.order
    );
  }

  triggerCollapse() {
    if (this.dashboardType !== 'reporting') {
      this.isCollapsed = !this.isCollapsed;
      this.cardStorageService.storeCollapseState(
        this.cardTitle.replace(/\s/g, '').toLowerCase(),
        this.isCollapsed
      );
    }
  }
}

export interface GlanceData {
  title: string;
  count: number;
  order: number;
}
