import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ReportPageFiltersService } from 'src/app/private/shared/services/report/report-page-filters.service';
import { ReportFilterModalComponent } from '../report-filter-modal/report-filter-modal.component';
import { ReportFiltersDTO } from '../report-filters.dto';

@Component({
  selector: 'app-report-filter-bar',
  templateUrl: './report-filter-bar.component.html',
  styleUrls: ['./report-filter-bar.component.scss'],
  standalone: false,
})
export class ReportFilterBarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('modal') modal: ReportFilterModalComponent;

  appliedFilters: ReportFiltersDTO;

  showModal = false;

  @Input() canFilterByDistrict: boolean;

  @Input() reportName = 'report_dashboard';

  _sub: Subscription;

  constructor(public filterService: ReportPageFiltersService) {}

  ngOnInit(): void {
    this.filterService.setReportName(this.reportName);
    this.appliedFilters = { ...this.filterService.currentFilters };

    // DO NOT DELETE CALL IS NOT REDUDANT
    this.filterService.getDistrictSchoolYear();
    // Needed to reset district year if it has been changed by configuration tab

    this._sub = this.filterService.filters.subscribe((filters) => {
      if (filters.reportName === this.reportName) {
        this.appliedFilters = { ...filters };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportName']) {
      this.filterService.setReportName(this.reportName);
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  setReportName() {
    this.filterService.setReportName(this.reportName);
  }
}
