import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject, Subscription, map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { v4 as uuidv4 } from 'uuid';
import { CompetenciesSelectService } from '../../../services/competencies/competencies-select.service';

@Component({
  selector: 'app-competency-select',
  templateUrl: './competency-select.component.html',
  styleUrls: ['./competency-select.component.scss'],
  standalone: false,
})
export class CompetencySelectComponent implements OnInit, OnChanges {
  competencyList: Observable<CompetencyDTO[]>;

  competencyLibrary: CompetencyDTO[] = [];

  searchInput$ = new Subject<string>();

  searchSubscription: Subscription | null;

  searchLoading = false;

  labelId = uuidv4();

  internalSelectedCompetency: CompetencyDTO | null = null;

  internalSelectedCompetencyList: CompetencyDTO[] = [];

  user: User;

  isEventInternal = false; // Flag to track internal operations

  @ViewChild('select') select: NgSelectComponent;

  @Input() clearAfterSelection = false;

  @Input() label = 'Search for and select a competency';

  @Input() placeholder = 'Search for a competency...';

  @Input() hideTags = false;

  @Input() isMulti = false;

  @Input() defaultCompetencies: number[] | CompetencyDTO[] | null;

  @Input() defaultCompetency: CompetencyDTO | null;

  @Input() isDisabled = false;

  @Input() districtIds: number[];

  @Input() rubricId: number | undefined;

  @Output() readonly selectedCompetency: EventEmitter<CompetencyDTO | null> =
    new EventEmitter();

  @Output() readonly selectedCompetencyList: EventEmitter<CompetencyDTO[]> =
    new EventEmitter();

  constructor(private competencySearch: CompetenciesSelectService) {}

  ngOnInit(): void {
    this.getCompetencyList();
    this.searchInput$.subscribe((term) => {
      this.searchLoading = true;

      if (this.districtIds.length > 0) {
        return this.competencySearch.search(term, this.districtIds);
      }
      return this.competencySearch.search(term);
    });

    if (this.defaultCompetencies) {
      const competencyIds =
        /* eslint-disable-next-line no-nested-ternary */
        typeof this.defaultCompetencies === 'number'
          ? [this.defaultCompetencies]
          : typeof this.defaultCompetencies[0] === 'number'
          ? this.defaultCompetencies
          : this.defaultCompetencies.map((c) => (c as CompetencyDTO).id);
      competencyIds.forEach((competencyId) => {
        this.competencySearch
          .getCompetency(competencyId as number)
          .subscribe((competency) => {
            if (competency) {
              if (this.isMulti) {
                this.internalSelectedCompetencyList.push(competency);
              } else {
                this.internalSelectedCompetency = competency;
              }
            }
          });
      });
    }
  }

  ngOnChanges(): void {
    if (this.defaultCompetency) {
      this.internalSelectedCompetency = this.defaultCompetency;
    } else {
      this.internalSelectedCompetency = null;
    }

    this.getCompetencyList();
  }

  getCompetencyList() {
    this.competencyList = this.competencySearch.items.pipe(
      map((competencies) => {
        this.competencyLibrary = [...this.competencyLibrary, ...competencies];
        this.searchLoading = false;
        if (this.rubricId) {
          return competencies.filter(
            (competency) => competency.rubric_id === this.rubricId
          );
        }
        return competencies;
      })
    );
  }

  competencySelected(competency: CompetencyDTO) {
    if (this.isEventInternal) {
      return;
    }

    if (this.isMulti && competency) {
      if (!this.internalSelectedCompetencyList.includes(competency)) {
        this.isEventInternal = true; // Suppress additional triggers
        this.internalSelectedCompetencyList.push(competency);
        this.select.handleClearClick();
        this.isEventInternal = false; // Re-enable after operations
      }
    } else {
      this.internalSelectedCompetency = competency;
    }

    this.outputCompetencies();

    if (this.clearAfterSelection) {
      setTimeout(() => {
        this.select.unselect(this.select.selectedItems[0]); // Avoid infinite recursion
      });
    }
  }

  outputCompetencies() {
    if (this.isMulti) {
      this.selectedCompetencyList.emit(this.internalSelectedCompetencyList);
    } else {
      this.selectedCompetency.emit(this.internalSelectedCompetency);
    }
  }

  removeCompetencyFromList(competency: CompetencyDTO) {
    this.internalSelectedCompetencyList =
      this.internalSelectedCompetencyList.filter(
        (item) => item.id !== competency.id
      );
    this.outputCompetencies();
  }

  reset() {
    if (this.isMulti) {
      this.internalSelectedCompetencyList = [];
    } else {
      this.internalSelectedCompetency = null;
    }
    this.select.selectedItems.forEach((item) => {
      this.select.unselect(item);
    });
  }
}
