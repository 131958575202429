import { Component, Input } from '@angular/core';
import { IndicatorGroupPreviewDTO } from 'src/app/private/shared/dtos/competencies.dto';

@Component({
  selector: 'app-preview-leveled-competency-strand',
  templateUrl: './preview-leveled-competency-strand.component.html',
  styleUrls: ['./preview-leveled-competency-strand.component.scss'],
  standalone: false,
})
export class PreviewLeveledCompetencyStrandComponent {
  @Input() strand: IndicatorGroupPreviewDTO;

  @Input() level1name: string;

  @Input() level2name: string;

  @Input() level3name: string;

  @Input() level4name: string;

  public isCollapsed = false;
}
