/* eslint-disable max-classes-per-file */
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CoacheeListService } from 'src/app/private/shared/services/coachee-list/coachee-list.service';
import { CoacheeDTO } from 'src/app/private/shared/types/responses/coaching-log.responses';

import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import {
  SortableHeader,
  SortEvent,
} from '../../shared/directives/sortable-header.directive';
import { NewCoachingSessionUserDto } from '../../shared/dtos/coaching-session.dto';
import { CoachingLogService } from '../../shared/services/coaching-log/coaching-log.service';
import { FilterTableSearch } from './search-filter.pipe';
import { SelectableCoachee } from './selectable.coachee';

interface CoacheeListSortIcon {
  name: 'gray' | 'none';
  badges: 'gray' | 'none';
  celebrations: 'gray' | 'none';
  sessions: 'gray' | 'none';
  observations: 'gray' | 'none';
  nextSession: 'gray' | 'none';
  cohorts: 'gray' | 'none';
}

const compare = (v1: string | number, v2: string | number) =>
  /* eslint-disable-next-line no-nested-ternary */
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

const columnDataMap = (
  coachee: CoacheeDTO,
  column: string
): string | number => {
  switch (column) {
    case 'badges':
      return coachee.badgeCount || 0;
    case 'celebrations':
      return coachee.celebrationsCount || 0;
    case 'sessions':
      return coachee.sessionCount || 0;
    case 'observations':
      return coachee.observationsCount || 0;
    case 'nextSession':
      return coachee.nextSessionDate || '';
    case 'cohorts':
      return coachee.cohorts.length > 0 ? coachee.cohorts[0].tag : '';
    case 'name':
    default:
      return `${coachee.profile.first_name} ${coachee.profile.last_name}`;
  }
};

@Component({
  selector: 'app-coachee-list-page',
  templateUrl: './coachee-list-page.component.html',
  styleUrls: ['./coachee-list-page.component.scss'],
  providers: [FilterTableSearch],
  standalone: false,
})
export class CoacheeListPageComponent implements OnInit, OnDestroy {
  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
        this.sortIconTemp[<keyof CoacheeListSortIcon>header.sortable] = 'gray';
      } else {
        this.sortIconTemp[<keyof CoacheeListSortIcon>header.sortable] = 'none';
      }
    });

    if (direction === '' || column === '') {
      this.tableData = [...this.tableData].sort((a, b) =>
        compare(columnDataMap(a, 'name'), columnDataMap(b, 'name'))
      );

      this.sortIconTemp[<keyof CoacheeListSortIcon>column] = 'gray';
    } else {
      this.tableData = [...this.tableData].sort((a, b) => {
        const res = compare(
          columnDataMap(a, column as string),
          columnDataMap(b, column as string)
        );
        return direction === 'desc' ? res : -res;
      });
    }
  }

  user: User;

  dayOfWeek = new Date().toLocaleDateString('en-US', { weekday: 'long' });

  coachees: SelectableCoachee[];

  tableData: SelectableCoachee[];

  selectedCoacheesIds: number[] = [];

  attendeeUsers: UserDTO[] | null;

  attendeeUserProfiles: NewCoachingSessionUserDto[] | null;

  masterSelected = false;

  totalCoachees: number;

  isLoading = false;

  sortIconTemp: CoacheeListSortIcon = {
    name: 'gray',
    badges: 'gray',
    celebrations: 'gray',
    sessions: 'gray',
    observations: 'gray',
    nextSession: 'gray',
    cohorts: 'gray',
  };

  serverError = '';

  subs: Subscription[] = [];

  constructor(
    private coacheeListService: CoacheeListService,
    private coachingLogService: CoachingLogService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    if (!this.attendeeUserProfiles) {
      this.attendeeUserProfiles = [];
    }

    this.subs.push(
      this.coachingLogService.coachlogCreated.subscribe(() => {
        this.getCoachees();
      })
    );

    this.getCoachees();
  }

  getCoachees(): void {
    this.isLoading = true;
    this.selectedCoacheesIds = [];
    this.masterSelected = false;
    this.coacheeListService
      .fetchCoachees({ details: 1, per_page: 500 })
      .subscribe(
        (coachees) => {
          if (coachees) {
            this.coachees = coachees.items as SelectableCoachee[];
            this.tableData = this.coachees;
            this.totalCoachees = coachees?._meta.totalCount;
            this.getCheckedItemList();
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  searchCoachees(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.tableData = new FilterTableSearch().transform(
      this.coachees,
      searchTerm
    );
    // resetting other headers
    this.headers.forEach((header) => {
      header.direction = '';
      this.sortIconTemp[<keyof CoacheeListSortIcon>header.sortable] = 'gray';
    });
  }

  createNewLog() {
    if (this.selectedCoacheesIds.length > 0) {
      if (this.user?.id && this.coachees) {
        this.coachingLogService
          .createLog(this.user.id, this.selectedCoacheesIds, true)
          .subscribe();
      }
    }
  }

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target) {
      if (target.checked) {
        this.selectedCoacheesIds.push(parseInt(target.value));
      } else {
        const index = this.selectedCoacheesIds.findIndex(
          (value) => value === parseInt(target.value)
        );
        this.selectedCoacheesIds.splice(index, 1);
      }
      this.checkIfAllSelected();
      this.updateCoachlogAttendees();
    }
  }

  getCheckedItemList(): void {
    this.coachees.forEach((item: SelectableCoachee) => {
      item.isSelected = this.masterSelected;
    });
  }

  checkIfAllSelected() {
    this.masterSelected = this.coachees.every(
      (item: SelectableCoachee) => item.isSelected
    );
  }

  handleCheckAll(): void {
    this.selectedCoacheesIds = [];
    this.coachees.forEach((item: SelectableCoachee) => {
      item.isSelected = this.masterSelected;
      if (this.masterSelected) {
        this.selectedCoacheesIds.push(item.id);
      }
    });
    this.updateCoachlogAttendees();
  }

  unassignCoachees() {
    this.coacheeListService
      .unassignCoachees(this.selectedCoacheesIds, this.user.id)
      .subscribe({
        error: (error) => {
          this.serverError = error.error.message;
          setTimeout(() => {
            this.serverError = '';
          }, 4000);
        },
        next: () => {
          this.coacheesUpdated();
        },
      });
  }

  updateCoachlogAttendees() {
    this.attendeeUserProfiles = [];
    this.selectedCoacheesIds.forEach((coacheeId) => {
      let foundUser = false;
      const getUserProfile: NewCoachingSessionUserDto = {
        id: coacheeId,
        profile: {
          user_id: 0,
          first_name: '',
          last_name: '',
          title: '',
          color: '',
          photo: '',
        },
      };
      if (this.tableData?.length) {
        this.tableData.forEach((eachCoachee) => {
          if (coacheeId === eachCoachee.id) {
            foundUser = true;
            getUserProfile.profile = eachCoachee.profile;
          }
        });
      }

      if (foundUser) {
        if (this.attendeeUserProfiles) {
          if (!this.attendeeUserProfiles.includes(getUserProfile)) {
            this.attendeeUserProfiles.push(getUserProfile);
          }
        }
      }
    });
  }

  coacheesUpdated() {
    this.getCoachees();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
