import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-basic-progress-bar',
  templateUrl: './basic-progress-bar.component.html',
  styleUrl: './basic-progress-bar.component.scss',
  standalone: false,
})
export class BasicProgressBarComponent {
  @Input() progress: number;
}
