import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { FEATURE_FLAGS } from '../../../services/unleash/unleash.helpers';
import { UnleashService } from '../../../services/unleash/unleash.service';

@Component({
  selector: 'app-settings-nav',
  templateUrl: './settings-nav.component.html',
  styleUrls: ['./settings-nav.component.scss'],
  standalone: false,
})
export class SettingsNavComponent implements OnInit {
  user: User;

  env = EnvironmentService;

  @Input() districtCode: string;

  Roles = UserRole;

  customCollapsed = false;

  selectedPath = '';

  competencyBuilderEnabled = false;

  pdEnabled = false;

  proFormsEnabled = false;

  constructor(
    private store: Store,
    private router: Router,
    private featureFlagService: UnleashService
  ) {
    this.competencyBuilderEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.competencyBuilder
    );
    this.pdEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.pdManagement
    );
    this.proFormsEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.proForms
    );
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    switch (this.router.url) {
      case `/settings/districts/${this.districtCode}`:
        this.selectedPath = 'Properties';
        break;

      case '/settings':
        this.selectedPath = 'Districts';
        break;

      case `/settings/districts/${this.districtCode}/rosters`:
        this.selectedPath = 'Rosters';
        break;

      case `/settings/districts/${this.districtCode}/cohorts`:
        this.selectedPath = 'Cohorts';
        break;

      case `/settings/districts/${this.districtCode}/configuration`:
        this.selectedPath = 'Configuration';
        break;

      case `/settings/districts/${this.districtCode}/topics`:
        this.selectedPath = 'Topics';
        break;

      case `/settings/districts/${this.districtCode}/competencies`:
        this.selectedPath = 'Competencies';
        break;

      case `/settings/districts/${this.districtCode}/proforms`:
        this.selectedPath = 'ProForms';
        break;

      case `/settings/districts/${this.districtCode}/forms`:
        this.selectedPath = 'Forms';
        break;

      case `/settings/districts/${this.districtCode}/pd`:
        this.selectedPath = 'Training';
        break;

      default:
        this.selectedPath = 'DEFAULT';
        break;
    }

    if (
      new RegExp(`/settings/districts/${this.districtCode}/topics/\\d+$`).test(
        this.router.url
      )
    ) {
      this.selectedPath = 'Topics';
    }
  }

  toggleCustomCollapsed(): void {
    this.customCollapsed = !this.customCollapsed;
  }
}
