import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { SessionTimeoutModalComponent } from '../../shared/components/session-timeout-modal/session-timeout-modal.component';
import { NotificationDTO } from '../../shared/dtos/notifications.dto';
import { NotificationsService } from '../../shared/services/notifications/notifications.service';
import { SessionTimeoutService } from '../../shared/services/session-timeout/session-timeout.service';

@Component({
  selector: 'app-growelab-layout',
  templateUrl: './growelab-layout.component.html',
  styleUrls: ['./growelab-layout.component.scss'],
  standalone: false,
})
export class GrowelabLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('timeoutModal') timeoutModal: SessionTimeoutModalComponent;

  navExpanded = false;

  isImpersonatingUser = false;

  currentValue: boolean;

  notifications: NotificationDTO[];

  firstLogin = false;

  constructor(
    private store: Store,
    public sessionTimeoutService: SessionTimeoutService,
    private zone: NgZone,
    private notificationsService: NotificationsService,
    private authService: AuthService
  ) {
    this.isImpersonatingUser = this.store.selectSnapshot(
      (state) => state.user.impersonated
    );
  }

  ngOnInit(): void {
    this.authService.firstLogin.subscribe((res) => {
      if (res) {
        this.firstLogin = true;
        this.getNotifications();
        this.authService.firstLogin.next(false);
        this.authService.firstLogin.unsubscribe();
      }
    });
    if (StorageService.getItem('firstImpersonatingLogin') === 'true') {
      this.getNotifications();
      StorageService.removeItem('firstImpersonatingLogin');
    }
  }

  ngAfterViewInit(): void {
    this.sessionTimeoutService.showTimeoutModal.subscribe((show) => {
      if (show) {
        if (!this.timeoutModal?.modal?.isOpen) {
          this.zone.run(() => {
            this.timeoutModal.modal.open();
          });
        }
      } else if (this.timeoutModal?.modal?.isOpen) {
        this.timeoutModal.close();
      }
    });
  }

  toggleNav() {
    this.navExpanded = !this.navExpanded;
  }

  getNotifications() {
    this.notificationsService.getNotifications().subscribe((res) => {
      if (res.items.length > 0) {
        const loadingNotifications: NotificationDTO[] = [];
        res.items.forEach((item: NotificationDTO) => {
          item.markedRead = false;
          loadingNotifications.push(item);
        });
        this.notifications = loadingNotifications;
      }
    });
  }
}
