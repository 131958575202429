import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from 'src/app/common/state/user/user.model';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { AttendeeRubricDTO } from '../../../dtos/attendee-rubric.dto';
import { SmartCoachPreAssessmentDTO } from '../../../dtos/smartcoach.dto';
import { CoachingLogService } from '../../../services/coaching-log/coaching-log.service';
import { PreAssessmentService } from '../../../services/smart-coach/pre-assessment/pre-assessment.service';
import { SetGoalPayload } from '../../../types/payloads/set-goal.payload';
import { PreAssessmentQuestionResponsePayload } from '../../../types/payloads/smart-coach-pre-assessment.payloads';
import { ModalComponent } from '../../modals/modal/modal.component';

@Component({
  selector: 'app-pre-assessment-modal',
  templateUrl: './pre-assessment-modal.component.html',
  styleUrls: ['./pre-assessment-modal.component.scss'],
  standalone: false,
})
export class PreAssessmentModalComponent implements OnDestroy {
  @ViewChild('preAssessmentModal')
  preAssessmentModal: ModalComponent;

  @Input() user: User | null;

  @Input() rubricData: AttendeeRubricDTO[] = [];

  @Input() preAssessmentQuestionGroups: SmartCoachPreAssessmentDTO[] = [];

  @Input() coachlogId: number;

  @Output() readonly goalIsSet: EventEmitter<boolean> = new EventEmitter();

  currentQuestionIndex = 0;

  currentQuestionGroupIndex = 0;

  currentAnswer: BooleanNumber | null = null;

  formGroup = new FormGroup({
    'pre-assessment': new FormControl(),
  });

  modalIsOpen = true;

  constructor(
    private preAssessmentService: PreAssessmentService,
    private coachingLogService: CoachingLogService
  ) {}

  openModal() {
    this.modalIsOpen = true;
    if (this.preAssessmentModal) this.preAssessmentModal.open();
  }

  closeModal() {
    this.modalIsOpen = false;
    if (this.preAssessmentModal) {
      this.preAssessmentModal.close();
      this.goalIsSet.emit(true);
    }
  }

  nextQuestion() {
    this.submitQuestionResponse();
    if (
      this.currentQuestionIndex <
      this.preAssessmentQuestionGroups[this.currentQuestionGroupIndex]
        .preassessment_questions.length -
        1
    ) {
      if (this.currentAnswer === 0) {
        this.currentQuestionIndex += 1;
      } else {
        this.nextQuestionGroup();
      }
    } else {
      this.nextQuestionGroup();
    }
  }

  nextQuestionGroup() {
    this.currentQuestionIndex = 0;
    if (
      this.currentQuestionGroupIndex <
      this.preAssessmentQuestionGroups.length - 1
    ) {
      this.currentQuestionGroupIndex += 1;
      setTimeout(() => {
        this.preAssessmentModal.loadConfig();
      }, 500);
    } else {
      this.closeModal();
      this.currentQuestionGroupIndex = 0;
    }
  }

  // eslint-disable-next-line
  updateAnswer(e: any) {
    const currentValue = e.currentTarget.value;
    if (currentValue === 'Yes') {
      this.currentAnswer = 0;
    } else {
      this.currentAnswer = 1;
    }
  }

  submitQuestionResponse() {
    const currentGroup =
      this.preAssessmentQuestionGroups[this.currentQuestionGroupIndex];
    const currentQuestion =
      currentGroup.preassessment_questions[this.currentQuestionIndex];
    const egroweGroupId = currentQuestion.egrowe_indicator_group_id;

    if (this.currentAnswer !== null) {
      const assessmentPayload: PreAssessmentQuestionResponsePayload = {
        preassessment_question_id: currentQuestion.id,
        rubric_id: currentGroup.rubric_id,
        standard_id: currentGroup.standard_id,
        coachlog_id: this.coachlogId,
        set_goal: this.currentAnswer,
      };

      this.preAssessmentService
        .submitPreAssessmentResponse(
          egroweGroupId,
          assessmentPayload,
          currentQuestion.level
        )
        .subscribe(() => {
          if (assessmentPayload.set_goal === 1 && this.user) {
            const setGoalPayload: SetGoalPayload = {
              strandId: currentQuestion.egrowe_indicator_group_id,
              coachlogId: this.coachlogId,
              userId: this.user.id,
              competencyId: currentGroup.standard_id,
              goalLevel: currentQuestion.level,
            };
            this.coachingLogService.setGoal(setGoalPayload);
          }
          this.currentAnswer = null;
        });
      this.formGroup.reset();
    }
  }

  ngOnDestroy(): void {
    if (this.modalIsOpen) this.closeModal();
  }
}
