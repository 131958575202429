import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import {
  dateFromNgbDate,
  ngbDateStructFromDate,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { TodoType } from '../../enums/todo-type.enum';
import { TodoService } from '../../services/todo/todo.service';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { ModalComponent } from '../modals/modal/modal.component';
import {
  ActionItemAPIPackage,
  ActionItemDTO,
  ActionPlanFiltersAPIPackage,
  UpdateActionItemAPIPackage,
} from '../todos/card-todos/todo.dto';
import { UserSelectComponent } from '../user-select/user-select.component';
import {
  WysiwygEditorComponent,
  WysiwygEditorConfig,
} from '../wysiwyg-editor/wysiwyg-editor.component';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss'],
  standalone: false,
})
export class ActionPlanComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  actionItems: ActionItemDTO[] = [];

  actionItemForm: FormGroup;

  actionType: 'create' | 'edit';

  @Input() coachlogId: number;

  @ViewChild('actionPlanModal')
  actionPlanModal: ModalComponent;

  @ViewChild('editor')
  editor: WysiwygEditorComponent;

  @ViewChild('userSelect')
  userSelect: UserSelectComponent;

  @ViewChild('datepicker')
  datepicker: DatepickerComponent;

  selectedDate = new BehaviorSubject<number>(1620000000);

  selectedUserIds: number[] = [];

  isEmpty = true;

  logAttendees: UserDTO[] = [];

  currentActionItemUserId: number | undefined;

  editorConfig: WysiwygEditorConfig = {
    initialContent: '',
    editId: '',
  };

  constructor(
    public todoService: TodoService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getActionItems();
    this.resetForm();

    this.subs.push(
      this.todoService.actionTodos.subscribe((todos) => {
        if (todos) {
          this.actionItems = todos;
        }
      })
    );
  }

  resetForm() {
    this.currentActionItemUserId = undefined;
    if (this.actionPlanModal) {
      this.actionPlanModal.config.titleText = 'Action Item';
    }
    this.actionItemForm = this.formBuilder.group({
      title: ['', Validators.required],
    });
    if (this.editor) {
      this.editor.editorContent = '';
      this.editor.config.editId = '';
    }
    if (this.userSelect) {
      this.userSelect.reset();
      this.userSelect.isMulti = true;
      this.userSelect.disabled = false;
    }
    if (this.datepicker) {
      this.datepicker.selectedDate = ngbDateStructFromDate(new Date());
    }
    this.actionType = 'create';
  }

  checkIfEditorEmpty() {
    if (this.editor) {
      if (this.editor.editorContent === '') {
        this.isEmpty = true;
        return true;
      }
      this.isEmpty = false;
      return false;
    }
    this.isEmpty = true;
    return true;
  }

  getActionItems() {
    const filters: ActionPlanFiltersAPIPackage = {
      ref_table: 'egrowe_coachlog',
      ref_table_id: this.coachlogId,
    };
    this.todoService.getActionItems(filters);
  }

  selectUserChange(event: UserLiteDTO[] | null) {
    if (event) {
      this.selectedUserIds = event.map((user) => user.id);
    }
  }

  selectUserEdit(event: UserLiteDTO | null) {
    if (event) {
      this.selectedUserIds[0] = event.id;
    }
  }

  saveActionItem() {
    let payloadUser: number | undefined = this.currentActionItemUserId;
    if (this.editorConfig.editId) {
      if (this.selectedUserIds[0]) {
        payloadUser = this.selectedUserIds[0];
      }

      const payload: UpdateActionItemAPIPackage = {
        coachlog_id: this.coachlogId,
        content: this.actionItemForm.controls['title'].value,
        description: this.editor.editorContent,
        due_date:
          dateFromNgbDate(this.datepicker.selectedDate).getTime() / 1000,
        user_id: payloadUser,
      };
      this.todoService
        .updateActionItem(parseInt(this.editorConfig.editId), payload)
        .subscribe(() => {
          this.selectedUserIds = [];
          this.currentActionItemUserId = undefined;
        });
    } else {
      const payload: ActionItemAPIPackage = {
        coachlog_id: this.coachlogId,
        content: this.actionItemForm.controls['title'].value,
        description: this.editor.editorContent,
        due_date:
          dateFromNgbDate(this.datepicker.selectedDate).getTime() / 1000,
        type: TodoType.EGROWE,
        userTodoMeta: {
          ref_table: 'egrowe_coachlog',
          ref_table_id: this.coachlogId,
        },
        users: this.selectedUserIds,
      };
      this.todoService.createActionItem(payload);
      this.selectedUserIds = [];
    }
  }

  editActionItem(id: number) {
    this.actionType = 'edit';

    const actionItem = this.actionItems.find((item) => item.id === id);
    if (actionItem) {
      this.currentActionItemUserId = actionItem.userId;
      this.actionItemForm = this.formBuilder.group({
        title: [actionItem.content, Validators.required],
      });
      this.editor.editorContent = actionItem.description;
      this.userService.fetchUserById(actionItem.userId).subscribe((userDTO) => {
        if (userDTO) {
          this.userSelect.internalSelectedUserList = [];
          this.userSelect.isMulti = false;
          this.userSelect.internalSelectedUser = userDTO;
        }
      });
      this.datepicker.selectedDate = ngbDateStructFromUnixTimestamp(
        actionItem.dueDate
      );
      this.editorConfig.editId = id.toString();
      this.actionPlanModal.config.titleText = 'Edit Action Item';
      this.actionPlanModal.open();
    }
  }

  deleteActionItem(id: number) {
    this.todoService.deleteActionItem(id, this.coachlogId).subscribe();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
