<div class="card border-0 mb-4">
  <ng-template [ngIf]="!isLoading" [ngIfElse]="placeHolder">
    <div class="card-header pt-3">
      <h2
        id="suggestions-title"
        class="card-title float-start"
        (click)="triggerCollapse()">
        Smart Suggestions
        <i class="bi bi-caret-{{ isCollapsed ? 'up' : 'down' }}-fill"></i>
      </h2>
      <div class="card-tools float-end"></div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div
        class="card-body pt-0 overflow-auto"
        tabindex="0"
        role="group"
        aria-labelledby="suggestions-title"
        style="max-height: 18rem">
        <ng-container *ngIf="smartSuggestions.length > 0; else noSuggestions">
          <div
            *ngFor="let suggestion of smartSuggestions"
            class="mt-1 mb-2"
            @fadeOut>
            <div class="mb-4">{{ suggestion.suggestion }}</div>
            <div class="d-flex justify-content-between">
              <ng-container
                *ngIf="startsWithHash(suggestion.action); else link">
                <button
                  (click)="outputCustomAction(suggestion.action)"
                  class="btn btn-primary px-2 py-1">
                  {{ suggestion.cta }}
                </button>
              </ng-container>
              <ng-template #link>
                <a href="{{ suggestion.action }}">
                  <button class="btn btn-primary px-2 py-1">
                    {{ suggestion.cta }}
                  </button>
                </a>
              </ng-template>
              <button
                (click)="dismissSuggestion(suggestion.id)"
                class="btn btn-link">
                Dismiss
              </button>
            </div>
            <hr *ngIf="smartSuggestions.length > 1" class="mb-3" />
          </div>
        </ng-container>
        <ng-template #noSuggestions>
          <div class="text-center py-3 px-1">
            You're all caught up! 👏<br />Check back later for suggestions on
            your next steps.
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template #placeHolder>
    <div class="card-header pt-3">
      <div class="card-title placeholder-glow">
        <span class="placeholder col-5"></span>
      </div>
    </div>
    <div class="card-body pt-0" style="height: 18rem">
      <div class="card-text placeholder-glow">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
      <div class="card-text placeholder-glow mt-2">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-4"></span>
        <span class="placeholder col-6"></span>
        <span class="placeholder col-8"></span>
        <span class="placeholder col-4"></span>
      </div>
    </div>
  </ng-template>
</div>
