import { Component, Input } from '@angular/core';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import {
  CompetencyDTO,
  StrandDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';

@Component({
  selector: 'app-portfolio-leveled-competency-strand',
  templateUrl: './portfolio-leveled-competency-strand.component.html',
  styleUrls: [
    '../../../../competency/leveled-competency-strand/strand/leveled-competency-strand.component.scss',
  ],
  standalone: false,
})
export class PortfolioLeveledCompetencyStrandComponent {
  @Input() competency: CompetencyDTO;

  @Input() competencyTitle: string;

  @Input() strand: StrandDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() level1name = 'Conventional Culture';

  @Input() level2name = 'Collaborative Culture';

  @Input() level3name = 'Synergistic Culture';

  @Input() level4name = 'Innovative Culture';

  @Input() level1video: VideoDTO | null | undefined;

  @Input() level2video: VideoDTO | null | undefined;

  @Input() level3video: VideoDTO | null | undefined;

  @Input() level4video: VideoDTO | null | undefined;

  @Input() showOptions = true;

  public isCollapsed = false;

  goalLevel = 0;
}
