import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { ModalComponent } from '../../components/modals/modal/modal.component';
import {
  resourceLeveledListItemDTOToResourcePreviewDTO,
  resourceListItemDTOToResourcePreviewDTO,
  ResourcePreviewDTO,
} from '../../components/resources/resource-preview/resource-preview.dto';
import { SelectResourceComponent } from '../../components/resources/select-resource/select-resource.component';
import { CompetencyDTO } from '../../dtos/attendee-rubric.dto';
import {
  ResourceLeveledListItemDTO,
  ResourceListItemDTO,
} from '../../dtos/resources.dto';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { GetLeveledResourcesPayload } from '../../types/payloads/coaching-log.payloads';
import { ResourceSearchModalService } from '../resources/resource-search-modal.service';
import { resourceLeveledListItemDTOToResourceListItemDTO } from './coaching-log-resource.service.translators';
import { CoachingLogService } from './coaching-log.service';

export interface CoachingLogResourceSearchDTO {
  competencyId: number;
  coachlogId: number;
  optionId: number;
  userId: number;
  rubricId: number;
  levelData: {
    number: number;
    description: string;
  };
  modalTitle?: string;
}

@Injectable({ providedIn: 'root' })
export class CoachingLogResourceService {
  public config: CoachingLogResourceSearchDTO = {
    competencyId: 0,
    coachlogId: 0,
    optionId: 0,
    userId: 0,
    rubricId: 0,
    levelData: {
      number: 0,
      description: '',
    },
    modalTitle: '',
  };

  private subscriptions: Subscription[];

  private modalRef: ModalComponent;

  private modalDescriptionRef: ElementRef<HTMLDivElement>;

  private selectedResources: ResourceListItemDTO[] = [];

  public leveledResources: ResourceLeveledListItemDTO[] = [];

  public levelNumber: number;

  public resourcePreviews: ResourcePreviewDTO[] = [];

  private competencyData$ = select(CoachingLogState.getCompetency);

  public resourceRestored: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private resourceSearchModalService: ResourceSearchModalService,
    private coachingLogService: CoachingLogService,
    private apiService: APICoreService
  ) {}

  public addRefs(
    modal: ModalComponent,
    divEl: ElementRef<HTMLDivElement>,
    selectComponent: SelectResourceComponent
  ) {
    this.resourceSearchModalService.addModalRef(modal);
    this.modalDescriptionRef = divEl;
    this.resourceSearchModalService.addResourceSelectRef(selectComponent);
  }

  public open(setupDto: CoachingLogResourceSearchDTO) {
    this.selectedResources = [];
    this.resourcePreviews = [];
    this.subscriptions = [];
    this.config = { ...this.config, ...setupDto };
    this.setSubscriptions();
    this.configureModal();
    this.updateLeveledResources();
    this.modalRef.open();
  }

  public addResource(resource: ResourceListItemDTO) {
    if (!this.selectedResources.find((item) => item.id === resource.id)) {
      this.selectedResources.push(resource);
      this.resourcePreviews.push(
        resourceListItemDTOToResourcePreviewDTO(resource)
      );
      this.hasSelectedResources();
    }
  }

  public addLeveledResource(resource: ResourceLeveledListItemDTO) {
    if (!this.selectedResources.find((item) => item.id === resource.id)) {
      this.selectedResources.push(
        resourceLeveledListItemDTOToResourceListItemDTO(resource)
      );
      this.resourcePreviews.push(
        resourceLeveledListItemDTOToResourcePreviewDTO(resource)
      );
      this.hasSelectedResources();
    }
  }

  public removeResource(resourceId: number) {
    this.resourceRestored.next(resourceId);

    this.selectedResources = this.selectedResources.filter(
      (resource) => resource.id !== resourceId
    );
    this.resourcePreviews = this.resourcePreviews.filter(
      (resource) => resource.id !== resourceId
    );
    this.hasSelectedResources();
  }

  private setSubscriptions() {
    this.subscriptions.push(
      this.competencyData$
        .pipe(
          map((filterFn) =>
            (
              filterFn as (
                userId: number,
                competenctyId: number,
                coachlogId: number
              ) => CompetencyDTO
            )(
              this.config.userId,
              this.config.competencyId,
              this.config.coachlogId
            )
          )
        )
        .subscribe((rubric) => {
          if (rubric) {
            this.config.modalTitle = rubric.title;
          }
        })
    );

    const { selectResourceRef } = this.resourceSearchModalService;
    this.subscriptions.push(
      selectResourceRef?.afterSelection.subscribe((resource) => {
        this.addResource(resource);
      })
    );

    this.subscriptions.push(
      this.resourceSearchModalService.modalRef.isDismissing.subscribe(() => {
        this.unsetSubscriptions();
      })
    );

    this.subscriptions.push(
      this.resourceSearchModalService.modalRef.isClosing.subscribe(() => {
        this.selectedResources.forEach((resource) => {
          this.coachingLogService.addResourceToOption(
            resource,
            this.config.optionId
          );
        });
        this.unsetSubscriptions();
      })
    );
  }

  private unsetSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private configureModal() {
    this.modalRef = this.resourceSearchModalService.modalRef;

    const title =
      this.modalDescriptionRef.nativeElement.querySelector('strong');
    if (title) {
      title.innerText = `Level ${this.config.levelData.number}`;
    }
    const descriptionText =
      this.modalDescriptionRef.nativeElement.querySelector('span');
    if (descriptionText) {
      descriptionText.innerHTML = this.config.levelData.description;
    }
    this.modalRef.config = {
      ...this.modalRef.config,
      headerClass: [
        `bg-level-${this.config.levelData.number}`,
        'text-white',
        'text-center',
        'modal-header',
      ],
      closeButton: {
        classes: [
          'btn',
          'bg-grey',
          'btn-md',
          'text-white',
          'fw-800',
          'disabled',
        ],
        label: 'Submit',
      },
      closeButtonDisabled: true,
      titleText: this.config.modalTitle || 'Select Resources',
      options: {
        size: 'lg',
      },
    };
  }

  private getLeveledResources(payload: GetLeveledResourcesPayload) {
    return this.apiService.getRequest(
      'egrowe/suggest-option-resources-v2',
      payload
    );
  }

  public updateLeveledResources() {
    const payload: GetLeveledResourcesPayload = {
      egrowe_coachlog_id: this.config.coachlogId,
      egrowe_rubric_id: this.config.rubricId,
      egrowe_standard_id: this.config.competencyId,
      level: this.config.levelData.number,
    };
    this.getLeveledResources(payload).subscribe((response) => {
      if (response) {
        const uniqueIds: number[] = [];

        this.leveledResources = response.items.filter(
          (item: ResourceLeveledListItemDTO) => {
            if (!uniqueIds.includes(item.id)) {
              uniqueIds.push(item.id);
              return true;
            }
            return false;
          }
        );

        this.levelNumber = this.config.levelData.number;
      }
    });
  }

  hasSelectedResources() {
    if (this.selectedResources.length > 0) {
      this.modalRef.config = {
        closeButtonDisabled: false,
        ...this.modalRef.config,
        closeButton: {
          classes: [
            'btn',
            `bg-level-${this.config.levelData.number}`,
            'btn-md',
            'text-white',
            'fw-800',
            'text-uppercase',
          ],
          label: 'Submit',
        },
      };
    } else {
      this.modalRef.config = {
        ...this.modalRef.config,
        closeButtonDisabled: true,
        closeButton: {
          classes: [
            'btn',
            'btn-md',
            'bg-grey',
            'text-white',
            'fw-800',
            'disabled',
            'text-uppercase',
          ],
          label: 'Submit',
        },
      };
    }
  }
}
