import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-implementation-view-report-modal',
  templateUrl: './implementation-view-report-modal.component.html',
  styleUrl: './implementation-view-report-modal.component.scss',
  standalone: false,
})
export class ImplementationViewReportModalComponent {
  @Input() reportContent: string;

  @ViewChild('modal') modal: ModalComponent;
}
