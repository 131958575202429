import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.scss'],
  standalone: false,
})
export class GeneralHeaderComponent {
  @Input() title = '';
}
