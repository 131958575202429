import { Pipe, PipeTransform } from '@angular/core';
import { ActionItemStateModel } from 'src/app/private/shared/state/implementation-plan/implementation-plan.state';
import { SchoolYearDTO } from '../dtos/school.dto';

@Pipe({
  name: 'checkActionItemWithinYear',
  pure: true,
  standalone: false,
})
export class CheckActionItemWithinYearPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(
    actionItem: ActionItemStateModel,
    selectedSchoolYear: SchoolYearDTO | null
  ): boolean {
    if (!selectedSchoolYear) {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (actionItem.hidden = false));
      return true;
    }
    const selectedSchoolYearStartDate = new Date(selectedSchoolYear.start_date);
    const selectedSchoolYearEndDate = new Date(selectedSchoolYear.end_date);
    const actionItemDueDate = new Date(actionItem.due_date);
    const actionItemValid =
      actionItemDueDate >= selectedSchoolYearStartDate &&
      actionItemDueDate <= selectedSchoolYearEndDate;

    // eslint-disable-next-line no-return-assign
    setTimeout(() => (actionItem.hidden = !actionItemValid));

    return actionItemValid;
  }
}
