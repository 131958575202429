/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

import { TimezoneLinkBack } from '../utilities/time-helpers';

@Pipe({
  name: 'formatDate',
  standalone: false,
})
export class FormatDatePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  // skipcq: JS-0105
  transform(
    value: number,
    outputFormat?: string,
    tz?: TimezoneLinkBack
  ): string {
    if (outputFormat) {
      if (tz) {
        return DateTime.fromSeconds(value).setZone(tz).toFormat(outputFormat);
      }
      return DateTime.fromSeconds(value).toFormat(outputFormat);
    }
    if (tz) {
      return DateTime.fromSeconds(value).setZone(tz).toFormat('MMM d, yyyy');
    }
    return DateTime.fromSeconds(value).toFormat('MMM d, yyyy');
  }
}
