import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ECharts, EChartsOption, PieSeriesOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import {
  ReportApiResponse,
  ReportDataSeries,
  ReportRequestParams,
  ReportSqlRequestParams,
} from 'src/app/common/dtos/reports.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { ChartType } from '../../../enums/chart-type.enum';
import { ModalComponent } from '../../modals/modal/modal.component';
import {
  ChartGrid,
  chartDrilldownHelper,
  chartOptionsHelper,
  checkDefaultChartFilters,
  drilldownDefinition,
  getBlankChartOptions,
} from './chart-options-helper';
import { ReportSeriesHelper } from './series-helper';

type TooltipTrigger = 'axis' | 'item' | 'none' | undefined;

@Component({
  selector: 'app-report-controller',
  templateUrl: './report-controller.component.html',
  styleUrls: ['./report-controller.component.scss'],
  standalone: false,
})
export class ReportControllerComponent implements OnInit, OnChanges {
  @ViewChild('fullscreenModal') modal: ModalComponent;

  @Input() reportTitle = '';

  @Input() chartId: string;

  @Input() chartType: ChartType[] = [ChartType.Bar];

  @Input() cardHeight = '32rem';

  @Input() colorPalette: string[] = [];

  @Input() xAxisLabel = '';

  @Input() yAxisLabel = '';

  @Input() requestParams: ReportRequestParams;

  @Input() drilldownDimensions: string[];

  @Input() initialDrilldownFilter = '';

  @Input() percentage: boolean;

  @Input() yNameGap = 25;

  @Input() yAxisPadding = [0, 0, 0, 0];

  @Input() xNameGap = 30;

  @Input() tooltipTrigger: TooltipTrigger = 'axis';

  @Input() limitDataPresentation = 0;

  @Input() minPercent = 0;

  @Input() goal: number | null;

  @Input() yMax: number;

  @Input() isSqlRequest = false;

  @Input() sqlRequestParams: ReportSqlRequestParams;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tooltipFormatter: (params: any, response: any) => string;

  @Input() gridDimensions: ChartGrid = {
    top: '30px',
    left: '23px',
    right: '35px',
    bottom: '25px',
  };

  @Input() legendOptions = {
    show: false,
    bottom: 0,
    icon: 'circle',
    formatter: '',
    textStyle: {
      fontFamily: 'greycliff-cf',
    },
  };

  @Input() chartColor: string[] = [];

  @Input() replaceChartOptions = false;

  @Input() horizontalBarThreshold = 12;

  @Input() seriesSort: '' | 'alpha' | 'reverse-alpha' | 'numeric' = '';

  @Input() isMultiDistrict = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() readonly clickEvent = new EventEmitter<any>();

  @Output() readonly errorMessage = new EventEmitter<string>();

  @Output() readonly chartOptionOutput = new BehaviorSubject<EChartsOption>({});

  @Output() readonly apiResponseEvent = new EventEmitter<ReportApiResponse>();

  @Output() readonly origApiResponseEventOutput =
    new BehaviorSubject<ReportApiResponse>({
      datasets: [],
      labels: [],
      label_ids: [],
    });

  origApiResponse: ReportApiResponse;

  private drilldownIndex = 0;

  drilldownType: string[] = [];

  isAtDeepestDrilldown = true;

  eChartsInstance: ECharts;

  labelIds: number[][] = [];

  previousDatasetNames: string[] = [];

  fullScreenCardHeight = '86vh';

  apiResponse: ReportApiResponse[] = [];

  chartOptions: EChartsOption = {};

  chartTitles: string[] = [];

  isLoading = true;

  isFullscreen = false;

  dataId: string[] = ['main'];

  params: ReportRequestParams[] = [];

  drilldownLabel: string[] = [];

  hasData = false;

  spinnerOptions = {
    text: 'Loading...',
    textColor: '#27004b',
    fontFamily: 'greycliff-cf',
    maskColor: 'rgba(255, 255, 255)',
    zlevel: 10,
    fontSize: 24,
    showSpinner: false,
  };

  user: User;

  controllerInitialized = false;

  eChartType: ChartType.Bar | ChartType.Line | ChartType.Pie;

  constructor(private apiService: APICoreService, private store: Store) {}

  /**
   * ngOninit grabs the current user from Store.
   *
   * It then initiates initializeController().
   *
   * initializeController will run through getDashboardData() and handleResponse() succesively.
   */
  ngOnInit(): void {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.initializeController();
  }

  /**
   *
   * @param e {ECharts}
   *
   * Creates an eCharts instance.
   *
   * If the controler is Initialized or there is an apiResponse handleResponse will be called.
   */
  onChartInit(e: ECharts) {
    this.eChartsInstance = e;
    if (this.controllerInitialized && this.apiResponse.length) {
      this.handleResponse();
    }
  }

  /**
   *
   * @param change {SimpleChanges}
   *
   * Checks if the requestParams have changed.
   *
   * If requestParams changes intializeController will be called.
   */
  ngOnChanges(change: SimpleChanges) {
    if (
      (change['requestParams'] &&
        change['requestParams'].firstChange === false) ||
      (this.isSqlRequest &&
        change['sqlRequestParams'] &&
        change['sqlRequestParams'].firstChange === false)
    ) {
      this.initializeController();
    }
  }

  /**
   * Resets a dirty parameter and drilldown history to prepare for getDashboardData().
   *
   * chartType is also set to be used later.
   */
  initializeController() {
    this.controllerInitialized = true;
    this.clearHistory();
    if (!this.isSqlRequest) {
      if (this.chartId) {
        this.requestParams = { id: this.chartId, ...this.requestParams };
      }
      this.params = [this.requestParams];
      if (!this.requestParams.dimension) {
        this.requestParams.dimension = 'month_of_school_year';
      }
      if (this.drilldownDimensions) {
        this.drilldownType = [this.drilldownDimensions[0]];
        this.isAtDeepestDrilldown = false;
      }
    }
    this.chartTitles = [this.reportTitle];
    switch (this.chartType[0]) {
      case ChartType.Donut:
      case ChartType.Pie:
        this.fullScreenCardHeight = '50vh';
        this.eChartType = ChartType.Pie;
        break;
      case ChartType.Area:
        this.eChartType = ChartType.Line;
        break;
      case ChartType.HorizontalBar:
      case ChartType.HorizontalStackedBar:
        this.eChartType = ChartType.Bar;
        break;
      default:
        this.eChartType = this.chartType[0] as
          | ChartType.Bar
          | ChartType.Line
          | ChartType.Pie;
    }
    this.getDashboardData();
  }

  /**
   *
   * @param e {click event}
   *
   * Watches for series or graphic events to traverse drilldowns.
   *
   * non-zero valued series events will update and traverse the drilldown foward.
   *
   * Title or graphic events will run onClickBack(), traversing the drilldown backward.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick(e: any) {
    // only drilldown if drilldowns are enabled and is series and there is data
    if (
      e.componentType === 'series' &&
      e.value !== 0 &&
      this.drilldownDimensions
    ) {
      const requestParams = this.params[this.params.length - 1];
      if (!this.isAtDeepestDrilldown) {
        let drilldown = this.drilldownType[this.drilldownType.length - 1];
        if (!drilldown && requestParams.dimension) {
          drilldown = drilldownDefinition(requestParams.dimension as string);
        }
        if (!drilldown && this.drilldownDimensions) {
          drilldown = this.drilldownDimensions[this.drilldownIndex];
        }

        if (drilldown !== '') {
          const drilldownTitle = this.chartTitles[this.chartTitles.length - 1]
            ? `${this.chartTitles[this.chartTitles.length - 1]} | ${
                e.data.groupId
              }`
            : e.data.groupId;
          const addInitialFilter =
            this.chartTitles.length === 1 ? this.initialDrilldownFilter : '';
          const [drilldownParams, title] = chartDrilldownHelper(
            addInitialFilter,
            drilldown,
            requestParams,
            this.labelIds[this.labelIds.length - 1][e.dataIndex].toString(),
            drilldownTitle
          );
          const nextDrilldown =
            this.drilldownDimensions[this.drilldownIndex + 1];
          this.drilldownIndex += 1;

          this.dataId.push(
            this.apiResponse[this.apiResponse.length - 1].labels[e.dataIndex]
          );
          this.chartTitles.push(title);

          this.params.push(drilldownParams);
          if (!nextDrilldown) {
            this.isAtDeepestDrilldown = true;
          }
          this.drilldownType.push(nextDrilldown);
          this.getDashboardData();
        } else {
          this.isAtDeepestDrilldown = true;
        }
      }
    }
    if (e.componentType === 'title' || e.componentType === 'graphic') {
      this.onClickBack();
    }
  }

  /**
   * Fetches and prepares the data from api endpoint.
   * It also begins the loading process displayed in the chart loader.
   *
   * Datasets, labelIds, and labes are reversed from the api order.
   *
   * If successful, getDashboardData will run handleResponse().
   * If unsuccessful, an errorMessage will be emitted.
   */
  getDashboardData(): void {
    if (this.isSqlRequest && this.sqlRequestParams) {
      this.isLoading = true;
      this.apiService
        .getRequest('reporting/load-report', this.sqlRequestParams)
        .subscribe((value) => {
          this.apiResponse.push(value);
          if (this.eChartsInstance) {
            this.handleResponse();
          }
          if (value.valid === false) {
            const errorMessage = value.error_messages[0];
            this.errorMessage.emit(errorMessage);
          }
          if (
            value.datasets.length > 0 &&
            !(value.datasets[0].data as number[]).every(
              (num: number) => num === 0
            )
          ) {
            this.hasData = true;
          }
        });
    } else {
      const params = checkDefaultChartFilters(
        this.params[this.params.length - 1],
        this.user
      );
      this.isLoading = true;
      this.apiService
        .getRequest('reporting/custom', params)
        .subscribe((value) => {
          if (params.dimension !== 'month_of_school_year') {
            if (value.datasets.length > 0 && value.datasets[0].data) {
              if (typeof value.datasets[0].data[0] === 'number') {
                // single series
                value.datasets[0].data.reverse();
              } else {
                // multi series
                value.datasets[0].data.forEach(
                  (dataArray: ReportDataSeries) => {
                    dataArray.data.reverse();
                    // Total Session hotfix to change name to present or absent
                    if (dataArray.name === 'Yes') {
                      dataArray.name = 'Present';
                    }
                    if (dataArray.name === 'No') {
                      dataArray.name = 'Absent';
                    }
                  }
                );
              }
            }
            // sort labels into correct order
            if (value.labels) {
              value.labels.reverse();
            }

            if (value.label_ids) {
              value.label_ids.reverse();
            }

            if (value.datasets[0]?.dataDenominator) {
              value.datasets[0].dataDenominator =
                value.datasets[0].dataDenominator.reverse();
            }
            if (value.datasets[0]?.dataNumerator) {
              value.datasets[0].dataNumerator =
                value.datasets[0].dataNumerator.reverse();
            }
          }

          if (this.previousDatasetNames.length === 0) {
            if (value.datasets.length > 0 && value.datasets[0].data) {
              if (typeof value.datasets[0].data[0] !== 'number') {
                this.previousDatasetNames = value.datasets[0].data.map(
                  (dataset: ReportDataSeries) => dataset.name
                );
              }
            }
          }

          this.apiResponse.push(value);
          this.apiResponseEvent.emit(value);
          this.origApiResponse = deepCopy(value);

          const pieChartItemsGreater: {
            value: number;
            label: string;
            labelId: string;
          }[] = [];

          if (this.minPercent > 0) {
            let totalCount = 0;
            if (value.datasets.length > 0 && value.datasets[0].data) {
              let i = 0;
              value.datasets[0].data.forEach((count: number) => {
                totalCount += count;
                i += 1;
              });
              if (totalCount > 0) {
                i = 0;
                value.datasets[0].data.forEach((count: number) => {
                  let labelId = value.label_ids[i];
                  if (!labelId) {
                    labelId = 0;
                  }
                  if ((count / totalCount) * 100 >= this.minPercent) {
                    pieChartItemsGreater.push({
                      value: count,
                      label: value.labels[i],
                      labelId: labelId.toString(),
                    });
                  }
                  i += 1;
                });
                pieChartItemsGreater.sort((a, b) => b.value - a.value);

                value.datasets[0].data = [];
                value.labels = [];
                value.label_ids = [];
                pieChartItemsGreater.forEach((item) => {
                  value.datasets[0].data.push(item.value);
                  value.labels.push(item.label);
                  value.label_ids.push(item.labelId);
                });
              }
            }
          }

          if (this.eChartsInstance) {
            this.handleResponse();
          }
          if (value.valid === false) {
            const errorMessage = value.error_messages[0];
            this.errorMessage.emit(errorMessage);
          }
          if (value.datasets.length > 0 || value.series?.length > 0) {
            this.hasData = true;
          }
        });
    }
  }

  /**
   * Creates the chartOptions for eCharts.
   * This is where the main customization work is done.
   *
   * Has SEVERAL helper functions and is best read individually.
   *
   * If no data is present, a blank chart option object will be created.
   *
   * Ends the loading process on completion.
   */
  handleResponse() {
    const response = this.apiResponse[this.apiResponse.length - 1];
    let chartOptions: EChartsOption;
    if (
      (response.datasets.length !== 0 &&
        !(response.datasets[0].data as number[]).every(
          (num: number) => num === 0
        )) ||
      (response.series && response.series.length !== 0)
    ) {
      this.labelIds.push(response.label_ids);
      chartOptions = chartOptionsHelper(this.chartType[0], {
        aria: {
          enabled: true,
          label: {
            data: { maxCount: 12 },
          },
        },
        title: {
          text: this.chartTitles[this.chartTitles.length - 1],
          textStyle: {
            fontSize: 18,
            fontWeight: 'lighter',
          },
          top: 'top',
          left: 0,
          triggerEvent: true,
          show: this.chartTitles.length > 1,
        },
        cursor: this.isAtDeepestDrilldown ? 'default' : 'pointer',
        tooltip: {
          textStyle: { color: 'black', fontFamily: 'greycliff-cf' },
          position: 'top',
          trigger: this.tooltipTrigger,
          formatter: this.tooltipFormatter
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (params: any) => this.tooltipFormatter(params, response)
            : '',
          valueFormatter:
            this.percentage && !this.tooltipFormatter
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (value: any) => `${(value * 100).toFixed(0)}%`
              : undefined,
        },
        graphic: {
          type: 'group',
          top: 5,
          right: 0,
          ignore: !(this.chartTitles.length > 1),
          onclick: this.handleClick.bind(this),
          children: [
            {
              type: 'image',
              style: {
                image: 'assets/report/back-arrow.svg',
                fill: 'red',
                width: 20,
                height: 20,
              },
            },
            {
              type: 'text',
              top: 3,
              right: 8,
              style: {
                text: 'Back',
                fontSize: 18,
                fontWeight: 'lighter',
              },
              textConfig: {
                position: 'right',
                offset: [50, 50],
              },
            },
          ],
        },
        grid: this.gridDimensions,
        color: this.colorPalette,
        dataZoom: this.createDataZoom(response, this.chartType[0]),
        ...(this.eChartType !== ChartType.Pie && {
          yAxis: {
            name: this.yAxisLabel,
            nameGap: this.yNameGap,
            nameTextStyle: {
              fontFamily: 'greycliff-cf',
              padding: this.yAxisPadding,
            },
            max: this.percentage
              ? 1
              : this.setMaxHorizontalLabel(response, this.goal),
            axisLabel: {
              fontFamily: 'greycliff-cf',
              // converts values to percentage based on input
              ...(this.percentage && {
                // eslint-disable-next-line func-names
                formatter: (val: number) => `${val * 100}%`,
              }),
            },
          },
        }),
        ...(this.eChartType !== ChartType.Pie && {
          xAxis: {
            name: this.xAxisLabel,
            nameGap: this.xNameGap,
            nameTextStyle: {
              fontFamily: 'greycliff-cf',
            },
            axisLabel: {
              fontFamily: 'greycliff-cf',
            },
            data: response.labels,
          },
        }),
        ...(this.eChartType === ChartType.Pie && {
          xAxis: { show: false },
        }),
        series: ReportSeriesHelper(
          response,
          this.chartType,
          this.eChartType,
          this.isAtDeepestDrilldown,
          this.chartColor,
          this.dataId[this.dataId.length - 1],
          this.previousDatasetNames,
          this.seriesSort,
          this.goal,
          this.isMultiDistrict
        ),
        legend: this.legendOptions,
      });

      if (this.minPercent === 0) {
        if (
          this.eChartType === ChartType.Pie &&
          this.limitDataPresentation !== 0 &&
          !this.isFullscreen &&
          Array.isArray(chartOptions?.series)
        ) {
          (chartOptions.series[0] as PieSeriesOption).data = (
            (chartOptions.series[0] as PieSeriesOption).data as number[]
          ).slice(0, this.limitDataPresentation);
        }
      }

      if (this.minPercent === 0) {
        this.chartOptionOutput.next(chartOptions);
      } else {
        this.origApiResponseEventOutput.next(this.origApiResponse);
        setTimeout(() => {
          this.chartOptionOutput.next(chartOptions);
        }, 500);
      }
      // 0 timeout to wait for the modal to transition in/out of fullscreen else the chart draws blank b/c no dimensions
      setTimeout(() => {
        // Clear existing chart styles (bar width, etc.) before setting new options
        this.eChartsInstance.clear();
        this.eChartsInstance.setOption(chartOptions, this.replaceChartOptions);
      }, 0);
    } else {
      this.chartOptionOutput.next(getBlankChartOptions(this.gridDimensions));
      this.eChartsInstance.setOption(
        getBlankChartOptions(this.gridDimensions),
        true
      );
    }
    this.isLoading = false;
  }

  /**
   *
   * @param response
   * @param chartType
   * @returns {object}
   * returns dataZoom object
   *
   * Creates the sliders for horizontal bar charts.
   * The amount of bar charts displayed at once is created dynamically based on horizontalBarThreshold
   */
  createDataZoom(response: ReportApiResponse, chartType: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let fullScreenHorizontalZoom: Array<any> = [];

    if (['horizontal-bar', 'stacked-horizontal-bar'].includes(chartType)) {
      if (
        response.labels.length > this.horizontalBarThreshold &&
        this.isFullscreen
      ) {
        fullScreenHorizontalZoom = [
          {
            yAxisIndex: 0,
            type: 'slider',
            startValue: response.labels[response.labels.length - 1],
            endValue:
              response.labels[
                response.labels.length - this.horizontalBarThreshold
              ],
            showDetail: false,
            zoomLock: true,
            brushSelect: false,
            width: '15',
            backgroundColor: '#dfd0f3',
            borderColor: '#dfd0f3',
            borderRadius: 2,
            fillerColor: 'white',
            handleSize: 33,
            handleStyle: {
              borderColor: '#dfd0f3',
            },
            emphasis: {
              handleStyle: {
                borderColor: '#dfd0f3',
              },
            },
          },
        ];
      }
    }

    return fullScreenHorizontalZoom;
  }

  /**
   *
   * @param response
   * @returns {number}
   *
   * Returns a number that is 5 percent greater (to the nearest 5)
   * than the maximum total of all series.
   */
  setMaxHorizontalLabel(response: ReportApiResponse, goal?: number | null) {
    let maxHorizontalLabel: string | number = 'dataMax';

    if (['horizontal-bar'].includes(this.chartType[0])) {
      maxHorizontalLabel = Math.max(...(response.datasets[0].data as number[]));
      maxHorizontalLabel = Math.round((maxHorizontalLabel * 1.05) / 5) * 5;
    }

    // Stacked charts have
    if (['stacked-horizontal-bar'].includes(this.chartType[0])) {
      const responseDataArray = response.datasets[0].data.map(
        (item) => (item as ReportDataSeries)?.data
      );

      const sumArray: number[] = responseDataArray.reduce(
        (accumulator, currentValue) =>
          accumulator.map((value, index) => value + currentValue[index]),
        new Array(responseDataArray[0].length).fill(0)
      );

      maxHorizontalLabel = Math.max(...sumArray);
      maxHorizontalLabel = Math.ceil((maxHorizontalLabel * 1.05) / 5) * 5;
    }

    // If goal is greater than maxHorizontalLabel, set maxHorizontalLabel to goal
    if (goal) {
      if (response.series && response.series.length > 0) {
        let maxValue = 0;
        response.series.forEach((series: ReportDataSeries) => {
          const seriesData = series.data as number[];
          seriesData.forEach((value: number) => {
            if (value > maxValue) {
              maxValue = value;
            }
          });
        });
        maxHorizontalLabel = maxValue;
      } else {
        maxHorizontalLabel = Math.max(
          ...(response.datasets[0].data as number[])
        );
      }
      if (goal > Number(maxHorizontalLabel)) {
        maxHorizontalLabel = goal;
      }
    }
    return maxHorizontalLabel;
  }

  /**
   * Traverses drilldown arrays by removing the latest entry.
   *
   * This allows the drilldown to move back by one position.
   */
  onClickBack() {
    if (this.apiResponse.length !== 1) {
      this.isAtDeepestDrilldown = false;
      this.drilldownType.pop();
      this.chartTitles.pop();
      this.apiResponse.pop();
      this.labelIds.pop();
      this.params.pop();
      this.dataId.pop();
      this.drilldownIndex -= 1;
      this.handleResponse();
    }
  }

  /**
   * Resets dirty drilldown history.
   */
  clearHistory() {
    this.isAtDeepestDrilldown = true;
    this.drilldownType = [];
    this.chartTitles = [];
    this.apiResponse = [];
    this.labelIds = [];
    this.params = [];
    this.dataId = [];
    this.drilldownIndex = 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickEventEmitter(event: any) {
    this.clickEvent.emit(event);
  }

  /**
   * Disposes chartInstance and opens modal with new chartInstance
   */
  showFullscreen() {
    this.eChartsInstance.dispose();
    this.modal.open();
    this.isFullscreen = true;
    this.handleResponse();
  }

  /**
   * Disposes chartInstance and closes modal
   */
  closeFullscreen() {
    this.eChartsInstance.dispose();
    this.isFullscreen = false;
    this.handleResponse();
  }
}
