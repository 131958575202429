import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { PlansService } from '../../shared/services/plans/plans.service';
import {
  PlanDashboardItemAPIResponse,
  PlanDetailsItemAPIResponse,
} from '../../shared/types/responses/plan.responses';

@Component({
  selector: 'app-implementation-plan-page',
  templateUrl: './implementation-plan-page.component.html',
  styleUrl: './implementation-plan-page.component.scss',
  standalone: false,
})
export class ImplementationPlanPageComponent implements OnInit {
  planId: number;

  planDashboardInfo: PlanDashboardItemAPIResponse;

  planDetails: PlanDetailsItemAPIResponse;

  forbidden = false;

  user: User | null = null;

  constructor(
    private route: ActivatedRoute,
    private plansService: PlansService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['planId']) {
        this.planId = parseInt(params['planId']);
        this.plansService.getPlanDashboard(this.planId).subscribe({
          error: (error) => {
            if (error.status === 403) {
              this.forbidden = true;
            }
          },
          next: (details) => {
            if (details.item) {
              this.planDashboardInfo = details.item;
              this.planDashboardInfo.start_date = this.formatDate(
                this.planDashboardInfo.start_date
              );
              this.planDashboardInfo.end_date = this.formatDate(
                this.planDashboardInfo.end_date
              );
            }
          },
        });
      }
    });
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatDate(date: string) {
    // date comes in YYYY-MM-DD format
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  }
}
