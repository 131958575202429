import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription, map } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { videoDTOFromAPIResponse } from 'src/app/private/shared/helpers/translators/video.translators';
import {
  CompetencyFiltersDTO,
  CompetencyListItemDTO,
  CompetencyUserDTO,
} from '../../../dtos/competencies.dto';
import { CoachingLogPusherService } from '../../../services/coaching-log/coaching-log-pusher.service';
import { VideoModalComponent } from '../../modals/video-modal/video-modal.component';

@Component({
  selector: 'app-competencies-summary',
  templateUrl: './competencies-summary.component.html',
  styleUrls: ['./competencies-summary.component.scss'],
  standalone: false,
})
export class CompetenciesSummaryComponent implements OnInit, OnDestroy {
  @Input() competencyId = 0;

  @Input() userId = 0;

  @Input() isSelectable = false;

  @Input() isSelected = false;

  @Input() showVideo = false;

  @Output() readonly isSelectedEvent = new EventEmitter<boolean>();

  @ViewChild('videoModal')
  videoModal: VideoModalComponent;

  subs: Subscription[] = [];

  isLoading = false;

  competency: CompetencyListItemDTO | null;

  user: CompetencyUserDTO | undefined;

  competencyTitle: string | undefined;

  apiUrl: string;

  iconUrl: string;

  competencyVideo: VideoDTO;

  constructor(
    private apicoreService: APICoreService,
    private coachingLogPusherService: CoachingLogPusherService
  ) {
    this.apiUrl = EnvironmentService.apiUrl();
  }

  ngOnInit(): void {
    this.getCompetencies();

    this.subs.push(
      this.coachingLogPusherService.indicatorsUpdatedNotifier.subscribe(() => {
        this.getCompetencies();
      })
    );
  }

  toggleSelectIcon() {
    this.isSelected = !this.isSelected;
    this.isSelectedEvent.emit(this.isSelected);
  }

  // This function will change to be passed in by the parent at a later date
  // A more general sulution must be found when the project is furether specified
  getCompetencies() {
    this.isLoading = true;

    this.getCompetencyOverview().subscribe(
      (response: CompetencyListItemDTO[]) => {
        const competencyFound = response.find(
          (item) => item?.id === this.competencyId
        );
        if (competencyFound) {
          this.competency = competencyFound;
          if (this.competency.overviewVideo) {
            this.competencyVideo = videoDTOFromAPIResponse(
              this.competency.overviewVideo
            );
          }
          this.competencyTitle = this.competency?.title;
          this.user = this.competency?.users.find(
            (user) => user.id === this.userId
          );

          this.iconUrl = this.apiUrl + this.competency.icon;
        }
        this.isLoading = false;
      }
    );
  }

  getCompetencyOverview() {
    const filterFreeze: CompetencyFiltersDTO = {
      competency_ids: [this.competencyId],
      user_ids: [this.userId],
    };

    return this.apicoreService
      .getRequest('growelab/competencies/overview', filterFreeze)
      .pipe(
        map((results) => {
          let competencyList: [];
          if (results.items.competencies) {
            competencyList = results.items.competencies;
          } else {
            competencyList = [];
          }
          return competencyList;
        })
      );
  }

  playVideo() {
    this.videoModal.open();
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
