import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import {
  GetPublicPlanAPIResponse,
  PublicPlanAPIResponse,
} from './sharedplan.responses';

@Component({
  selector: 'app-plans-public-page',
  templateUrl: './plans-public-page.component.html',
  styleUrl: './plans-public-page.component.scss',
  standalone: false,
})
export class PlansPublicPageComponent implements OnInit {
  @ViewChild('progressBar') progressBar: ElementRef;

  apiUrl: string;

  isLoading = true;

  districtCode: string;

  slug: string;

  planData: PublicPlanAPIResponse;

  serverError: string;

  constructor(
    private route: ActivatedRoute,
    private apiService: APICoreService
  ) {
    this.apiUrl = EnvironmentService.apiUrl();
  }

  ngOnInit(): void {
    this.route.params.subscribe((url) => {
      this.districtCode = url['district-code'];
      this.slug = url['slug'];
      if (this.districtCode && this.slug) {
        this.loadData();
      }
    });
  }

  loadData() {
    this.isLoading = true;
    this.apiService
      .getRequest(
        `growelab/public/implementation-plan/${this.districtCode}/${this.slug}`
      )
      .subscribe({
        error: (error) => {
          this.isLoading = false;
          this.serverError = error.error.message;
        },
        next: (res: GetPublicPlanAPIResponse) => {
          if (res) {
            this.isLoading = false;
            this.planData = res.item;
          }
        },
      });
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatProgress(progress: number) {
    return Math.round(progress * 10000) / 100;
  }
}
