import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription, take } from 'rxjs';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { APILoadingStatus } from 'src/app/common/types/types';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { checkPresentAttendee } from 'src/app/common/utilities/session-helpers';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import { TopicDTO } from 'src/app/private/shared/components/topic-select/topic-select.component';
import {
  AttendeeRubricDTO,
  CompetencyDTO,
  CompetencyListItemDTO,
} from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CoachingSessionDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import { PusherAttendeeDTO } from 'src/app/private/shared/dtos/pusher-user.dto';
import { SmartCoachPreAssessmentDTO } from 'src/app/private/shared/dtos/smartcoach.dto';
import {
  checkSessionDataType,
  checkSmartCoachAuthorization,
  SessionPermissions,
} from 'src/app/private/shared/helpers/coachee-log.utilities';
import { CoachingLogResourceService } from 'src/app/private/shared/services/coaching-log/coaching-log-resource.service';
import { CoachingLogService } from 'src/app/private/shared/services/coaching-log/coaching-log.service';
import { CoachDialogService } from 'src/app/private/shared/services/smart-coach/coach-dialog/coach-dialog.service';
import { PreAssessmentService } from 'src/app/private/shared/services/smart-coach/pre-assessment/pre-assessment.service';
import { SmartSessionService } from 'src/app/private/shared/services/smart-coach/smart-session.service';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import {
  FetchAttendeeRubrics,
  FetchSessionData,
} from 'src/app/private/shared/state/coaching-log/coaching-log.actions';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { CompetenciesAPIResponse } from 'src/app/private/shared/types/responses/coaching-log.responses';

@Component({
  selector: 'app-smart-coach-log-page',
  templateUrl: './smart-coach-log-page.component.html',
  styleUrls: ['./smart-coach-log-page.component.scss'],
  standalone: false,
})
export class SmartCoachLogPageComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  @ViewChild('smartOptionParentModal')
  smartOptionParentModal: ModalComponent;

  attendeeRubrics$ = select(CoachingLogState.getAttendeeRubricsData);

  rubricData: AttendeeRubricDTO[] = [];

  user: User;

  loggedInAsAttendee = false;

  sessionData$ = select(CoachingLogState.getSessionData);

  coachLogStatus$: Observable<APILoadingStatus> = select(
    CoachingLogState.getCoachLogStatus
  );

  coachLogStatus: APILoadingStatus;

  rubricLoadingStatus$: Observable<boolean> = select(
    CoachingLogState.getRubricLoadingStatus
  );

  rubricLoadingStatus = false;

  sessionData: CoachingSessionDTO | null;

  sessionId: number;

  logPermissions: SessionPermissions;

  Permissions = SessionPermissions;

  currentScreen = 'selectCompetencies';

  competencySelectionLoading = false;

  competencySelected = false;

  competencySelections: CompetencyListItemDTO[] = [];

  attendeeIds: number[] = [];

  selectedAttendee: UserDTO;

  assignedRubrics: CompetenciesAPIResponse[] = [];

  topics: TopicDTO[] = [];

  attendeeUserDTOs: UserDTO[] = [];

  hasPresentAttendee = false;

  showingOptionModal = false;

  preAssessmentQuestionGroups: SmartCoachPreAssessmentDTO[] = [];

  preAssessmentQuestionGroupsLoaded = false;

  allGoalsSet = true;

  pusherAttendees: PusherAttendeeDTO[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private featureFlagService: UnleashService,
    public coachingService: CoachingLogService,
    public coachlogResourceService: CoachingLogResourceService,
    private smartCoachDialogService: CoachDialogService,
    private smartSessionService: SmartSessionService,
    private preAssessmentService: PreAssessmentService
  ) {
    this.user = store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.coachLogStatus$.subscribe((status) => {
      if (status) {
        this.coachLogStatus = status;
      }
    });
    if (!this.featureFlagService.isEnabled(FEATURE_FLAGS.mySmartCoach)) {
      this.router.navigate(['/dashboard']);
    }
    this.subs.push(
      this.sessionData$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => CoachingSessionDTO)(this.sessionId)
          )
        )
        .subscribe((data) => {
          if (data) {
            this.sessionData = data;
            checkSessionDataType(data, 'coaching', this.router);
            this.logPermissions = checkSmartCoachAuthorization(data, this.user);
            this.attendeeUserDTOs = this.sessionData.attendees
              .filter((attendee) => attendee.present)
              .map((attendee) => attendee.user);
            this.attendeeIds = this.attendeeUserDTOs.map(
              (attendee) => attendee.id
            );
            if (!this.selectedAttendee) {
              if (this.attendeeUserDTOs && this.attendeeUserDTOs.length > 0) {
                this.selectedAttendee = this.attendeeUserDTOs[0];
              }
            }
            this.coachlogResourceService.config.modalTitle = data?.title;
            if (this.sessionData) {
              this.hasPresentAttendee = checkPresentAttendee(this.sessionData);
            } else {
              this.hasPresentAttendee = false;
            }
            if (this.sessionData.currentUsersRole === 'coachee') {
              this.loggedInAsAttendee = true;
            }
          }
        })
    );
    this.subs.push(
      this.rubricLoadingStatus$.subscribe((status) => {
        this.rubricLoadingStatus = status;
      })
    );

    this.route.params.subscribe((url) => {
      take(1);
      this.sessionId = parseInt(url['logId']);
      this.coachingService.currentPage = 'smart-coach';
      this.store.dispatch(new FetchSessionData(this.sessionId));
      this.store.dispatch(new FetchAttendeeRubrics(this.sessionId));
    });
    this.subs.push(
      this.attendeeRubrics$
        .pipe(
          map((filterFn) =>
            (filterFn as (id: number) => AttendeeRubricDTO[])(this.sessionId)
          )
        )
        .subscribe((data) => {
          if (data && data !== this.rubricData) {
            this.rubricData = data;
            setTimeout(() => {
              this.getCurrentCompetencyData();
            }, 500);
          }
        })
    );
    this.subs.push(
      this.smartSessionService.showOptionModal.subscribe((showOptionModal) => {
        this.showingOptionModal = showOptionModal;
      })
    );

    if (this.router.url.endsWith('growth')) {
      this.currentScreen = 'groweCoaching';
    }

    if (this.router.url.endsWith('summary')) {
      this.currentScreen = 'sessionSummary';
    }

    this.subs.push(
      this.coachingService.attendancePusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );

    this.subs.push(
      this.coachingService.logPusherService.attendeeRostersNotifier.subscribe(
        () => {
          this.getAttendees();
        }
      )
    );
  }

  getAttendees() {
    const attendeesOnPage =
      this.coachingService.attendancePusherService.attendeeRosters[
        `${this.sessionId}-smart-coach`
      ];

    if (attendeesOnPage && attendeesOnPage.length > 0) {
      this.pusherAttendees =
        this.coachingService.logPusherService.attendeeRosters[
          this.sessionId
        ].map((sessionAttendee) => {
          const samePage = attendeesOnPage.some(
            (pageAttendee) => pageAttendee.id === sessionAttendee.id
          );
          return {
            avatar: sessionAttendee.avatar,
            samePage,
            id: sessionAttendee.id,
          };
        });
    }
  }

  triggerDialog() {
    this.smartCoachDialogService.setQueue(4);
    this.smartCoachDialogService.getDialogMessage(
      'r-nextButton-workedOnStandard',
      'msc-session',
      1
    );
    this.smartCoachDialogService.getDialogMessage(
      'r-start-neverWorkedOnStandard',
      'msc-session',
      2
    );
    this.smartCoachDialogService.getDialogMessage(
      'r-pastCheckRealityDialog-hasSelfAssessments',
      'msc-session',
      3
    );
    this.smartCoachDialogService.getDialogMessage(
      'r-uploadMoreEvidenceAndSelfReflect-earnedGI',
      'msc-session',
      4
    );
  }

  triggerOptionDialog() {
    this.smartCoachDialogService.setQueue(3);
    this.smartCoachDialogService.getDialogMessage(
      'o-start-',
      'msc-session-option',
      1
    );
    this.smartCoachDialogService.getDialogMessage(
      'w-start-',
      'msc-session-option',
      2
    );
    this.smartCoachDialogService.getDialogMessage(
      'e2-start-',
      'msc-session-option',
      3
    );
  }

  handleCompetencySelectOutput(hasSelection: boolean) {
    this.competencySelected = hasSelection;
  }

  handleCompetencySelectLoading(loading: boolean) {
    this.competencySelectionLoading = loading;
  }

  getCurrentCompetencyData() {
    const attendeeCompetencyDictionary: {
      [key: number]: number[];
    } = {};
    if (this.sessionData) {
      this.attendeeUserDTOs.forEach((attendee) => {
        attendeeCompetencyDictionary[attendee.id] = [];
      });
      this.rubricData.forEach((attendee) => {
        attendee.rubrics.forEach((rubric) => {
          rubric.competencyGroups.forEach((group) => {
            if (this.preAssessmentQuestionGroupsLoaded) {
              group.competencies.forEach((competency) => {
                this.competencySelections.forEach((selection) => {
                  if (selection.id === competency.id) {
                    attendeeCompetencyDictionary[attendee.userId].push(
                      competency.id
                    );
                  }
                });
              });
            } else {
              group.competencies.forEach((competency, index) => {
                if (this.logPermissions === this.Permissions.EDIT) {
                  if (group.competencies.length - 1 === index) {
                    this.getPreAssessmentQuestions(rubric.id, competency, true);
                  } else {
                    this.getPreAssessmentQuestions(rubric.id, competency);
                  }
                }
                this.competencySelections.forEach((selection) => {
                  if (selection.id === competency.id) {
                    attendeeCompetencyDictionary[attendee.userId].push(
                      competency.id
                    );
                  }
                });
              });
            }
          });
        });
        this.preAssessmentQuestionGroupsLoaded = true;
      });
    }
  }

  navigateNextScreen() {
    if (this.competencySelected) {
      this.router.navigate([`/smart-coach/log/${this.sessionId}/growth`]);
    }
  }

  showingOptionModalChange(currState: boolean) {
    if (!currState) this.smartSessionService.hideAddOption();
  }

  getPreAssessmentQuestions(
    rubricId: number,
    competency: CompetencyDTO,
    last?: boolean
  ) {
    if (this.user) {
      this.preAssessmentService
        .getPreAssessmentQuestions(rubricId, competency.id, this.user.id)
        .subscribe((res: { items: SmartCoachPreAssessmentDTO[] }) => {
          if (res.items.length > 0) {
            res.items.forEach((item) => {
              if (item.preassessment_questions.length > 0) {
                this.preAssessmentQuestionGroups.push(item);
                competency.indicatorSet.strands.forEach((strand) => {
                  if (!strand.goalLevel) {
                    this.allGoalsSet = false;
                  }
                });
              }
            });
            if (last) {
              if (
                this.router.url.endsWith('growth') &&
                this.logPermissions === this.Permissions.EDIT &&
                this.allGoalsSet
              ) {
                this.triggerDialog();
              }
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }

    this.coachingService.disconnectFromPusherChannel(this.sessionId);
  }
}
