import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProfileLiteDTO } from 'src/app/common/dtos/profile.dto';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { SingleApiResponse } from 'src/app/common/types/responses/responses';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { CompetencyDTO } from './competencies.dto';

export interface PdSessionAPIResponse extends SingleApiResponse {
  item: PdSession;
  context: PdContext;
}

export interface PdContext {
  your_role: string;
  viewing_user: PdViewingUser;
  credits_received: PdCreditsReceived;
}

export interface PdViewingUser {
  id: number;
  email: string;
  status: number;
  district_id: number;
  school_id: number;
  profile: ProfileLiteDTO;
}

export interface PdCreditsReceived {
  credits: PdContextCredit[];
  certificate: string;
}

export interface PdContextCredit {
  id: number;
  user_id: number;
  pd_event_id: number;
  date: number;
  title: string;
  source: string;
  credit_type: string;
  credit_value: number;
  evidence_link: string;
  is_deleted: number;
  created_at: number;
  updated_at: number;
  event: PdEvent;
}

export interface PdSession {
  id: number;
  pd_event_id: number;
  district_id: number;
  is_shared: number;
  title: string;
  description: string;
  image: string;
  start_datetime: number;
  end_datetime: number;
  creator_user_id: number;
  location: string;
  is_virtual: number;
  virtual_link: string;
  max_registrations: number;
  registration_start_datetime: number;
  registration_end_datetime: number;
  notes: string;
  attendance_code: string;
  survey_proform_form_id: number;
  is_completed: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
  type: string;
  attendees: PdAttendee[];
  categories?: PdSessionCategories[];
  facilitators?: PdFacilitator[];
  credits?: PdSessionCredit[];
  event?: PdEvent;
  competencies?: PdSessionCompetency[];
  sharing?: PdSessionShare[];
}

export interface PdSessionShare {
  id: number;
  pd_session_id: number;
  district_id: number;
  is_shared: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}

export interface PdCredit {
  id: number;
  credit_type: string;
  credit_amount: number;
  district_id: number;
  is_deleted: number;
}

export interface PdSessionCredit {
  id: number;
  pd_session_id: number;
  credit_type: string;
  credit_amount: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}

export interface PdCreditOption {
  id: number;
  title: string;
  district_id: number;
  is_deleted: number;
}

export interface PdFacilitator {
  id: number;
  pd_session_id: number;
  facilitator_user_id: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
  user: UserLiteDTO;
}

export interface PdAttendee {
  id: number;
  pd_session_id: number;
  user_id: number;
  is_present: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
  user: UserLiteDTO;
}

export interface PdListFilterDTO {
  from_date: NgbDate | null;
  to_date: NgbDate | null;
  keyword: string;
  facilitators: UserLiteDTO[] | null;
  types: string[];
  virtual: BooleanNumber;
  onsite: BooleanNumber;
  categories: string[];
  category_options?: string[];
  per_page: number;
  page: number;
  include_completed: BooleanNumber;
  competencyList: CompetencyDTO[];
  user_id: number;
}

export interface PdListFilterPayload {
  from_date: number | null;
  to_date: number | null;
  search: string;
  facilitators: number[];
  types: string[];
  is_virtual: number;
  onsite: number;
  categories: string[];
  category_options?: string[];
  per_page: number;
  page: number;
  include_completed: number;
  competencies: number[];
  user_id: number;
}
export interface PdSessionCategories {
  id: number;
  pd_session_id: number;
  category: string;
  value: string;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}

export interface PdCompetencies {
  id: number;
  pd_session_id: number;
  egrowe_standard_id: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}

export interface GetPdTranscriptsPayload {
  sort_by: string;
  sort_by_desc: number;
  school_year: string;
  source?: string;
  credit_type?: string;
}

export interface AddPdTranscriptEntryPayload {
  date: number;
  title: string;
  source: string;
  credit_type?: string;
  credit_value?: number | null;
  attachment?: string;
}
export interface PdTranscriptAPIResponse {
  created_at: number;
  credit_type: string;
  credit_value: number;
  date: number;
  event: PdEvent;
  evidence_link: string;
  id: number;
  is_deleted: BooleanNumber;
  pd_event_id: number;
  pd_session_id: number;
  session: PdSession;
  source: string;
  title: string;
  updated_at: number;
  user: {
    district_id: number;
    email: string;
    id: number;
    school_id: number;
    status: number;
  };
  user_id: number;
  evidence_title?: string;
}

export interface PdTranscriptCreditDTO {
  credit_type: string;
  credit_value: number;
}
export interface PdTranscriptDTO {
  date: number;
  event: PdEvent;
  evidence_link: string;
  id: number;
  pd_event_id: number;
  pd_session_id: number;
  session: PdSession;
  source: string;
  title: string;
  user: {
    district_id: number;
    email: string;
    id: number;
    school_id: number;
    status: number;
  };
  user_id: number;
  evidence_title?: string;
  credits: PdTranscriptCreditDTO[];
  sort_order: number;
}

export interface PdEvent {
  id: number;
  title: string;
  is_deleted: BooleanNumber;
  created_at: number;
  updated_at: number;
}

export interface PdSettings {
  scope: string;
  categories: PdCategory[];
  credits: PdCreditOption[];
}

export interface PdCategory {
  id: number;
  district_id: number;
  options: PdCategoryOption[];
  title: string;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}
export interface PdCategoryOption {
  id: number;
  pd_category_id: number;
  option_value: string;
  district_id: number;
  is_deleted: number;
  created_at: number;
  updated_at: number;
}

export interface UpdateSessionPayload {
  notes?: string;
}

export interface PdSessionFinishedPayload {
  users: PdSessionFinishedUserPayload[];
}

export interface PdSessionFinishedUserPayload {
  user_id: number;
  credits: PdSessionFinishedUserCreditPayload[];
}

export interface PdSessionFinishedUserCreditPayload {
  credit_type: string;
  credit_value: number;
}

export interface PdFormUser {
  user_id: number;
  is_present?: boolean;
  user_profile?: ProfileLiteDTO;
  credits: PdFormUserCredit[];
}

export const PdSessionFinishedUserCreditPayloadFromPdFormUserCredit = (
  credit: PdFormUserCredit
): PdSessionFinishedUserCreditPayload => ({
  credit_type: credit.credit_type,
  credit_value: credit.credit_value,
});

export const PdSessionFinishedUserPayloadFromPdFormUser = (
  user: PdFormUser
): PdSessionFinishedUserPayload => ({
  user_id: user.user_id,
  credits: user.credits?.map((credit) =>
    PdSessionFinishedUserCreditPayloadFromPdFormUserCredit(credit)
  ),
});

export interface PdFormUserCredit {
  credit_type: string;
  credit_value: number;
  credit_max_value?: number;
}
export interface PdSessionCompetency {
  created_at: number;
  egrowe_standard_id: number;
  id: number;
  is_deleted: number;
  pd_session_id: number;
  updated_at: number;
}

export interface CreatePdSessionPayload {
  title: string;
  description: string;
  type: string;
  pd_event_id?: number; // optional, if empty creates a new one and uses its ID
  district_id?: number; // optional, defaults to current user's district
  is_shared: number;
  image: string;
  start_datetime: number;
  end_datetime: number;
  location: string;
  is_virtual: number;
  virtual_link: string | null;
  max_registrations: number;
  registration_start_datetime?: number; // optional
  registration_end_datetime?: number | null; // optional
  notes: string;
  credits: {
    type: string;
    amount: number;
  }[];
  facilitators: number[];
  competencies: number[];
  categories: {
    category: string;
    value: string;
  }[];
  sharing: {
    district_id: number;
    is_shared: number;
  }[];
  survey_proform_form_id: number;
}

export interface UpdatePdSessionPayload {
  title?: string;
  description?: string;
  is_shared?: number;
  image?: string;
  start_datetime?: number;
  end_datetime?: number;
  location?: string;
  is_virtual?: number;
  virtual_link?: string;
  max_registrations?: number;
  survey_proform_form_id?: number;
  registration_start_datetime?: number;
  registration_end_datetime?: number;
  notes?: string;
  type?: string;
}

export interface CreateUpdateSessionCreditPayload {
  credit_type: string;
  credit_amount: number;
}

export interface UpdateSessionCompetencyPayload {
  egrowe_standard_id: number;
}

export interface CreateUpdateSessionCategoryPayload {
  category: string;
  value: string;
}
export interface PdEarnedItem {
  user: UserLiteDTO;
  credits: PdEarnedCredit[];
}

export interface PdEarnedCredit {
  id: number;
  credit_type: string;
  credit_value: number;
  is_deleted: number;
}
