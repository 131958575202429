import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import {
  CoachingSessionAttendeeDTO,
  CoachingSessionDTO,
  CoachingSessionShadowerDTO,
} from '../../dtos/coaching-session.dto';
import { getSessionLink } from '../../helpers/coachee-log.utilities';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { DatepickerOutput } from '../datepicker/datepicker.component';
import { getValidTimepickerValue } from '../datepicker/datepicker.helpers';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-log-view-details-modal',
  templateUrl: './log-view-details-modal.component.html',
  styleUrls: ['./log-view-details-modal.component.scss'],
  standalone: false,
})
export class LogViewDetailsModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() coachlogId: number;

  @Input() user: User | null;

  @Output() readonly logUpdated: EventEmitter<boolean> = new EventEmitter();

  @Output() readonly duplicateSessionEvent: EventEmitter<CoachingSessionDTO> =
    new EventEmitter();

  coachlog?: CoachingSessionDTO;

  @ViewChild('logViewDetailsModal')
  logViewDetailsModal: ModalComponent;

  subscriptions: Subscription[] = [];

  getSessionLink = getSessionLink;

  errorMessage: string;

  loading = false;

  apiCall?: Subscription;

  logStart: string;

  logEnd: string;

  districtId: number | null = null;

  includeAllB2bDistricts = false;

  timezone: TimezoneLinkBack;

  isTimeValid = true;

  editable = false;

  isSmart = false;

  maxCount = 8;

  constructor(private coachingLogService: CoachingLogService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.coachingLogService.attendeeAdded.subscribe((res) => {
        if (res) {
          this.loadCoachingLog();
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['coachlogId'] &&
      changes['coachlogId'].currentValue !== 0 &&
      changes['coachlogId'].currentValue !== this.coachlog?.id
    ) {
      this.loadCoachingLog(true, changes['coachlogId'].currentValue);
    }
  }

  loadCoachingLog(force = false, id?: number): void {
    if (!this.loading || force) {
      this.loading = true;
      if (force && this.apiCall) {
        this.apiCall.unsubscribe();
      }
      const loadId = id || this.coachlogId;
      this.apiCall = this.coachingLogService
        .getCoachingSession(loadId, true)
        .subscribe({
          error: (res) => {
            this.loading = false;
            delete this.coachlog;
            if (res.status === 403) {
              this.errorMessage = res.error.message;
            }
          },
          next: (res) => {
            this.loading = false;
            if (res) {
              this.coachlog = res;
              this.logStart = getValidTimepickerValue(
                res.startDatetime,
                res.timezone
              );
              this.logEnd = getValidTimepickerValue(
                res.endDatetime,
                res.timezone
              );
              if (
                !this.user?.roles.includes(UserRole.E2L_EMPLOYEE) &&
                res.district
              ) {
                this.districtId = res.district.id;
                if (this.user?.district?.isB2b === 1) {
                  this.includeAllB2bDistricts = true;
                }
              }
              if (this.user?.id === res.user.id) {
                this.editable = true;
              }
              if (
                this.coachlog.type.isSmart &&
                this.user?.id === this.coachlog.attendees[0].user.id
              ) {
                this.isSmart = true;
              }
              this.errorMessage = '';
            }
          },
        });
    }
  }

  updateTitle() {
    if (this.coachlog) {
      const coachlogUpdates = { title: this.coachlog.title };
      this.coachingLogService.updateCoachingLog(
        this.coachlog.id,
        coachlogUpdates
      );
    }
  }

  updateSmartTitle() {
    if (this.coachlog) {
      this.coachingLogService.updateSmartLogTitle(
        this.coachlog.id,
        this.coachlog.title
      );
    }
  }

  updateDateTime(timeUpdate: DatepickerOutput[]) {
    const startJsTimestamp = timeUpdate[0].time / 1000;
    const endJsTimestamp = timeUpdate[1].time / 1000;
    this.logStart = getValidTimepickerValue(
      startJsTimestamp,
      timeUpdate[0].timezone
    );
    this.timezone = timeUpdate[0].timezone;
    this.logEnd = getValidTimepickerValue(
      endJsTimestamp,
      timeUpdate[0].timezone
    );
    if (this.isTimeValid && this.coachlog) {
      this.coachingLogService.updateCoachingLog(this.coachlog.id as number, {
        start_datetime: startJsTimestamp,
        end_datetime: endJsTimestamp,
        timezone: this.timezone,
      });
    }
  }

  updateSmartLogDate(dateUpdate: DatepickerOutput[]) {
    const startJsTimestamp = dateUpdate[0].time / 1000;
    if (this.coachlog) {
      this.coachingLogService.updateSmartLogDate(
        this.coachlog.id,
        startJsTimestamp
      );
    }
  }

  addCoachee(newCoachee: UserLiteDTO | null) {
    if (newCoachee) {
      this.coachingLogService.addAttendee(newCoachee.id, this.coachlog?.id);
    }
  }

  removeCoachee(coachee: CoachingSessionAttendeeDTO) {
    if (this.coachlog) {
      this.coachlog.attendees = this.coachlog.attendees.filter(
        (attendee) => attendee.id !== coachee.id
      );
    }
  }

  addShadower(newShadower: UserLiteDTO | null) {
    if (newShadower && this.coachlog) {
      const isCoachee = this.coachlog.attendees.find(
        (item) => item.userId === newShadower.id
      );
      if (!isCoachee) {
        this.coachingLogService.addShadower(newShadower.id, this.coachlog.id);
      }
    }
  }

  removeShadower(removedShadower: CoachingSessionShadowerDTO) {
    if (this.coachlog) {
      this.coachlog.shadowers = this.coachlog.shadowers.filter(
        (shadower) => shadower.id !== removedShadower.id
      );
    }
  }

  duplicateSession(session: CoachingSessionDTO) {
    this.duplicateSessionEvent.emit(session);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  closeModal() {
    if (this.coachlog) {
      this.editable = false;
      this.isSmart = false;
      delete this.coachlog;
      this.logUpdated.emit(true);
    }
    this.logViewDetailsModal.close();
    this.errorMessage = '';
  }

  deleteSession() {
    if (this.coachlog) {
      this.coachingLogService.deleteCoachlog(this.coachlog);
      this.closeModal();
    }
  }

  openModal() {
    if (!this.coachlog && !this.loading && this.coachlogId !== 0) {
      this.loadCoachingLog();
    }
    this.logViewDetailsModal.open();
  }
}
