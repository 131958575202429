import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';
import { UploadStyles } from '../../shared/components/file-management/file-upload/file-upload.component';
import { WysiwygEditorComponent } from '../../shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { FileDTO } from '../../shared/dtos/file.dto';
import { UpdatePlanSharingPayload } from '../../shared/dtos/plans.dto';
import { FileType } from '../../shared/enums/file-type.enum';
import { AlertService } from '../../shared/services/alert/alert.service';
import { DistrictSearchService } from '../../shared/services/district-search/district-search.service';
import { PlansService } from '../../shared/services/plans/plans.service';
import { FetchPlanDetails } from '../../shared/state/implementation-plan/implementation-plan.actions';
import {
  ImplementationPlanState,
  PlanDetailsItemStateModel,
} from '../../shared/state/implementation-plan/implementation-plan.state';
import { PlanSharingAPIResponse } from '../../shared/types/responses/plan.responses';

@Component({
  selector: 'app-implementation-plan-sharing-page',
  templateUrl: './implementation-plan-sharing-page.component.html',
  styleUrl: './implementation-plan-sharing-page.component.scss',
  standalone: false,
})
export class ImplementationPlanSharingPageComponent
  implements OnInit, OnDestroy
{
  @ViewChild('darkColorInput') darkColorInput: ElementRef<HTMLInputElement>;

  @ViewChild('lightColorInput') lightColorInput: ElementRef<HTMLInputElement>;

  @ViewChild('backgroundColorInput')
  backgroundColorInput: ElementRef<HTMLInputElement>;

  @ViewChild('editor') editor: WysiwygEditorComponent;

  user: User;

  planId: number;

  subs: Subscription[] = [];

  planDetails$: Observable<PlanDetailsItemStateModel> = select(
    ImplementationPlanState.getPlanDetails
  );

  planDetails: PlanDetailsItemStateModel;

  planSharing: PlanSharingAPIResponse;

  sharingEnabled: boolean;

  deliverablesEnabled: boolean;

  sharingUrl: string;

  copyTooltipMessage = 'Copy URL';

  attachments: FileDTO[] = [];

  uploadStyle = UploadStyles.SIMPLE;

  allowedFileTypes = FileType.IMAGE;

  logo: File | null;

  isLoading = false;

  formChanged = false;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private plansService: PlansService,
    private districtSearchService: DistrictSearchService,
    private clipboard: Clipboard,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['planId']) {
        this.planId = parseInt(params['planId']);
        this.store.dispatch(new FetchPlanDetails(this.planId));
        this.planDetails$.subscribe((details) => {
          if (details) {
            this.planDetails = deepCopy(details);
            this.planDetails.start_date = this.formatDate(
              this.planDetails.start_date
            );
            this.planDetails.end_date = this.formatDate(
              this.planDetails.end_date
            );
          }
        });

        this.getPlanSharing(this.planId);
      }
    });
  }

  getPlanSharing(planId: number) {
    this.plansService.getPlanSharing(planId).subscribe((response) => {
      if (response) {
        this.planSharing = response.item;
        this.sharingEnabled = this.planSharing.is_enabled === 1;
        this.deliverablesEnabled = this.planSharing.deliverables_enabled === 1;
        // Get the district code for the sharing URL
        this.subs.push(
          this.districtSearchService
            .getDistrict(this.planSharing.district_id)
            .subscribe((district) => {
              if (district) {
                this.sharingUrl = `${window.location.origin}/public-plan/${district.districtCode}/${this.planSharing.url_slug}`;
              }
            })
        );

        // Get the logo file
        this.subs.push(
          this.plansService.getSharingLogo(planId).subscribe((res) => {
            if (res) {
              this.attachments = [res];
            }
          })
        );
      }
    });
  }

  copyUrl(tooltip: NgbTooltip) {
    tooltip.close();
    const url = this.sharingUrl;
    this.clipboard.copy(url);
    this.copyTooltipMessage = 'Link copied to clipboard!';
    setTimeout(() => {
      tooltip.open();
    }, 250);
    setTimeout(() => {
      if (tooltip.isOpen()) {
        tooltip.close();
      }
      this.copyTooltipMessage = 'Copy URL';
    }, 2500);
  }

  viewPublicPage() {
    window.open(this.sharingUrl, '_blank');
  }

  openColorPicker(colorType: 'dark' | 'light' | 'background') {
    switch (colorType) {
      case 'dark':
        this.darkColorInput.nativeElement.click();
        break;
      case 'light':
        this.lightColorInput.nativeElement.click();
        break;
      case 'background':
        this.backgroundColorInput.nativeElement.click();
        break;
      default:
        break;
    }
  }

  updateColor(event: Event, colorType: 'dark' | 'light' | 'background') {
    const input = event.target as HTMLInputElement;
    switch (colorType) {
      case 'dark':
        this.planSharing.dark_color = input.value;
        break;
      case 'light':
        this.planSharing.light_color = input.value;
        break;
      case 'background':
        this.planSharing.background_color = input.value;
        break;
      default:
        break;
    }
  }

  // Handles deleting of file list on frontend, doesn't make any API calls
  deleteAttachment(file: FileDTO) {
    this.attachments = this.attachments.filter(
      (attachment) => attachment.id !== file.id
    );
    this.logo = null;
  }

  // Handles updating of file list on frontend, doesn't make any API calls
  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
  }

  // Updates local property to be sent in API call on submit
  updateLogo(file: File) {
    this.logo = file;
  }

  onFormChange() {
    this.formChanged = true;
    this.cdr.detectChanges();
  }

  submit() {
    this.isLoading = true;

    const payload: UpdatePlanSharingPayload = {
      is_enabled: this.sharingEnabled ? 1 : 0,
      dark_color: this.planSharing.dark_color,
      light_color: this.planSharing.light_color,
      background_color: this.planSharing.background_color,
      header_content: this.editor.editorContent,
      deliverables_enabled: this.deliverablesEnabled ? 1 : 0,
    };

    this.subs.push(
      this.plansService
        .updatePlanSharing(this.planId, payload)
        .subscribe((response) => {
          this.planSharing = response.item;
          this.formChanged = false;
          this.isLoading = false;
          window.scrollTo({ top: 0 });
          this.alertService.showAlert('Plan Sharing Updated');
        })
    );

    // Makes API call to update the logo at a separate endpoint
    const shouldUpdateLogo =
      (this.attachments.length > 0 &&
        this.attachments[0].location !== this.planSharing.logo) ||
      this.attachments.length === 0;

    if (shouldUpdateLogo) {
      this.subs.push(
        this.plansService.updateSharingLogo(this.planId, this.logo).subscribe()
      );
    }
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatDate(date: string) {
    // date comes in YYYY-MM-DD format
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
