import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';
import { SmartCoachDialogMessage } from '../../../dtos/smartcoach.dto';
import { CoachDialogService } from '../../../services/smart-coach/coach-dialog/coach-dialog.service';

@Component({
  selector: 'app-smart-coach-dialog',
  templateUrl: './coach-dialog.component.html',
  styleUrls: ['./coach-dialog.component.scss'],
  standalone: false,
})
export class SmartCoachDialogComponent implements OnDestroy {
  @ViewChildren('message') messageElements: QueryList<ElementRef>;

  @Input() coachProfile: ProfileDTO;

  @Input() dialogClass: string;

  displayCoach = false;

  currentMessage: ElementRef | undefined;

  currentMessageIdx: number;

  currentMessageGroup: string | undefined;

  currentMessages: SmartCoachDialogMessage[] = [];

  showMessageAtIdx: number | undefined;

  isDisplaying = false;

  goToMessage(index: number) {
    this.currentMessageIdx = index;
    this.currentMessage = this.messageElements.get(index);
  }

  nextMessage() {
    if (this.currentMessageIdx < this.currentMessages.length - 1) {
      this.goToMessage(this.currentMessageIdx + 1);
    } else {
      this.goToMessage(0);
    }
  }

  previousMessage() {
    if (this.currentMessageIdx > 0) {
      this.goToMessage(this.currentMessageIdx - 1);
    } else {
      this.goToMessage(this.currentMessages.length - 1);
    }
  }

  ngOnDestroy(): void {
    this.dialogService.clearMessages();
  }

  constructor(private dialogService: CoachDialogService) {
    this.dialogService.outputMessages.subscribe(
      (messages: SmartCoachDialogMessage[]) => {
        if (messages.length === 0) {
          this.displayCoach = false;
          delete this.currentMessageGroup;
          this.currentMessages = [];
        } else {
          let messageAlreadyExists = false;
          messages.forEach((message) => {
            messageAlreadyExists = this.currentMessages.some(
              (currentMessage) => currentMessage.message === message.message
            );
          });
          if (this.currentMessageGroup === messages[0].group) {
            if (!messageAlreadyExists) {
              this.currentMessages = [...this.currentMessages, ...messages];
            }
            if (!this.isDisplaying) {
              this.goToMessage(this.currentMessages.length - 1);
            }
          } else {
            if (this.currentMessage) {
              delete this.currentMessage;
            }
            this.currentMessages = messages;
            this.goToMessage(0);
          }
          this.isDisplaying = true;
          this.currentMessageGroup = messages[0].group;
          this.displayCoach = true;
        }
      }
    );
  }
}
