import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { CelebrationFilterDTO } from '../../../dtos/celebrations.dto';
import { DistrictSelectComponent } from '../../district-select/district-select.component';
import { SchoolSelectComponent } from '../../school-select/school-select.component';
import { UserSelectComponent } from '../../user-select/user-select.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-celebrations-filter-modal',
  templateUrl: './celebrations-filter-modal.component.html',
  styleUrls: ['./celebrations-filter-modal.component.scss'],
  standalone: false,
})
export class CelebrationsFilterModalComponent {
  @Output() readonly setFilters: EventEmitter<CelebrationFilterDTO> =
    new EventEmitter();

  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('schoolSelect') schoolSelect: SchoolSelectComponent;

  @ViewChild('districtSelect') districtSelect: DistrictSelectComponent;

  @ViewChild('coacheeSelect') coacheeSelect: UserSelectComponent;

  hasAttachment: BooleanNumber;

  currentFilters: CelebrationFilterDTO = {
    fromDate: null,
    toDate: null,
    hasAttachment: 0,
    school: null,
    district: null,
    coacheeList: [],
  };

  user: User;

  canSeeAllSchools = false;

  constructor(private store: Store) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canSeeAllSchools = compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
      this.user.roles
    );
  }

  openModal() {
    this.modal.open();
  }

  closeModal(saveFilters = false): void {
    if (saveFilters) {
      this.setFilters.emit(this.currentFilters);
    }
    this.modal.close();
  }

  clearFilters(): void {
    this.currentFilters = {
      fromDate: null,
      toDate: null,
      hasAttachment: 0,
      school: null,
      district: null,
      coacheeList: [],
    };
    if (this.schoolSelect) this.schoolSelect.reset();
    this.districtSelect.reset();
    this.coacheeSelect.reset();
  }

  updateSchool(school: SchoolDTO | null) {
    this.currentFilters.school = school;
  }

  updateDistrict(district: DistrictSimpleDto | null) {
    this.currentFilters.district = district;
  }

  updateCoachees(coachees: UserLiteDTO[] | null) {
    if (coachees) {
      this.currentFilters.coacheeList = coachees;
    }
  }
}
