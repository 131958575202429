import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { selectOptionsFromEnumCapitalized } from 'src/app/common/utilities/enum-helpers';
import { DistrictSelectComponent } from 'src/app/private/shared/components/district-select/district-select.component';
import { ProformPreviewModalComponent } from 'src/app/private/shared/components/modals/proform-preview-modal/proform-preview-modal.component';
import { FroalaOptions } from 'src/app/private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { AlertService } from 'src/app/private/shared/services/alert/alert.service';
import { DistrictSearchService } from 'src/app/private/shared/services/district-search/district-search.service';
import { ProformsAdminService } from 'src/app/private/shared/services/proforms/proforms-admin.service';
import {
  ProFormsAPIResponse,
  ProFormStatus,
} from 'src/app/private/shared/types/responses/proforms.responses';

@Component({
  selector: 'app-proforms-form-builder-page',
  standalone: false,
  templateUrl: './proforms-form-builder-page.component.html',
  styleUrl: './proforms-form-builder-page.component.scss',
})
export class ProformsFormBuilderPageComponent implements OnInit, OnDestroy {
  @ViewChild('titleInput') titleInput: ElementRef;

  @ViewChild('shareWithDistrictSelect')
  shareWithDistrictSelect: DistrictSelectComponent;

  @ViewChild('excludeFromDistrictSelect')
  excludeFromDistrictSelect: DistrictSelectComponent;

  @ViewChild('header') header!: ElementRef;

  @ViewChild('outroBody') outroBody!: ElementRef;

  @ViewChild('previewModal') previewModal!: ProformPreviewModalComponent;

  subs: Subscription[] = [];

  formId: number;

  form: ProFormsAPIResponse;

  isSidebarOpen = true;

  isEditingOutro = false;

  isArchived = false;

  statuses: { key: string; value: string }[];

  districtsSharedWith: DistrictSimpleDto[];

  districtsExcludedFrom: DistrictSimpleDto[];

  isStuck = false;

  headerOffset = 0;

  title = '';

  titleSubject: Subject<string> = new Subject<string>();

  toolbarBottomFroalaOptions: FroalaOptions = {
    toolbarBottom: true,
  };

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isStuck = window.scrollY > this.headerOffset;
  }

  // Close the editor when clicking outside the card-body
  // Listen for clicks anywhere in the document
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    // Check if the user is currently editing and if the click is outside the outro body
    if (
      this.isEditingOutro &&
      this.outroBody &&
      !this.outroBody.nativeElement.contains(event.target)
    ) {
      this.isEditingOutro = false;
    }
  }

  @HostListener('window:resize', [])
  hideSidebarOnMobile() {
    if (window.innerWidth <= 1200) {
      this.isSidebarOpen = window.innerWidth > 1200;
    }
  }

  onTitleChange(newTitle: string) {
    this.titleSubject.next(newTitle); // Emit the new title value to trigger debounce
  }

  // eslint-disable-next-line class-methods-use-this
  backToSettings() {
    window.history.back();
  }

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private store: Store,
    private proformsAdminService: ProformsAdminService,
    private districtSearchService: DistrictSearchService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    // APPLY BACKGROUND IMAGE
    document.body.classList.add('proformbuilder');

    this.route.params.subscribe((params) => {
      this.formId = parseInt(params['formId']);
      this.proformsAdminService.getProForm(this.formId);
    });

    this.subs.push(
      this.proformsAdminService.currentProForm.subscribe((response) => {
        if (response) {
          this.form = response;
          if (this.form.status === ProFormStatus.ARCHIVED) {
            this.isArchived = true;
          }
          if (this.form.districtsSharedWith) {
            this.districtsSharedWith = this.form.districtsSharedWith.map(
              (district) => district.district
            );
          }
          if (this.form.districtsExcludedFrom) {
            this.districtsExcludedFrom = this.form.districtsExcludedFrom.map(
              (district) => district.district
            );
          }
          setTimeout(() => {
            this.headerOffset =
              this.header.nativeElement.getBoundingClientRect().top +
              window.scrollY;
          }, 0);
        }
      })
    );

    this.hideSidebarOnMobile();

    this.titleSubject
      .pipe(
        debounceTime(500), // Wait for 500ms after the user stops typing
        distinctUntilChanged() // Only emit if the value has changed
      )
      .subscribe((value: string) => {
        if (value !== this.title) {
          this.updateTitle(value);
        }
      });

    this.statuses = selectOptionsFromEnumCapitalized(ProFormStatus).filter(
      (status) => status.key !== 'deleted'
    );
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  deleteForm() {
    this.proformsAdminService.deleteForm(this.formId).subscribe({
      next: () => {
        this.alertService.showAlert('Form Deleted');
        window.history.back();
      },
    });
  }

  updateTitle(newTitle: string) {
    this.title = newTitle; // Update local title value
    this.proformsAdminService.updateProForm(this.formId, { title: newTitle });
  }

  updateStatus() {
    this.proformsAdminService.updateProForm(this.formId, {
      status: this.form.status,
    });
  }

  toggleSharing($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    this.form.is_shared = inputElement.checked ? 1 : 0;
    this.proformsAdminService.updateProForm(this.formId, {
      is_shared: this.form.is_shared,
    });
  }

  updateOutro(outro: string) {
    this.proformsAdminService.updateProForm(this.formId, { outro });
  }

  showEditOutro(event: MouseEvent) {
    event.stopPropagation();
    this.isEditingOutro = true;
  }

  handleShareDistricts(incomingDistricts: DistrictSimpleDto[]) {
    this.subs.push(
      this.proformsAdminService
        .handleShareDistricts(incomingDistricts)
        .subscribe((response: number[]) => {
          const incomingDistrictIds = response;
          // Update the exclude dropdown's internalSelectedDistrictList
          this.excludeFromDistrictSelect.internalSelectedDistrictList =
            this.excludeFromDistrictSelect.internalSelectedDistrictList.filter(
              (district) => !incomingDistrictIds.includes(district.id)
            );
          this.alertService.showAlert('Sharing Settings Saved');
        })
    );
  }

  handleExcludeDistricts(incomingDistricts: DistrictSimpleDto[]) {
    this.subs.push(
      this.proformsAdminService
        .handleExcludeDistricts(incomingDistricts)
        .subscribe((response: number[]) => {
          const incomingDistrictIds = response;
          // Update the share dropdown's internalSelectedDistrictList
          this.shareWithDistrictSelect.internalSelectedDistrictList =
            this.shareWithDistrictSelect.internalSelectedDistrictList.filter(
              (district) => !incomingDistrictIds.includes(district.id)
            );
          this.alertService.showAlert('Sharing Settings Saved');
        })
    );
  }

  ngOnDestroy() {
    // REMOVE BACKGROUND IMAGE
    document.body.classList.remove('proformbuilder');
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
