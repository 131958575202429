import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResourcePreviewDTO } from '../resource-preview/resource-preview.dto';

@Component({
  selector: 'app-resource-preview-list',
  templateUrl: './resource-preview-list.component.html',
  styleUrls: ['./resource-preview-list.component.scss'],
  standalone: false,
})
export class ResourcePreviewListComponent {
  @Output() readonly signalRemoval = new EventEmitter<number>();

  @Input() showRemovalIcon = false;

  @Input() resources: ResourcePreviewDTO[];

  @Input() userDistrictId: number | null;
}
