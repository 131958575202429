import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  KpiReportFilter,
  ReportApiResponse,
} from 'src/app/common/dtos/reports.dto';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { ReportControllerComponent } from '../../../shared/components/reports/report-controller/report-controller.component';
import {
  ChartColorsAll,
  DetailedPieData,
} from '../../../shared/components/reports/report-controller/series-helper';
import { ChartType } from '../../../shared/enums/chart-type.enum';

@Component({
  selector: 'app-card-report-reasons-for-absence',
  templateUrl: './card-report-reasons-for-absence.component.html',
  standalone: false,
})
export class CardReportReasonsForAbsenceComponent implements OnInit {
  @Input() defaultFilters: KpiReportFilter[];

  @Input() minPercent: number;

  @ViewChild('pieChart', { static: true }) pieChart: ReportControllerComponent;

  reasonsForAbsenceFilters: KpiReportFilter[];

  cardTitle = 'Reasons For Absence';

  isCollapsed: boolean;

  donutChartType = ChartType.Donut;

  chartColors = ChartColorsAll;

  absenceTypeCounts: DetailedPieData[] = [];

  currentError = '';

  constructor(private cardStorageService: CardStorageService) {}

  ngOnInit() {
    this.isCollapsed = this.cardStorageService.getCollapsedState(
      this.cardTitle.replace(/\s/g, '').toLowerCase()
    );
    if (this.defaultFilters) {
      this.reasonsForAbsenceFilters = deepCopy(this.defaultFilters);
      this.reasonsForAbsenceFilters.push({
        codename: 'is_present',
        value: ['0'],
      });
    }
  }

  resetError() {
    this.currentError = '';
  }

  handleError(errorMessage: string) {
    this.currentError = errorMessage;
  }

  updateCountsFromOriginal(origApiResponse: ReportApiResponse) {
    this.absenceTypeCounts = [];
    let totalCount = 0;
    if (this.minPercent > 0) {
      if (
        origApiResponse.datasets.length === 1 &&
        origApiResponse.datasets[0].data
      ) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        origApiResponse.datasets[0].data.forEach((count: any) => {
          totalCount += count;
        });
        let i = 0;
        if (totalCount > 0) {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          origApiResponse.datasets[0].data.forEach((count: any) => {
            let labelId = origApiResponse.label_ids[i];
            if (!labelId) {
              labelId = 0;
            }
            this.absenceTypeCounts.push({
              name: origApiResponse.labels[i],
              groupId: '',
              value: count,
              labelId: labelId.toString(),
              color: '',
            });

            i += 1;
          });

          this.absenceTypeCounts.sort((a, b) => b.value - a.value);

          let absenceTypeCounts = this.absenceTypeCounts;
          this.absenceTypeCounts = [];
          absenceTypeCounts.forEach(
            (eachItem: DetailedPieData, index: number) => {
              eachItem.originalIndex = index;
              this.absenceTypeCounts.push(eachItem);
            }
          );

          absenceTypeCounts = this.absenceTypeCounts;
          this.absenceTypeCounts = [];
          absenceTypeCounts.forEach((eachItem: DetailedPieData) => {
            if (eachItem.originalIndex) {
              let color = '';
              if (this.chartColors.length > eachItem.originalIndex) {
                color = this.chartColors[eachItem.originalIndex];
              }
              eachItem.color = color;
              this.absenceTypeCounts.push(eachItem);
            }
          });

          absenceTypeCounts = this.absenceTypeCounts;
          this.absenceTypeCounts = absenceTypeCounts.filter(
            (eachItem: DetailedPieData) =>
              (eachItem.value / totalCount) * 100 < this.minPercent
          );
        }
      }
    }
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      this.cardTitle.replace(/\s/g, '').toLowerCase(),
      this.isCollapsed
    );
  }
}
