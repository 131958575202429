/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: false,
})
export class ArraySortPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(array: any, field: string): any {
    if (!Array.isArray(array)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
    return array;
  }
}
