import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  DistrictDTO,
  DistrictSimpleDto,
} from 'src/app/common/dtos/district.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { SingleApiResponse } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { DistrictListService } from 'src/app/private/shared/services/district-list/district-list-service';
import { ProformsFormListService } from 'src/app/private/shared/services/proforms/proforms-formlist.service';
import { NewProFormPayload } from 'src/app/private/shared/types/payloads/proforms.payloads';
import {
  GetAllProFormsAPIResponse,
  ProFormsAPIResponse,
} from 'src/app/private/shared/types/responses/proforms.responses';

@Component({
  selector: 'app-proforms-list-page',
  standalone: false,

  templateUrl: './proforms-list-page.component.html',
  styleUrl: './proforms-list-page.component.scss',
})
export class ProformsListPageComponent {
  isLoading = true;

  isCreating = false;

  districtList: DistrictSimpleDto[] = [];

  district: DistrictDTO;

  districtCode: string;

  user: User | null;

  pagination: PaginationDTO;

  currentPage = 1;

  forms: ProFormsAPIResponse[];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private districtListService: DistrictListService,
    private store: Store,
    private proformsFormListService: ProformsFormListService
  ) {
    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;
    this.user = this.store.selectSnapshot(UserState.getUser);
    this.districtListService
      .fetchDistrictsSimple({ per_page: 1000 })
      .subscribe((districts) => {
        if (districts) {
          this.districtList = districts;
          const district = districts.find(
            (searchDistrict) =>
              searchDistrict.districtCode === this.districtCode
          ) as DistrictDTO;
          if (district) {
            this.district = district;
          } else {
            this.district = districts.find(
              (searchDistrict) => searchDistrict.id === 2
            ) as DistrictDTO;
            this.districtCode = this.district.districtCode;
          }
          this.loadProForms();
        }
      });
  }

  loadProForms(): void {
    this.proformsFormListService
      .getForms(this.district.id, 10, this.currentPage)
      .subscribe((resp: GetAllProFormsAPIResponse) => {
        if (resp.items) {
          this.forms = resp.items;
          this.pagination = resp._meta;
          this.isLoading = false;
        }
      });
  }

  createForm() {
    this.isCreating = true;
    if (this.user) {
      const payload: NewProFormPayload = {
        title: 'New Form',
        outro: '',
        district_id: this.district?.id,
      };
      this.proformsFormListService
        .createForm(payload)
        .subscribe((resp: SingleApiResponse) => {
          if (resp.item.id) {
            setTimeout(() => {
              this.router.navigate([`/proforms/form-builder/${resp.item.id}`]);
            }, 1000);
          }
        });
    }
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadProForms();
  }
}
