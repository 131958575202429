import { Pipe, PipeTransform } from '@angular/core';
import { Form } from 'src/app/private/shared/dtos/forms.dto';

@Pipe({
  name: 'filterFormSearch',
  standalone: false,
})
export class FilterFormSearch implements PipeTransform {
  // eslint-disable-next-line
  transform(formValues: Form[], searchTerm: string, currentMode: boolean) {
    return formValues.filter((form: Form) => {
      const term = searchTerm.toLowerCase();
      return (
        form.title.toLowerCase().includes(term) ||
        form.description.toLowerCase().includes(term) ||
        form.status.toLowerCase().includes(term)
      );
    });
  }
}
