<div class="container">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="col-9">
      <div class="fs-19">Learning &amp; Events</div>
      <div class="fw-800" style="font-size: 37px; color: #430089">
        Event Catalog
      </div>
    </div>
    <div class="col-3 col-md-auto">
      <app-action-buttons />
    </div>
  </div>

  <div class="card sessions-card border-0 mb-12">
    <ng-container *ngIf="sessions">
      <div *ngIf="canCreate" class="d-flex justify-content-end">
        <button class="btn btn-primary" routerLink="/training/create-session">
          + Create a Session
        </button>
      </div>
      <div class="d-flex align-items-center mt-3">
        <app-applied-filters
          #appliedFilters
          [pdListSearchOptions]="currentFilters"
          [pdCategoryOptions]="currentCategoryOptions"
          filterType="pd-list"
          (filterRemoved)="removeFilter($event)"
          (filterListRemoved)="removeFilterFromList($event)"
          [showEditFiltersBtn]="true"
          (openModal)="pdFilterModal.pdFilterModal.open()" />
      </div>
      <hr class="mb-5" />
      <ng-container *ngFor="let session of sessions">
        <app-pd-list-item
          [session]="session"
          [userId]="user.id"
          [context]="'normal'" />
      </ng-container>
      <div
        *ngIf="listMeta.pageCount > 1"
        class="d-flex justify-content-center catalog-pagination">
        <ngb-pagination
          [(page)]="currentFilters.page"
          [pageSize]="currentFilters.per_page"
          [collectionSize]="listMeta.totalCount"
          (pageChange)="getSessions()"
          [maxSize]="5"
          [ellipses]="true"></ngb-pagination>
      </div>
      <!-- <div class="testing-box">
        <pre>{{ sessions | json }}</pre>
      </div> -->
    </ng-container>
  </div>
</div>

<app-pd-filter-modal
  #pdFilterModal
  [user]="user"
  [initialFilters]="currentFilters"
  [currentCategoryOptions]="currentCategoryOptions"
  (setCategoryOptions)="updateCategoryOptions($event)"
  [settings]="settings"
  (setFilters)="applyFilters($event)"
  [isE2L]="isE2L" />
