import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { CoachStatsService } from '../../../services/coach-stats/coach-stats.service';
import { QuickStatsAPIResponse } from '../../../types/responses/coachee-quick-stats.responses';

@Component({
  selector: 'app-card-coach-quick-stats',
  templateUrl: './card-coach-quick-stats.component.html',
  styleUrl: './card-coach-quick-stats.component.scss',
  standalone: false,
})
export class CardCoachQuickStatsComponent implements OnInit, OnDestroy {
  user: User | null = null;

  subs: Subscription[] = [];

  avgFeedbackRating: number;

  hasAvgFeedbackRating = false;

  avgCoacheeConfidence: number;

  hasAvgCoacheeConfidence = false;

  avgSessionsPerCoach: number;

  hasAvgSessionsPerCoach = false;

  avgBadgesPerSession: number;

  hasAvgBadgesPerSession = false;

  isCollapsed = this.cardStorageService.getCollapsedState('coachquick');

  userDistrictId = 0;

  constructor(
    private cardStorageService: CardStorageService,
    private coachStatsService: CoachStatsService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    if (this.user?.district?.id) {
      this.userDistrictId = this.user.district.id;
    }
  }

  ngOnInit(): void {
    this.subs.push(
      this.coachStatsService
        .getAvgFeedbackRating(this.userDistrictId)
        .subscribe((resp: QuickStatsAPIResponse) => {
          if (resp.datasets[0].data[0]) {
            this.hasAvgFeedbackRating = true;
            this.avgFeedbackRating = resp.datasets[0].data[0];
          }
        })
    );

    this.subs.push(
      this.coachStatsService
        .getAvgCoacheeConfidenceRating(this.userDistrictId)
        .subscribe((resp: QuickStatsAPIResponse) => {
          if (resp.datasets[0].data[0]) {
            this.hasAvgCoacheeConfidence = true;
            this.avgCoacheeConfidence = resp.datasets[0].data[0];
          }
        })
    );

    this.subs.push(
      this.coachStatsService
        .getAvgBadgesPerSession(this.userDistrictId)
        .subscribe((resp: QuickStatsAPIResponse) => {
          if (resp.datasets[0].data[0]) {
            this.hasAvgBadgesPerSession = true;
            this.avgBadgesPerSession = resp.datasets[0].data[0];
          }
        })
    );

    this.subs.push(
      this.coachStatsService
        .getAvgSessionsPerCoach(this.userDistrictId)
        .subscribe((resp: QuickStatsAPIResponse) => {
          if (resp.datasets[0].data[0]) {
            this.hasAvgSessionsPerCoach = true;
            this.avgSessionsPerCoach = resp.datasets[0].data[0];
          }
        })
    );
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState('coachquick', this.isCollapsed);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
