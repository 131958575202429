<app-modal
  #modal
  (isClosing)="reset()"
  [modalConfig]="{
    titleText: 'Upload Users: GroweLab CSV',
    headerClass: ['modal-header', 'bg-primary', 'text-white'],
    customFooter: true,
    options: {
      size: 'lg',
    },
    closeOnBgClick: false
  }">
  <button
    *ngIf="currentScreen === 'fileUpload' && !isUploading"
    class="bg-transparent border-0"
    title="Close"
    aria-label="close"
    headerContent
    (click)="modal.close()">
    <i class="bi bi-x-lg text-white"></i>
  </button>

  <ng-container *ngIf="currentScreen === 'fileUpload'">
    <input
      #fileUpload
      type="file"
      class="file-input"
      accept=".csv"
      (change)="onFileChange($event)" />

    <div
      class="file-upload p-3 mt-3 mb-4 text-center rounded"
      [ngClass]="[
        isUploading ? 'disabled' : '',
        dropzoneActive ? 'dropzone' : ''
      ]"
      (dragleave)="$event.preventDefault(); toggleDropZone(false); (false)"
      (dragover)="$event.preventDefault(); toggleDropZone(true); (false)"
      (dragend)="$event.preventDefault(); toggleDropZone(false); (false)"
      (drop)="handleDrop($event); (false)"
      (click)="onUploadButtonClick()">
      <app-file-list></app-file-list>
      <p class="color-dark-primary fw-bold">
        Click here or drag file here to upload
      </p>
      <div class="btn btn-primary">
        <i *ngIf="!isUploading" class="bi bi-upload me-2"></i
        ><span
          *ngIf="isUploading"
          class="spinner-border spinner-border-sm me-2"></span
        >Upload
      </div>
    </div>
    <div *ngIf="isUploading">
      <ngb-progressbar
        [value]="percentProcessed"
        [animated]="true"></ngb-progressbar>
      <p class="text-center pt-4 p-3 m-0">
        Do not navigate away from this page until upload is complete.
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="currentScreen === 'reviewChanges'">
    <p class="my-3 text-center">
      Please review and confirm these changes to your user database.
    </p>
    <p class="mb-2 fw-bold">Summary:</p>
    <table class="table gl-custom-table mb-4">
      <thead class="bg-secondary text-center">
        <tr>
          <th scope="col">
            <span class="fw-bold">Users Created</span>
          </th>
          <th scope="col">
            <span class="fw-bold">Users Updated</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>{{ usersCreated }}</td>
          <td>{{ usersUpdated }}</td>
        </tr>
      </tbody>
    </table>
    <p class="mb-2 fw-bold">Changes to be applied:</p>
    <div class="scrollable-table-wrapper">
      <table class="table gl-custom-table">
        <thead class="bg-secondary">
          <tr>
            <th scope="col">
              <span class="fw-bold">User</span>
            </th>
            <th scope="col">
              <span class="fw-bold">Change</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let change of changes">
            <tr *ngIf="hasAdditionalFields(change)">
              <td>
                {{ change.user_info.name }}<br />{{ change.user_info.email }}
              </td>
              <td class="fw-lighter">
                <ng-container *ngIf="change.user_info.is_new">
                  User Created<br />
                </ng-container>
                <ng-container *ngIf="change.status">
                  User Undeleted<br />
                </ng-container>
                <ng-container *ngIf="change.first_name">
                  Updated first name to
                  <span class="fw-normal">{{ change.first_name }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.last_name">
                  Updated last name to
                  <span class="fw-normal">{{ change.last_name }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.type">
                  Updated user type to
                  <span class="fw-normal">{{ change.type }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.title">
                  Updated title to
                  <span class="fw-normal">{{ change.title }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.coach">
                  Assigned to
                  <span class="fw-normal">{{ change.coach.name }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.school">
                  Assigned to Primary School:
                  <span class="fw-normal">{{ change.school }}</span
                  ><br />
                </ng-container>
                <ng-container *ngIf="change.additional_schools">
                  Assigned to Secondary School(s):<br />
                  <span
                    *ngFor="let additionalSchool of change.additional_schools">
                    <span class="fw-normal">{{ additionalSchool }}</span
                    ><br />
                  </span>
                </ng-container>
                <ng-container *ngIf="change.cohorts">
                  <ng-container
                    *ngIf="change.cohorts.length === 1; else multipleCohorts">
                    Added to
                    <span class="fw-normal">{{ change.cohorts[0] }}</span>
                  </ng-container>
                  <ng-template #multipleCohorts>
                    Added to cohorts:<br />
                    <span *ngFor="let cohort of change.cohorts">
                      <span class="fw-normal">{{ cohort }}</span
                      ><br />
                    </span>
                  </ng-template>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-container *ngIf="currentScreen === 'applyChanges'">
    <p class="text-center pt-4 p-3 m-0">Your changes are being applied...</p>
    <ngb-progressbar
      [value]="percentApplied"
      [animated]="true"></ngb-progressbar>
    <p class="text-center pt-4 p-3 m-0">
      Do not navigate away from this page until apply is complete.
    </p>
  </ng-container>

  <ng-container *ngIf="errorMessages.length > 0">
    <ngb-alert [type]="'danger'" (closed)="closeErrorMsg()">
      <div class="text-start">
        <ul>
          <li
            *ngFor="let errorMessage of errorMessages; let i = index"
            class="ms-2 mb-1">
            {{ errorMessage }}
          </li>
        </ul>
      </div>
    </ngb-alert>
  </ng-container>

  <div footerContent *ngIf="currentScreen === 'reviewChanges'">
    <button
      (click)="cancel()"
      [disabled]="submitLoading"
      type="submit"
      class="btn btn-md btn-secondary me-2">
      Cancel
    </button>
    <button
      (click)="applyChanges()"
      [disabled]="submitLoading"
      type="submit"
      class="btn btn-md btn-primary">
      <span
        *ngIf="submitLoading"
        class="spinner-border spinner-border-sm me-1"></span>
      Confirm Changes
    </button>
  </div>
</app-modal>
