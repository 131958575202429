<div class="container container-main">
  <h1>{{ (data.id) ? 'Edit' : 'Create' }} Form</h1>
  <div class="card border-0 p-4 mt-3 overflow-auto container-fluid">
    <div class="row justify-content-between">
      <div class="col-8">
        <div class="form-group">
          <label class="form-label" for="title">Title</label>
          <input
            aria-label="Form title"
            id="form-title"
            class="form-control title-input mb-3"
            [(ngModel)]="data.title"
            placeholder="Form title"
            name="title" />
        </div>
        <div
          *ngIf="data.fields.length > 0"
          id="fieldsContainer"
          cdkDropList
          (cdkDropListDropped)="drop($event)">
          <label class="form-label">Fields</label>
          <div *ngFor="let field of data.fields; let i = index" cdkDrag>
            <div class="block card bg-light">
              <div class="toolbar d-flex justify-content-between bg-gray mb-0">
                <div
                  cdkDragHandle
                  [ngClass]="[
                      'drag-handle',
                      'p-1',
                      data.fields.length < 2 ? 'disabled' : ''
                    ]">
                  <i class="bi bi-grip-vertical"></i>
                </div>
                <div>
                  <h2 class="text-body-secondary">
                    ({{ fieldTypeDisplay[field.type].toUpperCase() }})
                  </h2>
                </div>
                <div
                  [ngbPopover]="popContent"
                  [autoClose]="'outside'"
                  [style.cursor]="'pointer'"
                  class="p-1"
                  #popover="ngbPopover">
                  <i class="bi bi-trash color-danger mt-3 me-2"></i>
                </div>
                <ng-template #popContent>
                  Are you sure you want to delete this field?
                  <div class="text-center mt-3">
                    <button
                      (click)="deleteField(field.id); popover.close()"
                      class="btn btn-sm btn-danger">
                      Yes, delete
                    </button>
                  </div>
                </ng-template>
              </div>
              <app-form-edit-field
                [formStatus]="data.status"
                [type]="field.type"
                [inputData]="field"
                (outputData)="updateField($event)"></app-form-edit-field>
            </div>
            <button class="btn btn-link my-3" (click)="openFieldModal(i)">
              <i class="bi bi-plus-circle"></i> Add Field
            </button>
          </div>
        </div>
        <button
          *ngIf="data.fields.length === 0"
          class="btn btn-link my-3"
          (click)="openFieldModal(0)">
          <i class="bi bi-plus-circle"></i> Add Field
        </button>

        <ng-template [ngIf]="data.id">
          <button
            (click)="previewModal.modal.open()"
            [disabled]="!data.title || !data.description || isSaving"
            class="btn btn-secondary px-3 my-3 mx-2">
            <i class="bi bi-eye me-1"></i> Preview Form
          </button>
        </ng-template>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="form-label" for="description">Description</label>
          <textarea
            [(ngModel)]="data.description"
            class="form-control"
            style="height: 100px"
            placeholder="Form description"
            name="description"></textarea>
        </div>

        <div class="form-group">
          <label class="form-label">Form Header Image:</label>
          <ng-container
            *ngIf="attachments && attachments.length > 0; else addImage">
            <app-file-list
              *ngIf="attachments.length > 0"
              [skipApiCallOnDelete]="true"
              [fileList]="attachments"
              (deletedFile)="deleteAttachment($event)" />
          </ng-container>
          <ng-template #addImage>
            <app-file-upload
              #uploadComponent
              [disabled]="attachments.length > 0"
              [style]="uploadStyle"
              [allowedFileTypes]="allowedFileTypes"
              (outputFiles)="updateAttachments($event)"
              [outputRaw]="true" />
          </ng-template>
        </div>
        <div class="form-group">
          <label class="form-label" for="status">Status</label>
          <ng-select
            data-testid="form-status-select"
            class="growelab"
            labelForId="status"
            [items]="statuses"
            bindLabel="value"
            [clearable]="false"
            [searchable]="false"
            bindValue="key"
            [(ngModel)]="data.status"
            name="status"
            placeholder="Select status"></ng-select>
        </div>
        <div class="form-group">
          <label class="form-label" for="formType">Type</label>
          <ng-select
            class="growelab"
            labelForId="formType"
            [items]="formTypes"
            bindLabel="label"
            [clearable]="false"
            [searchable]="false"
            bindValue="key"
            [(ngModel)]="data.type"
            name="formType"
            placeholder="Select type"></ng-select>
        </div>
        <!-- This *ngIf would also include checking if they're b2b; if so, list of districts will be narrowed to their managed districts -->
        <div
          *ngIf="user.roles.includes(userRoles.E2L_EMPLOYEE)"
          class="form-group mt-2">
          <div class="d-flex align-items-center">
            <span id="isTemplateLabel" class="d-block pe-2"
              >Share form across districts</span
            >
            <div class="d-flex" (click)="changeTemplate()">
              <i
                *ngIf="!data.isTemplate"
                ngbTooltip="Turn sharing on"
                class="fs-2 bi cursor-pointer bi-toggle2-off color-neutral"></i>
              <i
                *ngIf="data.isTemplate"
                ngbTooltip="Turn sharing off"
                class="fs-2 bi cursor-pointer bi-toggle2-on color-primary"></i>
              <input
                class="visually-hidden"
                type="checkbox"
                aria-describedby="isTemplateLabel"
                [value]="data.isTemplate" />
            </div>
          </div>
        </div>
        <div class="my-2" *ngIf="data.isTemplate">
          <label
            >Share with these districts as a template (default is share across
            all your districts):</label
          >
          <app-district-select
            (selectedDistrictList)="updateDistrictsSharedWith($event)"
            [defaultDistricts]="data.districtsSharedWith || null"
            [isMulti]="true"></app-district-select>
        </div>
        <div class="my-2" *ngIf="data.isTemplate">
          <label>Exclude these districts:</label>
          <app-district-select
            (selectedDistrictList)="updateExcludedDistricts($event)"
            [defaultDistricts]="data.districtsSharedExclusion || null"
            [isMulti]="true"></app-district-select>
        </div>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mt-5">
          <div>
            <button
              (click)="backToFormsList()"
              ngbTooltip="Discard changes"
              class="btn btn-secondary me-2 mb-2">
              Cancel
            </button>
            <ng-container>
              <button
                aria-label="delete form button"
                class="btn btn-txt-delete me-2 mb-2"
                type="button"
                [autoClose]="'outside'"
                [ngbPopover]="deleteContent"
                [popoverTitle]="'Delete Form'"
                [autoClose]="'outside'"
                #popover="ngbPopover">
                Delete
              </button>
              <ng-template #deleteContent>
                Are you sure you want to delete this form?
                <div class="text-end mt-2">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm px-3 m-1"
                    (click)="popover.close()">
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm px-3 m-1"
                    (click)="deleteForm(); popover.close()">
                    Yes
                  </button>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <button
            (click)="saveForm(); backToFormsList()"
            ngbTooltip="Ensure all fields are complete"
            container="body"
            [disableTooltip]="!!data.title && !!data.description && !emptyPrompt"
            [disabled]="!data.title || !data.description || isSaving || emptyPrompt"
            style="pointer-events: auto"
            class="btn btn-primary btn-next mb-2 ms-3">
            <span
              *ngIf="isSaving"
              class="spinner-border spinner-border-sm me-1"></span
            >Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data.hasSubmission" class="alert alert-warning mt-2" role="alert">
    This form already has submissions. Do not significantly modify question
    text, as there are already responses for the question. If you need to
    materially change a question, delete the existing question and create a new
    one.
  </div>
</div>
<app-modal
  #AddFieldModal
  [modalConfig]="{
      modalLabel: 'Choose a field type to add',
      headerClass: ['visually-hidden'],
      hideCloseButton: true,
      options: { size: 'md' }
    }">
  <div class="row d-flex">
    <div
      class="card choice col-6 text-center"
      *ngFor="let choice of fieldChoices">
      <div class="p-4 m-1" (click)="addField(choice.type)">
        <i class="bi bi-{{ choice.icon }} fs-18 color-tertiary"></i>
        <p>{{ choice.name }}</p>
      </div>
    </div>
  </div>
</app-modal>
<app-form-modal *ngIf="data.id" #previewModal [form]="data"></app-form-modal>
