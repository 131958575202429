import { Component, Input } from '@angular/core';
import { CompetencyPreviewDTO } from 'src/app/private/shared/dtos/competencies.dto';

@Component({
  selector: 'app-preview-leveled-competency',
  templateUrl: './preview-leveled-competency.component.html',
  styleUrls: ['./preview-leveled-competency.component.scss'],
  standalone: false,
})
export class PreviewLeveledCompetencyComponent {
  @Input() competency: CompetencyPreviewDTO;

  @Input() rubricId: number;
}
