/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHTML',
  standalone: false,
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    const temp = value.replace(/&nbsp;/g, ' ');
    return temp.replace(/<.*?>/g, '');
  }
}
