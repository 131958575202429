import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { select } from 'src/app/common/utilities/ngxs-utils';

import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserDTO } from 'src/app/common/state/user/user.dto';
import { UserService } from 'src/app/common/state/user/user.service';
import { AssessmentDTO, IndicatorDTO } from '../../dtos/attendee-rubric.dto';
import { IndicatorPreviewDTO } from '../../dtos/competencies.dto';
import { IndicatorAssessmentPayload } from '../../services/coaching-log/coaching-log-service.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { ConfettiService } from '../../services/confetti/confetti.service';
import { PortfolioService } from '../../services/portfolio/portfolio.service';
import { CoachingLogState } from '../../state/coaching-log/coaching-log.state';
import { UncheckConfirmModalComponent } from '../uncheck-confirm-modal/uncheck-confirm-modal.component';

@Component({
  selector: 'app-growth-indicator',
  templateUrl: './growth-indicator.component.html',
  styleUrls: ['./growth-indicator.component.scss'],
  standalone: false,
})
export class GrowthIndicatorComponent implements OnInit, OnChanges {
  @ViewChild('uncheckConfirmModal')
  uncheckConfirmModal: UncheckConfirmModalComponent;

  @Input() indicatorId: number;

  @Input() portfolioIndicator: IndicatorDTO;

  @Input() previewIndicator: IndicatorPreviewDTO;

  @Input() rubricId: number;

  @Input() userId: number;

  @Input() logId: number;

  @Input() disabled = false;

  @Input() viewingPrevSession = false;

  indicator: IndicatorDTO;

  isChecked = false;

  isLoading = false;

  indicator$ = select(CoachingLogState.getIndicator);

  user: User;

  assessment: AssessmentDTO | null;

  assessorProfile: UserDTO;

  canToggle = false;

  currentLogId: number;

  constructor(
    private coachingService: CoachingLogService,
    private confettiService: ConfettiService,
    private portfolioService: PortfolioService,
    private route: ActivatedRoute,
    private store: Store,
    private userService: UserService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    if (this.indicatorId) {
      this.route.params.subscribe((params) => {
        this.currentLogId = params['logId'];
      });

      this.indicator$
        .pipe(
          map((filterFn) =>
            (filterFn as (userId: number, indicatorId: number) => IndicatorDTO)(
              this.userId,
              this.indicatorId
            )
          )
        )
        .subscribe((data) => {
          this.indicator = data;
          this.checkForAssessment();
        });
    } else if (this.portfolioIndicator) {
      this.indicator = this.portfolioIndicator;
      this.checkForAssessment();
    }
  }

  ngOnChanges() {
    this.checkForPermissions();
  }

  checkForAssessment() {
    this.isChecked = false;
    this.assessment = null;

    if (this.indicator?.assessments?.length > 0) {
      this.indicator.assessments.forEach((assessment) => {
        if (
          assessment.assessmentType === 'coach:standard' ||
          assessment.assessmentType === 'smartcoach:standard'
        ) {
          this.assessment = assessment;
          this.isChecked = true;
          if (this.assessment.assessorUserId) {
            const checkCachedUser = this.userService.getCachedUser(
              this.assessment.assessorUserId
            );
            if (checkCachedUser) {
              this.assessorProfile = checkCachedUser;
            } else {
              this.userService
                .fetchUserById(this.assessment.assessorUserId)
                .subscribe((res) => {
                  if (res) {
                    this.assessorProfile = res;
                    this.userService.addUserToCache(this.assessorProfile);
                  }
                });
            }
          }
        }
      });
    }
    this.checkForPermissions();
  }

  checkForPermissions() {
    if (!this.disabled) {
      if (this.user?.isCoach && this.user.id !== this.userId) {
        this.canToggle = true;
      }
    } else {
      this.canToggle = false;
    }
  }

  toggleGI() {
    if ((!this.indicatorId && !this.portfolioIndicator) || this.disabled) {
      return;
    }

    // only coach can do this, and not for themselves
    if (this.user?.isCoach && this.user.id !== this.userId) {
      if (this.indicatorId) {
        this.isLoading = true;
        if (this.isChecked) {
          if (this.assessment?.id) {
            // uncheck
            this.coachingService
              .deleteAssessment(this.assessment.id, this.logId)
              .subscribe(() => {
                this.isLoading = false;
              });
          } else {
            // nothing to delete
            this.isLoading = false;
          }
        } else {
          // check
          const payload: IndicatorAssessmentPayload = {
            egrowe_indicator_id: this.indicator.id,
            assessment_type: 'coach:standard',
            egrowe_rubric_id: this.rubricId,
            assessee_user_id: this.userId,
            egrowe_coachlog_id: this.logId,
            from_egrowe_portfolio: BooleanNumber.FALSE,
          };

          // apply to current log - not last session
          if (this.viewingPrevSession && this.currentLogId) {
            payload.egrowe_coachlog_id = this.currentLogId;
          }

          this.coachingService
            .assessIndicator(payload)
            .subscribe((response) => {
              if (response) {
                this.isLoading = false;
              }
            });
        }
      }

      if (this.portfolioIndicator) {
        this.isLoading = true;
        if (this.isChecked) {
          if (this.assessment?.id) {
            // uncheck
            this.portfolioService
              .deleteAssessment(this.assessment.id)
              .subscribe(() => {
                this.isChecked = false;
                this.isLoading = false;
              });
          } else {
            // nothing to delete
            this.isLoading = false;
          }
        } else {
          // check
          const payload: IndicatorAssessmentPayload = {
            egrowe_indicator_id: this.indicator.id,
            assessment_type: 'coach:standard',
            egrowe_rubric_id: this.rubricId,
            assessee_user_id: this.userId,
            egrowe_coachlog_id: this.logId,
            from_egrowe_portfolio: BooleanNumber.TRUE,
          };

          this.portfolioService
            .assessIndicator(payload)
            .subscribe((response) => {
              if (response) {
                this.confettiService.showConfetti();
              }
            });
        }
      }
    }
  }

  openUncheckConfirmModal() {
    if (this.user?.isCoach && this.user.id !== this.userId) {
      if (!this.disabled) {
        this.uncheckConfirmModal.openModal();
      }
    }
  }
}
