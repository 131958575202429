import { Route } from '@angular/router';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { SidebarRoute } from 'src/app/common/types/routes';
import {
  AUTH_GUARDED_ROUTE,
  AUTH_GUARDED_ROUTE_COACH,
  AUTH_ROUTE_GUARDED_ROUTE,
} from '../../common/utilities/route.helpers';
import { CalendarPageComponent } from '../pages/calendar-page/calendar-page.component';
import { CoachingSessionsPageComponent } from '../pages/coaching-sessions-page/coaching-sessions-page.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { PlansPageComponent } from '../pages/plans-page/plans-page.component';
import { PortfolioViewPageComponent } from '../pages/portfolio-view-page/portfolio-view-page.component';
import { ReportingPageComponent } from '../pages/reporting-page/reporting-page.component';
import { ResourcesSearchComponent } from '../pages/resources-search/resources-search.component';
import { SettingsComponent } from '../pages/settings/settings.component';
import { StyleLibraryComponent } from '../pages/style-library/style-library.component';
import { CatalogPageComponent } from '../pages/training/catalog/catalog.component';
import { FEATURE_FLAGS } from '../shared/services/unleash/unleash.helpers';

export const sidebarRoutes: SidebarRoute[] = [
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'dashboard',
    component: DashboardComponent,
    title: 'Dashboard',
    data: {
      icon: 'grid-1x2',
      ariaLabel: 'Dashboard',
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'calendar',
    component: CalendarPageComponent,
    title: 'Calendar',
    data: {
      icon: 'calendar2-date',
      ariaLabel: 'Calendar Page',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'portfolio',
    component: PortfolioViewPageComponent,
    title: 'Portfolio',
    data: {
      icon: 'briefcase',
      ariaLabel: 'Portfolio',
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'resources',
    title: 'Resources',
    component: ResourcesSearchComponent,
    data: {
      icon: 'book',
      ariaLabel: 'Resources',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'coaching',
    component: CoachingSessionsPageComponent,
    title: 'Coaching Sessions',
    data: {
      icon: 'card-checklist',
      ariaLabel: 'Coaching Sessions',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'training/catalog',
    component: CatalogPageComponent,
    title: 'Learning & Events',
    data: {
      icon: 'mortarboard',
      ariaLabel: 'Learning & Events',
      featureFlags: [FEATURE_FLAGS.trainingSessions],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'reporting',
    title: 'Reporting',
    component: ReportingPageComponent,
    data: {
      ...AUTH_GUARDED_ROUTE_COACH.data,
      icon: 'graph-up',
      ariaLabel: 'Reporting',
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'plans',
    title: 'Plans',
    component: PlansPageComponent,
    data: {
      icon: 'compass',
      ariaLabel: 'Plans',
      featureFlags: [FEATURE_FLAGS.implementationPlan],
    },
  },
  {
    ...AUTH_GUARDED_ROUTE,
    path: 'settings',
    component: SettingsComponent,
    title: 'Settings',
    data: {
      // ...AUTH_GUARDED_ROUTE_OPERATOR.data,
      // ...AUTH_GUARDED_ROUTE_REMOTE_OPERATOR.data,
      roles: [UserRole.REMOTE_OPERATOR, UserRole.OPERATOR],
      icon: 'gear-wide-connected',
      ariaLabel: 'Settings',
    },
  },
  {
    ...AUTH_ROUTE_GUARDED_ROUTE,
    path: 'style-library',
    component: StyleLibraryComponent,
    title: 'Style Library',
    data: {
      icon: 'collection',
      ariaLabel: 'Style Library',
    },
  },
];

export const getSidebarRoute = (path: string): Route | undefined =>
  sidebarRoutes.find((route) => route.path === path);
