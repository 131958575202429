import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { StrandDTO } from '../../dtos/attendee-rubric.dto';

@Component({
  selector: 'app-strand-select',
  templateUrl: './strand-select.component.html',
  styleUrls: ['./strand-select.component.scss'],
  standalone: false,
})
export class StrandSelectComponent implements OnChanges {
  labelId = uuidv4();

  @Input() label = 'Search for and select a strand';

  @Input() strands: StrandDTO[];

  @Input() placeholder = 'Strand';

  @Input() isDisabled = false;

  @Output() readonly selectedStrand: EventEmitter<StrandDTO> =
    new EventEmitter();

  internalSelectedStrand: StrandDTO | null;

  searchLoading = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['strands']) {
      this.reset();
    }
  }

  strandSelected(strand: StrandDTO) {
    this.internalSelectedStrand = strand;
    this.selectedStrand.emit(strand);
  }

  reset() {
    this.internalSelectedStrand = null;
  }
}
