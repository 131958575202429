import { Component, Input, OnInit } from '@angular/core';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import { CardStorageService } from 'src/app/common/services/storage/card-storage';
import { BadgeDataDTO } from '../../../dtos/badge.dto';

@Component({
  selector: 'app-card-recent-badges',
  templateUrl: './card-recent-badges.component.html',
  styleUrls: ['./card-recent-badges.component.scss'],
  standalone: false,
})
export class CardRecentBadgesComponent implements OnInit {
  @Input() userId: number;

  badges: BadgeDataDTO[];

  isCollapsed = this.cardStorageService.getCollapsedState('recentbadges');

  constructor(
    private readonly cardStorageService: CardStorageService,
    private readonly apicoreService: APICoreService
  ) {}

  ngOnInit() {
    this.apicoreService
      .getRequest('growelab/badges/recent')
      .subscribe((results) => {
        this.badges = results.items.filter(
          (badge: { type: string }) => badge.type !== ''
        );
      });
  }

  triggerCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.cardStorageService.storeCollapseState(
      'recentbadges',
      this.isCollapsed
    );
  }
}
