import { Component, Input } from '@angular/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { CompetencyListItemDTO } from '../../dtos/competencies.dto';

@Component({
  selector: 'app-current-competencies-list-item',
  templateUrl: './current-competencies-list-item.component.html',
  styleUrls: ['./current-competencies-list-item.component.scss'],
  standalone: false,
})
export class CurrentCompetenciesListItemComponent {
  @Input() competency: CompetencyListItemDTO;

  apiUrl: string;

  constructor() {
    this.apiUrl = EnvironmentService.apiUrl();
  }
}
