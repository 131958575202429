import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserDTO, UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { SingleApiResponse } from 'src/app/common/types/responses/responses';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { DistrictAPIResponse } from './district-list.responses';

export interface GetAllProFormsAPIResponse {
  items: ProFormsAPIResponse[];
  _links: {
    self: string;
    first: string;
    last: string;
    prev?: string;
    next?: string;
  };
  _meta: PaginationDTO;
}

export interface ItemProFormsAPIResponse extends SingleApiResponse {
  item: ProFormsAPIResponse;
}

export interface ProFormsAPIResponse {
  id: number;
  title: string;
  outro: string;
  creator_user_id: number;
  district_id: number;
  is_shared: number;
  anonymity: ProFormAnonymity;
  status: ProFormStatus;
  type: ProFormType;
  show_missed: number;
  show_answers: number;
  passing_grade: number;
  sections?: ProFormsSectionAPIResponse[];
  district?: DistrictAPIResponse;
  creator?: UserDTO;
  districtsSharedWith?: ProFormShareItemResponse[];
  districtsExcludedFrom?: ProFormShareItemResponse[];
  submissionCount?: number;
  lastSubmission?: number;
  hasSubmission?: boolean;
}

export enum ProFormAnonymity {
  NONE = 'none',
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}

export enum ProFormType {
  SURVEY = 'survey',
  QUIZ = 'quiz',
}

export enum ProFormStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export interface ProFormsSectionAPIResponse {
  id: number;
  proform_form_id: number;
  title: string;
  description: string;
  next_section: string;
  sort_order: number;
  questions: ProFormsQuestionAPIResponse[];
}

export interface ProFormsQuestionAPIResponse {
  id: number;
  proform_form_id: number;
  field_type: string;
  question: string;
  helper_text: string;
  required: number;
  allow_other_answer: number;
  sort_order: number;
  is_deleted: number;
  field_type_specs: string;
  proform_form_section_id: number;
  answerOptions: ProFormsAnswerOptionAPIResponse[];
}

export interface UpdateProFormsQuestionAPIResponse extends SingleApiResponse {
  item: {
    proform_form_section_id: number;
    proform_form_id: number;
    field_type: string;
    question: string;
    helper_text: string;
    sort_order: number;
    id: number;
  };
}

export interface ProFormsAnswerOptionAPIResponse {
  id: number;
  proform_form_id: number;
  proform_form_question_id: number;
  answer: string;
  is_correct: number;
  sort_order: number;
  is_deleted: number;
  next_section_override: string;
}

export interface ProFormShareItemResponse {
  id: number;
  proform_form_id: number;
  district_id: number;
  is_shared: number;
  district: DistrictSimpleDto;
}

export interface GetAllProFormsSubmissionsAPIResponse {
  items: ProFormsSubmissionAPIResponse[];
  _links: {
    self: string;
    first: string;
    last: string;
    prev?: string;
    next?: string;
  };
  _meta: PaginationDTO;
}

export interface ProFormsSubmissionAPIResponse {
  id: number;
  proform_form_id: number;
  user_id: number;
  context_table: string;
  context_table_id: number;
  is_deleted: number;
  grade: number;
  pass_or_fail: string;
  user?: UserLiteDTO;
  answers?: ProFormsAnswersAPIResponse[];
}

export interface ProFormsAnswersAPIResponse {
  id: number;
  proform_form_submission_id: number;
  proform_form_question_id: number;
  proform_form_question_answer_option_id: number;
  text_answer: string;
  is_deleted: number;
}

export interface CreateUpdateSectionAPIResponse extends SingleApiResponse {
  item: {
    proform_form_id: number;
    next_section: string;
    title: string;
    description: string;
    sort_order: number;
    id: number;
  };
}

export interface ShareFormAPIResponse extends SingleApiResponse {
  item: {
    proform_form_id: number;
    district_id: number;
    is_shared: number;
    id: number;
  };
}

export interface UpdateFormAPIResponse extends SingleApiResponse {
  item: ProFormsAPIResponse;
}

export interface CreateAnswerOptionAPIResponse extends SingleApiResponse {
  item: {
    proform_form_id: number;
    proform_form_question_id: number;
    answer: string;
    sort_order: number;
    id: number;
  };
}

export interface UpdateAnswerOptionAPIResponse extends SingleApiResponse {
  item: {
    id: number;
    proform_form_id: number;
    proform_form_question_id: number;
    answer: string;
    is_correct: null;
    sort_order: number;
    next_section_override: string;
  };
}
