import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NgbDate,
  NgbDatepicker,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  dateFromNgbDate,
  ngbDateFromDate,
} from 'src/app/common/utilities/date-helpers';
import { select } from 'src/app/common/utilities/ngxs-utils';
import {
  TimezoneLinkBack,
  getTimezoneLinkBackFromValue,
} from 'src/app/common/utilities/time-helpers';
import { DAY_IN_MILLISECONDS } from 'src/app/common/utilities/time.definitions';
import {
  CoachingSessionTypeDTO,
  NewCoachingSessionItemsPayloadDTO,
  NewCoachingSessionUserDto,
  SmartCoachingSessionDTO,
} from '../../dtos/coaching-session.dto';
import { CoachingLogService } from '../../services/coaching-log/coaching-log.service';
import { LogTypeSearchService } from '../../services/log-type-search/log-type-search.service';
import { CoachDialogService } from '../../services/smart-coach/coach-dialog/coach-dialog.service';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-coaching-plan',
  templateUrl: './intro-coaching-plan.component.html',
  styleUrls: ['./intro-coaching-plan.component.scss'],
  standalone: false,
})
export class IntroCoachingPlanComponent implements OnInit {
  user$: Observable<User | null> = select(UserState.getUser);

  user: User | null = null;

  currentStep = 0;

  previousSessionDate: NgbDate;

  nextSessionDate: NgbDate;

  selectedDates: string[] = [];

  @ViewChild('d1') d1: NgbInputDatepicker;

  @ViewChild('d2') d2: NgbInputDatepicker;

  @ViewChild('d3') d3: NgbInputDatepicker;

  @ViewChild('d4') d4: NgbInputDatepicker;

  @ViewChild('d5') d5: NgbInputDatepicker;

  @ViewChild('d6') d6: NgbInputDatepicker;

  @ViewChild('d7') d7: NgbInputDatepicker;

  @ViewChild('mobiledp') mobiledp: NgbDatepicker;

  smartLogType: CoachingSessionTypeDTO;

  coachlogItemsPayload: NewCoachingSessionItemsPayloadDTO = {
    title: '',
    logType: null,
    startDatetime: 0,
    endDatetime: 0,
    timezone: TimezoneLinkBack.Central,
    coachUserId: 0,
    creatorUserId: 0,
    coachees: [],
    shadowers: [],
    is_private: false,
  };

  firstLogCreated = false;

  constructor(
    private store: Store,
    private coachingLogTypeSearchService: LogTypeSearchService,
    public introSessionService: IntroSessionService,
    private coachingLogService: CoachingLogService,
    private smartcoachDialog: CoachDialogService
  ) {}

  ngOnInit() {
    this.user = this.store.selectSnapshot(UserState.getUser);
    this.loadSmartLogType();
    this.getSmartLogs();
  }

  onDateSelect(date: NgbDate, stepsCompleted: number) {
    this.selectedDates[
      stepsCompleted - 1
    ] = `${date.month}/${date.day}/${date.year}`;
    const nextDate = dateFromNgbDate(date);
    const minDate = new Date(nextDate);
    minDate.setDate(minDate.getDate() + 1);
    this.previousSessionDate = ngbDateFromDate(minDate);
    nextDate.setDate(nextDate.getDate() + 14);
    this.nextSessionDate = ngbDateFromDate(nextDate);

    this.coachlogItemsPayload.startDatetime =
      dateFromNgbDate(date).getTime() / 1000;
    this.createSmartLog();
    this.toggleButtons(stepsCompleted);
  }

  toggleButtons(stepsCompleted: number) {
    if (stepsCompleted >= 7) {
      this.d7.close();
      this.currentStep = 8;
    } else {
      switch (stepsCompleted) {
        case 0:
          this.d1.open();
          this.currentStep = 1;
          break;
        case 1:
          this.d1.close();
          this.d2.open();
          if (this.nextSessionDate) {
            this.d2.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 2;
          this.completePlan();
          break;
        case 2:
          this.d2.close();
          this.d3.open();
          if (this.nextSessionDate) {
            this.d3.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 3;
          break;
        case 3:
          this.d3.close();
          this.d4.open();
          if (this.nextSessionDate) {
            this.d4.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 4;
          break;
        case 4:
          this.d4.close();
          this.d5.open();
          if (this.nextSessionDate) {
            this.d5.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 5;
          break;
        case 5:
          this.d5.close();
          this.d6.open();
          if (this.nextSessionDate) {
            this.d6.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 6;
          break;
        case 6:
          this.d6.close();
          this.d7.open();
          if (this.nextSessionDate) {
            this.d7.navigateTo(this.nextSessionDate);
          }
          this.currentStep = 7;
          break;
        case 7:
          this.d7.close();
          this.currentStep = 8;
          break;
        default:
          this.currentStep = 1;
      }
      this.mobiledp.navigateTo(this.nextSessionDate);
    }
  }

  /* eslint-disable no-useless-return  */
  toggleStep(step: number) {
    if (step < this.currentStep) {
      return;
    }

    switch (step) {
      case 1:
        this.d1.toggle();
        break;
      case 2:
        this.d2.toggle();
        break;
      case 3:
        this.d3.toggle();
        break;
      case 4:
        this.d4.toggle();
        break;
      case 5:
        this.d5.toggle();
        break;
      case 6:
        this.d6.toggle();
        break;
      case 7:
        this.d7.toggle();
        break;
      default:
        return;
    }
  }

  loadSmartLogType() {
    this.coachingLogTypeSearchService.getLogType(27).subscribe((res) => {
      this.smartLogType = res;
    });
  }

  getSmartLogs() {
    if (this.user) {
      this.coachingLogService.getSmartLogs(this.user.id).subscribe((res) => {
        if (res) {
          if (res.items.length > 0) {
            this.firstLogCreated = true;
            res.items
              .sort(
                (a: SmartCoachingSessionDTO, b: SmartCoachingSessionDTO) => {
                  if (a.start_datetime > b.start_datetime) {
                    return 1;
                  }
                  if (a.start_datetime < b.start_datetime) {
                    return -1;
                  }
                  return 0;
                }
              )
              .forEach((session: SmartCoachingSessionDTO, index: number) => {
                const sessionDate = new Date(session.start_datetime * 1000);
                this.selectedDates[index] = `${
                  sessionDate.getMonth() + 1
                }/${sessionDate.getDate()}/${sessionDate.getFullYear()}`;
              });
            if (this.firstLogCreated) {
              this.addFirstSessionMessage(res.items[0].start_datetime);
            }
            const previousSession = new Date(
              res.items[res.items.length - 1].start_datetime * 1000
            );
            previousSession.setDate(previousSession.getDate() + 1);
            this.previousSessionDate = ngbDateFromDate(previousSession);

            const nextSession = new Date(
              previousSession.getTime() + 14 * DAY_IN_MILLISECONDS
            );
            this.nextSessionDate = ngbDateFromDate(nextSession);
          }
          this.toggleButtons(res.items.length);
        }
      });
    }
  }

  createSmartLog() {
    if (this.user) {
      const user: NewCoachingSessionUserDto = {
        id: this.user.id,
        profile: this.user.profile,
      };
      this.coachlogItemsPayload.logType = this.smartLogType;
      this.coachlogItemsPayload.coachees = [user];
      if (this.user.district) {
        this.coachlogItemsPayload.timezone = getTimezoneLinkBackFromValue(
          this.user.district.timezone
        );
      }
    }
    this.coachingLogService.createSmartlog(this.coachlogItemsPayload);
    this.coachingLogService.smartCoachlogCreated.subscribe((log) => {
      if (log) {
        if (!this.firstLogCreated) {
          this.addFirstSessionMessage(log.start_datetime);
          this.firstLogCreated = true;
        }
      }
    });
  }

  completePlan() {
    this.introSessionService.setPlanComplete(true, true);
  }

  addFirstSessionMessage(date: number) {
    const startDate = new Date(date * 1000);
    const dateString = `${startDate.toLocaleString('default', {
      month: 'long',
    })} ${startDate.getDate()}, ${startDate.getFullYear()}`;
    this.smartcoachDialog.outputMessages.next([
      {
        message: `Your first official mySmartCoach Session is ${dateString}! We recommend you schedule all 7 sessions here. If you have any questions between now and then, you can always contact our team by accessing the Help Center on the bottom left of the screen.`,
        group: '1',
      },
    ]);
  }
}
