import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadStyles } from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import { FroalaOptions } from 'src/app/private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { BadgeDataDTO } from 'src/app/private/shared/dtos/badge.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { getIcon } from 'src/app/private/shared/helpers/icon.utilities';
import { BadgeFileService } from 'src/app/private/shared/services/file/badge.file.service';
import { TopicBuilderService } from 'src/app/private/shared/services/topic-builder/topic-builder.service';
import { CompetencyBuilderIndicatorDTO } from 'src/app/private/shared/types/responses/competency-builder.responses';

@Component({
  selector: 'app-builder-leveled-competency-strand-level',
  templateUrl: './builder-leveled-competency-strand-level.component.html',
  styleUrls: ['./builder-leveled-competency-strand-level.component.scss'],
  standalone: false,
})
export class BuilderLeveledCompetencyStrandLevelComponent implements OnInit {
  @Input() level: number;

  @Input() levelName: string;

  @Input() strand: CompetencyBuilderIndicatorDTO;

  @Input() badgeData: BadgeDataDTO;

  @Input() apiLoading: boolean;

  @Output() readonly badgeGraphicUploadedEvent = new EventEmitter();

  @Output() readonly updatingEvent = new EventEmitter<string>();

  isDrawerCollapsed = true;

  isOptionCollapsed = true;

  uploadStyle = UploadStyles.BADGE;

  badgeExists = false;

  uploadType = UploadType.BADGE;

  froalaOptions: FroalaOptions = {
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline'],
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: ['formatUL'],
        buttonsVisible: 1,
      },
      moreRich: {
        buttons: ['insertLink'],
        buttonsVisible: 1,
      },
    },
    editorClass: 'builder-strand-level-content',
    wordCounterCount: false,
    toolbarInline: true,
    toolbarVisibleWithoutSelection: true,
    toolbarInlineClass: 'builder-strand-level-content-froala',
  };

  currentLevel = false;

  content: string;

  constructor(
    private topicBuilderService: TopicBuilderService,
    private badgeFileService: BadgeFileService
  ) {}

  ngOnInit(): void {
    if (
      this.badgeData?.image &&
      this.badgeData?.image !== '/img/e2L.Logo.webp'
    ) {
      this.badgeExists = true;
      this.badgeData.image = getIcon(this.badgeData.image);
      this.uploadStyle = UploadStyles.NONE;
    }

    if (this.strand && this.level) {
      this.froalaOptions.toolbarContainer = `#froala-container-strand-${this.strand.id}-level-${this.level}`;
      this.content = this.strand.indicator.content;
    }
  }

  updateContent(content: string) {
    // currentLevel variable is used so the current content being edited does not get disabled
    this.currentLevel = true;
    this.content = content;
    this.topicBuilderService.topicBuilderAPILoadingSource.next(true);
    if (content) {
      this.topicBuilderService
        .updateIndicatorContent(this.strand.indicator.id, content)
        .subscribe((res) => {
          if (res) {
            this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
            this.currentLevel = false;
          }
        });
    } else {
      this.topicBuilderService.topicBuilderAPILoadingSource.next(false);
      this.currentLevel = false;
    }
  }

  onTypingEvent() {
    this.currentLevel = true;
    this.updatingEvent.emit('levelContent');
  }

  setBadgeId() {
    if (this.badgeData) {
      this.badgeFileService.setBadgeId(this.badgeData.id);
    }
  }
}
