/* eslint-disable no-unused-vars  */
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgxsModule, Store } from '@ngxs/store';
import { MockProvider } from 'ng-mocks';
import { of } from 'rxjs';
import { SelectableCoach } from 'src/app/private/pages/coach-list-page/selectable.coach';
import { SelectableCoachee } from 'src/app/private/pages/coachee-list-page/selectable.coachee';
import {
  MOCK_COACHING_LOG_ATTENDANCE_PUSHER_PROVIDER,
  MOCK_COACHING_LOG_PUSHER_PROVIDER,
} from 'src/app/private/shared/services/coaching-log/coaching-log-pusher.service.mocks';
import { UserPusherService } from 'src/app/private/shared/services/pusher/user.pusher.service';
import { SchoolSearchService } from 'src/app/private/shared/services/school-search/school-search.service';
import { UserSearchService } from 'src/app/private/shared/services/user-search/user-search.service';
import { CoachingLogState } from 'src/app/private/shared/state/coaching-log/coaching-log.state';
import { ReportingState } from 'src/app/private/shared/state/reporting/reporting.state';
import { DistrictAPIResponse } from 'src/app/private/shared/types/responses/district-list.responses';
import { AvatarDTO } from '../../../private/shared/components/avatar/avatar.dto';
import { districtDTOFromAPIResponse } from '../../dtos/translators/district.translator';
import { SingleApiResponse } from '../../types/responses/responses';
import { BooleanNumber } from '../../utilities/enums/boolean-number.enum';
import { State } from '../../utilities/enums/state.enum';
import { MOCK_SCHOOL_YEAR } from '../../utilities/mocks/reporting.mocks';
import {
  MOCK_B2B_DISTRICT_RESPONSE,
  MOCK_DISTRICT_RESPONSE,
  MOCK_PROFILE,
  MOCK_PROFILE_COACH,
  MOCK_SCHOOL,
} from '../state.mocks';
import { UserRole } from './role/user-role';
import {
  MOCK_USER_ROLE_DTO_OPERATOR,
  MOCK_USER_ROLE_DTO_USER,
} from './role/user-role.mocks';
import { UserType } from './type/user-type';
import { UserDTO } from './user.dto';
import { User } from './user.model';
import { UserService } from './user.service';
import { UserState } from './user.state';
import { userFromDTO } from './user.utilities';

export const MOCK_USER_DTO: UserDTO = {
  id: 44408,
  username: 'matt@engage2learn.org',
  email: 'matt@engage2learn.org',
  status: 10,
  district: MOCK_DISTRICT_RESPONSE,
  school: MOCK_SCHOOL,
  profile: MOCK_PROFILE,
  roles: [MOCK_USER_ROLE_DTO_USER],
  coachee_type: 'Teacher',
  is_deleted: BooleanNumber.FALSE,
  is_smart: BooleanNumber.FALSE,
  is_smart_default: null,
  smartCoach: null,
  years_coached: 1,
  activityStreamToken: '13jedd',
};

export const MOCK_DISTRICT_API_RESPONSE: DistrictAPIResponse = {
  calendarBlocks: [],
  created_at: 1614787200,
  updated_at: 1614787200,
  data_contact_email: 'fake@district.isd',
  default_coach_timeline_id: 1,
  default_principal_timeline_id: 1,
  default_teacher_timeline_id: 1,
  default_private_logs: 0,
  id: 2,
  reporting_school_year: MOCK_SCHOOL_YEAR.id.toString(),
  hubspot_company_id: '1234',
  district_code: 'engage2learn',
  is_b2b: 0,
  is_internal: 1,
  tenantId: 2,
  parentDistrictId: 0,
  title: 'engage2learn',
  address: '',
  address2: '',
  city: '',
  state: State.TX,
  zip: '',
  phone: '',
  domain: 'engage2learn.org',
  is_deleted: 0,
  logo: '/uploads/user_upload/202211/eSuiteLogoHorizontal.png',
  timezone: 'US/Central',
  counts: {
    users: 295,
    schools: 38,
  },
  schools: [],
  why: '',
  users_can_login: 1,
  nightly_data_exports: 0,
};

export const MOCK_DISTRICT_DTO = districtDTOFromAPIResponse(
  MOCK_DISTRICT_API_RESPONSE
);

export const MOCK_USER_STATE_DTO: User = {
  id: 44408,
  coach: null,
  username: 'matt@engage2learn.org',
  profile: MOCK_PROFILE,
  type: UserType.COACH,
  status: 10,
  email: 'matt@engage2learn.org',
  district: MOCK_DISTRICT_DTO,
  school: MOCK_SCHOOL,
  roles: [UserRole.COACH],
  isDeleted: false,
  isSmart: false,
  isSmartDefault: false,
  smartCoach: null,
  yearsCoached: 1,
  isCoach: false,
  isLeader: false,
  fullName: 'matt webster',
  createdAt: 1614787200,
  coacheeType: UserType.LEARNER,
  lastLoginMs: 1614787200,
};

export const MOCK_USER_COACH_DTO: UserDTO = {
  ...MOCK_USER_DTO,
  id: 44409,
  username: 'shannon@engage2learn.org',
  email: 'shannon@engage2learn.org',
  status: 10,
  district: MOCK_DISTRICT_RESPONSE,
  school: MOCK_SCHOOL,
  profile: MOCK_PROFILE_COACH,
  coachee_type: UserType.COACH,
  roles: [MOCK_USER_ROLE_DTO_USER],
};

export const MOCK_USER = {
  ...userFromDTO(MOCK_USER_DTO),
};

export const MOCK_USER_WITH_ROLE = {
  ...MOCK_USER,
  roles: [UserRole.USER],
};

export const MOCK_USERS_COACH_USER = userFromDTO(MOCK_USER_COACH_DTO);
export const MOCK_USER_IN_B2B_DISTRICT = userFromDTO({
  ...MOCK_USER_DTO,
  district: MOCK_B2B_DISTRICT_RESPONSE,
});
export const MOCK_USER_NO_ROLES = userFromDTO({ ...MOCK_USER_DTO, roles: [] });
export const MOCK_USER_OPERATOR = userFromDTO({
  ...MOCK_USER_DTO,
  roles: [MOCK_USER_ROLE_DTO_OPERATOR],
});

export const MOCK_USER_COACH = userFromDTO(MOCK_USER_COACH_DTO);

export const MOCK_SELECTABLE_COACHEE: SelectableCoachee = {
  id: 44408,
  username: 'matt@engage2learn.org',
  profile: MOCK_PROFILE,
  badgeCount: 1,
  celebrationsCount: 0,
  sessionCount: 5,
  observationsCount: 0,
  nextSessionDate: null,
  nextSessionId: null,
  personalNotes: 'Great job',
  isSelected: true,
  cohorts: [
    {
      id: 183879,
      tag: 'DISD Central Learning Community',
      slug: 'disd-central-learning-community',
      group: 'Cohort',
      refTable: 'user',
      refTableId: 61468,
      createdAt: 1664503193,
      updatedAt: 1664503193,
      userCount: 1,
    },
  ],
};

export const MOCK_SELECTABLE_COACH: SelectableCoach = {
  id: 65921,
  username: 'allison@engage2learn.org',
  email: 'allison@engage2learn.org',
  status: 10,
  district_id: 2,
  school_id: 3288,
  estudiolegacy_uid: 36006,
  created_at: 1660927088,
  updated_at: 1722442565,
  is_deleted: 0,
  last_login: 1722349936,
  elx_saved_search_path: '',
  coachee_type: 'coach',
  years_coached: 1,
  exclude_from_reports: 0,
  is_smart: 0,
  is_smart_default: 0,
  smartCoach: null,
  already_set_school: 1,
  asked_for_google_calendar_sync: 1,
  last_login_method: 'Database',
  getstream_session_token: '0',
  profile: {
    id: 65919,
    user_id: 65921,
    first_name: 'Allison',
    last_name: 'Hillary',
    title: 'e2L Teacher Coach',
    color: '#0314b6',
    why: '',
    bio: '',
    website: '',
    facebook: '',
    twitter: '',
    instagram: '',
    googleplus: '',
    youtube: '',
    created_at: 1660927088,
    updated_at: 1668630820,
    photo:
      '/uploads/profile_pics/202210/8E0D703C942C48BB98DA530D2B67D35E_1_105_c.jpeg',
    is_influencer: 0,
    yoe: 20,
    strongest_egrowe_standard_id: 0,
    strongest_egrowe_standard_why: 'null',
    weakest_egrowe_standard_id: 0,
    weakest_egrowe_standard_why: 'null',
    goals_for_your_learners: 'null',
    goals_for_coaching: 'null',
    photoUrl:
      'https://api.estudio.local:8010/uploads/profile_pics/202210/8E0D703C942C48BB98DA530D2B67D35E_1_105_c.jpeg',
  },
  coachstats: {
    average_feedback_rating: 4,
    average_coachee_confidence_rating: 1,
    average_badges_per_session: 0,
    average_sessions_per_coachee: 1,
    average_gis_per_session: 1,
    celebrations_given: 0,
  },
  personalNotes: 'Well hello there you! test 2222',
  isSelected: false,
};

export const MOCK_REGULAR_USER = { ...MOCK_USER, roles: [UserRole.USER] };
export const MOCK_OPERATOR_USER = { ...MOCK_USER, roles: [UserRole.OPERATOR] };
export const MOCK_E2L_EMPLOYEE_USER = {
  ...MOCK_USER,
  roles: [UserRole.E2L_EMPLOYEE],
};

export const MOCK_LEARNER_USER = { ...MOCK_USER, type: UserType.LEARNER };
export const MOCK_LEADER_USER = {
  ...MOCK_USER,
  type: UserType.LEADER,
  roles: [UserRole.DISTRICT_ADMIN],
  isLeader: true,
  isCoach: false,
};

export const MOCK_LEADER_COACH_USER = {
  ...MOCK_USER,
  type: UserType.LEADER,
  roles: [UserRole.DISTRICT_ADMIN, UserRole.COACH],
  isLeader: true,
  isCoach: true,
};

export const MOCK_COACH_USER = {
  ...MOCK_USER,
  type: UserType.COACH,
  roles: [UserRole.COACH],
  isCoach: true,
};

export const MOCK_OPERATOR_COACH_USER = {
  ...MOCK_USER,
  type: UserType.COACH,
  roles: [UserRole.OPERATOR],
};

export const MOCK_USER_STATE_DEFAULT = {
  hasData: false,
  isLoading: false,
  user: null,
};

export const MOCK_USER_STATE_LOADING = {
  ...MOCK_USER_STATE_DEFAULT,
  isLoading: true,
};

export const MOCK_USER_STATE_SUCCESS = {
  hasData: true,
  isLoading: false,
  user: MOCK_USER,
};

export const MOCK_USER_API_RESPONSE: SingleApiResponse = {
  item: MOCK_USER_DTO,
};

export const MOCK_USER_COACH_API_RESPONSE: SingleApiResponse = {
  item: MOCK_USER_COACH,
};

export const STATE_TEST_PROVIDERS_MINUS_STORE = [
  MOCK_COACHING_LOG_PUSHER_PROVIDER,
  MOCK_COACHING_LOG_ATTENDANCE_PUSHER_PROVIDER,
  MockProvider(UserPusherService),
  MockProvider(SchoolSearchService, {
    getAssignedSchools: () => of([]),
  }),
  MockProvider(UserSearchService, {}),
  MockProvider(UserService, {
    fetchUser: () => of(MOCK_USER_API_RESPONSE),
  }),
];

export const STATE_TEST_CONFIG = {
  imports: [NgxsModule.forRoot([UserState, CoachingLogState, ReportingState])],
  providers: [
    ...STATE_TEST_PROVIDERS_MINUS_STORE,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: Store,
      useValue: {
        selectSnapshot: () => MOCK_USER,
        select: () => of(),
        dispatch: () => {},
        reset: () => {},
      },
    },
  ],
};

export const MOCK_SMART_USER_DTO = {
  ...MOCK_USER,
  type: UserType.COACH,
  roles: [UserRole.SMART_COACH_USER],
};

export const MOCK_AVATAR_DTO: AvatarDTO = {
  firstName: 'first',
  lastName: 'last',
  color: 'black',
};

export const MOCK_SMART_USER_COMPLETE_DTO = {
  ...MOCK_USER,
  type: UserType.COACH,
  roles: [UserRole.SMART_COACH_USER],
  profile: {
    ...MOCK_USER.profile,
    strongest_egrowe_standard_id: 22,
    strongest_egrowe_standard_why: 'strongest completed',
    weakest_egrowe_standard_id: 22,
    weakest_egrowe_standard_why: 'weakest completed',
    why: 'completed',
  },
};

export const MOCK_USER_LITE_DTO = {
  id: 72209,
  email: 'caleb.lee@engage2learn.org',
  status: 10,
  district_id: 2,
  school_id: 3295,
  profile: {
    user_id: 72209,
    first_name: 'Caleb',
    last_name: 'Lee',
    title: 'Full Stack Developer',
    color: '#d1567b',
    photo: '/uploads/user_upload/202412/profpiccropped_1734628952.png',
  },
};
