import { Component, Input, OnInit } from '@angular/core';

import { AbstractViewFieldChoicesComponent } from '../base/abstract-view-field-choices';

@Component({
  selector: 'app-form-view-radio',
  templateUrl: './view-radio.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
  standalone: false,
})
export class FormViewRadioComponent
  extends AbstractViewFieldChoicesComponent
  implements OnInit
{
  @Input() initialValue: string | undefined;

  ngOnInit(): void {
    if (this.initialValue) {
      this.selection = this.initialValue;
    }
  }

  chooseSelection(value: string) {
    if (!this.inputDisabled) {
      this.selection = value;
      this.value.emit(this.selection);
    }
  }
}
