<ng-container [ngSwitch]="filterType">
  <div *ngSwitchCase="'coaching-sessions'">
    <span class="filters-label">Applied Filters:</span>&nbsp; &nbsp;
    <div
      class="badge badge-item"
      *ngIf="coachlogSearchOptions.from_date && coachlogSearchOptions.to_date">
      <span class="fw-800"><i class="bi bi-table"></i> Dates: </span
      >{{
        coachlogSearchOptions.from_date
          | showDateTimezone : "MMM d, yyyy" : undefined : false
      }}
      -
      {{
        coachlogSearchOptions.to_date
          | showDateTimezone : "MMM d, yyyy" : undefined : false
      }}
    </div>
    <div
      class="badge badge-item"
      *ngIf="coachlogSearchOptions.district_item"
      (click)="
        this.filterType === 'celebrations'
          ? removeFilter('district_item')
          : null
      ">
      <span class="fw-800"><i class="bi bi-geo-alt"></i> District: </span
      ><app-district-name
        [districtId]="coachlogSearchOptions.district_item.id" />&nbsp;
      <i class="bi bi-x" *ngIf="this.filterType === 'celebrations'"></i>
    </div>
    <ng-container *ngFor="let school of coachlogSearchOptions.school_items">
      <div
        class="badge badge-item removable"
        (click)="removeFilterFromList('schools', school)">
        <span class="fw-800"><i class="bi bi-building"></i> School: </span>
        <app-school-name [schoolId]="school.id" />&nbsp; <i class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        !canSeeAllSchools &&
        (!coachlogSearchOptions.school_items ||
          coachlogSearchOptions.school_items.length === 0)
      ">
      <div class="badge badge-item removable">
        <span class="fw-800"><i class="bi bi-building"></i> School: </span>
        All Assigned Schools
      </div>
    </ng-container>
    <div
      class="badge badge-item removable"
      *ngIf="coachlogSearchOptions.logtype_item"
      (click)="removeFilter('logtype')">
      <span class="fw-800"><i class="bi bi-collection"></i> Session Type:</span>
      {{ coachlogSearchOptions.logtype_item.title }}&nbsp;
      <i class="bi bi-x"></i>
    </div>

    <ng-container
      *ngFor="let competency of coachlogSearchOptions.competency_items">
      <div
        class="badge badge-item removable"
        (click)="removeFilterFromList('competency_items', competency)">
        <span class="fw-800"><i class="bi bi-bi-person"></i> Competency: </span>
        <app-competency-name [competencyId]="competency.id" />&nbsp;
        <i class="bi bi-x"></i>
      </div>
    </ng-container>

    <ng-container *ngFor="let coach of coachlogSearchOptions.coach_items">
      <div
        class="badge badge-item removable"
        (click)="removeFilterFromList('coach_items', coach)">
        <span class="fw-800"><i class="bi bi-bi-person"></i> Coach: </span>
        <app-users-name [userProfile]="coach.profile" />&nbsp;
        <i class="bi bi-x"></i>
      </div>
    </ng-container>

    <ng-container *ngFor="let coachee of coachlogSearchOptions.coachee_items">
      <div
        class="badge badge-item removable"
        (click)="removeFilterFromList('coachee_items', coachee)">
        <span class="fw-800"><i class="bi bi-bi-person"></i> Coachee: </span>
        <app-users-name [userProfile]="coachee.profile" />&nbsp;
        <i class="bi bi-x"></i>
      </div>
    </ng-container>
  </div>

  <div *ngSwitchCase="'celebrations'" class="d-flex align-items-center">
    <span class="filters-label">Applied Filters:</span>&nbsp; &nbsp;
    <div
      class="nofilter"
      *ngIf="
        !celebrationSearchOptions.fromDate &&
        !celebrationSearchOptions.toDate &&
        !celebrationSearchOptions.district &&
        !celebrationSearchOptions.school &&
        !celebrationSearchOptions.hasAttachment &&
        celebrationSearchOptions.coacheeList.length === 0
      ">
      No filters applied &nbsp;
    </div>
    <div
      (click)="removeFilter('date')"
      class="badge badge-item removable"
      *ngIf="
        celebrationSearchOptions.fromDate && celebrationSearchOptions.toDate
      ">
      <span class="fw-800"><i class="bi bi-table"></i> Dates: </span>
      {{
        celebrationFromDate
          | showDateTimezone : "MMM d, yyyy" : undefined : false
      }}
      -
      {{
        celebrationToDate
          | showDateTimezone : "MMM d, yyyy" : undefined : false
      }}&nbsp;
      <i class="bi bi-x"></i>
    </div>
    <div
      class="badge badge-item removable"
      *ngIf="celebrationSearchOptions.district"
      (click)="removeFilter('district')">
      <span class="fw-800"><i class="bi bi-geo-alt"></i> District: </span
      ><app-district-name
        [districtId]="celebrationSearchOptions.district.id" />&nbsp;
      <i class="bi bi-x"></i>
    </div>
    <div
      *ngIf="celebrationSearchOptions.school"
      class="badge badge-item removable"
      (click)="removeFilter('school')">
      <span class="fw-800"><i class="bi bi-building"></i> School: </span>
      <app-school-name [schoolId]="celebrationSearchOptions.school.id" />&nbsp;
      <i class="bi bi-x"></i>
    </div>
    <ng-container *ngIf="!canSeeAllSchools && !celebrationSearchOptions.school">
      <div class="badge badge-item removable">
        <span class="fw-800"><i class="bi bi-building"></i> School: </span>
        All Assigned Schools
      </div>
    </ng-container>
    <div
      *ngIf="celebrationSearchOptions.hasAttachment"
      class="badge badge-item removable"
      (click)="removeFilter('hasAttachment')">
      <span class="fw-800"
        ><i class="bi bi-paperclip"></i> Has Attachment
      </span>
      <i class="bi bi-x"></i>
    </div>
    <ng-container *ngFor="let coachee of celebrationSearchOptions.coacheeList">
      <div
        class="badge badge-item removable"
        (click)="removeFilterFromList('coacheeList', coachee)">
        <span class="fw-800"><i class="bi bi-bi-person"></i> Coachee: </span>
        <app-users-name [userProfile]="coachee.profile" />&nbsp;
        <i class="bi bi-x"></i>
      </div>
    </ng-container>
  </div>

  <ng-container *ngSwitchCase="'topic-builder'">
    <div class="d-flex flex-column flex-lg-row align-items-lg-center">
      <div class="filters-label mt-2 me-2">Applied Filters:</div>
      <div class="filter-wrapper mt-2 mt-md-0">
        <div
          *ngIf="topicPageSearchOptions.districts.length === 0"
          class="nofilter mt-2 text-center text-lg-start">
          No filters applied &nbsp;
        </div>
        <ng-container *ngFor="let district of topicPageSearchOptions.districts">
          <div
            class="badge badge-item mt-2 me-1 removable"
            (click)="removeFilterFromList('districts', district)">
            <span class="fw-800"><i class="bi bi-geo-alt"></i> District: </span>
            <app-district-name [districtId]="district" />&nbsp;
            <i class="bi bi-x"></i>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div *ngSwitchCase="'pd-list'">
    <span class="filters-label me-3">Applied Filters:</span>
    <div
      *ngIf="pdFromDate && pdToDate"
      class="badge badge-item removable"
      (click)="removeFilter('date')">
      <span class="fw-800"><i class="bi bi-table"></i> Dates: </span
      >{{ pdFromDate | showDateTimezone : "MMM d, yyyy" : undefined : false }}
      -
      {{ pdToDate | showDateTimezone : "MMM d, yyyy" : undefined : false }}
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngIf="pdListSearchOptions.keyword"
      class="badge badge-item removable"
      (click)="removeFilter('keyword')">
      <span class="fw-800"><i class="bi bi-search"></i> Keyword: </span>
      {{ pdListSearchOptions.keyword }}
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngFor="let facilitator of pdListSearchOptions.facilitators"
      class="badge badge-item me-1 removable"
      (click)="removeFilterFromList('facilitators', facilitator)">
      <span class="fw-800"><i class="bi bi-person"></i> Facilitator: </span>
      <app-users-name [userId]="facilitator.id" />
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngFor="let type of pdListSearchOptions.types"
      class="badge badge-item me-1 removable"
      (click)="removeFilterFromList('types', type)">
      <span class="fw-800"> Type: </span>
      {{ type }}
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngFor="let category of pdCategoryOptions"
      class="badge badge-item me-1 removable"
      (click)="removeFilterFromList('categories', category)">
      <span class="fw-800"> Category: </span>
      {{ category.category }}: {{ category.option }} &nbsp;<i
        class="bi bi-x"></i>
    </div>

    <div
      *ngFor="let competency of pdListSearchOptions.competencyList"
      class="badge badge-item me-1 removable"
      (click)="removeFilterFromList('competencies', competency)">
      <span class="fw-800"> Competency: </span>
      <app-competency-name [competencyId]="competency.id" />&nbsp; &nbsp;<i
        class="bi bi-x"></i>
    </div>

    <div
      *ngIf="pdListSearchOptions.virtual"
      class="badge badge-item removable"
      (click)="removeFilter('virtual')">
      <span class="fw-800"><i class="bi bi-laptop"></i> Virtual </span>
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngIf="pdListSearchOptions.onsite"
      class="badge badge-item removable"
      (click)="removeFilter('onsite')">
      <span class="fw-800"><i class="bi bi-geo-alt"></i> Onsite </span>
      &nbsp;<i class="bi bi-x"></i>
    </div>

    <div
      *ngIf="pdListSearchOptions.include_completed"
      class="badge badge-item removable"
      (click)="removeFilter('completed')">
      <span class="fw-800"
        ><i class="bi bi-check2-circle"></i> Status: Open and Completed
      </span>
      &nbsp;<i class="bi bi-x"></i>
    </div>
    <button
      *ngIf="showEditFiltersBtn"
      (click)="openModal.emit(true)"
      class="btn btn-link">
      Edit Filters
    </button>
  </div>
</ng-container>
