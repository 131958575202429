import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, retry, throwError } from 'rxjs';
import {
  DistrictDTO,
  DistrictPayloadDTO,
} from 'src/app/common/dtos/district.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { states } from 'src/app/common/utilities/physical-location-helpers';
import { checkIfE2LOperator } from 'src/app/common/utilities/role-helpers';
import { timezones } from 'src/app/common/utilities/time-helpers';
import { APICoreService } from '../../../common/services/api-core/api-core.service';
import { ModalComponent } from '../../shared/components/modals/modal/modal.component';
import { DistrictListService } from '../../shared/services/district-list/district-list-service';
import { DistrictOperatorService } from '../../shared/services/district-operator/district-operator.service';

@Component({
  selector: 'app-edit-district-page',
  templateUrl: './edit-district.component.html',
  styleUrls: ['./edit-district.component.scss'],
  standalone: false,
})
export class EditDistrictComponent implements OnInit {
  @ViewChild('selectOperatorModal') selectOperatorModal: ModalComponent;

  districts: DistrictDTO[];

  selectedDistrict: DistrictDTO | undefined;

  districtCode: string;

  form: FormGroup;

  submitted = false;

  isLoading = false;

  userRoleDict = UserRole;

  licenseCount: number;

  submitLoading = false;

  errorMessage: string;

  states = states;

  timezones = timezones;

  user: User;

  showCreateUserModal = false;

  operators: UserLiteDTO[];

  operatorsLoading = true;

  Roles = UserRole;

  isE2LOperator = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private districtListService: DistrictListService,
    private formBuilder: FormBuilder,
    private apiCoreService: APICoreService,
    private districtOperatorService: DistrictOperatorService
  ) {}

  ngOnInit() {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;

    this.isE2LOperator = checkIfE2LOperator(this.user);

    this.districtCode = this.route.snapshot.paramMap.get(
      'districtCode'
    ) as string;

    this.isLoading = true;

    this.districtListService
      .fetchDistrictsSimple({ per_page: 10000 })
      .subscribe((districts) => {
        if (districts) {
          this.districts = districts as DistrictDTO[];
          this.selectedDistrict = this.districts.find(
            (district) => district.districtCode === this.districtCode
          );
          this.getOperators();

          this.populateForm();
          if (
            this.selectedDistrict?.hubspotCompanyId &&
            (this.user.roles.includes(UserRole.DISTRICT_ADMIN) ||
              this.user.roles.includes(UserRole.E2L_EMPLOYEE))
          ) {
            this.apiCoreService
              .getRequest(
                `data-warehouse/customer/${this.selectedDistrict?.hubspotCompanyId}`
              )
              .pipe(retry(1), catchError(this.handleDWError))
              .subscribe((res) => {
                this.licenseCount = res.item.license_count || 0;
              });
          }
        }
      });
  }

  /* eslint-disable-next-line class-methods-use-this */
  private handleDWError(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message));
  }

  populateForm() {
    this.form = this.formBuilder.group({
      // Temporarily removing this requirement since we can't update Demo district
      // crmId: [this.selectedDistrict?.hubspotCompanyId, Validators.required],
      crmId: [this.selectedDistrict?.hubspotCompanyId],
      districtName: [
        {
          value: this.selectedDistrict?.title,
          disabled: !this.user.roles.includes(UserRole.E2L_EMPLOYEE),
        },
        Validators.required,
      ],
      districtCode: [
        {
          value: this.selectedDistrict?.districtCode,
          disabled: !this.user.roles.includes(UserRole.E2L_EMPLOYEE),
        },
        Validators.required,
      ],
      address1: [this.selectedDistrict?.address],
      address2: [this.selectedDistrict?.address2],
      city: [this.selectedDistrict?.city],
      state: [this.selectedDistrict?.state],
      zipCode: [this.selectedDistrict?.zip],
      timezone: [this.selectedDistrict?.timezone],
      domain: [this.selectedDistrict?.domain, Validators.required],
      description: [''],
      isB2b: [
        {
          value: this.selectedDistrict?.isB2b,
          disabled: !this.user.roles.includes(UserRole.E2L_EMPLOYEE),
        },
      ],
      isInternal: [
        {
          value: this.selectedDistrict?.isInternal,
          disabled: !this.user.roles.includes(UserRole.E2L_EMPLOYEE),
        },
      ],
      usersCanLogin: [
        {
          value: this.selectedDistrict?.users_can_login,
          disabled:
            !this.user.roles.includes(UserRole.E2L_EMPLOYEE) ||
            this.selectedDistrict?.domain === 'engage2learn.org',
        },
      ],
      dataExports: [
        {
          value: this.selectedDistrict?.nightlyDataExports,
          disabled: !this.user.roles.includes(UserRole.E2L_EMPLOYEE),
        },
      ],
    });
    this.isLoading = false;
  }

  get f() {
    return this.form.controls;
  }

  /* eslint-disable-next-line class-methods-use-this */
  formatNumber(num: number): string {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  onCancel() {
    this.router.navigate(['settings']);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submitLoading = true;

    const payload: DistrictPayloadDTO = {
      title: this.f['districtName'].value,
      domain: this.f['domain'].value,
      is_internal: this.f['isInternal'].value ? 1 : 0,
      is_b2b: this.f['isB2b'].value ? 1 : 0,
      address: this.f['address1'].value,
      address2: this.f['address2'].value,
      city: this.f['city'].value,
      state: this.f['state'].value,
      zip: this.f['zipCode'].value,
      phone: '',
      logo: '',
      timezone: this.f['timezone'].value,
      hubspot_company_id: this.f['crmId'].value,
      district_code: this.f['districtCode'].value
        .toLowerCase()
        .replace(/\s/g, ''),
      data_contact_email: '',
      why: '',
      reporting_school_year: this.selectedDistrict?.reportingSchoolYear
        ? this.selectedDistrict.reportingSchoolYear
        : '',
      users_can_login: this.f['usersCanLogin'].value ? 1 : 0,
      nightly_data_exports: this.f['dataExports'].value ? 1 : 0,
    };

    if (this.selectedDistrict) {
      this.districtListService
        .updateDistrict(this.selectedDistrict.id.toString(), payload)
        .subscribe({
          error: (err) => {
            this.submitLoading = false;
            this.errorMessage = err.error.message;
          },
          complete: () => {
            if (!this.user.roles.includes(UserRole.E2L_EMPLOYEE)) {
              this.submitLoading = false;
            } else {
              this.router.navigate(['settings']);
            }
          },
        });
    }
  }

  archiveDistrict() {
    if (this.selectedDistrict) {
      this.districtListService
        .archiveDistrict(this.selectedDistrict.id.toString())
        .subscribe({
          error: (err) => {
            this.submitLoading = false;
            this.errorMessage = err.error.message;
          },
          complete: () => {
            this.router.navigate(['settings']);
          },
        });
    }
  }

  getOperators() {
    if (this.selectedDistrict) {
      this.districtOperatorService
        .getOperators(this.selectedDistrict.id)
        .subscribe((res) => {
          this.operators = res.items;
          this.operatorsLoading = false;
        });
    }
  }

  close() {
    this.selectOperatorModal.close();
  }

  selectOperator(newOperator: UserLiteDTO | null) {
    if (newOperator) {
      this.districtOperatorService
        .addOperator(newOperator.id)
        .subscribe((res) => {
          if (res) {
            this.operators.push(newOperator);
            this.selectOperatorModal.close();
          }
        });
    }
  }

  removeOperator(userId: number) {
    this.districtOperatorService.removeOperator(userId).subscribe((res) => {
      if (res) {
        this.operators = this.operators.filter(
          (ops: UserLiteDTO) => ops.id !== userId
        );
      }
    });
  }
}
