import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { VideoDTO } from 'src/app/common/dtos/video.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { videoDTOFromAPIResponse } from '../../helpers/translators/video.translators';
import { IntroSessionService } from '../../services/smart-coach/intro-session.service';

@Component({
  selector: 'app-intro-growe-process',
  templateUrl: './intro-growe-process.component.html',
  styleUrls: ['./intro-growe-process.component.scss'],
  standalone: false,
})
export class IntroGroweProcessComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  user: User;

  initialLearners: string;

  initialCoaching: string;

  egroweComplete = false;

  groweProcessVideo: VideoDTO;

  constructor(
    public introSessionService: IntroSessionService,
    private userService: UserService,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit() {
    this.introSessionService.getGroweProcessVideo().subscribe((res) => {
      if (res.item) {
        this.groweProcessVideo = videoDTOFromAPIResponse(res.item);
      }
    });

    this.subs.push(
      this.introSessionService.egroweComplete.subscribe((bool) => {
        this.egroweComplete = bool;
      })
    );

    this.setUserDetails();
  }

  setUserDetails() {
    if (this.user?.profile.goals_for_your_learners) {
      if (!this.egroweComplete) {
        this.completeGroweProcess();
      }
      this.initialLearners = this.user?.profile.goals_for_your_learners;
    } else {
      this.initialLearners = '';
    }

    if (this.user?.profile.goals_for_coaching) {
      this.initialCoaching = this.user?.profile.goals_for_coaching;
    } else {
      this.initialCoaching = '';
    }
  }

  completeGroweProcess() {
    if (!this.egroweComplete) {
      this.introSessionService.setEgroweComplete(true, true);
    }
  }

  saveUserProfileLearners() {
    if (this.initialLearners == null || this.initialLearners.length < 1) {
      return;
    }
    if (this.user) {
      this.user.profile.goals_for_your_learners = this.initialLearners;
      const payload = {
        profile: {
          goals_for_your_learners: this.user.profile.goals_for_your_learners,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {
        if (!this.egroweComplete) {
          this.completeGroweProcess();
        }
      });
    }
  }

  saveUserProfileCoaching() {
    if (this.initialCoaching == null || this.initialCoaching.length < 1) {
      return;
    }
    if (this.user) {
      this.user.profile.goals_for_coaching = this.initialCoaching;
      const payload = {
        profile: {
          goals_for_coaching: this.user.profile.goals_for_coaching,
        },
      };
      this.userService.updateUser(this.user.id, payload).subscribe(() => {});
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
}
