import { Component, Input } from '@angular/core';
import { ProfileDTO } from 'src/app/common/dtos/profile.dto';

@Component({
  selector: 'app-smart-coach-dialog-single',
  templateUrl: './coach-dialog-single.component.html',
  styleUrls: ['./coach-dialog-single.component.scss'],
  standalone: false,
})
export class SmartCoachDialogSingleComponent {
  @Input() coachProfile: ProfileDTO;

  @Input() message: string;
}
