import { Component } from '@angular/core';

import { FormFieldAbstractComponent } from '../base/edit-field-abstract';

@Component({
  selector: 'app-form-edit-media',
  templateUrl: './edit-media.component.html',
  styleUrls: ['../edit-field/form-edit-field.component.scss'],
  standalone: false,
})
export class FormEditMediaComponent extends FormFieldAbstractComponent {}
