/* eslint-disable class-methods-use-this */
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { TimezoneAbbreviation, guessTimezone } from '../utilities/time-helpers';

@Pipe({
  name: 'formatTime',
  standalone: false,
})
export class FormatTimePipe implements PipeTransform {
  /* eslint-disable class-methods-use-this */
  transform(
    value: number,
    outputFormat = 'h:mm a',
    timezone?: TimezoneAbbreviation,
    showTimezone = true
  ): string {
    if (!timezone) {
      timezone = guessTimezone();
    }
    const dateString = DateTime.fromSeconds(value).toFormat(outputFormat);
    if (showTimezone) {
      return `${dateString} ${timezone}`;
    }
    return dateString;
  }
}
