import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { deepCopy } from 'src/app/common/utilities/copy.helpers';
import { AssessmentDTO } from 'src/app/private/shared/dtos/attendee-rubric.dto';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import {
  CompetencyFieldOutput,
  Field,
  FieldType,
  FormCompetencyDTO,
  FormIndicatorDTO,
  FormIndicatorGroupDTO,
  FormIndicatorObservedDTO,
} from 'src/app/private/shared/dtos/forms.dto';
import { FormsService } from 'src/app/private/shared/services/forms/forms.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-view-competency',
  templateUrl: './view-competency.component.html',
  styleUrls: ['../view-field/form-view-field.component.scss'],
  standalone: false,
})
export class FormViewCompetencyComponent implements OnInit, OnDestroy {
  id = uuidv4();

  fieldTypes = FieldType;

  @Output() readonly value = new EventEmitter<CompetencyFieldOutput>();

  @Input() fieldDefinition: Field;

  assessments: AssessmentDTO[] = [];

  assessmentsToRemove: AssessmentDTO[] = [];

  userInput = '';

  formCompetency: FormCompetencyDTO;

  selectedCompetency: CompetencyDTO;

  indicatorsChecked: number[] = [];

  indicatorGroups: FormIndicatorGroupDTO[] = [];

  answer: FormIndicatorDTO[] = [];

  @Input() inputDisabled = false;

  @Input() readOnly = false;

  @Input() initialValue: FormIndicatorDTO[] | null;

  subs: Subscription[] = [];

  constructor(private formsService: FormsService) {}

  ngOnInit() {
    if (this.fieldDefinition.competency) {
      this.fetchIndicators();
    }
    if (this.formsService.isLiveFormSubmission) {
      this.subs.push(
        this.formsService.assessmentsList.subscribe((assessments) => {
          this.assessments = assessments;
        })
      );
    }
  }

  fetchIndicators() {
    this.formsService.getCompetencies();
    this.formsService.competencyList.subscribe((competencies) => {
      if (competencies) {
        const foundCompetency = competencies.find(
          (competency) => competency.id === this.fieldDefinition?.competency?.id
        );
        if (foundCompetency) {
          this.selectedCompetency = foundCompetency;
        }
      }
    });
    this.formsService.indicatorsList.subscribe((indicatorsList) => {
      indicatorsList.forEach((competencyDTO) => {
        if (
          competencyDTO.competencyId === this.fieldDefinition.competency?.id
        ) {
          this.formCompetency = competencyDTO;
          this.indicatorGroups = competencyDTO.indicatorSet.groups;
          if (this.initialValue) {
            this.answer = this.initialValue;
            this.setIndicatorsChecked();
          }
        }
      });
    });
    this.formsService.fetchIndicators(
      this.fieldDefinition.competency?.id as number,
      this.fieldDefinition.competency?.rubric_id as number
    );
  }

  setIndicatorsChecked() {
    if (this.answer.length > 0) {
      this.indicatorsChecked = this.answer.map((item) => item.id);
    } else {
      this.indicatorsChecked = [];
    }
  }

  outputAnswer(): void {
    let assessmentsToAdd: FormIndicatorObservedDTO[] = [];
    if (this.answer.length > 0) {
      assessmentsToAdd = deepCopy(this.answer).map((item: FormIndicatorDTO) => {
        let committedToDb = false;
        if (this.assessments.length > 0) {
          committedToDb = this.assessments.some(
            (assessment) => assessment.egroweIndicatorId === item.id
          );
        }
        const answerData: FormIndicatorObservedDTO = {
          ...item,
          committedToDb,
        };
        if (!answerData.committedToDb) {
          answerData.assesmentData = {
            indicatorId: item.id,
            rubricId: this.selectedCompetency.rubric_id,
            assesseeUserId: this.formsService.assessedUser.id as number,
            coachlogId: this.formsService.coachlogId,
          };
        }
        return answerData;
      });
    }
    this.value.emit({
      assessmentsToAdd,
      assessmentsToRemove: this.assessmentsToRemove,
    });
  }

  selectIndicator(indicator: FormIndicatorDTO) {
    if (this.inputDisabled) return;
    if (
      this.answer.length === 0 ||
      !this.answer.find((item) => item.id === indicator.id)
    ) {
      this.answer.push(indicator);
    } else {
      const assessment = this.assessments.find(
        (item) => item.egroweIndicatorId === indicator.id
      );
      if (assessment) {
        this.assessmentsToRemove.push(assessment as AssessmentDTO);
      }
      this.answer = this.answer.filter((item) => item.id !== indicator.id);
    }
    this.setIndicatorsChecked();
    this.outputAnswer();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
