import { Input, Component } from '@angular/core';

@Component({
  selector: 'app-quartile-progress-bar',
  templateUrl: './quartile-progress-bar.component.html',
  styleUrls: ['./quartile-progress-bar.component.scss'],
  standalone: false,
})
export class QuartileProgressBarComponent {
  @Input() progress: number | undefined = 0;

  @Input() goalLevel: number;

  @Input() highlightedBlockIndex: number[] = [];

  @Input() description: string;
}
