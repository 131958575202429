import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-current-competencies-progress-bar',
  templateUrl: './current-competencies-progress-bar.component.html',
  styleUrls: ['./current-competencies-progress-bar.component.scss'],
  standalone: false,
})
export class CurrentCompetenciesProgressBarComponent {
  @Input() progress = 0;

  @Input() goalLevel: number;

  @Input() highlightedBlockIndex: number[] = [];

  @Input() description: string;

  @Input() competencyIcon: string;
}
